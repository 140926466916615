import React from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.scss";
import { FaLongArrowAltDown } from "react-icons/fa";
import MiniCardImages from "../../ForHeroes/MiniCard/MiniCardImages";
import cx from "classnames";

const HeroSlot = ({ onClick, hero, index }) => {
  return (
    <motion.div
      className={styles.slot}
      onClick={onClick}
      whileTap={hero ? undefined : { scale: 0.95 }}
      initial={{ scale: 1 }}
      animate={!hero ? { scale: [1, 1.1, 1] } : undefined}
      transition={
        !hero
          ? {
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }
          : undefined
      }
    >
      {hero ? (
        <MiniCardImages character={hero} isHide />
      ) : (
        <FaLongArrowAltDown
          size={48}
          className={cx(styles.plusIcon, {
            [styles.rotateRight]: index === 0,
            [styles.rotateLeft]: index === 2,
          })}
        />
      )}
    </motion.div>
  );
};

export default HeroSlot;
