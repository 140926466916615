import React from "react";
import styles from "./styles.module.scss";

const RadioButton = ({ name, value, checked, onChange, label }) => {
  return (
    <label className={styles.optionLabel}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={styles.radio}
      />
      {label}
    </label>
  );
};

export default RadioButton;
