import { getImageUrl } from "../../utils/constants";

export const equipmentSlots = {
  left: ["head", "neck", "shoulders", "chest", "back", "main_hand"],
  right: ["hands", "finger_1", "finger_2", "legs", "feet", "off_hand"],
};

export const getSlotImage = (slotName) => {
  return `${getImageUrl()}inventory/${slotName}.webp`;
};

export const getNonZeroItemsAttributes = (item) => {
  const attrs = {
    agility: item.agility,
    charisma: item.charisma,
    endurance: item.endurance,
    intelligence: item.intelligence,
    luck: item.luck,
    strength: item.strength,
  };

  return Object.entries(attrs)
    .filter(([_, value]) => value > 0)
    .map(([key, value]) => ({ key, value }));
};
