import React from "react";
import styles from "./styles.module.scss";
import { useReferralHook } from "../../hooks/Pages/useReferralHook";
import ScrollableList from "./List/ScrollableList";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { FiCopy } from "react-icons/fi";
import { BsArrowUpCircle } from "react-icons/bs";
import { formatAmount } from "../../utils/formatNumbers";
import cx from "classnames";
import { PreloadImages } from "../../utils/globals";

const Referral = () => {
  const {
    users,
    isLoading,
    handleCopyLink,
    handleShareLink,
    referralAmount,
    loadMoreReferrals,
    page,
    totalEntries,
    currentStep,
    t,
  } = useReferralHook();

  if (isLoading && page === 1) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={cx(styles.wrapper, styles.fadeIn)}>
        <div className={styles.avatar}>
          <img
            src={PreloadImages.PROFILE_LIGHT}
            alt="Avatar"
            className={styles.avatarImage}
          />
        </div>
        <div className={styles.column}>
          <div className={styles.subheadline}>
            <span
              className={cx({
                [styles.active]: currentStep === 3,
              })}
            >
              {t("total_friends_invited")}
            </span>
          </div>
          <span className={styles.headline}>
            {formatAmount(totalEntries) || "0"}
          </span>
          {!!parseFloat(referralAmount?.reward?.amount) && (
            <div className={styles.reward}>
              {`${formatAmount(referralAmount?.reward?.amount)} ${
                referralAmount?.reward?.currency_code || ""
              }`}
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div
            className={cx(styles.linkButton, {
              [styles.activeNoPadding]: currentStep === 1,
            })}
            onClick={handleCopyLink}
          >
            <FiCopy size={20} />
            <span>{t("copy_referral_link")}</span>
          </div>
          <div
            className={cx(styles.linkButton, {
              [styles.activeNoPadding]: currentStep === 2,
            })}
            onClick={handleShareLink}
          >
            <BsArrowUpCircle size={20} />
            <span>Send via Telegram</span>
          </div>
        </div>
        <span>{t("friends_history")}</span>
        <ScrollableList
          users={users}
          loadMore={loadMoreReferrals}
          isLoading={isLoading}
          page={page}
        />
      </div>
    </>
  );
};

export default Referral;
