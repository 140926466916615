// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_optionLabel__\\+1Bj0 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

.styles_radio__kQ0Dx {
  width: 20px;
  height: 20px;
  border: 2px solid var(--border-color);
  border-radius: 50%;
  background: #2e3a4a;
  cursor: pointer;
  appearance: none;
  transition: all 0.3s ease-in-out;
}
.styles_radio__kQ0Dx:checked {
  background: radial-gradient(circle, #ffcc00, #ff8c00);
  border-color: var(--subhead-color);
  box-shadow: 0 4px 8px rgba(255, 215, 0, 0.8), 0 0 12px rgba(255, 165, 0, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/RadioButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,wBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,qCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,gCAAA;AACF;AACE;EACE,qDAAA;EACA,kCAAA;EACA,6EAAA;AACJ","sourcesContent":[".optionLabel {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 1rem;\n  color: var(--text-color);\n  cursor: pointer;\n}\n\n.radio {\n  width: 20px;\n  height: 20px;\n  border: 2px solid var(--border-color);\n  border-radius: 50%;\n  background: #2e3a4a;\n  cursor: pointer;\n  appearance: none;\n  transition: all 0.3s ease-in-out;\n\n  &:checked {\n    background: radial-gradient(circle, #ffcc00, #ff8c00);\n    border-color: var(--subhead-color);\n    box-shadow: 0 4px 8px rgba(255, 215, 0, 0.8), 0 0 12px rgba(255, 165, 0, 0.6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionLabel": `styles_optionLabel__+1Bj0`,
	"radio": `styles_radio__kQ0Dx`
};
export default ___CSS_LOADER_EXPORT___;
