import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket } from "phoenix";
import { socketApiUrl } from "./globals";

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children, telegramData, user }) => {
  const [socket, setSocket] = useState(null);
  const [userChannel, setUserChannel] = useState(null);
  const [lobbyChannel, setLobbyChannel] = useState(null);

  const initializeSocket = () => {
    if (!telegramData?.initData || !user) return;

    let decodedInitData;
    try {
      decodedInitData = decodeURIComponent(telegramData.initData);
    } catch (error) {
      console.error("error", error);
      decodedInitData = telegramData.initData;
    }

    let socketInstance;
    try {
      socketInstance = new Socket(`${socketApiUrl}/socket`, {
        params: { init_data: decodedInitData },
      });

      socketInstance.connect();
      setSocket(socketInstance);
    } catch (error) {
      console.error("error", error);
      return;
    }

    const lobbyChannelInstance = socketInstance.channel("user:lobby", {});

    lobbyChannelInstance
      .join()
      .receive("ok", (resp) => {
        console.log("lobby channel success", resp);
        setLobbyChannel(lobbyChannelInstance);
        joinUserChannel(resp.user_id, socketInstance);
      })
      .receive("error", (resp) => {
        console.error("lobby channel error", resp);
      });
  };

  const disconnectAll = () => {
    leaveChannel(lobbyChannel);
    leaveChannel(userChannel);
    if (socket) {
      socket.disconnect();
    }
    setSocket(null);
    setLobbyChannel(null);
    setUserChannel(null);
  };

  const joinUserChannel = (userId, socketInstance) => {
    if (!socketInstance || userChannel || userId.startsWith("anon")) return;

    try {
      const userChannelInstance = socketInstance.channel(`user:${userId}`, {});

      userChannelInstance
        .join()
        .receive("ok", (resp) => {
          console.log("user channel success", resp);
          setUserChannel(userChannelInstance);
        })
        .receive("error", (resp) => {
          console.error("user channel error", resp);
        });
    } catch (error) {
      console.error("user channel success:", error);
    }
  };

  const leaveChannel = (channel) => {
    if (channel) {
      channel.leave().receive("ok", () => {
        console.log("chanel leaved");
      });
    }
  };

  const connectToUserChannel = (userId) => {
    if (socket) {
      joinUserChannel(userId, socket);
    }
  };

  useEffect(() => {
    return () => {
      disconnectAll();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        userChannel,
        lobbyChannel,
        connectToUserChannel,
        initializeSocket,
        disconnectAll,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
