import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { formatNumber } from "../../utils/formatNumbers";
import { getLeaderImage } from "../../utils/imagesGetter";

const RatingCell = ({ user, isCurrentUser, isGuide }) => {
  const rank = user?.rank;
  const balance = formatNumber(user?.total_stats);
  const username = user?.username || "-";
  const imageSrc = getLeaderImage(rank);
  const isLeader = rank < 2;
  const amountClass = cx(styles.amount, { [styles.leaderAmount]: isLeader });

  return (
    <div
      className={cx(styles.cell, {
        [styles.fadeIn]: isCurrentUser,
        [styles.userCell]: isCurrentUser,
        [styles.active]: rank > 3 && isGuide,
      })}
    >
      <div className={styles.cellContent}>
        <div className={styles.leftSection}>
          <div
            className={cx(styles.imageWrapper, { [styles.leader]: isLeader })}
          >
            {imageSrc && (
              <img src={imageSrc} alt="leader" className={styles.image} />
            )}
            {rank > 3 && (
              <span
                className={cx(styles.rankText, rank > 999 && styles.customText)}
              >
                {rank || "-"}
              </span>
            )}
          </div>
          <span
            className={cx(styles.username, { [styles.leaderAmount]: isLeader })}
          >
            {username}
          </span>
        </div>
        <div className={styles.rightSection}>
          <span className={amountClass}>{balance}</span>
        </div>
      </div>
    </div>
  );
};

export default RatingCell;
