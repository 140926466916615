import React from "react";
import styles from "./nft.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import Button from "../../components/ui/Button/Button";
import { GetGemsIcon } from "../../assets/icons";
import { SiTon } from "react-icons/si";
import { useNftPageHook } from "../../hooks/Pages/useNftPageHook";

const NFTList = () => {
  const {
    loading,
    nftLoading,
    userWallet,
    handleGoToInventory,
    t,
    error,
    nfts,
    handleClick,
    handleGoToCollection,
  } = useNftPageHook();

  if (loading || nftLoading)
    return (
      <div className={styles.loader}>
        <LoaderComponent />
      </div>
    );

  if (!userWallet)
    return (
      <div className={styles.loader}>
        <span className={styles.noLinkedText}>{t("walletNotLinked")}</span>
        <Button onClick={handleGoToInventory}>
          <SiTon />
          {t("connect_wallet")}
        </Button>
      </div>
    );

  if (error)
    return (
      <div className={styles.error}>
        <span>
          {t("error")}: {error}
        </span>
      </div>
    );

  return (
    <div className={styles.container}>
      {!nfts?.length ? (
        <div className={styles.noData}>
          <span className={styles.text}>{t("itsEmpty")}</span>
        </div>
      ) : (
        <ul className={styles.list}>
          {nfts.map((nft) => (
            <li
              key={nft.address}
              className={styles.item}
              onClick={() => handleClick(nft)}
            >
              <div className={styles.imageWrapper}>
                {nft.metadata?.image && (
                  <img
                    src={nft.metadata.image}
                    alt={nft.metadata.name || "NFT"}
                    className={styles.image}
                  />
                )}
              </div>
              <div className={styles.details}>
                <h3 className={styles.nftName}>
                  {nft.metadata?.name || "Unnamed NFT"}
                </h3>
              </div>
            </li>
          ))}
        </ul>
      )}
      <Button onClick={handleGoToCollection}>
        {t("openCollection")}
        <GetGemsIcon />
      </Button>
    </div>
  );
};

export default NFTList;
