// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestTimer_progress__psYq4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transform-origin: center;
}
.QuestTimer_progress__psYq4 .QuestTimer_CircularProgressbar__vUaRm {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ForQuests/QuestTimer/QuestTimer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".progress {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 30px;\n  height: 30px;\n  transform-origin: center;\n  .CircularProgressbar {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `QuestTimer_progress__psYq4`,
	"CircularProgressbar": `QuestTimer_CircularProgressbar__vUaRm`
};
export default ___CSS_LOADER_EXPORT___;
