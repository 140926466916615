import React from "react";
import styles from "./styles.module.scss";
import { getGradientByClassName } from "../../../utils/imagesGetter";
import LoaderComponent from "../../ui/LoaderComponent/LoaderComponent";
import cx from "classnames";
import { getItemTranslation } from "../../../utils/utils";
import InventoryFilters from "../InventoryFilters/InventoryFilters";

const HeroInventory = ({
  items,
  isDataLoading,
  lastItemElementRef,
  page,
  isInventory,
  inventory,
  handleItemClick,
  currentInventorySlot,
  filters,
  setFilters,
  userFilters,
  pathname,
  resetFilters,
}) => {
  return (
    <div className={styles.container}>
      {currentInventorySlot && (
        <span className={styles.title}>
          {getItemTranslation(currentInventorySlot)}
        </span>
      )}
      <InventoryFilters
        isInventory={isInventory}
        filters={filters}
        setFilters={setFilters}
        userFilters={userFilters}
        pathname={pathname}
        resetFilters={resetFilters}
      />
      {isDataLoading && page === 1 ? (
        <div
          className={cx(styles.loader, {
            [styles.inventoryLoader]: isInventory,
          })}
        >
          <LoaderComponent />
        </div>
      ) : (
        <div className={styles.wrapper}>
          {inventory.map((_, index) => {
            const item = items[index];
            const isLastItem = index === items?.length - 1;

            return (
              <div
                key={index}
                className={cx(styles.item, {
                  [styles.disabledItem]: !!item?.user_hero && !isInventory,
                })}
                ref={isLastItem && item ? lastItemElementRef : null}
                style={
                  item
                    ? {
                        backgroundImage: getGradientByClassName(
                          item.rarity_level,
                        )?.background,
                      }
                    : {
                        backgroundImage: `radial-gradient(circle, rgb(204, 204, 204) 0%, black 100%)`,
                        filter: "brightness(50%)",
                      }
                }
                onClick={item ? () => handleItemClick(item) : undefined}
              >
                {item && (
                  <>
                    <img src={item?.item_image_url} alt={item?.item_name} />
                    <div className={styles.level}>{`Lv. ${item?.level}`}</div>
                    {item?.user_hero?.preview_url && (
                      <img
                        src={item?.user_hero?.preview_url}
                        className={styles.heroImg}
                        alt={"hero"}
                      />
                    )}
                    {item?.class_image_url && (
                      <img
                        width={20}
                        height={20}
                        alt={"class"}
                        src={item?.class_image_url}
                        className={styles.classImageUrl}
                      />
                    )}
                    {item?.type === "double_handed" && !isInventory && (
                      <span className={styles.type}>
                        {getItemTranslation(item?.type)}
                      </span>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HeroInventory;
