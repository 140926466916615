import cx from "classnames";
import styles from "./styles.module.scss";
import { formatAmount } from "../../utils/formatNumbers";
import React from "react";

const TransactionCell = ({
  transaction,
  isLast,
  refCallback,
  getDate,
  getIsSentTransaction,
  t,
}) => {
  const isSent = getIsSentTransaction(transaction);
  const amountClass = cx(styles.amount, { [styles.received]: !isSent });

  return (
    <div className={styles.cell} ref={isLast ? refCallback : null}>
      <div className={styles.cellContent}>
        <div className={styles.description}>{transaction.description}</div>
        <div className={styles.date}>{getDate(transaction.inserted_at)}</div>
      </div>
      <div className={styles.cellAfter}>
        <div className={amountClass}>
          {`${!isSent ? "+" : ""}${formatAmount(transaction?.value?.amount)} ${transaction?.value?.currency_code}`}
        </div>
        <div className={amountClass}>{isSent ? t("Sent") : t("Received")}</div>
      </div>
    </div>
  );
};

export default TransactionCell;
