import React from "react";
import cx from "classnames";
import { BsArrowRight } from "react-icons/bs";
import { StarIcon } from "../../assets/icons";
import styles from "./common.module.scss";
import { formatAmount } from "../../utils/formatNumbers";

const MarketCard = ({
  item,
  handleCardClick,
  handleChipClick,
  t,
  currentStep,
}) => {
  const renderSaleStarSubtitle = () => (
    <div className={styles.starsRow}>
      <span className={styles.strikethrough}>
        {formatAmount(item?.final_price_no_sale?.amount)}
      </span>
      <BsArrowRight color={"white"} />
      <span className={styles.prize}>
        {formatAmount(item?.final_price?.amount)}
      </span>
      <StarIcon className={styles.star} size={15} />
    </div>
  );

  const renderSaleSubtitle = () => (
    <div className={styles.noWrap}>
      <span className={styles.strikethrough}>
        {formatAmount(item?.final_price_no_sale?.amount)}
      </span>
      <BsArrowRight color={"white"} className={styles.arrowIcon} />
      <span className={styles.prize}>
        {formatAmount(item?.final_price?.amount)}
      </span>
      <span className={styles.currency}>{item?.price?.currency_code}</span>
    </div>
  );

  const renderSubtitle = () => {
    return item?.price?.currency_code === "STAR"
      ? renderSaleStarSubtitle()
      : renderSaleSubtitle();
  };

  const renderDefaultSubtitle = () => (
    <div
      className={cx(styles.row, {
        [styles.notCenter]: item?.price?.currency_code === "STAR",
      })}
    >
      <span className={styles.prize}>{formatAmount(item.price.amount)}</span>
      {item?.price?.currency_code === "STAR" && (
        <StarIcon className={styles.star} size={15} />
      )}
      {item?.price?.currency_code !== "STAR" && (
        <span className={styles.currency}>{item?.price?.currency_code}</span>
      )}
    </div>
  );

  return (
    <div
      className={cx(styles.card, {
        [styles.disabledCard]: item?.disabled_until,
      })}
      onClick={() => handleCardClick(item)}
    >
      <div
        className={cx(styles.chip, { [styles.active]: currentStep === 2 })}
        onClick={(e) => handleChipClick(e, item)}
      >
        {item.status === "coming_soon" ? t("why_click_here") : t("Buy")}
      </div>
      <img className={styles.cardImg} alt={item.title} src={item.image_url} />
      <div className={styles.cardCell}>
        <span className={styles.text}>{item.title}</span>
        {item.sale ? renderSubtitle() : renderDefaultSubtitle()}
      </div>
    </div>
  );
};

export default MarketCard;
