// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_listWrapper__z2hgF {
  position: relative;
  height: calc(100% - 320px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_list__xoWO3 {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
}
.styles_list__xoWO3::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.styles_cell__Cz8tD {
  padding: 10px;
  cursor: unset;
}

.styles_cell__Cz8tD:hover {
  background: var(--tgui--bg_color);
}

.styles_avatar__FDyTP {
  background: var(--tgui--bg_color);
}
.styles_avatar__FDyTP > img {
  object-fit: contain;
}

.styles_noWrap__l3OzL {
  white-space: nowrap;
}

.styles_noData__Kmr5H {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 16px 16px 16px;
  text-align: center;
  height: 100%;
  width: 100%;
}
.styles_noData__Kmr5H > img {
  width: 100%;
  height: 50%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/pages/Referral/List/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,0BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EAMA,qBAAA;AAJF;AADE;EACE,QAAA;EACA,SAAA;AAGJ;;AAGA;EACE,aAAA;EACA,aAAA;AAAF;;AAEA;EACE,iCAAA;AACF;;AAEA;EACE,iCAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,mBAAA;AAEJ","sourcesContent":[".listWrapper {\n  position: relative;\n  height: calc(100% - 320px);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  overflow: auto;\n  height: 100%;\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  scrollbar-width: none;\n}\n\n.cell {\n  padding: 10px;\n  cursor: unset;\n}\n.cell:hover {\n  background: var(--tgui--bg_color);\n}\n\n.avatar {\n  background: var(--tgui--bg_color);\n  > img {\n    object-fit: contain;\n  }\n}\n\n.noWrap {\n  white-space: nowrap;\n}\n\n.noData {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 12px;\n  padding: 0 16px 16px 16px;\n  text-align: center;\n  height: 100%;\n  width: 100%;\n  > img {\n    width: 100%;\n    height: 50%;\n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listWrapper": `styles_listWrapper__z2hgF`,
	"list": `styles_list__xoWO3`,
	"cell": `styles_cell__Cz8tD`,
	"avatar": `styles_avatar__FDyTP`,
	"noWrap": `styles_noWrap__l3OzL`,
	"noData": `styles_noData__Kmr5H`
};
export default ___CSS_LOADER_EXPORT___;
