import React from "react";
import styles from "./styles.module.scss";
import { useTaskHook } from "../../hooks/Pages/useTaskHook";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import ValidatedTask from "./ValidatedTask";
import SimpleTask from "./SimpleTask";
import { MdDone } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
import { tasksTypes } from "../../utils/tasksUtils";

const Task = () => {
  const {
    task,
    accountName,
    handleSetAccountName,
    isLoading,
    isValidatedTask,
    t,
    handleSubmitTask,
    isButtonDisabled,
    handleNavigate,
  } = useTaskHook();

  if (isLoading || !task) return <LoaderComponent />;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.taskCell}>
          <div className={styles.before}>
            <img className={styles.taskImage} src={task.image_url} alt="task" />
          </div>
          <div className={styles.cellMain}>
            <span className={styles.taskTitle}>{task.title || ""}</span>
            <div className={styles.amountRow}>
              <img
                className={styles.coinImage}
                src={PreloadImages.COIN_ICON}
                alt="coin"
              />
              <span className={styles.amount}>
                +{formatAmount(task.money_reward.amount)}
              </span>
            </div>
          </div>
          <div className={styles.after}>
            {task.status === "started" &&
            task?.machine_name !== tasksTypes.complete_onboarding ? (
              <FaExternalLinkAlt
                onClick={handleNavigate}
                className={styles.linkIcon}
              />
            ) : null}
          </div>
        </div>

        {task.status === "finished" && (
          <div className={styles.tabList}>
            <button className={`${styles.button} ${styles.buttonFinished}`}>
              <div className={styles.buttonRow}>
                {t("Finished")}
                <MdDone size={24} />
              </div>
            </button>
          </div>
        )}

        {task.status !== "finished" && (
          <div className={styles.taskDescription}>{task.description}</div>
        )}

        {isValidatedTask && task.status !== "finished" && (
          <ValidatedTask
            t={t}
            task={task}
            handleSubmitTask={handleSubmitTask}
            accountName={accountName}
            handleSetAccountName={handleSetAccountName}
            buttonTitle={task.button_text}
            isButtonDisabled={isButtonDisabled}
          />
        )}

        {!(
          task.status === "finished" &&
          task.machine_name === tasksTypes.complete_onboarding
        ) &&
          (!isValidatedTask || task.status === "finished") && (
            <SimpleTask
              task={task}
              handleSubmitTask={handleSubmitTask}
              isButtonDisabled={isButtonDisabled}
              t={t}
            />
          )}
      </div>
    </>
  );
};

export default Task;
