// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__TPcIp {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: 0;
  animation: styles_fadeInAnimation__LCAXy 1s forwards;
}

.styles_image__eG47u {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.styles_button__GXWC3 {
  margin-top: auto;
}

@keyframes styles_fadeInAnimation__LCAXy {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__s-1pr {
  opacity: 0;
  animation: styles_fadeInAnimation__LCAXy 1s forwards;
}`, "",{"version":3,"sources":["webpack://./src/pages/TreasureOpen/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;EACA,oDAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,UAAA;EACA,oDAAA;AAAF","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  object-fit: contain;\n}\n\n.button {\n  margin-top: auto;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__TPcIp`,
	"fadeInAnimation": `styles_fadeInAnimation__LCAXy`,
	"image": `styles_image__eG47u`,
	"button": `styles_button__GXWC3`,
	"fadeIn": `styles_fadeIn__s-1pr`
};
export default ___CSS_LOADER_EXPORT___;
