import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import paths from "../../../pages/paths";
import { FaPlus } from "react-icons/fa";

const QuestHeroes = ({
  selectedHeroes,
  handleOpenHeroesList,
  quest,
  handleRemoveHero,
  navigate,
  currentStep,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={cx(styles.fadeIn, styles.heroesText)}>
        {["started", "finished", "completed"].includes(quest.status)
          ? t("Your heroes")
          : t("Choose your heroes")}
      </span>

      {!selectedHeroes?.length && quest.status !== "completed" && (
        <div
          className={cx(styles.addHeroButton, {
            [styles.activeSelector]: currentStep === 2,
          })}
          onClick={handleOpenHeroesList}
        >
          <FaPlus color={"#f5e1a4"} />
        </div>
      )}

      <div className={styles.heroesRow}>
        {!!selectedHeroes?.length &&
          selectedHeroes.map((hero) => (
            <div key={hero.id} className={styles.hero}>
              <div className={cx(styles.selectedHero, styles.fadeIn)}>
                <div
                  className={styles.heroImageContainer}
                  onClick={() => {
                    if (["created", "failed"].includes(quest.status)) return;
                    navigate(`${paths.heroDetail}`, {
                      state: { hero: hero, isFromQuest: true },
                    });
                  }}
                >
                  <img
                    src={hero?.hero_preview_url}
                    alt={hero.hero_name}
                    className={cx(styles.heroImg, styles.fadeIn)}
                  />

                  {quest.status !== "finished" &&
                    quest.status !== "started" &&
                    quest.status !== "completed" && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveHero(hero.id);
                        }}
                        className={styles.clearIcon}
                      >
                        <IoCloseCircleOutline />
                      </div>
                    )}
                </div>
              </div>
              <span className={styles.heroName}>{hero.hero_name}</span>
            </div>
          ))}
      </div>
    </>
  );
};

export default QuestHeroes;
