import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../Layout/useTelegramHook";
import { fetchUserData } from "../../services/registrationService";
import useLoaderHook from "../Layout/useLoaderHook";
import { getNextQuestTime } from "../../services/questsService";
import { getUserBadges } from "../../services/userService";
import { PreloadImages } from "../../utils/globals";
import paths from "../../pages/paths";
import { getGiveaways } from "../../services/giveawaysService";
import {
  setIsGuider,
  setPosition,
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import { setIsPlaying } from "../../store/slices/telegramSlice";
import { updateUserSettings } from "../../services/settingsService";
import useGuide from "./useGuideHook";
import { mainPageSteps } from "../../utils/guideSteps";

export const useMainPageHook = () => {
  const { t } = useTranslation();
  const { tg } = useTelegram();
  useLoaderHook();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const tutorials = useSelector((state) => state.guide.tutorials);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [nextQuestTime, setNextQuestTime] = useState(null);
  const [badges, setBadges] = useState(null);
  const [isTimerLoading, setIsTimerLoading] = useState(true);
  const [giveawayBanner, setGiveawayBanner] = useState(null);

  useEffect(() => {
    tg.BackButton.hide();

    fetchUserData(dispatch).then(() => setIsLoading(false));

    getUserBadges().then(setBadges);

    getQuestsCount();

    getGiveaways(0, 10, "active").then((r) => {
      setGiveawayBanner(
        r?.data?.data?.find((item) => item?.pool_currency === "NOT"),
      );
    });
  }, []);

  const getQuestsCount = () => {
    if (!isTimerLoading) setIsTimerLoading(true);
    getNextQuestTime()
      .then((r) => {
        const { next_quests_generation_at } = r;
        setNextQuestTime(next_quests_generation_at);
      })
      .finally(() => setIsTimerLoading(false));
  };

  const handleRedirect = (path) => {
    if (!path) {
      if (hapticEnabled) {
        tg.HapticFeedback.notificationOccurred("error");
        return;
      }
    }
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(path);
  };

  const checkBadge = (type) =>
    badges?.find((badge) => badge.type === type)?.badge;

  const leftSideIcons = [
    {
      src: PreloadImages.INVENTORY_ICON,
      onClick: () => handleRedirect(paths.inventory),
      badge: checkBadge("market_items"),
      label: t("Inventory"),
    },
    {
      src: PreloadImages.GIVEAWAYS_ICON,
      onClick: () => handleRedirect(paths.giveaways),
      label: t("Giveaways"),
    },
    {
      src: PreloadImages.SHOP_ICON,
      onClick: () => handleRedirect(paths.market),
      badge: checkBadge("market"),
      label: t("Market"),
    },
    {
      src: PreloadImages.QUEST_ICON,
      onClick: () => handleRedirect(paths.quests),
      badge: checkBadge("quests"),
      label: t("Quests"),
    },
    {
      src: PreloadImages.DUNGEON_ICON,
      onClick: () => handleRedirect(""),
      text: t("Coming soon"),
      label: t("Dungeons"),
    },
  ];

  const rightSideIcons = [
    {
      src: PreloadImages.SETTINGS_ICON,
      onClick: () => handleRedirect(paths.settings),
      label: t("Settings"),
    },
    {
      src: PreloadImages.TASKS_ICON,
      onClick: () => handleRedirect(paths.tasks),
      badge: checkBadge("tasks"),
      label: t("Tasks"),
    },
    {
      src: PreloadImages.REFERRAL_ICON,
      onClick: () => handleRedirect(paths.referral),
      label: t("Referrals"),
    },
    {
      src: PreloadImages.TRANSACTIONS_HISTORY_ICON,
      onClick: () => handleRedirect(paths.transactions),
      label: t("Transaction History"),
    },
    {
      src: PreloadImages.CAULDRON_ICON,
      onClick: () => handleRedirect(""),
      text: t("Coming soon"),
      label: t("Cauldron"),
    },
  ];

  const handleGoToQuests = () => {
    handleRedirect(paths.quests);
  };

  const handleCastleClick = () => {
    if (hapticEnabled) {
      tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleGoToGiveaway = () => {
    navigate(paths.giveaway, { state: giveawayBanner?.id });
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  const handleGoToInventory = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.inventory);
  };

  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    setIsExist(tutorials?.includes("isExist") || false);
  }, [tutorials]);

  const steps = useMemo(() => mainPageSteps(isExist), [isExist]);

  const selectedActionMap = useMemo(() => {
    return isExist
      ? {
          2: (dispatch) => dispatch(toggleHideBackground()),
          3: (dispatch) => {
            dispatch(toggleShowBackground());
            dispatch(setIsPlaying(true));
          },
          5: (dispatch) => {
            dispatch(toggleHideBackground());
            dispatch(setIsGuider(false));
          },
          7: (dispatch) => {
            dispatch(setIsGuider(true));
            dispatch(setPosition("top"));
            updateUserSettings({
              tutorials: tutorials
                .filter((item) => item !== "isExist")
                .concat([paths.index]),
            });
          },
        }
      : {
          2: (dispatch) => dispatch(toggleHideBackground()),
          3: (dispatch) => {
            dispatch(toggleShowBackground());
            dispatch(setIsPlaying(true));
          },
          5: (dispatch) => {
            dispatch(toggleHideBackground());
            dispatch(setIsGuider(false));
          },
          7: (dispatch) => {
            dispatch(setIsGuider(true));
            dispatch(setPosition("top"));
          },
          8: (dispatch) => dispatch(setPosition("bottom")),
          9: () => handleGoToInventory(),
        };
  }, [isExist, tutorials]);

  const { currentStep } = useGuide(steps, selectedActionMap);

  return {
    isLoading,
    isTimerLoading,
    t,
    getQuestsCount,
    nextQuestTime,
    leftSideIcons,
    rightSideIcons,
    handleGoToQuests,
    handleCastleClick,
    giveawayBanner,
    handleGoToGiveaway,
    handleGoToInventory,
    currentStep,
  };
};
