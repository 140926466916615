import React from "react";
import styles from "./styles.module.scss";
import { getCardImagesByRarity } from "../../../utils/imagesGetter";
import cx from "classnames";
import HeroCardActions from "../HeroCardActions/HeroCardActions";
import Button from "../../ui/Button/Button";

const HeroCard = ({
  character,
  allLoaded,
  isImageLoaded,
  handleImageLoad,
  handleImageError,
  handleOpenDetailPage,
  onClickFavorite,
  currentStep,
  isMinting,
  handleMint,
  isWithoutActions,
  contactSupport,
  handleShare,
}) => {
  return (
    <>
      <div
        onClick={handleOpenDetailPage}
        className={cx(styles.imagesWrapper, {
          [styles.visible]: allLoaded,
          [styles.isMinting]: isMinting,
        })}
      >
        <img
          alt="mainCard"
          src={getCardImagesByRarity(character?.rarity_level).FRONT.MAIN}
          className={cx(styles.cardWrapper, {
            [styles.hidden]: !isImageLoaded.mainCard,
          })}
          onLoad={() => handleImageLoad("mainCard")}
          onError={() => handleImageError("mainCard")}
        />
        <img
          alt="texture"
          src={getCardImagesByRarity(character?.rarity_level).FRONT.TEXTURE}
          className={cx(styles.cardTexture, {
            [styles.hidden]: !isImageLoaded.texture,
          })}
          onLoad={() => handleImageLoad("texture")}
          onError={() => handleImageError("texture")}
        />
        <img
          className={cx(styles.heroImg, {
            [styles.hidden]: !isImageLoaded.hero,
          })}
          alt="hero"
          src={character?.hero_preview_url}
          onLoad={() => handleImageLoad("hero")}
          onError={() => handleImageError("hero")}
        />
        {!isMinting && !isWithoutActions && (
          <HeroCardActions
            character={character}
            onClickFavorite={onClickFavorite}
            currentStep={currentStep}
            handleOpenDetailPage={handleOpenDetailPage}
            handleMint={handleMint}
            handleShare={handleShare}
          />
        )}
      </div>
      {isMinting && allLoaded && (
        <div className={styles.mintingContainer}>
          <span>{`Minting status: ${isMinting}`}</span>
          {isMinting === "blocked" && (
            <Button onClick={contactSupport}>Contact support</Button>
          )}
        </div>
      )}
    </>
  );
};

export default HeroCard;
