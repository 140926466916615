import { setFinishedQuestId } from "../store/slices/socketSlice";
import paths from "../pages/paths";
import {
  showActionToast,
  showErrorToast,
  showSuccessToast,
} from "../components/ui/Toast/Toast";

export const eventHandlers = [
  {
    eventName: "quests_created",
    channel: "lobbyChannel",
    handler: (payload, context) => {
      const { t, navigate } = context;
      showActionToast(t("Quests have been updated"), () => navigate(`/quests`));
    },
  },
  {
    eventName: "successful_payment",
    channel: "userChannel",
    handler: (payload, context) => {
      const pathname = window.location.pathname;
      const { t, navigate } = context;
      if ([paths.marketItem].includes(pathname)) {
        return;
      }
      if (payload?.content_type === "mint") {
        showSuccessToast(t("Your purchase was successful"));
        return;
      }
      if (payload?.content_type === "user_quests") {
        showActionToast(t("Your purchase was successful"), () =>
          navigate(paths.quests),
        );
        return;
      }
      if (
        payload?.content_type === "user_market_item" &&
        payload?.market_item_type === "hero_items_lootbox"
      ) {
        showActionToast(t("Your purchase was successful"), () =>
          navigate(paths.treasureOpen, {
            state: { id: payload?.user_market_item_id },
          }),
        );
        return;
      }
      if (
        payload?.content_type === "user_market_item" &&
        payload?.market_item_type === "hero_lootbox"
      ) {
        showActionToast(t("Your purchase was successful"), () =>
          navigate(paths.roulette, {
            state: { id: payload?.user_market_item_id },
          }),
        );
      }
    },
  },
  {
    eventName: "mint_completed",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t, navigate } = context;
      showActionToast(t("heroMinted"), () =>
        navigate("/inventory", { state: { activeType: "nft" } }),
      );
    },
  },
  {
    eventName: "mint_failed",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t } = context;
      showErrorToast(t("mintFailed"));
    },
  },
  {
    eventName: "quest_finished",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t, navigate, dispatch } = context;
      const pathname = window.location.pathname;
      const id = pathname.split("/").pop();
      if (payload?.quest_id && payload?.quest_id !== id) {
        dispatch(setFinishedQuestId(payload.quest_id));
        showActionToast(t("You have completed the quest"), () =>
          navigate(`/quest/${payload.quest_id}`),
        );
      }
    },
  },
];
