// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_settings__7Q8Il {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 100%;
  height: 100%;
  justify-content: center;
  animation: styles_fadeInAnimation__C-Grd 1s forwards;
}

.styles_table__iJMs9 {
  display: table;
  width: 100%;
}

.styles_row__N4eub {
  display: table-row;
  height: 60px;
}

.styles_labelCell__fHaWV,
.styles_controlCell__mKC-2 {
  display: table-cell;
  vertical-align: middle;
}

.styles_labelCell__fHaWV {
  white-space: nowrap;
  width: 1%;
  padding: 8px 15px 8px 0;
}

.styles_controlCell__mKC-2 {
  width: 100%;
  padding: 8px 0 8px 15px;
}

.styles_range__nH8l- {
  width: 100%;
}

.styles_fixedLayout__nzG2D {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.styles_confirmationButtons__JUIyt {
  display: flex;
  gap: 8px;
}

.styles_button__INd-e {
  height: 40px;
  width: 60px;
}

.styles_resetButton__Foxcd {
  height: 40px;
}

@keyframes styles_fadeInAnimation__C-Grd {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,oDAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA;;EAEE,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,SAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".settings {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.table {\n  display: table;\n  width: 100%;\n}\n\n.row {\n  display: table-row;\n  height: 60px;\n}\n\n.labelCell,\n.controlCell {\n  display: table-cell;\n  vertical-align: middle;\n}\n\n.labelCell {\n  white-space: nowrap;\n  width: 1%;\n  padding: 8px 15px 8px 0;\n}\n\n.controlCell {\n  width: 100%;\n  padding: 8px 0 8px 15px;\n}\n\n.range {\n  width: 100%;\n}\n\n.fixedLayout {\n  display: flex;\n  justify-content: center;\n  padding: 16px;\n}\n\n.confirmationButtons {\n  display: flex;\n  gap: 8px;\n}\n\n.button {\n  height: 40px;\n  width: 60px;\n}\n\n.resetButton {\n  height: 40px;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `styles_settings__7Q8Il`,
	"fadeInAnimation": `styles_fadeInAnimation__C-Grd`,
	"table": `styles_table__iJMs9`,
	"row": `styles_row__N4eub`,
	"labelCell": `styles_labelCell__fHaWV`,
	"controlCell": `styles_controlCell__mKC-2`,
	"range": `styles_range__nH8l-`,
	"fixedLayout": `styles_fixedLayout__nzG2D`,
	"confirmationButtons": `styles_confirmationButtons__JUIyt`,
	"button": `styles_button__INd-e`,
	"resetButton": `styles_resetButton__Foxcd`
};
export default ___CSS_LOADER_EXPORT___;
