import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useEffect, useMemo, useState } from "react";
import { getUserQuestById } from "../../services/questsService";
import { getHeroes } from "../../services/heroService";
import paths from "../../pages/paths";
import { fetchUserData } from "../../services/registrationService";
import {
  getQuestChance,
  getQuestResult,
  startQuest,
} from "../../services/questService";
import { useSocket } from "../../utils/socketContext";
import { useTelegram } from "../Layout/useTelegramHook";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getQuestSteps } from "../../utils/guideSteps";

export const useQuestHook = () => {
  const dispatch = useDispatch();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const navigate = useNavigate();
  const { id } = useParams();
  const { tg } = useTelegram();
  const { userChannel, lobbyChannel } = useSocket();

  useTelegramBackButton(-1);

  const [isLoading, setIsLoading] = useState(true);
  const [isSelect, setIsSelect] = useState(false);
  const [quest, setQuest] = useState(null);
  const [selectedHeroes, setSelectedHeroes] = useState([]);
  const [questChance, setQuestChance] = useState(null);
  const [isChanceLoading, setIsChanceLoading] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [levelUpInfo, setLevelUpInfo] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [prize, setPrize] = useState(null);

  const handleCloseModal = () => {
    setPrize(null);
  };

  const handleCloseHeroes = (isClearChance) => {
    setIsSelect(false);
    if (isClearChance) setQuestChance(null);
  };

  const getData = () => {
    if (!isLoading) setIsLoading(true);
    if (isUpdate) setIsUpdate(false);

    getUserQuestById(id)
      .then((r) => {
        setQuest(r);
        if (isSelect) setIsSelect(false);

        if (r.status === "created") {
          return Promise.resolve(null);
        }

        if (r.status === "completed") {
          const heroes = r.completed_with_heroes || [];
          return getHeroes({
            page: 1,
            pageSize: 10,
            orderParam: null,
            classIds: null,
            heroIds: null,
            rarityLevelIds: null,
            questId: null,
            ids: heroes.join(","),
          });
        } else {
          setSelectedHeroes(r?.active_user_heroes);
          return Promise.resolve(null);
        }
      })
      .then((response) => {
        if (response) {
          const heroes = response?.data?.data || [];
          setSelectedHeroes(heroes);
        }
      })
      .catch(() => {
        navigate(paths.quests);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isResultModalOpen) setIsResultModalOpen(false);
    if (prize) setPrize(null);
    if (levelUpInfo?.length) setLevelUpInfo([]);
    if (questChance) setQuestChance(null);
    if (isTooltipOpen) setIsTooltipOpen(false);
    if (!id) {
      navigate(paths.quests);
    }
    getData();
  }, [id]);

  useEffect(() => {
    fetchUserData(dispatch);
  }, []);

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (payload && payload.quest_id) {
        if (payload.quest_id === id) {
          if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
          getData();
        }
      }
      return payload;
    };
  }, [userChannel]);

  useEffect(() => {
    if (!lobbyChannel) return;
    lobbyChannel.onMessage = (event, payload) => {
      if (
        ["created", "failed"].includes(quest?.status) &&
        event === "quests_created"
      ) {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
        navigate(paths.quests);
      }
      return payload;
    };
  }, [lobbyChannel, quest]);

  useEffect(() => {
    if (
      quest &&
      !["completed", "created"].includes(quest.status) &&
      selectedHeroes?.length === quest.required_heroes_count
    ) {
      if (!isChanceLoading) setIsChanceLoading(true);
      getQuestChance(
        id,
        selectedHeroes.map((hero) => hero.id),
      )
        .then((r) => {
          setQuestChance(r.final_chance.toString());
        })
        .finally(() => setIsChanceLoading(false));
    } else {
      setIsChanceLoading(false);
    }
  }, [selectedHeroes]);

  const handleRemoveHero = (heroId) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (isTooltipOpen) setIsTooltipOpen(false);
    setQuestChance(false);
    setSelectedHeroes((prevSelectedHeroes) =>
      prevSelectedHeroes.filter((hero) => hero.id !== heroId),
    );
  };

  const handleOpenHeroesList = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsSelect(true);
  };

  const handleStartQuest = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (isTooltipOpen) setIsTooltipOpen(false);
    setIsLoading(true);

    if (quest.status === "finished") {
      getQuestResult(quest.id)
        .then((quest) => {
          setQuest(quest?.user_quest);
          setIsResultModalOpen(true);

          if (!!quest?.new_items?.length) {
            setPrize(quest.new_items);
          }

          const levelUpInfo = quest?.user_hero_results
            ?.filter((heroResult) => heroResult?.level_ups?.length > 0)
            ?.map((heroResult) => {
              const heroName = heroResult?.user_hero.hero_name;

              return heroResult?.level_ups.map((levelUp) => {
                const levelUpDetails = {};
                if (levelUp.strength > 0)
                  levelUpDetails.strength = levelUp.strength;
                if (levelUp.intelligence > 0)
                  levelUpDetails.intelligence = levelUp.intelligence;
                if (levelUp.endurance > 0)
                  levelUpDetails.endurance = levelUp.endurance;
                if (levelUp.charisma > 0)
                  levelUpDetails.charisma = levelUp.charisma;
                if (levelUp.agility > 0)
                  levelUpDetails.agility = levelUp.agility;
                if (levelUp.user_skill)
                  levelUpDetails.skill = levelUp.user_skill;

                return {
                  hero_name: heroName,
                  level: levelUp?.level,
                  ...levelUpDetails,
                };
              });
            })
            ?.flat();
          if (levelUpInfo?.length > 0) {
            setLevelUpInfo(levelUpInfo);
          }
        })
        .finally(() => setIsLoading(false));

      return;
    }

    startQuest(
      quest.id,
      selectedHeroes.map((elem) => elem.id),
    )
      .then((quest) => setQuest(quest))
      .catch((e) => {
        if (e?.response?.data?.message === "Too many active user heroes") {
          setIsTooltipOpen(true);
        } else {
          navigate(paths.quests);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const startDate = new Date(quest?.started_at);

  const endDate = new Date(
    startDate.getTime() + quest?.duration_in_seconds * 1000,
  );

  const actionMap = {
    1: (dispatch) => dispatch(toggleHideBackground()),
    3: (dispatch) => dispatch(toggleShowBackground()),
  };

  const steps = useMemo(() => getQuestSteps(), []);

  const { currentStep } = useGuide(steps, actionMap);

  return {
    isLoading,
    isSelect,
    quest,
    endDate,
    handleRemoveHero,
    selectedHeroes,
    setIsSelect,
    handleStartQuest,
    setSelectedHeroes,
    handleCloseHeroes,
    questChance,
    setQuestChance,
    isChanceLoading,
    isResultModalOpen,
    setIsResultModalOpen,
    levelUpInfo,
    setIsChanceLoading,
    handleOpenHeroesList,
    navigate,
    isTooltipOpen,
    currentStep,
    handleCloseModal,
    prize,
    isUpdate,
    setIsUpdate,
    getData,
  };
};
