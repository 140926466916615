// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__oNj5h {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 16px;
}

.styles_image__YEhRL {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.styles_button__TaSap {
  margin-top: auto;
}

.styles_description__\\+uA62 {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/CauldronDetail/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.image {\n  width: 100%;\n  object-fit: contain;\n  height: auto;\n}\n\n.button {\n  margin-top: auto;\n}\n\n.description {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__oNj5h`,
	"image": `styles_image__YEhRL`,
	"button": `styles_button__TaSap`,
	"description": `styles_description__+uA62`
};
export default ___CSS_LOADER_EXPORT___;
