import React from "react";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";

const NoData = ({ children, buttonText, onButtonClick, mainText, subText }) => {
  return (
    <div>
      <div>{children}</div>
      {mainText && <p>{mainText}</p>}
      {subText && <p>{subText}</p>}
      {buttonText && onButtonClick && (
        <TelegramButton onClick={onButtonClick}>{buttonText}</TelegramButton>
      )}
    </div>
  );
};

export default NoData;
