import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useTelegram } from "../Layout/useTelegramHook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSettings } from "../../services/settingsService";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { setVolume } from "../../store/slices/telegramSlice";
import paths from "../../pages/paths";
import { useNavigate } from "react-router-dom";

export const useSettingsPageHook = () => {
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeLanguage, setActiveLanguage] = useState("en");
  const [hapticEnabled, setHapticEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useTelegramBackButton(-1);

  useEffect(() => {
    fetchUserData(dispatch)
      .then((r) => {
        setActiveLanguage(r?.user_settings?.language_code || "en");
        setHapticEnabled(r?.user_settings?.is_haptic_feedback_enabled || false);
      })
      .catch((e) => console.error(e))
      .then(() => setIsLoading(false));
  }, []);

  const handleHapticToggle = () => {
    tg.HapticFeedback.selectionChanged();
    const newHapticEnabled = !hapticEnabled;
    updateUserSettings({ is_haptic_feedback_enabled: newHapticEnabled })
      .then(() => {
        dispatch(setHapticEnabled(newHapticEnabled));
      })
      .catch((error) => {
        console.error("Error updating hapticEnabled on the backend:", error);
      });
  };

  const volume = useSelector((state) => state.telegram.volume);

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    dispatch(setVolume(newVolume));
  };

  const navigate = useNavigate();

  const navigateToMainPage = () => {
    navigate(paths.index);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const tutorials = useSelector((state) => state.guide.tutorials);

  const handleResetGuideClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmReset = () => {
    updateUserSettings({
      tutorials: [paths.inventory, paths.roulette, paths.index],
    });
    setShowConfirmation(false);
  };

  const handleCancelReset = () => {
    setShowConfirmation(false);
  };

  return {
    t,
    hapticEnabled,
    handleHapticToggle,
    activeLanguage,
    setActiveLanguage,
    isLoading,
    setIsLoading,
    volume,
    handleVolumeChange,
    navigateToMainPage,
    handleCancelReset,
    handleConfirmReset,
    handleResetGuideClick,
    tutorials,
    showConfirmation,
  };
};
