// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_content__7qaQ- {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 30px;
}

.styles_filtersContent__OH9du {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 72px;
}

.styles_title__FOKnE {
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  color: var(--subhead-color);
}

.styles_hidden__QACOR {
  visibility: hidden;
}

.styles_noData__lEKVi {
  display: flex;
  flex: 1 1;
}

.styles_select__B98mQ {
  min-height: 42px;
}

.styles_clearAllButton__5TOJB {
  position: absolute;
  left: 16px;
  top: 0;
  padding: 4px 0;
  outline: none;
  border: unset;
  background: none;
  color: var(--text-color);
  text-decoration: underline;
  cursor: pointer;
}

.styles_applyButton__HWSUR {
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
}

.styles_clearAllButton__5TOJB:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/FiltersModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,wBAAA;EACA,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,UAAA;EACA,wBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding-top: 30px;\n}\n\n.filtersContent {\n  min-height: 70vh;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding-bottom: 72px;\n}\n\n.title {\n  width: 100%;\n  font-size: 1.3rem;\n  text-align: center;\n  color: var(--subhead-color);\n}\n\n.hidden {\n  visibility: hidden;\n}\n\n.noData {\n  display: flex;\n  flex: 1;\n}\n\n.select {\n  min-height: 42px;\n}\n\n.clearAllButton {\n  position: absolute;\n  left: 16px;\n  top: 0;\n  padding: 4px 0;\n  outline: none;\n  border: unset;\n  background: none;\n  color: var(--text-color);\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.applyButton {\n  position: fixed;\n  bottom: 16px;\n  left: 16px;\n  width: calc(100% - 32px);\n}\n\n.clearAllButton:focus {\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `styles_content__7qaQ-`,
	"filtersContent": `styles_filtersContent__OH9du`,
	"title": `styles_title__FOKnE`,
	"hidden": `styles_hidden__QACOR`,
	"noData": `styles_noData__lEKVi`,
	"select": `styles_select__B98mQ`,
	"clearAllButton": `styles_clearAllButton__5TOJB`,
	"applyButton": `styles_applyButton__HWSUR`
};
export default ___CSS_LOADER_EXPORT___;
