import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import {
  Avatar,
  Cell,
  List,
  SegmentedControl,
  Text,
} from "@telegram-apps/telegram-ui";
import cx from "classnames";
import { useQuestsHook } from "../../hooks/Pages/useQuestsHook";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useTranslation } from "react-i18next";
import {
  getColorByStatus,
  renderAttributeIcons,
} from "../../utils/attributesIconsUtils";
import QuestTimer from "../../components/QuestTimer/QuestTimer";
import Popup from "reactjs-popup";
import Countdown from "react-countdown";
import { formatAmount } from "../../utils/formatNumbers";

const Quests = () => {
  const { t } = useTranslation();
  const {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    questsCounts,
    nextQuestTime,
    handleGoToHistory,
    currentStep,
  } = useQuestsHook();

  const renderSegmentedControlItem = (status, label) => (
    <SegmentedControlItem
      className={styles.controlItem}
      selected={activeStatus === status}
      onClick={() => handleSetActiveStatus(status)}
    >
      {t(label)}&nbsp;
      {questsCounts && questsCounts[status] !== undefined
        ? `${questsCounts[status]}`
        : ""}
    </SegmentedControlItem>
  );

  if (isLoading && page === 1) {
    return <LoaderComponent />;
  }

  const countdownRenderer = ({ hours, minutes, seconds }) => {
    return (
      <span>
        {hours > 0 && `${hours}${t("h")} `}
        {minutes > 0 && `${minutes}${t("m")} `}
        {seconds}
        {t("s")}
      </span>
    );
  };
  return (
    <div className={cx(styles.wrapper, styles.fadeIn)}>
      <div className={styles.column}>
        <PageTitle
          isNoMargin
          title={t("Quests")}
          leftComponent={
            activeStatus === "new" && (
              <Popup
                keepTooltipInside
                position="bottom center"
                arrow={false}
                trigger={
                  <div>
                    <QuestTimer
                      className={cx({ [styles.blink]: currentStep === 2 })}
                      startQuestTime={nextQuestTime?.startQuestTime}
                      nextQuestTime={nextQuestTime?.nextQuestTime}
                    />
                  </div>
                }
              >
                <div className={styles.popupContent}>
                  <span>{t("Next quests")}:&nbsp;</span>
                  <Countdown
                    autoStart
                    date={nextQuestTime?.nextQuestTime}
                    renderer={countdownRenderer}
                  />
                </div>
              </Popup>
            )
          }
          rightComponent={
            <img
              className={cx(styles.icon, { [styles.blink]: currentStep === 3 })}
              src={AsyncPreloadImages.COMPLETED_QUESTS}
              alt={"titleIcon"}
              onClick={handleGoToHistory}
            />
          }
        />
        <div className={styles.segmentControlWrapper}>
          <SegmentedControl
            className={cx(styles.segmentControl, styles.fadeIn)}
          >
            {renderSegmentedControlItem("new", "Available")}
            {renderSegmentedControlItem("active", "Active")}
          </SegmentedControl>
        </div>
      </div>

      {isDataLoading && page === 1 ? (
        <div className={styles.loader}>
          <LoaderComponent />
        </div>
      ) : !quests.length ? (
        <div className={styles.noDataWrapper}>
          <img
            className={styles.noDataImg}
            alt={t("no_quest")}
            src={AsyncPreloadImages.NO_QUESTS}
          />
        </div>
      ) : (
        <>
          <List className={cx(styles.list, styles.fadeIn)}>
            {quests.map((quest, index) => (
              <Cell
                multiline
                key={quest.id}
                className={styles.cell}
                onClick={() => navigateToQuest(quest.id)}
                after={<Text />}
                ref={index === quests.length - 1 ? lastQuestElementRef : null}
                before={
                  <div className={styles.levelAvatar}>
                    <Text
                      className={cx(styles.level, {
                        [styles.level100]: quest.level >= 100,
                      })}
                    >
                      {quest.level}
                    </Text>
                    <Avatar
                      className={styles.rarityImg}
                      size={48}
                      src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
                    />
                  </div>
                }
                titleBadge={
                  <div
                    className={cx(styles.statusBadge, {
                      [styles.blink]: quest.status === "started",
                    })}
                    style={{ background: getColorByStatus(quest.status) }}
                  />
                }
                description={
                  <div className={styles.attributeIcons}>
                    {renderAttributeIcons(quest)}
                  </div>
                }
                subtitle={`${t("Reward")}: ${formatAmount(quest?.money_reward.amount)} ${quest?.money_reward?.currency_code}`}
              >
                {quest.name}
              </Cell>
            ))}
          </List>
        </>
      )}
    </div>
  );
};

export default Quests;
