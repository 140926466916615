import React from "react";
import styles from "./styles.module.scss";
import { useMainPageHook } from "../../hooks/Pages/useMainPageHook";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { PreloadImages } from "../../utils/globals";
import GiveawayBanner from "../../components/ForMainPage/GiveawayBanner/GiveawayBanner";
import QuestsBanner from "../../components/ForMainPage/QuestsBanner/QuestsBanner";
import cx from "classnames";
import { motion } from "framer-motion";

const IconButton = ({
  src,
  onClick,
  badge,
  text,
  showLabel,
  labelPosition,
  labelText,
  className,
}) => (
  <div className={styles.iconWrapper} onClick={onClick}>
    <img
      alt={"icon"}
      height={50}
      width={50}
      className={cx(styles.image, className)}
      src={src}
    />
    {badge && <span className={styles.notificationDot} />}
    {text && (
      <div className={styles.textWrapper}>
        <span>{text}</span>
      </div>
    )}
    {showLabel && (
      <div
        className={`${styles.label} ${
          labelPosition === "left" ? styles.labelLeft : styles.labelRight
        }`}
      >
        {labelText}
      </div>
    )}
  </div>
);

const MainPage = () => {
  const {
    isLoading,
    isTimerLoading,
    t,
    getQuestsCount,
    nextQuestTime,
    leftSideIcons,
    rightSideIcons,
    handleGoToQuests,
    handleCastleClick,
    giveawayBanner,
    handleGoToGiveaway,
    currentStep,
  } = useMainPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.mainPageWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftSide}>
          {leftSideIcons.map((icon, index) => (
            <IconButton
              key={index}
              {...icon}
              showLabel={currentStep === 5}
              labelPosition="right"
              labelText={icon.label}
              className={cx({
                [styles.activeInventory]:
                  currentStep === 8 &&
                  icon.src === PreloadImages.INVENTORY_ICON,
              })}
            />
          ))}
        </div>
        <div className={styles.mainContent}>
          {currentStep !== 5 && currentStep !== 6 && (
            <motion.img
              className={styles.castleImg}
              onClick={handleCastleClick}
              src={PreloadImages.CASTLE_ICON}
              alt="Castle"
              initial={{ scale: 1, rotate: 0 }}
              animate={{ scale: 1.1, rotate: [0, 5, -5, 5, -5, 0] }}
              transition={{
                duration: 10,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror",
              }}
            />
          )}
          {(!isTimerLoading || giveawayBanner) && (
            <div
              className={cx(styles.bannersWrapper, {
                [styles.noCastle]:
                  currentStep === 6 || currentStep === 5 || currentStep === 7,
                [styles.active]: currentStep === 7,
              })}
            >
              {giveawayBanner && (
                <GiveawayBanner
                  giveawayBanner={giveawayBanner}
                  handleGoToGiveaway={handleGoToGiveaway}
                />
              )}
              {!isTimerLoading && (
                <QuestsBanner
                  getQuestsCount={getQuestsCount}
                  handleGoToQuests={handleGoToQuests}
                  t={t}
                  nextQuestTime={nextQuestTime}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.rightSide}>
          {rightSideIcons.map((icon, index) => (
            <IconButton
              key={index}
              {...icon}
              showLabel={currentStep === 6}
              labelPosition="left"
              labelText={icon.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
