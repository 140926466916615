import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { getCauldronHeroes } from "../../../services/heroService";
import { getFilters } from "../../../services/filtersService";
import Button from "../../ui/Button/Button";
import LoaderComponent from "../../ui/LoaderComponent/LoaderComponent";
import LevelFilter from "../../ForHeroes/InventoryFilters/LevelFilter/LevelFilter";
import { useTranslation } from "react-i18next";
import RarityFilter from "../../ForHeroes/InventoryFilters/RarityFIlter/RarityFilter";
import { TbFilterOff } from "react-icons/tb";
import { TiDelete } from "react-icons/ti";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";
import HeroCell from "../../ForHeroes/HeroCell/HeroCell";

const CauldronHeroes = ({
  handleCloseSelect,
  onSelectHero,
  tg,
  hapticEnabled,
}) => {
  const [heroes, setHeroes] = useState([]);
  const [selectedHeroes, setSelectedHeroes] = useState([]);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [isHeroesLoading, setIsHeroesLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [userFilters, setUserFilters] = useState({});
  const [filters, setFilters] = useState({
    level: [1, 100],
    page: 1,
    rarity_level_ids: [],
  });
  const [loadedHeroes, setLoadedHeroes] = useState([]);

  const pageSize = 10;
  const observerRef = useRef();
  const sentinelRef = useRef();
  const { t } = useTranslation();

  const updateHeroes = (isAppending = false) => {
    if (isFiltersLoading) return;

    if (!isHeroesLoading) setIsHeroesLoading(true);

    getCauldronHeroes({ ...filters })
      .then((r) => {
        const newHeroes = r?.data?.data || [];

        setHeroes((prevHeroes) =>
          isAppending ? [...prevHeroes, ...newHeroes] : newHeroes,
        );

        if (newHeroes.length === 0 || newHeroes.length < pageSize) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .finally(() => setIsHeroesLoading(false));
  };

  useEffect(() => {
    getFilters()
      .then((r) => {
        setUserFilters(r);
      })
      .finally(() => setIsFiltersLoading(false));
  }, []);

  useEffect(() => {
    if (!isFiltersLoading) {
      updateHeroes(filters.page > 1);
    }
  }, [filters, isFiltersLoading]);

  useEffect(() => {
    if (isHeroesLoading || !hasMore) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page: prevFilters.page + 1,
          }));
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      },
    );

    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [heroes, isHeroesLoading, hasMore]);

  const toggleHeroSelection = (hero) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedHeroes((prevSelected) => {
      if (prevSelected.some((selectedHero) => selectedHero.id === hero.id)) {
        return prevSelected.filter(
          (selectedHero) => selectedHero.id !== hero.id,
        );
      }
      if (prevSelected.length >= 3) {
        return prevSelected;
      }
      return [...prevSelected, hero];
    });
  };

  const handleConfirmSelection = () => {
    onSelectHero(selectedHeroes);
    handleCloseSelect();
  };

  const updateFilter = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
    setHeroes([]);
    setHasMore(true);
    setLoadedHeroes([]);
  };

  const createFilterUpdater = (key) => (value) => updateFilter(key, value);

  const areFiltersActive = Boolean(
    filters.rarity_level_ids.length ||
      filters.level[0] !== 1 ||
      filters.level[1] !== 100,
  );

  const resetFilters = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setFilters({
      page: 1,
      rarity_level_ids: [],
      level: [1, 100],
    });
    setHeroes([]);
    setHasMore(true);
    setLoadedHeroes([]);
  };

  const handleHeroImagesLoaded = (heroId) => {
    setLoadedHeroes((prevLoaded) => [...prevLoaded, heroId]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <LevelFilter
          selectedRange={filters?.level}
          setSelectedRange={createFilterUpdater("level")}
          t={t}
          tg={tg}
          hapticEnabled={hapticEnabled}
        />
        <button
          className={`${styles.resetButton} ${
            areFiltersActive ? "" : styles.disabled
          }`}
          onClick={resetFilters}
          disabled={!areFiltersActive}
        >
          <div>
            <TbFilterOff />
          </div>
        </button>
        <RarityFilter
          rarityLevels={userFilters?.rarity_levels}
          selectedRarities={filters.rarity_level_ids}
          setSelectedRarities={createFilterUpdater("rarity_level_ids")}
          t={t}
          tg={tg}
          hapticEnabled={hapticEnabled}
        />
      </div>

      <div className={styles.selectedHeroes}>
        {selectedHeroes.map((hero) => (
          <div key={hero.id} className={styles.heroTag}>
            <span>{hero.hero_name}</span>
            <TiDelete
              color={"red"}
              size={15}
              onClick={() => toggleHeroSelection(hero)}
            />
          </div>
        ))}
      </div>
      {isHeroesLoading && filters.page === 1 && (
        <div className={styles.loader}>
          <LoaderComponent />
        </div>
      )}
      <div className={styles.list}>
        {!heroes.length && !isHeroesLoading && areFiltersActive && (
          <img
            className={styles.image}
            src={AsyncPreloadImages.NO_HEROES_ICON}
            alt="noHero"
          />
        )}
        {!!heroes.length &&
          heroes.map((item, index) => {
            const isSentinel =
              index === heroes.length - 3 ||
              (heroes.length < 3 && index === heroes.length - 1);
            const isLoaded = loadedHeroes.includes(item.id);

            return (
              <HeroCell
                handleHeroImagesLoaded={handleHeroImagesLoaded}
                heroes={heroes}
                index={index}
                isLoaded={isLoaded}
                isSentinel={isSentinel}
                item={item}
                selectedHeroes={selectedHeroes}
                sentinelRef={sentinelRef}
                toggleHeroSelection={toggleHeroSelection}
              />
            );
          })}
        {isHeroesLoading && <LoaderComponent />}
      </div>
      <div className={styles.buttonRow}>
        <Button className={styles.button} onClick={handleCloseSelect}>
          {t("Close")}
        </Button>
        <Button
          disabled={selectedHeroes.length !== 3}
          className={styles.button}
          onClick={handleConfirmSelection}
        >
          {t("Select")}
        </Button>
      </div>
    </div>
  );
};

export default CauldronHeroes;
