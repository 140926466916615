import React from "react";
import styles from "../Quests/styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import cx from "classnames";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useQuestsHistoryHook } from "../../hooks/Pages/useQuestsHistoryHook";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import QuestsCount from "../../components/ForQuests/QuestsCount/QuestsCount";
import { QuestList } from "../../components/ForQuests/QuestComponents";

const QuestsHistory = () => {
  const { t } = useTranslation();
  const {
    quests,
    navigateToQuest,
    lastQuestElementRef,
    isLoading,
    questsCount,
  } = useQuestsHistoryHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.wrapper, styles.fadeIn)}>
      <PageTitle isNoMargin>{t("Completed quests")}</PageTitle>
      {!quests?.length && (
        <div className={styles.noDataWrapper}>
          <img
            className={styles.noDataImg}
            alt={t("no_quest")}
            src={AsyncPreloadImages.NO_QUESTS}
          />
        </div>
      )}
      {!!quests?.length && (
        <>
          <QuestList
            quests={quests}
            navigateToQuest={navigateToQuest}
            lastQuestElementRef={lastQuestElementRef}
            isHistory
            t={t}
          />
          <QuestsCount questsCount={questsCount} />
        </>
      )}
    </div>
  );
};

export default QuestsHistory;
