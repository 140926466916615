export const formatNumber = (input) => {
  if (!input) {
    return 0;
  }
  let number;

  if (typeof input === "string") {
    number = parseFloat(input.replace(/[^0-9.-]/g, ""));
  } else if (typeof input === "number") {
    number = input;
  } else {
    throw new Error("Invalid input type");
  }

  // Проверка на NaN
  if (isNaN(number)) {
    throw new Error("Invalid number");
  }

  // Форматирование числа
  if (number >= 1_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + "B";
  } else if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1) + "K";
  } else {
    return number.toString(); // меньше тысячи
  }
};

export const formatAmount = (input) => {
  if (!input) {
    return "0";
  }

  const number = parseFloat(input);

  const formattedNumber =
    number % 1 === 0 ? number.toFixed(0) : number.toFixed(2);

  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
