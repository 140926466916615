import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import LoaderComponent from "../../../components/common/LoaderComponent/LoaderComponent";
import { getColorByClassName } from "../../../utils/imagesGetter";
import { IoClose } from "react-icons/io5";
import QuestAttributes from "../../Quest/QuestAttributes";
import { attributeIcons } from "../../../utils/attributesIconsUtils";
import TelegramButton from "../../../components/common/TelegramButton/TelegramButton";
import { useTranslation } from "react-i18next";

const getGradientByClassName = (classname) => {
  const colors = {
    common: "saddlebrown",
    rare: "silver",
    elite: "gold",
    legendary: "cornflowerblue",
    divine: "rgb(50, 229, 94)",
  };
  const baseColor = colors[classname] || "black";
  return {
    background: `radial-gradient(circle, white 0%, ${baseColor} 100%)`,
    glowColor: baseColor,
  };
};

const PrizeModal = ({
  prize,
  onClose,
  isInventory,
  isHeroInventory,
  onModalButtonClick,
  isActive,
}) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const prizes = Array.isArray(prize) ? prize : [prize];
  const { t } = useTranslation();

  useEffect(() => {
    const loadImage = (url) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });

    Promise.all(prizes.map((item) => loadImage(item?.item_image_url)))
      .then(() => setImagesLoaded(true))
      .catch(() => setImagesLoaded(true));
  }, [prizes]);

  if (!prize) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={cx(styles.modalContent, {
          [styles.animate]: imagesLoaded,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <IoClose size={24} onClick={onClose} className={styles.closeButton} />
        {imagesLoaded ? (
          <div
            className={cx(styles.prizesContainer, {
              [styles.multiplePrizes]: prizes.length > 1,
            })}
          >
            {prizes.map((item, index) => (
              <div
                key={item.id}
                className={cx(styles.prizeItem)}
                style={{ animationDelay: isInventory ? 0 : `${index * 0.3}s` }}
              >
                <div
                  className={styles.imageContainer}
                  style={{
                    animationDelay: isInventory ? 0 : `${index * 0.3}s`,
                  }}
                >
                  <div
                    className={styles.backgroundBlock}
                    style={{
                      backgroundImage: getGradientByClassName(item.rarity_level)
                        .background,
                      "--glow-color": getGradientByClassName(item.rarity_level)
                        .glowColor,
                    }}
                  >
                    <img
                      src={item?.item_image_url}
                      alt={item?.item_name}
                      className={cx(styles.prizeImage, {
                        [styles.withoutAnimation]: isInventory,
                      })}
                    />
                    {isInventory && (
                      <span
                        className={styles.level}
                      >{`Lv. ${item?.level}`}</span>
                    )}
                    {item?.class_image_url && (
                      <img
                        width={20}
                        height={20}
                        alt={"class"}
                        src={item?.class_image_url}
                        className={cx(styles.classImageUrl, {
                          [styles.withoutAnimation]: isInventory,
                        })}
                      />
                    )}
                  </div>
                </div>
                {prizes.length === 1 && (
                  <>
                    <span
                      className={cx(styles.itemName, {
                        [styles.withoutAnimation]: isInventory,
                      })}
                    >
                      {item.item_name}
                    </span>
                    <span
                      className={cx(styles.itemName, {
                        [styles.withoutAnimation]: isInventory,
                      })}
                    >
                      <span
                        style={{
                          color: getColorByClassName(item.rarity_level),
                        }}
                      >
                        {item?.rarity_level_name}
                      </span>
                    </span>
                    {!isInventory && (
                      <span
                        className={cx(styles.itemName, {
                          [styles.withoutAnimation]: isInventory,
                        })}
                      >{`${t("Level")}: ${item?.level}`}</span>
                    )}
                    <QuestAttributes
                      className={cx(styles.attributes, {
                        [styles.withoutAnimation]: isInventory,
                      })}
                      attributeIcons={attributeIcons}
                      quest={item}
                    />
                    {isHeroInventory && (
                      <div className={styles.buttonRow}>
                        <TelegramButton
                          onClick={() => onModalButtonClick(item?.id)}
                        >
                          {isActive ? t("remove") : t("wear")}
                        </TelegramButton>
                        {/*{isActive && (*/}
                        {/*  <TelegramButton disabled>*/}
                        {/*    {t("change")}*/}
                        {/*  </TelegramButton>*/}
                        {/*)}*/}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </div>
  );
};

export default PrizeModal;
