import React from "react";
import styles from "./styles.module.scss";
import Countdown from "react-countdown";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { formatAmount } from "../../utils/formatNumbers";
import GiveawayNoLeaders from "./GiveawayNoLeaders";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useGiveawayHook } from "../../hooks/Pages/useGiveawayHook";
import { getLeaderImage } from "../../utils/imagesGetter";

const Giveaway = () => {
  const {
    giveaway,
    ratingList,
    isLoading,
    userData,
    isRatingLoading,
    countdownTimestamp,
    t,
  } = useGiveawayHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={`${styles.wrapper} ${styles.fadeIn}`}>
      <div className={styles.title}>
        <div className={styles.avatar}>
          <img src={giveaway.image_url} alt={giveaway.title} />
        </div>
        <div className={styles.titleColumn}>
          <span className={styles.titleText}>{giveaway.title}</span>
          <span>
            {`${formatAmount(giveaway?.pool?.amount)} ${giveaway?.pool?.currency_code}`}
          </span>
        </div>
      </div>
      <PageTitle isNoMargin>
        {giveaway.status === "completed" ? (
          `${t("This giveaway has ended")} ${new Date(giveaway.ended_at)
            .toLocaleString("default", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, ".")}`
        ) : (
          <Countdown date={countdownTimestamp} />
        )}
      </PageTitle>
      {giveaway.status !== "completed" && (
        <div className={styles.description}>
          <span className={styles.descriptionTitle}>
            {t("DESCRIPTION_CAPS")}
          </span>
          <span>{giveaway.description}</span>
        </div>
      )}
      <span className={styles.descriptionTitle}>{t("LEADERBOARD")}</span>
      {isRatingLoading && (
        <div className={styles.loading}>
          <LoaderComponent />
        </div>
      )}
      {!isRatingLoading && !ratingList?.length && (
        <GiveawayNoLeaders giveaway={giveaway} />
      )}
      {!isRatingLoading && !!ratingList?.length && (
        <div className={styles.list}>
          {ratingList.map((rating) => (
            <div key={rating.id} className={`${styles.cell} ${styles.fadeIn}`}>
              <div className={styles.image}>
                <img
                  src={getLeaderImage(rating?.rank)}
                  alt={`Rank ${rating?.rank}`}
                />
                {rating?.rank > 3 && (
                  <span className={styles.text}>{rating?.rank || "-"}</span>
                )}
              </div>
              <div className={styles.column}>
                <span>{rating?.username || "-"}</span>
                <span>{`${rating.referral_count} ${t("Invited")}`}</span>
              </div>
              <div className={styles.info}>
                {`${formatAmount(Math.round(rating?.prize))} ${giveaway.pool?.currency_code}`}
              </div>
            </div>
          ))}
        </div>
      )}
      {userData && (
        <div className={`${styles.cell} ${styles.customCell}`}>
          <div className={styles.image}>
            <img
              src={getLeaderImage(userData?.rank)}
              alt={`Rank ${userData?.rank}`}
            />
            {userData?.rank > 3 && (
              <span className={styles.text}>{userData?.rank || "-"}</span>
            )}
          </div>
          <div className={styles.column}>
            <span>{userData?.username || "-"}</span>
            <span>{`${userData?.referral_count} ${t("Invited")} `}</span>
          </div>
          <div className={styles.info}>
            {`${formatAmount(Math.round(userData?.prize))} ${giveaway?.pool?.currency_code}`}
          </div>
        </div>
      )}
    </div>
  );
};

export default Giveaway;
