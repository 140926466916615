import { setFinishedQuestId } from "../store/slices/socketSlice";

export const eventHandlers = [
  {
    eventName: "quests_created",
    channel: "lobbyChannel",
    handler: (payload, context) => {
      const { t, navigate, handleShowSnackbar, handleCloseSnackbar } = context;
      handleShowSnackbar({
        message: t("Quests have been updated"),
        description: t("Click here to check"),
        buttonText: t("Close"),
        onButtonClick: () => {
          navigate(`/quests`);
          handleShowSnackbar({});
          handleCloseSnackbar();
        },
      });
    },
  },
  {
    eventName: "mint_completed",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t, navigate, handleShowSnackbar, handleCloseSnackbar } = context;
      handleShowSnackbar({
        message: t("heroMinted"),
        description: t("heroWillAppearInInventory"),
        buttonText: t("Close"),
        onButtonClick: () => {
          navigate("/inventory", { state: { activeType: "nft" } });
          handleShowSnackbar({});
          handleCloseSnackbar();
        },
      });
    },
  },
  {
    eventName: "mint_failed",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t, handleShowSnackbar, handleCloseSnackbar } = context;
      handleShowSnackbar({
        message: t("mintFailed"),
        buttonText: t("Close"),
        onButtonClick: () => {
          handleShowSnackbar({});
          handleCloseSnackbar();
        },
      });
    },
  },
  {
    eventName: "quest_finished",
    channel: "userChannel",
    handler: (payload, context) => {
      const { t, navigate, handleShowSnackbar, handleCloseSnackbar, dispatch } =
        context;
      const pathname = window.location.pathname;
      const id = pathname.split("/").pop();
      if (payload?.quest_id && payload?.quest_id !== id) {
        dispatch(setFinishedQuestId(payload.quest_id));
        handleShowSnackbar({
          message: t("You have completed the quest"),
          description: t("Click here to check"),
          buttonText: t("Close"),
          onButtonClick: () => {
            navigate(`/quest/${payload.quest_id}`);
            handleCloseSnackbar();
          },
        });
      }
    },
  },
];
