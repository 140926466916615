import React from "react";
import styles from "./styles.module.scss";
import {
  Cell,
  Image,
  Text,
  Button,
  Snackbar,
} from "@telegram-apps/telegram-ui";
import { useTaskHook } from "../../hooks/Pages/useTaskHook";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import ValidatedTask from "./ValidatedTask";
import SimpleTask from "./SimpleTask";
import { MdDone, MdErrorOutline } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
import { tasksTypes } from "../../utils/tasksUtils";

const Task = () => {
  const {
    task,
    accountName,
    handleSetAccountName,
    isLoading,
    isValidatedTask,
    t,
    handleSubmitTask,
    snackbarOpen,
    handleCloseSnackbar,
    isButtonDisabled,
    handleNavigate,
  } = useTaskHook();

  if (isLoading || !task) return <LoaderComponent />;

  return (
    <>
      <div className={styles.wrapper}>
        <Cell
          multiline
          className={styles.cell}
          after={
            task.status === "started" &&
            task?.machine_name !== tasksTypes.complete_onboarding ? (
              <FaExternalLinkAlt
                onClick={handleNavigate}
                className={styles.linkIcon}
              />
            ) : null
          }
          before={
            <Image size={48} className={styles.img} src={task.image_url} />
          }
          subtitle={
            <div className={styles.amountRow}>
              <Image
                size={25}
                className={styles.coinImage}
                src={PreloadImages.COIN_ICON}
              />
              <Text className={styles.amount} weight="1">
                +{formatAmount(task.money_reward.amount)}
              </Text>
            </div>
          }
        >
          {task.title || ""}
        </Cell>
        {task.status === "finished" && (
          <div className={styles.tabList}>
            <Button
              className={`${styles.button} ${styles.buttonFinished}`}
              mode={"bezeled"}
            >
              <div className={styles.buttonRow}>
                {t("Finished")}
                <MdDone size={24} />
              </div>
            </Button>
          </div>
        )}
        {task.status !== "finished" && <Text>{task.description}</Text>}
        {isValidatedTask && task.status !== "finished" && (
          <ValidatedTask
            t={t}
            task={task}
            handleSubmitTask={handleSubmitTask}
            accountName={accountName}
            handleSetAccountName={handleSetAccountName}
            buttonTitle={task.button_text}
            isButtonDisabled={isButtonDisabled}
          />
        )}
        {!(
          task.status === "finished" &&
          task.machine_name === tasksTypes.complete_onboarding
        ) &&
          (!isValidatedTask || task.status === "finished") && (
            <SimpleTask
              task={task}
              handleSubmitTask={handleSubmitTask}
              isButtonDisabled={isButtonDisabled}
              t={t}
            />
          )}
      </div>
      {snackbarOpen && (
        <Snackbar
          className={styles.snackbar}
          before={<MdErrorOutline size={20} color="red" />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={t("Wrong social link")}
        />
      )}
    </>
  );
};

export default Task;
