// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContainer__Lsor6 {
  height: min-content !important;
  max-height: 95vh;
  background-color: #18222d !important;
}

.styles_header__k3LYS {
  background-color: #18222d !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.styles_sheetContent__e3DAK {
  background-color: #18222d;
  color: white;
  padding: 0 16px 16px 16px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,gBAAA;EACA,oCAAA;AACF;;AAMA;EACE,oCAAA;EACA,uCAAA;EACA,wCAAA;AAHF;;AAMA;EACE,yBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AAHF","sourcesContent":[".modalContainer {\n  height: min-content !important;\n  max-height: 95vh;\n  background-color: #18222d !important;\n}\n\n.modal {\n\n}\n\n.header {\n  background-color: #18222d !important;\n  border-top-left-radius: 16px !important;\n  border-top-right-radius: 16px !important;\n}\n\n.sheetContent {\n  background-color: #18222d;\n  color: white;\n  padding: 0 16px 16px 16px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `styles_modalContainer__Lsor6`,
	"header": `styles_header__k3LYS`,
	"sheetContent": `styles_sheetContent__e3DAK`
};
export default ___CSS_LOADER_EXPORT___;
