import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useLoaderHook from "../Layout/useLoaderHook";
import { useTelegram } from "../Layout/useTelegramHook";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { getMarketItems } from "../../services/marketService";
import { getUserBadges, getUserWallets } from "../../services/userService";
import paths from "../../pages/paths";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getMarketSteps } from "../../utils/guideSteps";

export const useMarketHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLoaderHook();
  const { tg } = useTelegram();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);
  const [marketItemBadge, setMarketItemBadge] = useState(null);

  useEffect(() => {
    tg.BackButton.hide();

    fetchUserData(dispatch);

    getMarketItems()
      .then((data) => {
        setMarketItems(data);
      })
      .finally(() => setIsLoading(false));

    fetchUserWallets();

    getUserBadges("market_items").then((r) => {
      setMarketItemBadge(
        r?.find((item) => item?.type === "market_items")?.badge,
      );
    });

    getMarketItemBadge();
  }, []);

  const getMarketItemBadge = () => {
    getUserBadges("market_items").then((r) => {
      setMarketItemBadge(
        r?.find((item) => item?.type === "market_items")?.badge,
      );
    });
  };

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency_code === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  const handleMarketItemClick = (id, type) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.marketItem, { state: { id, type } });
  };

  const handleNavigateToInventory = () => {
    navigate(paths.inventory, { state: { from: pathname } });
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  const handleCardClick = (item) => {
    if (item?.disabled_until) {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }
    if (item.status !== "coming_soon") {
      handleMarketItemClick(item.id, item.type);
    } else {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleChipClick = (e, item) => {
    if (item?.disabled_until || item.status === "coming_soon") {
      e.stopPropagation();
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const actionMap = {
    2: (dispatch) => dispatch(toggleHideBackground()),
    3: (dispatch) => dispatch(toggleShowBackground()),
  };

  const steps = useMemo(() => getMarketSteps(), []);

  const { currentStep } = useGuide(steps, actionMap);

  return {
    t,
    marketItems,
    isLoading,
    userAmount,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    marketItemBadge,
    currentStep,
  };
};
