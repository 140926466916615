import { configureStore } from "@reduxjs/toolkit";
import telegramReducer from "./slices/telegramSlice";
import loaderReducer from "./slices/loaderSlice";
import socketReducer from "./slices/socketSlice";
import guideReducer from "./slices/guideSlice";

export const store = configureStore({
  reducer: {
    telegram: telegramReducer,
    loader: loaderReducer,
    socket: socketReducer,
    guide: guideReducer,
  },
});
