import React, { useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import Popup from "reactjs-popup";

const RarityFilter = ({
  rarityLevels,
  selectedRarities,
  setSelectedRarities,
  t,
  tg,
  hapticEnabled,
}) => {
  const [tempRarities, setTempRarities] = useState(selectedRarities);
  const isActive = selectedRarities.length > 0;

  const handlePopupClose = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (
      tempRarities.length !== selectedRarities.length ||
      !tempRarities.every((r) => selectedRarities.includes(r))
    ) {
      setSelectedRarities(tempRarities);
    }
    setTempRarities(selectedRarities);
  };

  const handlePopupOpen = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setTempRarities(selectedRarities);
  };

  const handleCheckboxChange = (value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (tempRarities.includes(value)) {
      setTempRarities(tempRarities.filter((item) => item !== value));
    } else {
      setTempRarities([...tempRarities, value]);
    }
  };

  const renderCheckboxes = () => {
    if (!rarityLevels || rarityLevels.length === 0) {
      return (
        <div className={styles.noRarities}>{t("noRarityLevelsAvailable")}</div>
      );
    }

    return rarityLevels.map((rarity) => (
      <div key={rarity.id} className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id={rarity.id}
          className={styles.checkbox}
          checked={tempRarities.includes(rarity.id)}
          onChange={() => handleCheckboxChange(rarity.id)}
        />
        <label htmlFor={rarity.id} className={styles.checkboxLabel}>
          {rarity.name}
        </label>
      </div>
    ));
  };

  return (
    <Popup
      keepTooltipInside
      arrow={false}
      trigger={
        <button
          className={cx(styles.triggerButton, { [styles.active]: isActive })}
        >
          {t("Rarity")}
        </button>
      }
      position="left center"
      className={styles.popupWrapper}
      onClose={handlePopupClose}
      onOpen={handlePopupOpen}
    >
      <div className={styles.popupContent}>
        <div className={styles.title}>{t("selectRarity")}</div>
        <div className={styles.optionsContainer}>{renderCheckboxes()}</div>
      </div>
    </Popup>
  );
};

export default RarityFilter;
