import React from "react";
import styles from "./styles.module.scss";
import Navbar from "../Navbar/Navbar";
import { PreloadImages } from "../../../utils/globals";
import Header from "../Header/Header";
import { useDefaultLayoutHook } from "../../../hooks/Layout/useDefaultLayoutHook";
import cx from "classnames";
import Guide from "../../Guide/Guide";

const DefaultLayout = ({ children }) => {
  const {
    isInitialEnter,
    isNoGameInterface,
    isOnlyNavbar,
    isWithoutHeader,
    isGuideVisible,
    handleCloseGuide,
    isBlackOverlay,
  } = useDefaultLayoutHook();

  return (
    <>
      {isGuideVisible && <Guide closeGuide={handleCloseGuide} />}
      <div
        style={{
          backgroundImage: `url(${PreloadImages.BACKGROUND_IMAGE})`,
        }}
        className={cx(styles.wrapper, styles.fadeIn)}
      >
        <div
          className={cx(styles.overlay, {
            [styles.blackOverlay]: isBlackOverlay,
          })}
        />

        {!isInitialEnter && !isNoGameInterface && !isWithoutHeader && (
          <Header />
        )}
        <div className={styles.content}>{children}</div>
        {((!isInitialEnter && !isNoGameInterface) || isOnlyNavbar) && (
          <Navbar />
        )}
      </div>
    </>
  );
};

export default DefaultLayout;
