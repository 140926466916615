// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__3IoP0 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.styles_list__jATHr {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 100px);
}

.styles_cell__L-gno {
  display: flex;
  align-items: center;
  gap: 16px;
  background: var(--primary-bg);
  border-radius: 16px;
  padding: 12px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.styles_avatar__Xph9K img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.styles_cellContent__21FzM {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.styles_subhead__6j6hr {
  font-size: 0.9rem;
  color: var(--subhead-color);
}

.styles_progress__mvz44 {
  background: rgba(0, 0, 0, 0.2);
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
}

.styles_progressBar__cmzWy {
  height: 100%;
  background: #76b947;
}

.styles_lowProgress__ouhEe {
  background: red;
}

.styles_dataLoader__7mRxz {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.styles_fadeIn__3bZ1T {
  opacity: 0;
  animation: styles_fadeInAnimation__HyZXf 1s forwards;
}

@keyframes styles_fadeInAnimation__HyZXf {
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Giveaways/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;EACA,gBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,6BAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,qDAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,UAAA;EACA,oDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  padding: 16px;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n  overflow-y: auto;\n  height: calc(100% - 100px);\n}\n\n.cell {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  background: var(--primary-bg);\n  border-radius: 16px;\n  padding: 12px;\n  cursor: pointer;\n  transition: transform 0.2s ease, box-shadow 0.3s ease;\n}\n\n.avatar img {\n  width: 48px;\n  height: 48px;\n  object-fit: contain;\n}\n\n.cellContent {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  justify-content: center;\n}\n\n.subhead {\n  font-size: 0.9rem;\n  color: var(--subhead-color);\n}\n\n.progress {\n  background: rgba(0, 0, 0, 0.2);\n  height: 6px;\n  border-radius: 3px;\n  overflow: hidden;\n}\n\n.progressBar {\n  height: 100%;\n  background: #76b947;\n}\n\n.lowProgress {\n  background: red;\n}\n\n.dataLoader {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n@keyframes fadeInAnimation {\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__3IoP0`,
	"list": `styles_list__jATHr`,
	"cell": `styles_cell__L-gno`,
	"avatar": `styles_avatar__Xph9K`,
	"cellContent": `styles_cellContent__21FzM`,
	"subhead": `styles_subhead__6j6hr`,
	"progress": `styles_progress__mvz44`,
	"progressBar": `styles_progressBar__cmzWy`,
	"lowProgress": `styles_lowProgress__ouhEe`,
	"dataLoader": `styles_dataLoader__7mRxz`,
	"fadeIn": `styles_fadeIn__3bZ1T`,
	"fadeInAnimation": `styles_fadeInAnimation__HyZXf`
};
export default ___CSS_LOADER_EXPORT___;
