import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { setGlobalLoading } from "../../store/slices/loaderSlice";
import { fetchUserData } from "../../services/registrationService";
import { updateUserWallet } from "../../services/walletService";
import { toast } from "react-toastify";
import { getUserBadges, getUserWallets } from "../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserRating } from "../../services/ratingService";
import { Buffer } from "buffer";
import * as TON_CONNECT_UI from "@tonconnect/ui";
import { useTranslation } from "react-i18next";
import paths from "../../pages/paths";
import {
  setPosition,
  toggleHideBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getProfileSteps } from "../../utils/guideSteps";
import { showSuccessToast } from "../../components/ui/Toast/Toast";

window.Buffer = Buffer;
const TonWeb = require("tonweb");

export const useProfileHook = () => {
  const location = useLocation();
  const taskId = location.state?.id;
  const backState = taskId
    ? { state: { from: location.pathname, id: taskId } }
    : {};

  useTelegramBackButton(-1, backState, !!taskId);
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);
  const [userName, setUsername] = useState("");
  const [userRating, setUserRating] = useState("");
  const [userWallet, setUserWallet] = useState(null);
  const [tonConnectUI, setTonConnectUI] = useState(null);
  const [badges, setBadges] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!window.tonConnectUI) {
      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl:
          "https://s3.pathgame.app/public/config/tonconnect-manifest.json",
      });
      window.tonConnectUI = tonConnectUI;
      setTonConnectUI(tonConnectUI);
    } else {
      setTonConnectUI(window.tonConnectUI);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const truncateAddress = (address) => {
    if (!address) return "";
    const firstPart = address.substring(0, 6);
    const lastPart = address.substring(address?.length - 6);
    return `${firstPart}...${lastPart}`;
  };

  const fetchData = async () => {
    try {
      const userRes = await fetchUserData(dispatch);
      if (userRes) {
        setUsername(userRes?.username);
      }

      const walletAddressFromBackend =
        userRes?.user_settings?.ton_wallet_address;
      const currentWallet = tonConnectUI?.wallet;

      setUserWallet(walletAddressFromBackend);

      if (!walletAddressFromBackend && currentWallet) {
        await tonConnectUI.disconnect();
        console.log(t("Wallet disconnected due to null from backend"));
      }
      getUserWallets().then((r) => setUserAmount(r));
      getUserRating().then((r) => setUserRating(r.rank));
      getUserBadges("tasks,market_items").then(setBadges);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  const convertToUserFriendlyAddress = (rawAddress) => {
    const address = new TonWeb.utils.Address(rawAddress);
    return address.toString(true, true, false, false);
  };

  useEffect(() => {
    const handleStatusChange = (wallet) => {
      if (wallet && !userWallet) {
        updateUserWallet(
          convertToUserFriendlyAddress(wallet?.account?.address),
        ).then(() => fetchData());
      }
    };

    tonConnectUI?.onStatusChange(handleStatusChange);
    return () => {
      tonConnectUI?.closeModal();
    };
  }, [tonConnectUI]);

  const handleDeleteWallet = async () => {
    closeModal();
    try {
      const wallet = tonConnectUI?.wallet;

      if (wallet) {
        await tonConnectUI.disconnect();
        console.log(t("Wallet disconnected"));
      } else {
        console.log(t("No wallet connected"));
      }

      await updateUserWallet(null);
      await fetchData();
    } catch (error) {
      toast.error(t("Failed to delete wallet. Please try again."), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOpenConnectModal = () => {
    const currentWallet = tonConnectUI?.wallet;
    if (currentWallet) {
      tonConnectUI.disconnect().then(() => tonConnectUI.openModal());
      return;
    }
    tonConnectUI.openModal();
  };
  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(userWallet);
      console.log("Copying text command was successful");
      tg.HapticFeedback.notificationOccurred("success");
      showSuccessToast(t("Copied to clipboard"));
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleOpenTransactions = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.transactions);
  };

  const handleOpenTreasures = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.inventory, {
      state: { from: location.pathname, activeType: "nft" },
    });
  };

  const handleOpenUserTasks = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.tasks, { state: { from: location.pathname } });
  };

  const handleOpenSettings = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.settings);
  };

  const checkBadge = (type) =>
    badges?.find((badge) => badge.type === type)?.badge;

  const actionMap = {
    1: (dispatch) => dispatch(toggleHideBackground()),
    3: (dispatch) => dispatch(setPosition("top")),
  };

  const steps = useMemo(() => getProfileSteps(), []);

  const { currentStep } = useGuide(steps, actionMap);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleContactSupport = () => {
    tg.openTelegramLink("https://t.me/thepathchat/5");
  };

  return {
    isLoading,
    userAmount,
    userName,
    userRating,
    userWallet,
    truncateAddress,
    handleDeleteWallet,
    handleOpenConnectModal,
    handleCopyAddress,
    handleOpenTransactions,
    handleOpenUserTasks,
    handleOpenTreasures,
    t,
    handleOpenSettings,
    checkBadge,
    currentStep,
    openModal,
    closeModal,
    isModalOpen,
    handleContactSupport,
  };
};
