// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__7CYVe {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  text-align: center;
}

.styles_buttonRow__U0-bD {
  padding-bottom: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}
.styles_buttonRow__U0-bD > button {
  width: 100%;
}
.styles_buttonRow__U0-bD > span {
  width: 100%;
}

.styles_userAmount__ZehRs {
  display: flex;
  gap: 4px;
  align-items: center;
  position: absolute;
  font-size: 10px;
  left: 8px;
  top: 0;
}

.styles_priceRow__m98Wr {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.styles_priceNoSale__kL5bZ {
  text-decoration: line-through;
}`, "",{"version":3,"sources":["webpack://./src/components/ForCauldron/CauldronPaymentModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAGA;EACE,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AAAF;AACE;EACE,WAAA;AACJ;AACE;EACE,WAAA;AACJ;;AAGA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,SAAA;EACA,MAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AAAF;;AAGA;EACE,6BAAA;AAAF","sourcesContent":[".modalContent {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 24px;\n  width: 100%;\n  text-align: center;\n}\n\n\n.buttonRow {\n  padding-bottom: 16px;\n  text-align: center;\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  width: 100%;\n  > button {\n    width: 100%;\n  }\n  > span {\n    width: 100%;\n  }\n}\n\n.userAmount {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  position: absolute;\n  font-size: 10px;\n  left: 8px;\n  top: 0;\n}\n\n.priceRow {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 12px;\n}\n\n.priceNoSale {\n  text-decoration: line-through;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__7CYVe`,
	"buttonRow": `styles_buttonRow__U0-bD`,
	"userAmount": `styles_userAmount__ZehRs`,
	"priceRow": `styles_priceRow__m98Wr`,
	"priceNoSale": `styles_priceNoSale__kL5bZ`
};
export default ___CSS_LOADER_EXPORT___;
