import React from "react";
import styles from "./styles.module.scss";
import { Caption, Modal, Text } from "@telegram-apps/telegram-ui";
import {
  getCharImagesByRarity,
  getColorByClassName,
} from "../../../utils/imagesGetter";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import HeroCardLevel from "../HeroCardLevel/HeroCardLevel";

const CustomModal = ({ trigger, content }) => (
  <Modal
    header={
      <ModalHeader
        className={styles.header}
        after={
          <ModalClose>
            <Icon28Close className={styles.closeIcon} />
          </ModalClose>
        }
      />
    }
    trigger={trigger}
  >
    {content}
  </Modal>
);

const HeroCardTopRow = ({ character, currentStep, allImagesLoaded }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <CustomModal
        trigger={
          <div
            className={cx(styles.levelRow, {
              [styles.blink]: currentStep === 1,
            })}
          >
            <img
              alt={"levelIcon"}
              src={AsyncPreloadImages.LEVEL_ICON}
              width={"50px"}
              height={"50px"}
            />
            <Text className={styles.level}>{Math.floor(character.level)}</Text>
          </div>
        }
        content={
          <div className={styles.heroInfo}>
            <Text className={styles.heroName}>{character.hero_name}</Text>
            <img
              className={styles.heroImage}
              src={character.hero_preview_url}
              alt={"hero"}
            />
            <div className={styles.heroInfoRow}>
              <Caption>{t("Level")}:</Caption>
              <Caption className={styles.levelDigit}>
                {Math.floor(character.level)}
              </Caption>
            </div>
            <Caption>{`${t("Class")}: ${character.class}`}</Caption>
            <div className={styles.heroInfoRow}>
              <Caption>{t("Rarity type")}:</Caption>
              <Caption
                style={{
                  color: getColorByClassName(
                    character?.rarity_level?.toLowerCase(),
                  ),
                }}
              >
                {character?.rarity_level_name}
              </Caption>
            </div>
            <div className={styles.rarityRow}>
              <Caption>{t("Characteristic Roll Range")}:</Caption>
              <Caption
                style={{
                  color: getColorByClassName(
                    character?.rarity_level?.toLowerCase(),
                  ),
                }}
              >
                {`${character?.rarity_level_characteristic_min} - ${character.rarity_level_characteristic_max}`}
              </Caption>
            </div>
            <Caption>{character?.rarity_level_description}</Caption>
          </div>
        }
      />

      <HeroCardLevel character={character} allImagesLoaded={allImagesLoaded} />

      <CustomModal
        trigger={
          <img
            className={cx(styles.classIcon, {
              [styles.blink]: currentStep === 2,
            })}
            alt={"classIcon"}
            src={character?.class_image_url}
            width={"50px"}
            height={"50px"}
          />
        }
        content={
          <div className={styles.classContent}>
            <Text className={styles.classTitle}>{character.class}</Text>
            <img
              alt={"classIcon"}
              src={character?.class_image_url}
              width={"100px"}
              height={"100px"}
            />
            <div className={styles.characteristicRow}>
              <Caption
                className={styles.mainCharacteristic}
              >{`${t("Main characteristic")}: ${t(character.class_main_characteristic)}`}</Caption>
              <img
                height={"20px"}
                width={"20px"}
                className={styles.class_main_characteristic}
                alt={character.id}
                src={getCharImagesByRarity(
                  character?.class_main_characteristic,
                )}
              />
            </div>
            <Caption>{character.class_description}</Caption>
          </div>
        }
      />
    </div>
  );
};

export default HeroCardTopRow;
