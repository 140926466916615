import React from "react";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { Cell, Image, Info, Text } from "@telegram-apps/telegram-ui";
import { PreloadImages } from "../../utils/globals";
import styles from "./styles.module.scss";
import MarketSnackbar from "../Market/MarketSnackbar";
import { formatAmount } from "../../utils/formatNumbers";
import { BsArrowRight } from "react-icons/bs";
import { useMarketItemHook } from "../../hooks/Pages/useMarketItemHook";
import InfoModal from "../../components/InfoModal/InfoModal";
import { StarIcon } from "../../assets/icons";
import SuccessModal from "../Market/SuccessModal";
import cx from "classnames";
import { customStyles } from "../../components/ForHeroes/FiltersModal/selectStyles";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const MarketItem = () => {
  const { t } = useTranslation();
  const {
    handleCloseModal,
    handleOpenModal,
    handleBuyMarketItem,
    isLoading,
    isModalOpen,
    snackbar,
    setSnackbar,
    marketItem,
    handleOpenRoulette,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
    levelOptions,
    quantityOptions,
    handleLevelChange,
    handleQuantityChange,
    isButtonLoading,
    maxHeroLevel,
    iframe,
  } = useMarketItemHook();

  if (isLoading) return <LoaderComponent />;

  const CurrencyIndicator = ({ currency, size = 24 }) => {
    if (currency === "STAR") {
      return <StarIcon size={20} />;
    }

    if (currency === "PATH") {
      return (
        <Image
          size={size}
          className={styles.coinImage}
          src={PreloadImages.COIN_ICON}
        />
      );
    }

    return currency;
  };

  return (
    <>
      <div className={cx(styles.marketItemWrapper, styles.fadeIn)}>
        <img
          alt={t("loot")}
          className={styles.image}
          src={marketItem.image_url}
        />
        <div className={styles.marketItemContent}>
          <Info
            className={styles.info}
            subtitle={
              marketItem.sale
                ? `${marketItem.sale.discount_percentage}% ${t("SALE")}`
                : ""
            }
            type="text"
          >
            {marketItem.title}
          </Info>
          {["quest_lootbox"].includes(marketItem.type) && (
            <>
              <label htmlFor="my-select">{t("Select quantity")}:</label>
              <Select
                inputId="my-select"
                className={styles.select}
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                options={quantityOptions}
                styles={customStyles}
                isClearable={false}
                placeholder={t("Quantity")}
                onChange={handleQuantityChange}
                defaultValue={quantityOptions.find((opt) => opt.value === 1)}
              />
            </>
          )}
          {[
            "hero_items_lootbox",
            "quest_lootbox",
            "daily_quest_lootbox",
          ].includes(marketItem.type) && (
            <>
              <label htmlFor="my-select-level">{t("Select level")}:</label>
              <Select
                inputId="my-select-level"
                className={styles.select}
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                options={levelOptions}
                styles={customStyles}
                isClearable={false}
                placeholder={t("Level")}
                onChange={handleLevelChange}
                defaultValue={levelOptions.find(
                  (opt) =>
                    opt.value ===
                    (marketItem.type === "daily_quest_lootbox"
                      ? maxHeroLevel
                      : 1),
                )}
              />
            </>
          )}
          <TelegramButton
            disabled={isButtonLoading}
            onClick={handleBuyMarketItem}
            className={styles.button}
          >
            <div className={styles.buttonColumn}>
              <Text>
                {marketItem.sale ? (
                  <>
                    <span className={styles.strikethrough}>
                      {["hero_items_lootbox", "quest_lootbox"].includes(
                        marketItem.type,
                      )
                        ? formatAmount(marketItem?.final_price.amount)
                        : formatAmount(marketItem?.price.amount)}
                    </span>
                    <BsArrowRight className={styles.arrowIcon} />
                    {formatAmount(marketItem?.final_price?.amount)}
                  </>
                ) : (
                  `${
                    ["hero_items_lootbox", "quest_lootbox"].includes(
                      marketItem.type,
                    )
                      ? formatAmount(marketItem?.final_price?.amount)
                      : formatAmount(marketItem?.price.amount)
                  }`
                )}
              </Text>
              <CurrencyIndicator currency={marketItem?.price?.currency_code} />
            </div>
          </TelegramButton>
          <Cell className={styles.topCell} multiline subhead={t("Description")}>
            <Text>{marketItem.description}</Text>
          </Cell>
          <Cell
            className={styles.bottomCell}
            multiline
            after={
              <TelegramButton onClick={handleOpenModal}>
                {t("More")}
              </TelegramButton>
            }
            subhead={t("Content")}
          >
            <Text>{t("Click more or check out on pathgame.app")}</Text>
          </Cell>
        </div>
      </div>
      {snackbar.open && (
        <MarketSnackbar
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          handleOpenRoulette={handleOpenRoulette}
        />
      )}

      {isModalOpen && (
        <InfoModal handleCloseModal={handleCloseModal} url={iframe} />
      )}
      <SuccessModal
        activeItemImage={marketItem?.image_url}
        handleOpenRoulette={handleOpenRoulette}
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
    </>
  );
};

export default MarketItem;
