import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

const SegmentedControl = ({ children }) => (
  <div className={styles.segmentControl}>{children}</div>
);

export const SegmentedControlItem = ({ selected, onClick, children }) => (
  <button
    className={cx(styles.controlItem, { [styles.active]: selected })}
    onClick={onClick}
  >
    {children}
  </button>
);

export default SegmentedControl;
