// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_giveawayBanner__N7CCd {
  justify-content: space-around;
  background-size: cover !important;
  border-radius: 8px;
  color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
  opacity: 0;
  animation: styles_fadeInAnimation__26LDc 1s forwards;
  height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.styles_bannerOverlay__8Gipe {
  padding: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
}

.styles_notcoinImg__0cQ6s {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: 50%;
}

.styles_poolAmount__KxRGf {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: bold;
}

.styles_progress__0v2YA {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.styles_row__xuQG6 {
  display: flex;
  gap: 4px;
}

@keyframes styles_fadeInAnimation__26LDc {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ForMainPage/GiveawayBanner/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,iCAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;EACA,oDAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,8BAAA;EACA,oCAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,wBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".giveawayBanner {\n  justify-content: space-around;\n  background-size: cover !important;\n  border-radius: 8px;\n  color: #fff;\n  font-size: 0.7rem;\n  cursor: pointer;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n}\n\n.bannerOverlay {\n  padding: 4px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: space-between;\n  background-color: rgba(0, 0, 0, 0.6);\n  border-radius: 8px;\n}\n\n.notcoinImg {\n  width: 36px;\n  height: 36px;\n  object-fit: contain;\n  border-radius: 50%;\n}\n\n.poolAmount {\n  font-size: 1rem;\n  color: var(--text-color);\n  font-weight: bold;\n}\n\n.progress {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 30px;\n  height: 30px;\n}\n\n.row {\n  display: flex;\n  gap: 4px;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giveawayBanner": `styles_giveawayBanner__N7CCd`,
	"fadeInAnimation": `styles_fadeInAnimation__26LDc`,
	"bannerOverlay": `styles_bannerOverlay__8Gipe`,
	"notcoinImg": `styles_notcoinImg__0cQ6s`,
	"poolAmount": `styles_poolAmount__KxRGf`,
	"progress": `styles_progress__0v2YA`,
	"row": `styles_row__xuQG6`
};
export default ___CSS_LOADER_EXPORT___;
