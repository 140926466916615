import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../pages/paths";
import { getUserRating, getUsersRating } from "../../services/ratingService";
import { fetchUserData } from "../../services/registrationService";
import { useDispatch } from "react-redux";
import useLoaderHook from "../Layout/useLoaderHook";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { useTelegram } from "../Layout/useTelegramHook";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getRatingSteps } from "../../utils/guideSteps";

export const useRatingPageHook = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [leaders, setLeaders] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { tg } = useTelegram();

  useLoaderHook();
  useAndroidScrollFix();

  const handleGoToReferralPage = () => {
    navigate(paths.referral);
  };

  useEffect(() => {
    tg.BackButton.hide();
    fetchUserData(dispatch).then((user) => {
      getUserRating().then((rating) => {
        setUserData({
          username: user?.username,
          rank: rating?.rank,
          total_stats: rating?.total_stats,
        });
      });
    });
    getUsersRating()
      .then((r) => {
        setLeaders(r);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const actionMap = {
    3: (dispatch) => dispatch(toggleHideBackground()),
    4: (dispatch) => dispatch(toggleShowBackground()),
  };

  const steps = useMemo(() => getRatingSteps(), []);

  const { currentStep } = useGuide(steps, actionMap);

  return {
    leaders,
    handleGoToReferralPage,
    isLoading,
    userData,
    currentStep,
  };
};
