import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Avatar } from "../QuestComponents";
import Popup from "reactjs-popup";
import { FaCircleQuestion } from "react-icons/fa6";
import Countdown from "react-countdown";
import { RxUpdate } from "react-icons/rx";
import QuestAttributes from "../../../pages/Quest/QuestAttributes";
import { formatAmount } from "../../../utils/formatNumbers";
import { getColorByClassName } from "../../../utils/imagesGetter";
import { attributeIcons } from "../../../utils/attributesIconsUtils";

const QuestInfo = ({
  quest,
  endDate,
  questChance,
  currentStep,
  isUpdate,
  setIsUpdate,
  getData,
}) => {
  const { t } = useTranslation();

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours}${t("h")} ${remainingMinutes}${t("m")} ${remainingSeconds}${t("s")}`;
    } else if (minutes > 0) {
      return `${minutes}${t("m")} ${remainingSeconds}${t("s")}`;
    } else {
      return `${remainingSeconds}${t("s")}`;
    }
  };

  const getChanceColorClass = () => {
    if (questChance === undefined || questChance === null) {
      return;
    }
    if (questChance <= 20) {
      return styles.red;
    } else if (questChance <= 50) {
      return styles.orange;
    } else if (questChance <= 80) {
      return styles.yellow;
    } else {
      return styles.green;
    }
  };

  return (
    <>
      <div className={styles.cell}>
        <div className={styles.before}>
          <div className={styles.levelAvatar}>
            <span
              className={cx(styles.level, {
                [styles.level100]: quest.level >= 100,
              })}
            >
              {quest.level}
            </span>
            <Avatar
              className={styles.rarityImg}
              size={48}
              src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
              alt={`Rarity ${quest.rarity_level}`}
            />
          </div>
        </div>

        <div className={styles.subtitle}>
          <div className={styles.caption}>
            <span
              className={cx(styles.info, {
                [styles.active]: currentStep === 1,
                [styles.reward]: true,
              })}
            >
              {`${t("Reward")}: ${formatAmount(quest.money_reward.amount)} ${quest.money_reward.currency_code}`}
            </span>

            <div>
              <span>{t("Rarity type")}:</span>&nbsp;
              <span style={{ color: getColorByClassName(quest.rarity_level) }}>
                {quest.rarity_level_name}
              </span>
            </div>

            <span>{`${t("Experience")}: ${quest.experience_reward}`}</span>

            <span>{`${t("Duration")}: ~${formatDuration(quest.duration_in_seconds)}`}</span>

            {quest.status !== "completed" && (
              <div className={styles.chanceRow}>
                <span>{t("Chance")}:&nbsp;</span>
                {!questChance && (
                  <Popup
                    keepTooltipInside
                    position="bottom center"
                    arrow={false}
                    trigger={
                      <div className={styles.chanceIcon}>
                        <FaCircleQuestion
                          size={10}
                          className={styles.question}
                        />
                      </div>
                    }
                  >
                    <div className={styles.popupContent}>
                      <span className={styles.caption}>
                        {t("Choose a hero to see the quest success rate")}
                      </span>
                    </div>
                  </Popup>
                )}
                {questChance && (
                  <span className={cx(getChanceColorClass())}>
                    {questChance}%
                  </span>
                )}
              </div>
            )}
          </div>

          {quest.status !== "completed" && (
            <span className={cx(styles.chip)}>{t(quest.status)}</span>
          )}
        </div>
      </div>

      {quest.status === "started" && (
        <div className={styles.countDown}>
          <div className={styles.updateRow}>
            <Countdown onComplete={() => setIsUpdate(true)} date={endDate} />
            {isUpdate && (
              <RxUpdate onClick={getData} className={styles.updateIcon} />
            )}
          </div>
        </div>
      )}

      <QuestAttributes attributeIcons={attributeIcons} quest={quest} />
    </>
  );
};

export default QuestInfo;
