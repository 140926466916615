import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { useTasksPageHook } from "../../hooks/Pages/useTasksHook";
import { formatAmount } from "../../utils/formatNumbers";
import { AiOutlineCheck, AiOutlineRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";

const Tasks = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    lastTaskElementRef,
    isFirstRender,
    handleTaskClick,
    tasksData,
  } = useTasksPageHook();

  if (isLoading && isFirstRender) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.tasksWrapper}>
      <PageTitle title={t("earn_more_coins")} />
      <div className={styles.tasksList}>
        {tasksData.map((task, index) => (
          <div
            key={index}
            className={styles.cell}
            onClick={() => handleTaskClick(task)}
            ref={index === tasksData.length - 1 ? lastTaskElementRef : null}
          >
            <div className={styles.before}>
              <img
                className={styles.taskImage}
                src={task.image_url}
                alt="task"
              />
            </div>
            <div className={styles.cellMain}>
              <span className={styles.taskTitle}>{t(task.title)}</span>
              <span className={styles.reward}>
                +{formatAmount(task.money_reward.amount)}&nbsp;
                {task.money_reward.currency_code}
              </span>
            </div>
            <div className={styles.after}>
              {task.status === "finished" ? (
                <AiOutlineCheck color="green" size={24} />
              ) : (
                <AiOutlineRight size={24} color="#f5e1a4" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tasks;
