import { useState, useEffect, useCallback, useRef } from "react";
import {
  getHeroesFilters,
  getHeroItems,
} from "../../services/heroItemsService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import paths from "../../pages/paths";
import { usePrevious } from "../Layout/usePreviousHook";

export const useHeroItems = ({
  currentInventorySlot,
  classType,
  order_by,
  heroLevel,
  isModalOpen,
}) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    selectedClass: null,
    selectedRarities: [],
    selectedRange: [1, 100],
    selectedSlots: [],
    isEquipped: null,
  });
  const [userFilters, setUserFilters] = useState(null);
  const { pathname } = useLocation();

  const observer = useRef();
  const { tg } = useTelegram();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const prevFilters = usePrevious(filters);

  const fetchData = (filters) => {
    setIsDataLoading(true);

    const getEquippedParam = () => {
      if (pathname !== paths.inventory) return "f";
      if (filters?.isEquipped) return "t";
      if (filters?.isEquipped === false) return "f";
      return undefined;
    };

    getHeroItems({
      page: filters.page,
      inventorySlot: currentInventorySlot,
      availableForClass: classType,
      is_equipped: getEquippedParam(),
      order_by,
      levelLessOrEqual: heroLevel,
      filters: filters,
    })
      .then((response) => {
        const data = response.data?.data || [];
        const paginationInfo = response.data?.pagination_info || {};

        setItems((prevItems) =>
          filters.page === 1 ? data : [...prevItems, ...data],
        );
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    if (!isModalOpen) return;
    getHeroesFilters().then((r) =>
      setUserFilters({ classes: r.classes, rarity_levels: r.rarity_levels }),
    );
  }, [isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) return;

    const otherFiltersChanged = () => {
      if (!prevFilters) return false;
      return (
        prevFilters.selectedClass !== filters.selectedClass ||
        prevFilters.selectedRarities !== filters.selectedRarities ||
        prevFilters.selectedRange[0] !== filters.selectedRange[0] ||
        prevFilters.selectedRange[1] !== filters.selectedRange[1] ||
        prevFilters.selectedSlots !== filters.selectedSlots ||
        prevFilters.isEquipped !== filters.isEquipped
      );
    };

    if (otherFiltersChanged()) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: 1,
      }));
    } else {
      fetchData(filters);
    }
  }, [filters, currentInventorySlot, isModalOpen]);

  const lastItemElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page: prevFilters.page + 1,
          }));
          if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  const refreshItems = () => {
    resetFilters();
  };

  const closeModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(null);
  };

  const totalSlots = Math.max(50, items?.length);
  const inventory = Array.from({ length: totalSlots });

  const handleItemClick = (item) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(item);
  };

  const resetFilters = () => {
    setFilters({
      page: 1,
      selectedClass: null,
      selectedRarities: [],
      selectedRange: [1, 100],
      selectedSlots: [],
      isEquipped: null,
    });
  };

  return {
    items,
    setItems,
    isDataLoading,
    lastItemElementRef,
    fetchData,
    refreshItems,
    selectedItem,
    closeModal,
    setSelectedItem,
    totalSlots,
    inventory,
    handleItemClick,
    filters,
    setFilters,
    userFilters,
    pathname,
    resetFilters,
  };
};
