import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Text, Tooltip } from "@telegram-apps/telegram-ui";
import QuestHeroes from "./QuestHeroes";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const QuestActions = ({
  quest,
  selectedHeroes,
  handleRemoveHero,
  handleOpenHeroesList,
  handleStartQuest,
  isChanceLoading,
  navigate,
  isTooltipOpen,
  currentStep,
}) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  return (
    <>
      <div className={cx(styles.description, styles.fadeIn)}>
        <span className={styles.descriptionHeader}>{t("Description")}</span>
        <Text>{quest.description}</Text>
      </div>
      <QuestHeroes
        quest={quest}
        selectedHeroes={selectedHeroes}
        handleRemoveHero={handleRemoveHero}
        handleOpenHeroesList={handleOpenHeroesList}
        navigate={navigate}
        currentStep={currentStep}
      />
      {quest.status !== "started" && quest.status !== "completed" && (
        <TelegramButton
          ref={ref}
          disabled={
            selectedHeroes.length < quest.required_heroes_count ||
            isChanceLoading
          }
          className={cx(styles.button, {
            [styles.disabledButton]: isChanceLoading,
          })}
          onClick={handleStartQuest}
        >
          {quest.status === "finished" ? t("View the results") : t("Start")}
        </TelegramButton>
      )}
      {isTooltipOpen && (
        <Tooltip className={styles.tooltip} mode="dark" targetRef={ref}>
          {t("StartQuestError")}
        </Tooltip>
      )}
    </>
  );
};

export default QuestActions;
