// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__2gP46 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 65vh;
  position: relative;
}

.styles_heroCard__sYdSt {
  width: 100%;
  height: 100%;
  position: relative;
}

.styles_loader__DxlJf {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_cardWrapper__equwJ {
  z-index: 3;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

.styles_heroImg__vLyEj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40%;
  width: 100%;
  object-fit: contain;
  z-index: 1;
}

.styles_cardTexture__xShaw {
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/NewHeroModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,UAAA;AACF;;AAEA;EACE,6BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,MAAA;EACA,OAAA;AACF","sourcesContent":[".modalContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  width: 100%;\n  height: 65vh;\n  position: relative;\n}\n\n.heroCard {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.loader {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.cardWrapper {\n  z-index: 3;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  position: absolute;\n}\n\n.heroImg {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  height: 40%;\n  width: 100%;\n  object-fit: contain;\n  z-index: 1;\n}\n\n.cardTexture {\n  position: absolute !important;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  top: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__2gP46`,
	"heroCard": `styles_heroCard__sYdSt`,
	"loader": `styles_loader__DxlJf`,
	"cardWrapper": `styles_cardWrapper__equwJ`,
	"heroImg": `styles_heroImg__vLyEj`,
	"cardTexture": `styles_cardTexture__xShaw`
};
export default ___CSS_LOADER_EXPORT___;
