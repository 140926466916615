import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "../../pages/paths";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from "../../services/tasksService";
import useLoaderHook from "../Layout/useLoaderHook";
import { fetchUserData } from "../../services/registrationService";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { toast } from "react-toastify";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import useGuide from "./useGuideHook";
import { getTasksSteps } from "../../utils/guideSteps";

export const useTasksPageHook = () => {
  const navigate = useNavigate();
  const { tg } = useTelegram();
  const { pathname } = useLocation();
  useAndroidScrollFix();
  useTelegramBackButton(-1);
  useLoaderHook();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const dispatch = useDispatch();

  const [tasksData, setTasksData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTasks = (page, reset = false) => {
    setIsLoading(true);
    getTasks(page, 10)
      .then((response) => {
        if (isFirstRender) setIsFirstRender(false);
        const tasks = response?.data?.data || [];
        const paginationInfo = response?.data?.pagination_info || {};
        setTasksData((prevTasks) => (reset ? tasks : [...prevTasks, ...tasks]));
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .catch(() => toast.error("Something went wrong, please reload page"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUserData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    fetchTasks(page);
  }, [page]);

  const handleTaskClick = async (task) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    navigate(paths.task, { state: { id: task.id, from: pathname } });
  };

  const loadMoreTasks = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const observer = useRef();
  const lastTaskElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreTasks();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading],
  );

  const steps = useMemo(() => getTasksSteps(), []);

  useGuide(steps, null);

  return {
    handleTaskClick,
    tasksData,
    isLoading,
    lastTaskElementRef,
    isFirstRender,
  };
};
