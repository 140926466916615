import { useEffect, useState } from "react";

const preloadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = resolve;
  });
};

const usePreloadImages = (images) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const preloadAllImages = async () => {
      const imagePromises = [];
      const totalImages = Object.values(images)?.length;

      let loadedImages = 0;

      const updateProgress = () => {
        loadedImages += 1;
        setProgress(Math.round((loadedImages / totalImages) * 100));
      };

      for (const image of Object.values(images)) {
        imagePromises.push(preloadImage(image).then(updateProgress));
      }

      await Promise.all(imagePromises);
    };

    preloadAllImages();
  }, []);

  return progress;
};

export default usePreloadImages;
