import React, { useEffect, useMemo, useState } from "react";
import { getMeMarketItems } from "../../services/marketService";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../services/registrationService";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import styles from "./styles.module.scss";
import {
  Caption,
  Card,
  SegmentedControl,
  Text,
} from "@telegram-apps/telegram-ui";
import { CardCell } from "@telegram-apps/telegram-ui/dist/components/Blocks/Card/components/CardCell/CardCell";
import useTelegramBackButton from "../../hooks/Layout/useTelegramBackButton";
import paths from "../paths";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useTelegram } from "../../hooks/Layout/useTelegramHook";
import cx from "classnames";
import PageTitle from "../../components/PageTitle/PageTitle";
import useGuide from "../../hooks/Pages/useGuideHook";
import {
  resetGuide,
  toggleHideBackground,
} from "../../store/slices/guideSlice";
import { getInventorySteps } from "../../utils/guideSteps";
import NFTList from "./NFT";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import InventoryItems from "./InventoryItems/InventoryItems";

const Inventory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useTelegramBackButton(-1);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const location = useLocation();

  const initialActiveType = location.state?.activeType || "boxes";

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeType, setActiveType] = useState(initialActiveType);

  const getData = () => {
    if (!isLoading) setIsLoading(true);
    getMeMarketItems()
      .then((data) => {
        setMarketItems(data);
        startGuide();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUserData(dispatch);
    getData();
  }, []);

  const navigateToRoulette = (id, type) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(
      type === "hero_items_lootbox" ? paths.treasureOpen : paths.roulette,
      { state: { id } },
    );
  };

  const navigateToMarket = () => {
    navigate(paths.market);
  };

  const actionMap = {
    1: (dispatch) => dispatch(toggleHideBackground()),
    2: (dispatch) => {
      dispatch(resetGuide());
      navigateToRoulette(marketItems?.[0]?.id);
    },
  };

  const steps = useMemo(() => getInventorySteps(), []);

  const { startGuide } = useGuide(steps, actionMap, {
    autoStart: false,
  });

  const handleSetActiveType = (type) => {
    setActiveType(type);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.inventoryWrapper, styles.fadeIn)}>
      <PageTitle isNoMargin title={"Inventory"} />
      <SegmentedControl className={styles.segmentControl}>
        <SegmentedControlItem
          className={styles.controlItem}
          onClick={() => handleSetActiveType("boxes")}
          selected={activeType === "boxes"}
        >
          {t("boxes")}
        </SegmentedControlItem>
        <SegmentedControlItem
          className={styles.controlItem}
          selected={activeType === "nft"}
          onClick={() => handleSetActiveType("nft")}
        >
          NFT
        </SegmentedControlItem>
        <SegmentedControlItem
          className={styles.controlItem}
          selected={activeType === "items"}
          onClick={() => handleSetActiveType("items")}
        >
          {t("Items")}
        </SegmentedControlItem>
      </SegmentedControl>
      {activeType === "nft" && <NFTList />}
      {activeType === "items" && <InventoryItems isInventory />}
      {activeType === "boxes" && (
        <>
          {!marketItems.length && (
            <div className={cx(styles.noData, styles.fadeIn)}>
              <img
                alt={t("No Treasures Yet")}
                src={AsyncPreloadImages.NO_TREASURES_ICON}
                className={styles.noDataImage}
              />
              <Text>{t("No Treasures Yet")}</Text>
              <Caption>
                {t(
                  "As soon as you acquire chests, they'll appear here for you to explore.",
                )}
              </Caption>
              <TelegramButton onClick={navigateToMarket}>
                {t("Get a Chest")}
              </TelegramButton>
            </div>
          )}
          {!!marketItems.length && (
            <div className={cx(styles.marketItemsList, styles.fadeIn)}>
              {marketItems.map((item) => (
                <Card
                  key={item.id}
                  className={styles.card}
                  onClick={() =>
                    navigateToRoulette(item.id, item?.market_item?.type)
                  }
                >
                  <img
                    className={styles.cardImg}
                    alt={item.market_item.title}
                    src={item.market_item.image_url}
                  />
                  <CardCell className={styles.cardCell} readOnly multiline>
                    <span className={styles.text}>
                      {item.market_item.title}
                    </span>
                  </CardCell>
                </Card>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Inventory;
