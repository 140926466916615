import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import cx from "classnames";
import { useQuestsHook } from "../../hooks/Pages/useQuestsHook";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useTranslation } from "react-i18next";
import QuestTimer from "../../components/ForQuests/QuestTimer/QuestTimer";
import Popup from "reactjs-popup";
import Countdown from "react-countdown";
import CustomSheet from "../../components/ui/Modal/Modal";
import CauldronDetail from "../CauldronDetail/CauldronDetail";
import SegmentedControl, {
  SegmentedControlItem,
} from "../../components/SegmentControl/SegmentControl";
import { QuestList } from "../../components/ForQuests/QuestComponents";

const Quests = () => {
  const { t } = useTranslation();
  const {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    questsCounts,
    nextQuestTime,
    handleGoToHistory,
    currentStep,
    isModalOpen,
    setIsModalOpen,
    activeItem,
  } = useQuestsHook();

  const renderSegmentedControlItem = (status, label) => (
    <SegmentedControlItem
      selected={activeStatus === status}
      onClick={() => handleSetActiveStatus(status)}
    >
      {t(label)}&nbsp;
      {questsCounts && questsCounts[status] !== undefined
        ? `${questsCounts[status]}`
        : ""}
    </SegmentedControlItem>
  );

  if (isLoading && page === 1) {
    return <LoaderComponent />;
  }

  const countdownRenderer = ({ hours, minutes, seconds }) => (
    <span>
      {hours > 0 && `${hours}${t("h")} `}
      {minutes > 0 && `${minutes}${t("m")} `}
      {seconds}
      {t("s")}
    </span>
  );

  return (
    <>
      <div className={cx(styles.wrapper, styles.fadeIn)}>
        <div className={styles.column}>
          <PageTitle
            isNoMargin
            title={t("Quests")}
            leftComponent={
              activeStatus === "new" && (
                <Popup
                  keepTooltipInside
                  position="bottom center"
                  arrow={false}
                  trigger={
                    <div>
                      <QuestTimer
                        className={cx({ [styles.blink]: currentStep === 2 })}
                        startQuestTime={nextQuestTime?.startQuestTime}
                        nextQuestTime={nextQuestTime?.nextQuestTime}
                      />
                    </div>
                  }
                >
                  <div className={styles.popupContent}>
                    <span>{t("Next quests")}:&nbsp;</span>
                    <Countdown
                      autoStart
                      date={nextQuestTime?.nextQuestTime}
                      renderer={countdownRenderer}
                    />
                  </div>
                </Popup>
              )
            }
            rightComponent={
              <img
                className={cx(styles.icon, {
                  [styles.blink]: currentStep === 3,
                })}
                src={AsyncPreloadImages.COMPLETED_QUESTS}
                alt={t("completed_quests")}
                onClick={handleGoToHistory}
              />
            }
          />
          <div className={styles.segmentControlWrapper}>
            <SegmentedControl>
              {renderSegmentedControlItem("new", "Available")}
              {renderSegmentedControlItem("active", "Active")}
            </SegmentedControl>
          </div>
        </div>

        {isDataLoading && page === 1 ? (
          <div className={styles.loader}>
            <LoaderComponent />
          </div>
        ) : !quests?.length ? (
          <div className={styles.noDataWrapper}>
            <img
              className={styles.noDataImg}
              alt={t("no_quest")}
              src={AsyncPreloadImages.NO_QUESTS}
            />
          </div>
        ) : (
          <QuestList
            t={t}
            quests={quests}
            navigateToQuest={navigateToQuest}
            lastQuestElementRef={lastQuestElementRef}
          />
        )}
      </div>
      <CustomSheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CauldronDetail data={activeItem} t={t} />
      </CustomSheet>
    </>
  );
};

export default Quests;
