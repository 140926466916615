import React from "react";
import { Sheet } from "react-modal-sheet";
import styles from "./styles.module.scss";

const CustomSheet = ({ isOpen, onClose, children, disableDrag }) => {
  return (
    <Sheet
      disableDrag={disableDrag}
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Sheet.Container className={styles.modalContainer}>
        <Sheet.Header className={styles.header} />
        <Sheet.Content className={styles.sheetContent}>
          {children}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={onClose} />
    </Sheet>
  );
};

export default CustomSheet;
