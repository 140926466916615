import { useEffect, useMemo, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { getUserReferrals } from "../../services/referralService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReferralAmount } from "../../services/ratingService";
import { fetchUserData } from "../../services/registrationService";
import useLoaderHook from "../Layout/useLoaderHook";
import { useTranslation } from "react-i18next";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getReferralSteps } from "../../utils/guideSteps";
import { showSuccessToast } from "../../components/ui/Toast/Toast";

export const useReferralHook = () => {
  const location = useLocation();
  const taskId = location.state?.id;
  const backState = taskId ? { state: { id: taskId } } : {};

  useTelegramBackButton(-1, backState);
  useLoaderHook();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [referralAmount, setReferralAmount] = useState(null);
  const [totalEntries, setTotalEntries] = useState(0);
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const dispatch = useDispatch();
  const colorScheme = telegramData?.colorScheme;

  const { tg } = useTelegram();
  const { t } = useTranslation();
  const userData = useSelector((state) => state.telegram.user);

  const referralLink = `https://t.me/ThePathGameBot/discover?startapp=${userData?.id}`;

  const fetchReferrals = (page) => {
    return getUserReferrals(page, 10)
      .then((response) => {
        const data = response?.data?.data || [];
        const paginationInfo = response.data.pagination_info || {};
        setUsers((prevUsers) => [...prevUsers, ...data]);
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
        setTotalEntries(response?.data?.pagination_info?.total_entries || 0);
      })
      .catch((error) => console.error("Failed to fetch referrals:", error));
  };

  useEffect(() => {
    const fetchReferralAmount = async () => {
      try {
        const response = await getReferralAmount();
        setReferralAmount(response);
      } catch (error) {
        console.error("Failed to fetch referral amount:", error);
      }
    };
    fetchUserData(dispatch);
    fetchReferralAmount();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await fetchReferrals(page);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);

      console.log("Referral link copied successfully!");
      tg.HapticFeedback.notificationOccurred("success");
      handleShowSnackbar(); // Показываем сообщение об успешном копировании
    } catch (err) {
      console.error("Failed to copy referral link: ", err);
      tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleShareLink = () => {
    const text = t("shareLink");
    const fullPath = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(text)}`;

    tg.HapticFeedback.selectionChanged();
    tg.openTelegramLink(fullPath);
  };

  const loadMoreReferrals = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
    showSuccessToast(t("Copied to clipboard"));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const actionMap = {
    1: (dispatch) => dispatch(toggleHideBackground()),
    4: (dispatch) => dispatch(toggleShowBackground()),
  };

  const steps = useMemo(() => getReferralSteps(), []);

  const { startGuide, currentStep } = useGuide(steps, actionMap, {
    autoStart: false,
  });

  useEffect(() => {
    if (isLoading || page !== 1) return;
    startGuide();
  }, [isLoading, page]);

  return {
    users,
    isLoading,
    handleCopyLink,
    handleShareLink,
    referralAmount,
    loadMoreReferrals,
    page,
    totalEntries,
    colorScheme,
    snackbarOpen,
    handleCloseSnackbar,
    currentStep,
    t,
  };
};
