import React from "react";
import styles from "./styles.module.scss";
import Button from "../../components/ui/Button/Button";

const SimpleTask = ({ handleSubmitTask, isButtonDisabled, task, t }) => {
  return (
    <Button
      disabled={task.status === "started" && isButtonDisabled}
      className={styles.telegramButton}
      onClick={handleSubmitTask}
    >
      {task.status === "started" ? t("Claim") : task.button_text}
    </Button>
  );
};

export default SimpleTask;
