import React from "react";
import { Modal } from "@telegram-apps/telegram-ui";
import styles from "./styles.module.scss";
import TelegramButton from "../common/TelegramButton/TelegramButton";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NftModal = ({ isOpen, setIsOpen, handleMint }) => {
  const { t } = useTranslation();

  const whiteList = ["289182513", "122603602"];

  const telegramData = useSelector((state) => state.telegram.telegramData);
  const user = telegramData?.user;

  const isDisabled = !whiteList.includes(user?.id.toString());

  const onClick = () => {
    if (isDisabled) return;
    handleMint();
  };

  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) setIsOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <div className={styles.modalContent}>
        <h2 className={styles.title}>{t("mintingYourHero")}</h2>
        <p className={styles.congrats}>{t("congratsMessage")}</p>

        <h3 className={styles.subtitle}>{t("whatIsMintingHero")}</h3>
        <ul className={styles.list}>
          <li>
            <strong>{t("uniqueness")}:</strong> {t("uniquenessDescription")}
          </li>
          <li>
            <strong>{t("web3Integration")}:</strong>{" "}
            {t("web3IntegrationDescription")}
          </li>
          <li>
            <strong>{t("irreversibility")}:</strong>{" "}
            {t("irreversibilityDescription")}
          </li>
          <li>
            <strong>{t("preservationOfCharacteristics")}:</strong>{" "}
            {t("preservationOfCharacteristicsDescription")}
          </li>
        </ul>

        <p className={styles.warning}>
          <strong>{t("important")}!</strong> {t("confirmationWarning")}
        </p>

        <p className={styles.callToAction}>{t("readyToCreateHero")}</p>

        <TelegramButton
          disabled={isDisabled}
          onClick={onClick}
          className={styles.button}
        >
          {t("continue")}
        </TelegramButton>
      </div>
    </Modal>
  );
};

export default NftModal;
