import React from "react";
import styles from "./styles.module.scss";
import LevelFilter from "./LevelFilter/LevelFilter";
import ClassFilter from "./ClassFilter/ClassFilter";
import RarityFilter from "./RarityFIlter/RarityFilter";
import SlotFilter from "./SlotFilter/SlotFilter";
import paths from "../../../pages/paths";
import { TbFilterOff } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { useSelector } from "react-redux";

const InventoryFilters = ({
  filters,
  setFilters,
  userFilters,
  pathname,
  resetFilters,
}) => {
  const isHeroInventory = pathname === paths.hero;

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  const { t } = useTranslation();

  const updateFilter = (key, value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const createFilterUpdater = (key) => (value) => updateFilter(key, value);

  const areFiltersActive = Boolean(
    filters.selectedClass ||
      filters.selectedRarities.length ||
      filters.selectedRange[0] !== 1 ||
      filters.selectedRange[1] !== 100 ||
      filters.selectedSlots.length ||
      filters.isEquipped === false ||
      filters.isEquipped === true,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.column}>
        <div className={styles.row}>
          {!isHeroInventory && (
            <ClassFilter
              t={t}
              classes={userFilters?.classes}
              selectedClass={filters.selectedClass}
              setSelectedClass={createFilterUpdater("selectedClass")}
              tg={tg}
              hapticEnabled={hapticEnabled}
            />
          )}
          <RarityFilter
            rarityLevels={userFilters?.rarity_levels}
            selectedRarities={filters.selectedRarities}
            setSelectedRarities={createFilterUpdater("selectedRarities")}
            t={t}
            tg={tg}
            hapticEnabled={hapticEnabled}
          />
          {isHeroInventory && (
            <LevelFilter
              selectedRange={filters.selectedRange}
              setSelectedRange={createFilterUpdater("selectedRange")}
              isHeroInventory={isHeroInventory}
              t={t}
              tg={tg}
              hapticEnabled={hapticEnabled}
            />
          )}
        </div>
        {!isHeroInventory && (
          <div className={styles.row}>
            <LevelFilter
              selectedRange={filters.selectedRange}
              setSelectedRange={createFilterUpdater("selectedRange")}
              tg={tg}
              hapticEnabled={hapticEnabled}
              t={t}
            />
            <SlotFilter
              t={t}
              selectedSlots={filters.selectedSlots}
              setSelectedSlots={createFilterUpdater("selectedSlots")}
              tg={tg}
              hapticEnabled={hapticEnabled}
            />
          </div>
        )}
        <button
          className={`${styles.resetButton} ${
            areFiltersActive ? "" : styles.disabled
          }`}
          onClick={() => {
            if (hapticEnabled) tg.HapticFeedback.selectionChanged();
            resetFilters();
          }}
          disabled={!areFiltersActive}
        >
          <div>
            <TbFilterOff />
          </div>
        </button>
      </div>
      {!isHeroInventory && (
        <div className={styles.centeredRow}>
          <button
            className={styles.triggerButton}
            onClick={() => {
              const nextState =
                filters.isEquipped === null
                  ? true
                  : filters.isEquipped
                    ? false
                    : null;
              updateFilter("isEquipped", nextState);
            }}
          >
            {filters.isEquipped === true
              ? t("isEquipped")
              : filters.isEquipped === false
                ? t("notEquipped")
                : t("All")}
          </button>
        </div>
      )}
    </div>
  );
};

export default InventoryFilters;
