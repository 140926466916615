import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTelegram } from "../Layout/useTelegramHook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  fetchUserData,
  getNftCollectionAddress,
} from "../../services/registrationService";
import TonWeb from "tonweb";
import paths from "../../pages/paths";

export const useNftPageHook = () => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userWallet, setUserWallet] = useState(null);
  const [nftCollection, setNftCollection] = useState(null);
  const [nftLoading, setNftLoading] = useState(true);
  const dispatch = useDispatch();
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const getUrl = (collection, id) => {
    const baseUrl = process.env.REACT_APP_NFT_URL;
    return baseUrl.replace(":collection", collection).replace(":address", id);
  };

  const getCollectionUrl = (collection, id) => {
    const baseUrl = process.env.REACT_APP_COLLECTION_URL;
    return baseUrl.replace(":collection", collection).replace(":address", id);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [userData, collectionData] = await Promise.all([
          fetchUserData(dispatch),
          getNftCollectionAddress(),
        ]);

        setUserWallet(userData?.user_settings?.ton_wallet_address || null);
        if (!userData?.user_settings?.ton_wallet_address) {
          setNftLoading(false);
        }
        setNftCollection(collectionData?.collection_address || null);
      } catch {
        setError(t("errorLoadingData"));
        setNftLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const convertToUserFriendlyAddress = (rawAddress) => {
    const address = new TonWeb.utils.Address(rawAddress);
    return address.toString(true, true, false, false);
  };

  useEffect(() => {
    if (!userWallet || !nftCollection) return;

    const fetchNFTs = async () => {
      try {
        const response = await fetch(
          getCollectionUrl(nftCollection, userWallet),
        );
        const data = await response.json();

        if (response.ok) {
          setNfts(data.nft_items);
        } else {
          setError(t("errorFetchingNFTs"));
        }
      } catch (err) {
        setError(t("networkError"));
      } finally {
        setNftLoading(false);
      }
    };

    fetchNFTs();
  }, [userWallet, nftCollection]);

  const handleClick = (nft) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    tg.openLink(
      getUrl(
        convertToUserFriendlyAddress(nft?.collection?.address),
        convertToUserFriendlyAddress(nft?.address),
      ),
    );
  };

  const handleGoToCollection = () => {
    tg.openLink(getUrl(nftCollection, ""));
  };

  const handleGoToInventory = () => {
    navigate(paths.profile);
  };
  return {
    loading,
    nftLoading,
    userWallet,
    handleGoToInventory,
    t,
    error,
    nfts,
    handleClick,
    handleGoToCollection,
  };
};
