import React, { useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import { Avatar, Cell, Headline, Info, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../../components/common/LoaderComponent/LoaderComponent";
import { formatAmount } from "../../../utils/formatNumbers";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";

const ScrollableList = ({ users, loadMore, isLoading, page, colorScheme }) => {
  const { t } = useTranslation();
  const observer = useRef();

  const lastUserElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, loadMore],
  );

  const renderUser = (user, index) => {
    if (users.length === index + 1) {
      return (
        <Cell
          ref={lastUserElementRef}
          className={styles.cell}
          key={index}
          after={
            <Info subtitle={t("received")} type="text">
              <span className={styles.noWrap}>
                {`+${formatAmount(user?.amount)} ${user.currency_code}`}
              </span>
            </Info>
          }
          before={
            <Avatar
              size={48}
              className={styles.avatar}
              src={
                colorScheme !== "dark"
                  ? AsyncPreloadImages.REFERRAL_DARK
                  : AsyncPreloadImages.REFERRAL_WHITE
              }
            />
          }
        >
          {user?.referral_user?.username || "-"}
        </Cell>
      );
    } else {
      return (
        <Cell
          className={styles.cell}
          key={index}
          after={
            <Info subtitle={t("received")} type="text">
              <span className={styles.noWrap}>
                {`+${formatAmount(user?.amount)} ${user.currency_code}`}
              </span>
            </Info>
          }
          before={
            <Avatar
              size={48}
              className={styles.avatar}
              src={
                colorScheme !== "dark"
                  ? AsyncPreloadImages.REFERRAL_DARK
                  : AsyncPreloadImages.REFERRAL_WHITE
              }
            />
          }
        >
          {user?.referral_user?.username || "-"}
        </Cell>
      );
    }
  };

  return (
    <div className={styles.listWrapper}>
      {!users.length && (
        <div className={styles.noData}>
          <img src={AsyncPreloadImages.NO_REFERRAL_ICON} alt={"no"} />
          <Headline level="1" weight="1">
            {t("noHistory")}
          </Headline>
          <Text weight="1">{t("inviteFriendsMessage")}</Text>
        </div>
      )}
      {!!users.length && (
        <ul className={styles.list}>
          {users.map((user, index) => renderUser(user, index))}
          {isLoading && page > 1 && <LoaderComponent />}
        </ul>
      )}
    </div>
  );
};

export default ScrollableList;
