import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import CustomSheet from "../../../ui/Modal/Modal";
import {
  getSortOptions,
  getAttributeOptions,
} from "../../../../utils/imagesGetter";
import RadioButton from "../../../ui/RadioButton/RadioButton";
import CustomCheckbox from "../../../ui/Checkbox/Checkbox";

const SortModalForQuest = ({
  isModalOpen,
  setIsModalOpen,
  applySorting,
  selectedOption,
  handleSetFavorites,
  favoritesOption,
}) => {
  const { t } = useTranslation();

  const handleOptionChange = (value) => {
    applySorting(value);
    setIsModalOpen(false);
  };

  const sortOptions = getSortOptions(t);
  const attributeOptions = getAttributeOptions(t);

  return (
    <CustomSheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className={styles.content}>
        <div className={styles.filtersContent}>
          <div className={styles.section}>
            <span>{t("General")}</span>
            <CustomCheckbox
              label={t("Favorites First")}
              checked={favoritesOption === "enabled"}
              onChange={() => handleSetFavorites()}
            />
          </div>

          <div className={styles.section}>
            <span>{t("Sort by Overall Stats")}</span>
            {sortOptions.map((option, index) => (
              <RadioButton
                key={index}
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option.value)}
                label={option.label}
              />
            ))}
          </div>

          <div className={styles.section}>
            <span>{t("Sort by Attribute in Descending Order")}</span>
            {attributeOptions.map((option, index) => (
              <RadioButton
                key={index}
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option.value)}
                label={option.label}
              />
            ))}
          </div>
        </div>
      </div>
    </CustomSheet>
  );
};

export default SortModalForQuest;
