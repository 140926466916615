// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__oq5lZ {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: styles_fadeInAnimation__3AxrY 1s forwards;
}

.styles_topSide__EdoWh {
  height: 55%;
  width: 100%;
}

.styles_bottomSide__9lY3V {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 45%;
  width: 100%;
  gap: 30px;
}

.styles_slots__x44Ol {
  display: flex;
  gap: 16px;
  width: 100%;
  flex: 1 1;
  align-items: flex-end;
  justify-content: center;
}

.styles_mainContainer__8FTMK {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: styles_fadeInAnimation__3AxrY 1s forwards;
}

.styles_actionsRow__\\+e44e {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.styles_actionsRow__\\+e44e > button {
  width: 100%;
  height: min-content;
}

@keyframes styles_fadeInAnimation__3AxrY {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Cauldron/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,oDAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,WAAA;EACA,SAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,oDAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,QAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,mBAAA;AAEJ;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF","sourcesContent":[".wrapper {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.topSide {\n  height: 55%;\n  width: 100%;\n}\n\n.bottomSide {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  height: 45%;\n  width: 100%;\n  gap: 30px;\n}\n\n.slots {\n  display: flex;\n  gap: 16px;\n  width: 100%;\n  flex: 1;\n  align-items: flex-end;\n  justify-content: center;\n}\n\n.mainContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  height: 100%;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.actionsRow {\n  width: 100%;\n  display: flex;\n  gap: 8px;\n  justify-content: center;\n  align-items: center;\n  > button {\n    width: 100%;\n    height: min-content;\n  }\n}\n\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__oq5lZ`,
	"fadeInAnimation": `styles_fadeInAnimation__3AxrY`,
	"topSide": `styles_topSide__EdoWh`,
	"bottomSide": `styles_bottomSide__9lY3V`,
	"slots": `styles_slots__x44Ol`,
	"mainContainer": `styles_mainContainer__8FTMK`,
	"actionsRow": `styles_actionsRow__+e44e`
};
export default ___CSS_LOADER_EXPORT___;
