import React, { forwardRef } from "react";
import styles from "../../pages/Quests/styles.module.scss";
import cx from "classnames";
import { formatAmount } from "../../utils/formatNumbers";
import {
  getColorByStatus,
  renderAttributeIcons,
} from "../../utils/attributesIconsUtils";

export const Avatar = ({ src, alt, className }) => (
  <img src={src} alt={alt} className={cx(styles.avatar, className)} />
);

export const QuestCard = forwardRef(({ quest, onClick, t, isHistory }, ref) => (
  <div className={styles.card} onClick={onClick} ref={ref}>
    <div className={styles.imageWrapper}>
      <Avatar
        src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
        alt={`Rarity ${quest.rarity_level}`}
        className={styles.rarityImg}
      />
      <span
        className={cx(styles.level, {
          [styles.level100]: quest.level >= 100,
        })}
      >
        {quest.level}
      </span>
    </div>
    <div className={styles.cardContent}>
      <span
        className={cx(styles.questTitle, { [styles.historyTitle]: isHistory })}
      >
        {quest.name}
      </span>
      <span className={styles.questReward}>
        {t("Reward")}: {formatAmount(quest.money_reward.amount)}{" "}
        {quest.money_reward.currency_code}
      </span>
      <div className={styles.attributeIcons}>{renderAttributeIcons(quest)}</div>
    </div>
    {!isHistory && (
      <span
        className={cx(styles.statusBadge, {
          [styles.blink]: quest.status === "started",
        })}
        style={{ background: getColorByStatus(quest.status) }}
      />
    )}
  </div>
));

export const QuestList = ({
  quests,
  navigateToQuest,
  lastQuestElementRef,
  t,
  isHistory,
}) => (
  <div className={styles.questList}>
    {quests.map((quest, index) => (
      <QuestCard
        isHistory={isHistory}
        t={t}
        key={quest.id}
        quest={quest}
        onClick={() => navigateToQuest(quest.id)}
        ref={index === quests.length - 1 ? lastQuestElementRef : null}
      />
    ))}
  </div>
);
