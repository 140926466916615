import React from "react";
import styles from "./styles.module.scss";
import { LargeTitle } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import cx from "classnames";

const PageTitle = ({
  title,
  children,
  isNoMargin,
  leftComponent,
  rightComponent,
}) => {
  const { t } = useTranslation();

  return (
    <LargeTitle
      className={cx(styles.title, { [styles.noMargin]: isNoMargin })}
      weight="3"
    >
      <div className={styles.titleContent}>
        {leftComponent && (
          <div className={styles.leftComponent}>{leftComponent}</div>
        )}
        <span className={styles.text}>{title ? t(title) : children}</span>
        {rightComponent && (
          <div className={styles.rightComponent}>{rightComponent}</div>
        )}
      </div>
    </LargeTitle>
  );
};

export default PageTitle;
