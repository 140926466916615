import React from "react";
import styles from "./styles.module.scss";
import Button from "../../components/ui/Button/Button";

const ValidatedTask = ({
  t,
  accountName,
  handleSetAccountName,
  handleSubmitTask,
  task,
  isButtonDisabled,
}) => {
  return (
    <>
      {task.status === "started" && (
        <>
          <label htmlFor="accountName" className={styles.label}>
            {t("YOUR SOCIAL ACCOUNT HANDLE")}
          </label>
          <input
            className={styles.input}
            id="accountName"
            name="accountName"
            type="text"
            required
            minLength="3"
            placeholder={t("Please enter your social account handle")}
            value={accountName}
            onChange={handleSetAccountName}
          />
        </>
      )}
      <Button
        disabled={task.status === "started" && isButtonDisabled}
        className={styles.telegramButton}
        onClick={handleSubmitTask}
      >
        {task.status === "started" ? t("Claim") : task.button_text}
      </Button>
    </>
  );
};

export default ValidatedTask;
