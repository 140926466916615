import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useTelegram } from "../Layout/useTelegramHook";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import {
  finishTask,
  getTaskById,
  getTaskStatus,
  startTask,
} from "../../services/tasksService";
import { useTranslation } from "react-i18next";
import { updateSocialLinks } from "../../services/settingsService";
import { getTaskType, tasksTypes } from "../../utils/tasksUtils";
import { showErrorToast } from "../../components/ui/Toast/Toast";

export const useTaskHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useTelegramBackButton(-1, {}, true);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { id } = location.state || {};
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const [task, setTask] = useState(null);

  const [accountName, setAccountName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [socialNetworks, setSocialNetworks] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (!id) {
      navigate(paths.tasks);
      return;
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (task && task.status === "started") {
      getTaskStatus(task.id)
        .then((r) => {
          if (r?.result) setIsButtonDisabled(false);
        })
        .catch((error) => console.error(error));
    }
  }, [task]);

  const fetchData = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    fetchUserData(dispatch)
      .then((user) => {
        getTaskById(id).then((task) => {
          setTask(task);
          setSocialNetworks(user?.user_settings?.social_networks);
          if (task?.machine_name === tasksTypes.subscribe_to_social_network) {
            setAccountName(
              user?.user_settings?.social_networks?.[getTaskType(task)] || "",
            );
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateUserSettings = (
    taskType,
    currentAccountName,
    finalizeTask,
  ) => {
    if (accountName && accountName !== currentAccountName) {
      const updatedSocialNetworks = {
        ...socialNetworks,
        [taskType]: accountName,
      };
      updateSocialLinks({ social_networks: updatedSocialNetworks })
        .then(() => {
          fetchUserData(dispatch).then((user) => {
            setSocialNetworks(user?.user_settings?.social_networks);
            finalizeTask();
          });
        })
        .catch((error) => {
          console.error("Failed to update user settings", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchUserData(dispatch).then((user) => {
        setSocialNetworks(user?.user_settings?.social_networks);
        finalizeTask();
        setIsLoading(false);
      });
    }
  };

  const isValidUrl = (string) => {
    const refCodeRegex =
      /^(https?:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    return !!refCodeRegex.test(string);
  };

  const handleTaskCompletion = (newTask) => {
    setTask(newTask);
    if (hapticEnabled) {
      tg.HapticFeedback.notificationOccurred("success");
    }
    setIsLoading(false);
  };

  const handleTaskError = (error) => {
    console.error("Failed to update", error);
    setIsLoading(false);
    showErrorToast(t("Wrong social link"));
    if (hapticEnabled) {
      tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleSubmitTask = () => {
    if (!task) return;
    const taskType = getTaskType(task);

    const processTask = () => {
      startTask(task.id)
        .then((newTask) => {
          switch (task.machine_name) {
            case tasksTypes.complete_onboarding:
              fetchData();
              break;
            case tasksTypes.subscribe_to_social_network:
              if (task.status === "created") {
                tg.openLink(task.url);
              }
              break;
            case tasksTypes.connect_ton_wallet:
              navigate(paths.profile, {
                state: { from: location.pathname, id: task.id },
              });
              break;
            case tasksTypes.invite_3_referrals:
              navigate(paths.referral, {
                state: { from: location.pathname, id: task.id },
              });
              break;
            case tasksTypes.subscribe_to_telegram:
              tg.openTelegramLink(task.url);
              break;
            default:
              setIsLoading(false);
              return;
          }
          handleTaskCompletion(newTask);
        })
        .catch(handleTaskError);
    };

    const finalizeTask = () => {
      finishTask(task.id).then(handleTaskCompletion).catch(handleTaskError);
    };

    setIsLoading(true);

    switch (task.machine_name) {
      case tasksTypes.subscribe_to_social_network:
        if (task.status === "created") {
          processTask();
        } else if (task.status === "started") {
          if (accountName?.length <= 3 || !isValidUrl(accountName)) {
            handleTaskError("Invalid account name or URL");
            return;
          }
          const currentAccountName = socialNetworks?.[taskType];
          handleUpdateUserSettings(taskType, currentAccountName, finalizeTask);
        } else if (task.status === "finished") {
          tg.openLink(task.url);
          setIsLoading(false);
        }
        break;

      case tasksTypes.complete_onboarding:
        if (task.status === "created") {
          processTask();
        } else if (task.status === "started") {
          finalizeTask();
        }
        break;

      case tasksTypes.connect_ton_wallet:
      case tasksTypes.invite_3_referrals:
        if (task.status === "created") {
          processTask();
        } else if (task.status === "started") {
          finalizeTask();
        } else if (task.status === "finished") {
          const navigatePath =
            task.machine_name === tasksTypes.connect_ton_wallet
              ? paths.profile
              : paths.referral;
          navigate(navigatePath, {
            state: { from: location.pathname, id: task.id },
          });
          setIsLoading(false);
        }
        break;

      case tasksTypes.subscribe_to_telegram:
        if (task.status === "created") {
          tg.openTelegramLink(task.url);
          processTask();
        } else if (task.status === "started") {
          finalizeTask();
        } else if (task.status === "finished") {
          tg.openTelegramLink(task.url);
          setIsLoading(false);
        }
        break;

      default:
        setIsLoading(false);
        break;
    }
  };

  const handleSetAccountName = (e) => {
    setAccountName(e.target.value);
  };

  const isValidatedTask =
    task?.machine_name !== tasksTypes.connect_ton_wallet &&
    task?.machine_name !== tasksTypes.invite_3_referrals &&
    task?.machine_name !== tasksTypes.subscribe_to_telegram &&
    task?.machine_name !== tasksTypes.complete_onboarding;

  const handleNavigate = () => {
    if (!task) return;
    switch (task.machine_name) {
      case tasksTypes.subscribe_to_telegram:
        tg.openTelegramLink(task.url);
        break;
      case tasksTypes.invite_3_referrals:
        navigate(paths.referral, {
          state: { from: location.pathname, id: task.id },
        });
        break;
      case tasksTypes.connect_ton_wallet:
        navigate(paths.profile, {
          state: { from: location.pathname, id: task.id },
        });
        break;
      case tasksTypes.subscribe_to_social_network:
        tg.openLink(task.url);
        break;
      default:
        break;
    }
  };

  return {
    task,
    accountName,
    handleSetAccountName,
    isLoading,
    isValidatedTask,
    t,
    handleSubmitTask,
    isButtonDisabled,
    handleNavigate,
  };
};
