import { useDispatch, useSelector } from "react-redux";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useTelegram } from "../Layout/useTelegramHook";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { getMeMarketItems } from "../../services/marketService";
import { fetchUserData } from "../../services/registrationService";
import paths from "../../pages/paths";
import {
  resetGuide,
  toggleHideBackground,
} from "../../store/slices/guideSlice";
import { getInventorySteps } from "../../utils/guideSteps";
import useGuide from "./useGuideHook";

export const useInventoryHook = () => {
  const dispatch = useDispatch();
  useTelegramBackButton(-1);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const location = useLocation();

  const initialActiveType = location.state?.activeType || "boxes";

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeType, setActiveType] = useState(initialActiveType);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const getData = () => {
    if (!isLoading) setIsLoading(true);
    getMeMarketItems()
      .then((data) => {
        setMarketItems(data);
        startGuide();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchUserData(dispatch);
    getData();
  }, []);

  const navigateToRoulette = (id, type, item) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (type === "brew_ticket") {
      setIsModalOpen(true);
      setActiveItem(item);
      return;
    }
    navigate(
      type === "hero_items_lootbox" ? paths.treasureOpen : paths.roulette,
      { state: { id } },
    );
  };

  const navigateToMarket = () => {
    navigate(paths.market);
  };

  const actionMap = {
    1: (dispatch) => dispatch(toggleHideBackground()),
    2: (dispatch) => {
      dispatch(resetGuide());
      navigateToRoulette(marketItems?.[0]?.id);
    },
  };

  const steps = useMemo(() => getInventorySteps(), []);

  const { startGuide } = useGuide(steps, actionMap, {
    autoStart: false,
  });

  const handleSetActiveType = (type) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setActiveType(type);
  };
  return {
    activeType,
    handleSetActiveType,
    navigateToMarket,
    isModalOpen,
    isLoading,
    marketItems,
    navigateToRoulette,
    setIsModalOpen,
    activeItem,
  };
};
