import React from "react";
import { AsyncPreloadImages } from "./asyncPreload";

export const attributeIcons = {
  strength: AsyncPreloadImages.STRENGTH_ICON,
  agility: AsyncPreloadImages.AGILITY_ICON,
  intelligence: AsyncPreloadImages.INTELLIGENCE_ICON,
  endurance: AsyncPreloadImages.ENDURANCE_ICON,
  charisma: AsyncPreloadImages.CHARISMA_ICON,
};

export const getColorByStatus = (status) => {
  const colors = {
    failed: "red",
    created: "cornflowerblue",
    started: "orange",
    finished: "purple",
  };
  return colors[status] || "black";
};

export const renderAttributeIcons = (quest) =>
  Object.entries({
    strength: quest.strength,
    agility: quest.agility,
    intelligence: quest.intelligence,
    endurance: quest.endurance,
    charisma: quest.charisma,
  })
    .filter(([_, value]) => value > 0)
    .map(([key]) => (
      <img
        key={key}
        height="20px"
        width="20px"
        src={attributeIcons[key]}
        alt={key}
      />
    ));

export const getAttributeValue = (attributeName) => {
  const attributeValues = {
    strength: "strength_desc",
    agility: "agility_desc",
    intelligence: "intelligence_desc",
    endurance: "endurance_desc",
    charisma: "charisma_desc",
    luck: "luck_desc",
  };

  return attributeValues[attributeName.toLowerCase()] || null;
};
