export const tasksTypes = {
  connect_ton_wallet: "connect_ton_wallet",
  invite_3_referrals: "invite_3_referrals",
  subscribe_to_social_network: "subscribe_to_social_network",
  subscribe_to_telegram: "subscribe_to_telegram",
  complete_onboarding: "complete_onboarding",
};

export const getTaskType = (task) => {
  if (!task || !task.title) {
    return "";
  }

  const title = task.title.toLowerCase();

  if (title.includes("facebook")) {
    return "facebook";
  } else if (title.includes("instagram")) {
    return "instagram";
  } else if (title.includes("youtube")) {
    return "youtube";
  } else if (title.includes("x")) {
    return "x";
  } else {
    return "";
  }
};
