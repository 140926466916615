import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Game from "./pages/Game";
import usePreloadImages from "./hooks/Layout/usePreloadImages";
import useAudioHook from "./hooks/Layout/useAudioHook";
import { PreloadImages } from "./utils/globals";
import CoinLoader from "./components/CoinLoader/CoinLoader";
import { SocketProvider } from "./utils/socketContext";
import Registration from "./pages/Registration/Registration";
import CustomToast from "./components/ui/Toast/Toast";

const App = () => {
  const progress = usePreloadImages(PreloadImages);
  const isGame = useSelector((state) => state.telegram.isGame);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useAudioHook({ loadingComplete });

  const telegramData = useSelector((state) => state.telegram.telegramData);
  const user = telegramData?.user;

  useEffect(() => {
    if (progress === 100) {
      setLoadingComplete(true);
    }
  }, [progress]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window?.telegramAnalytics?.init({
        token: process.env.REACT_APP_ANALYTICS_TOKEN,
        appName: "ThePath",
      });
    }
  }, []);

  if (!loadingComplete) {
    return (
      <>
        <CoinLoader />
      </>
    );
  }

  return (
    <SocketProvider telegramData={telegramData} user={user}>
      <CustomToast />
      {!isGame && <Registration />}
      {isGame && <Game />}
    </SocketProvider>
  );
};

export default App;
