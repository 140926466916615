import { axiosInstance, axiosListInstance } from "./axiosService";
import { apiUrl } from "../utils/globals";

export const getUserQuests = (page = 1, pageSize = 10, statuses) => {
  const isCompletedStatus = statuses.includes("completed");
  return axiosListInstance.get(`${apiUrl}me/quests`, {
    params: {
      page,
      page_size: pageSize,
      statuses: statuses,
      ...(isCompletedStatus && { order_by: "ended_at_desc" }),
    },
  });
};

export const getUserQuestById = (id) => {
  return axiosInstance.get(`/me/quests/${id}`);
};
export const getNextQuestTime = () => {
  return axiosInstance.get(`/quests/quests_generation_at`);
};
