import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useAndroidScrollFix = () => {
  const { t } = useTranslation();
  const telegramData = useSelector((state) => state.telegram.telegramData);

  useEffect(() => {
    if (telegramData) {
      const isAndroid =
        telegramData.platform === "android" ||
        /Android/i.test(navigator.userAgent);

      if (isAndroid) {
        const preventCollapse = () => {
          if (window.scrollY === 0) {
            window.scrollTo(0, 1);
          }
        };

        const ensureDocumentIsScrollable = () => {
          const isScrollable =
            document.documentElement.scrollHeight > window.innerHeight;
          if (!isScrollable) {
            document.documentElement.style.setProperty(
              "height",
              "calc(100vh + 1px)",
              "important",
            );
          }
        };

        const disableVerticalSwipes = () => {
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.disableVerticalSwipes();
          }
        };

        ensureDocumentIsScrollable();
        window.addEventListener("touchstart", preventCollapse);
        disableVerticalSwipes();

        return () => {
          window.removeEventListener("touchstart", preventCollapse);
        };
      }
    }
  }, [telegramData, t]);
};

export default useAndroidScrollFix;
