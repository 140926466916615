import React from "react";
// import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import Navbar from "../Navbar/Navbar";
import { PreloadImages } from "../../../utils/globals";
import Header from "../Header/Header";
import { useDefaultLayoutHook } from "../../../hooks/Layout/useDefaultLayoutHook";
import cx from "classnames";
import QuestSnackbar from "../../../pages/Quest/QuestSnackbar";
import Guide from "../../Guide/Guide";

const DefaultLayout = ({ children }) => {
  const {
    isInitialEnter,
    isDarkScheme,
    isNoGameWrapper,
    isNoGameInterface,
    isOnlyNavbar,
    handleCloseSnackbar,
    snackbarOpen,
    snackbarConfig,
    isWithoutHeader,
    isGuideVisible,
    handleCloseGuide,
  } = useDefaultLayoutHook();

  return (
    <>
      {isGuideVisible && <Guide closeGuide={handleCloseGuide} />}
      <div
        style={{
          backgroundImage: isNoGameWrapper
            ? ``
            : `url(${PreloadImages.BACKGROUND_IMAGE})`,
        }}
        className={cx(styles.wrapper, styles.fadeIn)}
      >
        {!isNoGameWrapper && isDarkScheme && (
          <div className={styles.overlay}></div>
        )}
        {!isNoGameWrapper &&
          !isInitialEnter &&
          !isNoGameInterface &&
          !isWithoutHeader && <Header />}
        <div className={styles.content}>{children}</div>
        {((!isNoGameWrapper && !isInitialEnter && !isNoGameInterface) ||
          isOnlyNavbar) && <Navbar />}
      </div>
      {snackbarOpen && (
        <QuestSnackbar
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarConfig.message}
          description={snackbarConfig.description}
          buttonText={snackbarConfig.buttonText}
          onButtonClick={snackbarConfig.onButtonClick}
        />
      )}
    </>
  );
};

export default DefaultLayout;
