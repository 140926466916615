import React from "react";
import styles from "./styles.module.scss";
import Button from "../../ui/Button/Button";
import cx from "classnames";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { RiNftFill } from "react-icons/ri";
import { FaShare } from "react-icons/fa";

const HeroCardActions = ({
  currentStep,
  character,
  onClickFavorite,
  handleOpenDetailPage,
  handleMint,
  handleShare,
}) => {
  return (
    <div className={styles.row}>
      <Button
        className={cx({ [styles.active]: currentStep === 3 })}
        onClick={(e) => onClickFavorite(e, character.id, !character.favorite)}
      >
        {character.favorite ? <MdFavorite /> : <MdFavoriteBorder />}
      </Button>

      <Button
        className={cx({ [styles.active]: currentStep === 3 })}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDetailPage();
        }}
      >
        <BsInfoCircle />
      </Button>

      <Button
        className={cx({ [styles.active]: currentStep === 3 })}
        onClick={(e) => {
          e.stopPropagation();
          handleMint();
        }}
      >
        <RiNftFill />
      </Button>

      {/*<Button*/}
      {/*  className={cx({ [styles.active]: currentStep === 3 })}*/}
      {/*  onClick={(e) => {*/}
      {/*    e.stopPropagation();*/}
      {/*    handleShare();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <FaShare />*/}
      {/*</Button>*/}
    </div>
  );
};

export default HeroCardActions;
