import React, { useState, useEffect } from "react";
import styles from "./QuestTimer.module.scss";
import { CircularProgress } from "@telegram-apps/telegram-ui";
import { calculateProgress } from "../../utils/utils";
import cx from "classnames";

const QuestTimer = ({ startQuestTime, nextQuestTime, className }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      setProgress(calculateProgress(startQuestTime, nextQuestTime));
    };

    updateProgress();
    const intervalId = setInterval(updateProgress, 1000);

    return () => clearInterval(intervalId);
  }, [startQuestTime, nextQuestTime]);

  return (
    <div className={cx(styles.progress, className)}>
      <CircularProgress progress={progress || 0} size="small" />
    </div>
  );
};

export default QuestTimer;
