import { formatNumber } from "../../utils/formatNumbers";
import styles from "./styles.module.scss";
import { Cell, Image, Info, Text } from "@telegram-apps/telegram-ui";
import { getLeaderImage } from "../../utils/imagesGetter";
import React from "react";
import cx from "classnames";

const RatingCell = ({ user, isCurrentUser, isGuide }) => {
  const rank = user?.rank;
  const balance = formatNumber(user?.total_stats);
  const username = user?.username || "-";
  const imageClass = `${styles.image} ${rank < 2 && styles.leaderImage}`;
  const rankTextClass = rank > 999 ? styles.customText : styles.text;

  return (
    <Cell
      className={cx(styles.cell, {
        [styles.fadeIn]: isCurrentUser,
        [styles.userCell]: isCurrentUser,
        [styles.active]: rank > 3 && isGuide,
      })}
      after={<Info type="text">{balance}</Info>}
      before={
        <Image src={getLeaderImage(rank)} className={imageClass} size={48}>
          {rank > 3 && <Text className={rankTextClass}>{rank || "-"}</Text>}
        </Image>
      }
    >
      {username}
    </Cell>
  );
};

export default RatingCell;
