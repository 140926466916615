import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { nextStep, hideGuide } from "../../store/slices/guideSlice";
import { PreloadImages } from "../../utils/globals";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { updateUserSettings } from "../../services/settingsService";
import { Typewriter } from "react-simple-typewriter";
import paths from "../../pages/paths";
import { useTranslation } from "react-i18next";

const Guide = ({ closeGuide }) => {
  const [isGuideVisible, setIsGuideVisible] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const [currentStepData, setCurrentStepData] = useState(null);
  const isBackgroundVisible = useSelector(
    (state) => state.guide.isBackgroundVisible,
  );
  const currentStep = useSelector((state) => state.guide.currentStep);
  const position = useSelector((state) => state.guide.position);
  const steps = useSelector((state) => state.guide.steps);
  const tutorials = useSelector((state) => state.guide.tutorials);
  const isGuider = useSelector((state) => state.guide.isGuider);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [holdProgress, setHoldProgress] = useState(0);
  const holdTimerRef = useRef(null);

  const holdDuration = 2000;

  useEffect(() => {
    const basePath = pathname.split("/")[1]
      ? `/${pathname.split("/")[1]}`
      : pathname;

    if (tutorials && !tutorials.includes(basePath)) {
      setIsGuideVisible(true);
    } else {
      setIsGuideVisible(false);
    }
  }, [tutorials, pathname]);

  useEffect(() => {
    if (steps && steps[currentStep]) {
      setCurrentStepData(steps[currentStep]);
    }
  }, [steps, currentStep]);

  const handleClose = () => {
    const basePath = pathname.split("/")[1]
      ? `/${pathname.split("/")[1]}`
      : pathname;
    setIsHiding(true);
    if (!tutorials.includes(basePath)) {
      updateUserSettings({ tutorials: [...tutorials, basePath] });
    }
  };

  useEffect(() => {
    if (isHiding) {
      const timer = setTimeout(() => {
        dispatch(hideGuide());
        closeGuide();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isHiding, closeGuide, dispatch]);

  const handleNextStep = () => {
    if (currentStep < steps?.length - 1) {
      dispatch(nextStep());
    } else {
      setIsHiding(true);
    }
  };

  const handleButtonDown = () => {
    if (holdTimerRef.current) return;
    const startTime = Date.now();
    holdTimerRef.current = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min((elapsed / holdDuration) * 100, 100);
      setHoldProgress(progress);
      if (progress >= 100) {
        clearInterval(holdTimerRef.current);
        holdTimerRef.current = null;
        handleClose();
      }
    }, 50);
  };

  const handleButtonUp = () => {
    if (holdTimerRef.current) {
      clearInterval(holdTimerRef.current);
      holdTimerRef.current = null;
    }
    setHoldProgress(0);
  };

  if (!isGuideVisible || !currentStepData) {
    return null;
  }

  return (
    <div className={`${styles.wrapper} ${isHiding ? styles.hide : ""}`}>
      {![paths.inventory, paths.index, paths.roulette].includes(pathname) && (
        <div className={styles.skipButtonWrapper}>
          {currentStep !== steps?.length - 1 && (
            <div
              className={styles.skipButton}
              onMouseDown={handleButtonDown}
              onMouseUp={handleButtonUp}
              onMouseLeave={handleButtonUp}
              onTouchStart={handleButtonDown}
              onTouchEnd={handleButtonUp}
            >
              <div className={styles.buttonText}>{t("Skip tutorial")}</div>
              <div
                className={styles.progressOverlay}
                style={{ width: `${holdProgress}%` }}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={`${styles.guide} ${
          isBackgroundVisible ? styles.fadeInBg : styles.fadeOutBg
        }`}
        onClick={handleNextStep}
      >
        {isGuider && (
          <div
            className={cx(styles.absoluteWrapper, {
              [styles.guideContentTop]: position === "top",
            })}
          >
            <div className={cx(styles.guideContent)}>
              {position !== "top" && (
                <img
                  className={styles.guider}
                  src={PreloadImages.GUIDER}
                  alt="guider"
                />
              )}
              <div
                className={cx(styles.textBox, {
                  [styles.topTextBox]: position === "top",
                })}
              >
                <div className={styles.message}>
                  <Typewriter
                    key={currentStepData.message}
                    words={[currentStepData.message]}
                    loop={1}
                    typeSpeed={25}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </div>
                {position !== "top" && <div className={styles.triangle} />}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Guide;
