import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getAttributeOptions,
  getSortOptions,
} from "../../../utils/imagesGetter";
import CustomSheet from "../../ui/Modal/Modal";
import CustomCheckbox from "../../ui/Checkbox/Checkbox";
import RadioButton from "../../ui/RadioButton/RadioButton";

const SortModal = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOption = searchParams.get("order_by") || "total_stats_desc";
  const favoritesOption = searchParams.get("favorite_desc") || "enabled";

  const handleCheckboxChange = (value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    searchParams.set("order_by", value);
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setIsModalOpen(false);
  };

  const sortOptions = getSortOptions(t);
  const attributeOptions = getAttributeOptions(t);

  const handleChangeFavorites = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    searchParams.set(
      "favorite_desc",
      favoritesOption === "enabled" ? "disabled" : "enabled",
    );
    setSearchParams(searchParams);
    setIsModalOpen(false);
  };

  return (
    <CustomSheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className={styles.filtersContent}>
        <div className={styles.section}>
          <span>{t("General")}</span>
          <label className={styles.optionLabel}>
            <CustomCheckbox
              label={t("Favorites First")}
              checked={favoritesOption === "enabled"}
              onChange={handleChangeFavorites}
            />
          </label>
        </div>

        <div className={styles.section}>
          <span>{t("Sort by Overall Stats")}</span>
          {sortOptions.map((option, index) => (
            <RadioButton
              key={index}
              name="sortOption"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleCheckboxChange(option.value)}
              label={option.label}
            />
          ))}
        </div>

        <div className={styles.section}>
          <span>{t("By Attribute in Descending Order")}</span>
          {attributeOptions.map((option, index) => (
            <RadioButton
              key={index}
              name="sortOption"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleCheckboxChange(option.value)}
              label={option.label}
            />
          ))}
        </div>
      </div>
    </CustomSheet>
  );
};

export default SortModal;
