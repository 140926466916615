import React from "react";
import styles from "./styles.module.scss";
import { PreloadImages } from "../../utils/globals";

const CoinLoader = ({ size = 60 }) => {
  return (
    <div className={styles.loader}>
      <img
        alt={"coin"}
        className={styles.logo}
        width={size}
        height={size}
        src={PreloadImages.COIN_ICON}
      />
    </div>
  );
};

export default CoinLoader;
