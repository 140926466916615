import React from "react";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "./styles.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { Modal } from "@telegram-apps/telegram-ui";
import Select from "react-select";
import TelegramButton from "../../common/TelegramButton/TelegramButton";
import { customStyles } from "./selectStyles";
import LoaderComponent from "../../common/LoaderComponent/LoaderComponent";
import { useHeroesFilters } from "../../../hooks/Pages/heroesFiltersHook";

const FiltersModal = ({ isModalOpen, setIsModalOpen }) => {
  const {
    t,
    clearAllFilters,
    applyFilters,
    handleFilterChange,
    handleHeroStatusChange, // Import the new handler
    isLoading,
    filters,
    classOptions,
    rarityOptions,
    heroOptions,
  } = useHeroesFilters(setIsModalOpen);

  const heroesOptions = [
    { value: "ALL", label: t("All") },
    { value: "ACTIVE", label: t("Active") },
    { value: "INACTIVE", label: t("Inactive") },
  ];

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          before={
            (filters.class_ids.length > 0 ||
              filters.hero_ids.length > 0 ||
              filters.rarity_level_ids.length > 0 ||
              filters.hero_status !== "ALL") && (
              <button
                onClick={clearAllFilters}
                className={styles.clearAllButton}
              >
                {t("Clear all")}
              </button>
            )
          }
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <div className={styles.filtersContent}>
        {isLoading && (
          <div className={styles.noData}>
            <LoaderComponent />
          </div>
        )}
        {!isLoading && (
          <>
            <Select
              noOptionsMessage={() => t("No options")}
              isSearchable={false}
              options={heroesOptions}
              value={heroesOptions.find(
                (option) => option.value === filters.hero_status,
              )}
              onChange={handleHeroStatusChange}
              styles={customStyles}
              isClearable={false}
              placeholder={t("All")}
            />
            <Select
              noOptionsMessage={() => t("No options")}
              isSearchable={false}
              isMulti
              options={rarityOptions}
              value={rarityOptions.filter((option) =>
                filters.rarity_level_ids.includes(option.value),
              )}
              onChange={(value) =>
                handleFilterChange("rarity_level_ids", value)
              }
              styles={customStyles}
              isClearable
              placeholder={t("Rarity")}
            />
            <Select
              noOptionsMessage={() => t("No options")}
              isClearable
              isSearchable={false}
              isMulti
              options={classOptions}
              value={classOptions.filter((option) =>
                filters.class_ids.includes(option.value),
              )}
              onChange={(value) => handleFilterChange("class_ids", value)}
              styles={customStyles}
              placeholder={t("Class")}
            />
            <Select
              noOptionsMessage={() => t("No options")}
              isClearable
              isSearchable={false}
              isMulti
              options={heroOptions}
              value={heroOptions.filter((option) =>
                filters.hero_ids.includes(option.value),
              )}
              onChange={(value) => handleFilterChange("hero_ids", value)}
              styles={customStyles}
              placeholder={t("Hero")}
            />
            <TelegramButton className={styles.button} onClick={applyFilters}>
              {t("Apply")}
            </TelegramButton>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FiltersModal;
