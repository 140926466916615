import React, { useRef } from "react";
import styles from "./styles.module.scss";
import QuestHeroes from "../QuestHeroes/QuestHeroes";
import Button from "../../ui/Button/Button";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

const QuestActions = ({
  quest,
  selectedHeroes,
  handleRemoveHero,
  handleOpenHeroesList,
  handleStartQuest,
  isChanceLoading,
  navigate,
  isTooltipOpen,
  currentStep,
}) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <span className={styles.descriptionHeader}>{t("Description")}</span>
        <span className={styles.text}>{quest.description}</span>
      </div>
      <QuestHeroes
        quest={quest}
        selectedHeroes={selectedHeroes}
        handleRemoveHero={handleRemoveHero}
        handleOpenHeroesList={handleOpenHeroesList}
        navigate={navigate}
        currentStep={currentStep}
      />
      {quest.status !== "started" && quest.status !== "completed" && (
        <Button
          ref={ref}
          disabled={
            selectedHeroes?.length < quest.required_heroes_count ||
            isChanceLoading
          }
          className={styles.button}
          onClick={handleStartQuest}
        >
          {quest.status === "finished" ? t("View the results") : t("Start")}
        </Button>
      )}
      {isTooltipOpen && (
        <Popup
          open={isTooltipOpen}
          onClose={() => {}}
          anchor={ref.current}
          position="right center"
          arrow={false}
          contentStyle={{
            background: "rgba(0, 0, 0, 0.85)",
            color: "#fff",
            padding: "8px 12px",
            borderRadius: "4px",
            fontSize: "0.875rem",
          }}
          arrowStyle={{
            display: "none",
          }}
        >
          <span className={styles.tooltipText}>{t("StartQuestError")}</span>
        </Popup>
      )}
    </div>
  );
};

export default QuestActions;
