import React from "react";
import styles from "./styles.module.scss";
import { formatAmount } from "../../../utils/formatNumbers";
import { calculateProgress } from "../../../utils/utils";
import { PreloadImages } from "../../../utils/globals";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const GiveawayBanner = ({ handleGoToGiveaway, giveawayBanner }) => {
  return (
    <div
      className={styles.giveawayBanner}
      style={{
        background: `url(${PreloadImages.GIVEAWAY_BANNER}) no-repeat center center`,
        backgroundSize: "cover",
        maxHeight: 48,
      }}
      onClick={handleGoToGiveaway}
    >
      <div className={styles.bannerOverlay}>
        <img
          className={styles.notcoinImg}
          src={giveawayBanner?.image_url}
          alt="Giveaway"
        />
        <div className={styles.row}>
          <span className={styles.poolAmount}>
            {formatAmount(giveawayBanner?.pool?.amount)}
          </span>
          <span className={styles.poolAmount}>
            {giveawayBanner?.pool?.currency_code}
          </span>
        </div>
        <div className={styles.progress}>
          <CircularProgressbar
            value={calculateProgress(
              giveawayBanner?.started_at,
              giveawayBanner?.ended_at,
            )}
            rotation={0.25}
            styles={buildStyles({
              pathColor: `#f5e1a4`,
              trailColor: "#3c2f2f",
              strokeLinecap: "butt",
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default GiveawayBanner;
