// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__FEXqY {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}
.styles_wrapper__FEXqY > div {
  padding: 0 0 16px 0;
}

.styles_overflow__qic\\+f {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/Inventory/InventoryItems/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  position: relative;\n  > div {\n    padding: 0 0 16px 0;\n  }\n}\n\n.overflow {\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__FEXqY`,
	"overflow": `styles_overflow__qic+f`
};
export default ___CSS_LOADER_EXPORT___;
