import React from "react";
import styles from "./styles.module.scss";
import Button from "../../ui/Button/Button";
import CustomSheet from "../../ui/Modal/Modal";
import { PreloadImages } from "../../../utils/globals";
import { StarIcon } from "../../../assets/icons";
import { formatAmount } from "../../../utils/formatNumbers";
import { BsArrowRight } from "react-icons/bs";

const CauldronPaymentModal = ({
  isOpen,
  setOpen,
  handleBuyStarMarketItem,
  handleBuyMarketItem,
  marketItems,
  t,
  userAmount,
}) => {
  const pathAmount = marketItems?.path?.final_price?.amount;
  const starAmount = marketItems?.star?.final_price?.amount;

  const path = marketItems?.path;
  const star = marketItems?.star;

  const getAmount = (type, amount) => {
    if (type.sale)
      return (
        <div className={styles.priceRow}>
          <span className={styles.priceNoSale}>
            {formatAmount(type?.price.amount)}
          </span>
          <BsArrowRight className={styles.arrowIcon} />
          <span>{formatAmount(type?.final_price?.amount)}</span>
        </div>
      );
    return <span>{formatAmount(amount)}</span>;
  };

  return (
    <CustomSheet isOpen={isOpen} onClose={() => setOpen(false)}>
      <div className={styles.modalContent}>
        <span>{t("selectBoilCurrency")}</span>
        <div className={styles.userAmount}>
          <span>{formatAmount(userAmount)}</span>
          <img
            src={PreloadImages.COIN_ICON}
            height={10}
            width={10}
            alt={"pathcoin"}
          />
        </div>
        <div className={styles.buttonRow}>
          {!pathAmount && !starAmount && <span>{t("Error")}</span>}
          {!!pathAmount && (
            <Button onClick={handleBuyMarketItem}>
              <span>{getAmount(path, pathAmount)}</span>
              <img
                src={PreloadImages.COIN_ICON}
                height={15}
                width={15}
                alt={"pathcoin"}
              />
            </Button>
          )}
          {!!starAmount && (
            <Button onClick={handleBuyStarMarketItem}>
              <span>{getAmount(star, starAmount)}</span>
              <StarIcon size={15} />
            </Button>
          )}
        </div>
      </div>
    </CustomSheet>
  );
};

export default CauldronPaymentModal;
