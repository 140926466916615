import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const QuestsCount = ({ questsCount, isDataLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.countdownWrapper}>
      <span
        className={styles.questsCount}
      >{`${t("Amount")}: ${!!questsCount && !isDataLoading && questsCount}`}</span>
    </div>
  );
};

export default QuestsCount;
