import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "../components/common/DefaultLayout/DefaultLayout";
import routesConfig from "../utils/routesConfig";
import { useSocket } from "../utils/socketContext";

const Game = () => {
  const { initializeSocket } = useSocket();

  useEffect(() => {
    initializeSocket();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routesConfig.map(({ path, component: Component }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <DefaultLayout>
                <Component />
              </DefaultLayout>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Game;
