// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_favoriteContainer__OiP30 {
  position: absolute;
  top: 0.8vh;
  right: 13.5vw;
  z-index: 10;
}
@media (min-width: 500px) {
  .styles_favoriteContainer__OiP30 {
    right: 70px;
    top: 3px;
  }
}

.styles_questsFavorite__uKNIx {
  top: 0;
  right: 20px;
}

.styles_favoriteRow__dIa2a {
  position: relative;
}

.styles_heart__rUlmy {
  position: absolute;
  width: 15px;
  height: 15px;
  background: white;
  right: -27px;
  top: 9px;
  z-index: 0;
}

.styles_questsHeart__xFOFW {
  width: 9px;
  height: 9px;
  right: -15px;
  top: 3px;
}

.styles_favorite__ylZYH {
  color: var(--secondory-bg);
  position: absolute;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Favorite/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,WAAA;AACF;AAAE;EALF;IAMI,WAAA;IACA,QAAA;EAGF;AACF;;AAAA;EACE,MAAA;EACA,WAAA;AAGF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;AAGF;;AAAA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;AAGF;;AAAA;EACE,0BAAA;EACA,kBAAA;EACA,UAAA;AAGF","sourcesContent":[".favoriteContainer {\n  position: absolute;\n  top: 0.8vh;\n  right: 13.5vw;\n  z-index: 10;\n  @media (min-width: 500px) {\n    right: 70px;\n    top: 3px;\n  }\n}\n\n.questsFavorite {\n  top: 0;\n  right: 20px;\n}\n\n.favoriteRow {\n  position: relative;\n}\n\n.heart {\n  position: absolute;\n  width: 15px;\n  height: 15px;\n  background: white;\n  right: -27px;\n  top: 9px;\n  z-index: 0;\n}\n\n.questsHeart {\n  width: 9px;\n  height: 9px;\n  right: -15px;\n  top: 3px;\n}\n\n.favorite {\n  color: var(--secondory-bg);\n  position: absolute;\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"favoriteContainer": `styles_favoriteContainer__OiP30`,
	"questsFavorite": `styles_questsFavorite__uKNIx`,
	"favoriteRow": `styles_favoriteRow__dIa2a`,
	"heart": `styles_heart__rUlmy`,
	"questsHeart": `styles_questsHeart__xFOFW`,
	"favorite": `styles_favorite__ylZYH`
};
export default ___CSS_LOADER_EXPORT___;
