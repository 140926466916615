import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getFilters } from "../../../../services/filtersService";

export const useQuestHeroesFiltersHook = (activeFilters) => {
  const { t } = useTranslation();

  const [classOptions, setClassOptions] = useState([]);
  const [heroOptions, setHeroOptions] = useState([]);
  const [rarityOptions, setRarityOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    class_ids: activeFilters.class_ids || [],
    hero_ids: activeFilters.hero_ids || [],
    rarity_level_ids: activeFilters.rarity_level_ids || [],
    hero_status: activeFilters.hero_status || "ALL", // Added hero_status
  });

  useEffect(() => {
    getFilters()
      .then((filtersData) => {
        setClassOptions(
          filtersData.classes.map((cls) => ({
            value: cls.id,
            label: cls.name,
          })),
        );
        setHeroOptions(
          filtersData.heroes.map((hero) => ({
            value: hero.id,
            label: hero.name,
          })),
        );
        setRarityOptions(
          filtersData.rarity_levels.map((rarity) => ({
            value: rarity.id,
            label: rarity.name,
          })),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value ? value.map((item) => item.value) : [],
    }));
  };

  // New handler for hero_status
  const handleHeroStatusChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      hero_status: value ? value.value : "ALL",
    }));
  };

  const clearAllFilters = () => {
    setFilters({
      class_ids: [],
      hero_ids: [],
      rarity_level_ids: [],
      hero_status: "ALL", // Reset hero_status
    });
  };

  return {
    t,
    clearAllFilters,
    handleFilterChange,
    handleHeroStatusChange, // Export the new handler
    isLoading,
    filters,
    classOptions,
    rarityOptions,
    heroOptions,
  };
};
