export const calculateProgress = (startedAt, endedAt) => {
  const now = new Date();
  const start = new Date(startedAt);
  const end = new Date(endedAt);

  const totalDuration = end - start;

  if (totalDuration === 0) {
    return 0;
  }

  const elapsedTime = now - start;
  return Math.min((elapsedTime / totalDuration) * 100, 100);
};

const itemTranslations = {
  head: "Head",
  chest: "Chest",
  legs: "Legs",
  feet: "Feet",
  hands: "Hands",
  neck: "Neck",
  finger: "Ring",
  finger_1: "Ring",
  finger_2: "Ring",
  main_hand: "Main Hand",
  off_hand: "Off-Hand",
  back: "Back",
  double_handed: "Two-Handed",
  shoulders: "Shoulders",
};

export const getItemTranslation = (key) => {
  return itemTranslations[key] || "Unknown";
};
