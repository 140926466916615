import React from "react";
import styles from "./styles.module.scss";
import { useHeroesPageHook } from "../../hooks/Pages/useHeroesPageHook";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import PageTitle from "../../components/PageTitle/PageTitle";
import FiltersButtonsRow from "./FiltersButtonsRow";
import HeroesList from "./HeroesList";
import PaginationControls from "./PaginationControls";
import SortModal from "../../components/ForHeroes/SortModal/SortModal";
import FiltersModal from "../../components/ForHeroes/FiltersModal/FiltersModal";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const HeroesPage = () => {
  const { t } = useTranslation();

  const {
    heroes,
    isLoading,
    pageParam,
    hasMore,
    goToNextPage,
    goToPrevPage,
    allImagesLoaded,
    handleOpenMarket,
    initialRequest,
    paginationInfo,
    swipeHandlers,
    areFiltersApplied,
    appliedFiltersCount,
    orderParam,
    isSortModalOpen,
    handleOpenSortModal,
    isFiltersModalOpen,
    handleOpenFiltersModal,
    setIsSortModalOpen,
    setIsFiltersModalOpen,
    favoritesOption,
    clearAllFilters,
    currentStep,
  } = useHeroesPageHook();

  return (
    <>
      <div className={cx(styles.wrapper, styles.fadeIn)} {...swipeHandlers}>
        <PageTitle title={t("My heroes")} />
        {isLoading && initialRequest && (
          <div className={styles.loaderWrapper}>
            <LoaderComponent />
          </div>
        )}
        {!initialRequest && (!!heroes.length || areFiltersApplied) && (
          <FiltersButtonsRow
            orderParam={orderParam}
            appliedFiltersCount={appliedFiltersCount}
            onFiltersClick={handleOpenFiltersModal}
            onSortClick={handleOpenSortModal}
            favoritesOption={favoritesOption}
            clearAllFilters={clearAllFilters}
            currentStep={currentStep}
          />
        )}

        {!isLoading && !initialRequest && (
          <HeroesList
            heroes={heroes}
            isLoading={isLoading}
            allImagesLoaded={allImagesLoaded}
            handleOpenMarket={handleOpenMarket}
          />
        )}
        {!initialRequest && !!heroes.length && (
          <div className={styles.paginationInfo}>
            <span
              className={cx({
                [styles.active]: currentStep === 4,
              })}
            >{`${t("TOTAL HEROES")}: ${paginationInfo?.total_entries}`}</span>
            <span>{`${pageParam}/${paginationInfo?.total_pages}`}</span>
          </div>
        )}
        {!!heroes.length && (
          <PaginationControls
            page={pageParam}
            hasMore={hasMore}
            onNext={goToNextPage}
            onPrev={goToPrevPage}
          />
        )}
      </div>
      {isSortModalOpen && (
        <SortModal
          isModalOpen={isSortModalOpen}
          setIsModalOpen={setIsSortModalOpen}
        />
      )}
      {isFiltersModalOpen && (
        <FiltersModal
          setIsModalOpen={setIsFiltersModalOpen}
          isModalOpen={isFiltersModalOpen}
        />
      )}
    </>
  );
};

export default HeroesPage;
