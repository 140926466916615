import React from "react";
import { Snackbar } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { PiSealQuestionLight } from "react-icons/pi";
import styles from "./styles.module.scss";

const QuestSnackbar = ({
  open,
  onClose,
  message,
  description,
  buttonText,
  onButtonClick,
}) => {
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Snackbar
      className={styles.snackbar}
      onClick={onButtonClick}
      before={<PiSealQuestionLight size={30} />}
      open={open}
      onClose={onClose}
      description={description}
      duration={7000}
      after={
        <TelegramButton className={styles.closeButton} onClick={handleClose}>
          {buttonText}
        </TelegramButton>
      }
    >
      {message}
    </Snackbar>
  );
};

export default QuestSnackbar;
