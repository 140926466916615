// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.styles_wrapper__gUCLP {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1;
}

.styles_description__o4vBg {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.styles_descriptionHeader__PX0JE {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--text-color);
}

.styles_text__a9EBl {
  font-size: 1rem;
  color: var(--text-color);
}

.styles_button__qixzQ {
  margin-top: auto;
}

.styles_tooltipText__cRupr {
  font-size: 0.875rem;
  color: #fff;
}

.styles_fadeIn__NMkil {
  opacity: 0;
  animation: styles_fadeInAnimation__tXb5F 1s forwards;
}

@keyframes styles_fadeInAnimation__tXb5F {
  to {
    opacity: 1;
  }
}
.styles_chanceIcon__UuX\\+t {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Стили для спиннера */
.styles_spinner__D9aWl {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Дополнительные стили, если необходимо */`, "",{"version":3,"sources":["webpack://./src/components/ForQuests/QuestActions/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAEF;;AACA;EACE,iBAAA;EACA,iBAAA;EACA,wBAAA;AAEF;;AACA;EACE,eAAA;EACA,wBAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AACA;EAEE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,oDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAF;;AAGA,uBAAA;AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA,0CAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  flex: 1;\n}\n\n.description {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.descriptionHeader {\n  font-weight: bold;\n  font-size: 1.2rem;\n  color: var(--text-color);\n}\n\n.text {\n  font-size: 1rem;\n  color: var(--text-color);\n}\n\n.button {\n  margin-top: auto;\n}\n\n.tooltipText {\n\n  font-size: 0.875rem;\n  color: #fff;\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n@keyframes fadeInAnimation {\n  to {\n    opacity: 1;\n  }\n}\n\n.chanceIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}\n\n/* Стили для спиннера */\n.spinner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n/* Дополнительные стили, если необходимо */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__gUCLP`,
	"description": `styles_description__o4vBg`,
	"descriptionHeader": `styles_descriptionHeader__PX0JE`,
	"text": `styles_text__a9EBl`,
	"button": `styles_button__qixzQ`,
	"tooltipText": `styles_tooltipText__cRupr`,
	"fadeIn": `styles_fadeIn__NMkil`,
	"fadeInAnimation": `styles_fadeInAnimation__tXb5F`,
	"chanceIcon": `styles_chanceIcon__UuX+t`,
	"spinner": `styles_spinner__D9aWl`
};
export default ___CSS_LOADER_EXPORT___;
