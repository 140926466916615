import { useState, useEffect, useCallback, useRef } from "react";
import { getHeroItems } from "../../services/heroItemsService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useSelector } from "react-redux";

export const useHeroItems = ({
  currentInventorySlot,
  classType,
  isInventory,
  order_by,
  heroLevel,
}) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const observer = useRef();
  const { tg } = useTelegram();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const fetchData = (page, shouldReplace = false) => {
    setIsDataLoading(true);

    getHeroItems({
      page,
      inventorySlot: currentInventorySlot,
      availableForClass: classType,
      is_equipped: isInventory ? null : "f",
      order_by,
      levelLessOrEqual: heroLevel,
    })
      .then((response) => {
        const data = response.data?.data || [];
        const paginationInfo = response.data?.pagination_info || {};

        setItems((prevItems) =>
          shouldReplace ? data : [...prevItems, ...data],
        );
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    if (currentInventorySlot || isInventory) fetchData(1, true);
  }, [currentInventorySlot]);

  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page]);

  const lastItemElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
          if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  const refreshItems = () => {
    fetchData(1, true);
  };

  const closeModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(null);
  };

  const totalSlots = Math.max(50, items.length);
  const inventory = Array.from({ length: totalSlots });

  const handleItemClick = (item) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(item);
  };

  return {
    items,
    setItems,
    isDataLoading,
    lastItemElementRef,
    page,
    fetchData,
    refreshItems,
    selectedItem,
    closeModal,
    setSelectedItem,
    totalSlots,
    inventory,
    handleItemClick,
  };
};
