import React from "react";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { TbFilterOff } from "react-icons/tb";
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi";
import { getSortText } from "../../utils/imagesGetter";

const FiltersButtonsRow = ({
  onSortClick,
  onFiltersClick,
  appliedFiltersCount,
  orderParam,
  favoritesOption,
  clearAllFilters,
  currentStep,
}) => {
  const { t } = useTranslation();
  const { text, direction } = getSortText(orderParam, t);

  const renderArrowIcon = () => {
    if (direction === "up") return <PiSortAscendingBold size={17} />;
    if (direction === "down") return <PiSortDescendingBold size={17} />;
    return null;
  };

  return (
    <div className={cx(styles.filtersButtonsRow, styles.fadeIn)}>
      <TelegramButton
        onClick={onFiltersClick}
        className={cx(styles.filtersButton, {
          [styles.active]: currentStep === 2,
        })}
      >
        {t("Filters")}&nbsp;
        {!!appliedFiltersCount && (
          <span className={styles.badge}>({appliedFiltersCount})</span>
        )}
      </TelegramButton>
      {(appliedFiltersCount > 0 ||
        orderParam !== "total_stats_desc" ||
        favoritesOption === "disabled") && (
        <TelegramButton
          onClick={clearAllFilters}
          className={styles.clearButton}
        >
          <div className={styles.clear}>
            <TbFilterOff size={17} />
          </div>
        </TelegramButton>
      )}
      <TelegramButton
        before={renderArrowIcon()}
        onClick={onSortClick}
        className={cx(styles.filtersButton, {
          [styles.active]: currentStep === 2,
        })}
      >
        {text}
      </TelegramButton>
    </div>
  );
};

export default FiltersButtonsRow;
