import React, { useCallback, useEffect, useRef, useState } from "react";
import { getHeroes } from "../../../services/heroService";
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import paths from "../../../pages/paths";
import { useNavigate } from "react-router-dom";
import { getQuestChance } from "../../../services/questService";
import { useSelector } from "react-redux";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { getAttributeValue } from "../../../utils/attributesIconsUtils";
import { getSortText } from "../../../utils/imagesGetter";
import useAndroidScrollFix from "../../../hooks/Layout/useAndroidScrollFix";

export const useHeroesForQuestHook = ({
  selectedHeroes,
  setSelectedHeroes,
  handleCloseHeroes,
  heroesCount,
  mainCharacteristic,
  questId,
  setQuestChance,
  setIsChanceLoading,
  isChanceLoading,
}) => {
  const { t } = useTranslation();
  const { tg } = useTelegram();
  const navigate = useNavigate();
  useAndroidScrollFix();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [heroes, setHeroes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [internalSelectedHeroes, setInternalSelectedHeroes] =
    useState(selectedHeroes);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    class_ids: [],
    hero_ids: [],
    rarity_level_ids: [],
    hero_status: "ALL",
  });
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [favoritesOption, setFavoritesOption] = useState("enabled");

  const [sortOption, setSortOption] = useState(
    getAttributeValue(mainCharacteristic),
  );

  const handleSetFavorites = () => {
    setFavoritesOption((prev) => (prev === "enabled" ? "disabled" : "enabled"));
    setIsSortModalOpen(false);
  };

  const observer = useRef();

  const fetchHeroes = () => {
    if (!isDataLoading) setIsDataLoading(true);
    getHeroes({
      page: page,
      pageSize: 10,
      orderParam: sortOption,
      classIds: filters.class_ids.join(","),
      heroIds: filters.hero_ids.join(","),
      rarityLevelIds: filters.rarity_level_ids.join(","),
      questId: "",
      favoritesOption: favoritesOption,
      heroStatus: filters.hero_status,
      mint_status: "",
    })
      .then((response) => {
        const newHeroes = response?.data?.data || [];
        setHeroes((prevHeroes) => [...prevHeroes, ...newHeroes]);
        const paginationInfo = response?.data?.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => {
        setIsDataLoading(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (internalSelectedHeroes.length === 0) {
      setQuestChance(null);
    } else if (internalSelectedHeroes.length === heroesCount) {
      if (!isChanceLoading) setIsChanceLoading(true);
      getQuestChance(
        questId,
        internalSelectedHeroes.map((hero) => hero.id),
      )
        .then((r) => setQuestChance(r.final_chance.toString()))
        .finally(() => setIsChanceLoading(false));
    }
  }, [internalSelectedHeroes, heroesCount, questId]);

  useEffect(() => {
    setPage(1);
    setHeroes([]);
    fetchHeroes();
  }, [filters, sortOption, favoritesOption]);

  useEffect(() => {
    if (page !== 1) fetchHeroes();
  }, [page]);

  const lastHeroElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  useEffect(() => {
    let count = 0;
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        count += 1;
      } else if (key === "hero_status" && value !== "ALL") {
        count += 1;
      }
    });
    setAppliedFiltersCount(count);
  }, [filters]);

  const handleCheckboxChange = (hero) => {
    setInternalSelectedHeroes((prevSelected) => {
      if (prevSelected.some((selectedHero) => selectedHero.id === hero.id)) {
        return prevSelected.filter(
          (selectedHero) => selectedHero.id !== hero.id,
        );
      }

      if (prevSelected.length < heroesCount) {
        return [...prevSelected, hero];
      }

      return prevSelected;
    });
  };

  const isHeroSelected = (heroId) =>
    internalSelectedHeroes.some((hero) => hero.id === heroId);

  const handleSelectHeroes = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedHeroes(internalSelectedHeroes);
    handleCloseHeroes();
  };

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const applySorting = (newSortOption) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSortOption(newSortOption);
  };

  const handleOpenFiltersModal = () => {
    setIsFiltersModalOpen(true);
  };

  const handleOpenSortModal = () => {
    setIsSortModalOpen(true);
  };

  const initialFilters = {
    class_ids: [],
    hero_ids: [],
    rarity_level_ids: [],
    hero_status: "ALL", // Added hero_status
  };

  const filtersAreDifferent =
    JSON.stringify(filters) !== JSON.stringify(initialFilters);

  const sortOptionIsDefault =
    getAttributeValue(mainCharacteristic) === sortOption;

  const resetFilters = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    if (
      filtersAreDifferent ||
      !sortOptionIsDefault ||
      favoritesOption === "disabled"
    ) {
      setFavoritesOption("enabled");
      setFilters(initialFilters);
      setSortOption(getAttributeValue(mainCharacteristic));
      setPage(1);
      setHeroes([]);
    }
  };

  const { text, direction } = getSortText(sortOption, t);

  const renderArrowIcon = () => {
    if (direction === "up") return <PiSortAscendingBold size={17} />;
    if (direction === "down") return <PiSortDescendingBold size={17} />;
    return null;
  };

  const handleOpenMarket = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.inventory);
  };
  return {
    heroes,
    isFiltersModalOpen,
    isSortModalOpen,
    handleOpenSortModal,
    handleOpenFiltersModal,
    applySorting,
    applyFilters,
    handleSelectHeroes,
    isHeroSelected,
    handleCheckboxChange,
    lastHeroElementRef,
    internalSelectedHeroes,
    isDataLoading,
    setIsFiltersModalOpen,
    setIsSortModalOpen,
    sortOption,
    isLoading,
    resetFilters,
    page,
    appliedFiltersCount,
    activeFilters: filters,
    text,
    renderArrowIcon,
    handleOpenMarket,
    favoritesOption,
    handleSetFavorites,
    filtersAreDifferent,
    sortOptionIsDefault,
  };
};
