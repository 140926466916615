import useTelegramBackButton from "../Layout/useTelegramBackButton";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import paths from "../../pages/paths";
import { fetchUserData } from "../../services/registrationService";
import {
  getGiveaway,
  getGiveawayRating,
  getMyGiveawayRating,
} from "../../services/giveawaysService";
import useGuide from "./useGuideHook";
import { getGiveawaySteps } from "../../utils/guideSteps";

export const useGiveawayHook = () => {
  useTelegramBackButton(-1, {}, true);
  useAndroidScrollFix();
  const location = useLocation();
  const id = location.state;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [giveaway, setGiveaway] = useState(null);
  const [ratingList, setRatingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isRatingLoading, setIsRatingLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      navigate(paths.giveaways);
    }
    fetchUserData(dispatch);
    getGiveaway(id)
      .then((response) => {
        setGiveaway(response);
        getGiveawayRating(id)
          .then((response) => {
            setRatingList(response);
          })
          .finally(() => setIsRatingLoading(false));
        getMyGiveawayRating(id).then((response) => {
          setUserData(response);
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const getCountdownTimestamp = (endedAt) => {
    return new Date(endedAt).getTime();
  };

  const countdownTimestamp = getCountdownTimestamp(giveaway?.ended_at);

  const steps = useMemo(() => getGiveawaySteps(), []);

  useGuide(steps, null);

  return {
    giveaway,
    ratingList,
    isLoading,
    userData,
    isRatingLoading,
    countdownTimestamp,
    t,
  };
};
