import React, { Fragment } from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import { useMarketHook } from "../../hooks/Pages/useMarketHook";
import MarketCard from "./MarketCard";
import cx from "classnames";

const Market = () => {
  const {
    t,
    marketItems,
    isLoading,
    userAmount,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    marketItemBadge,
    currentStep,
  } = useMarketHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.container, styles.fadeIn)}>
      <div className={styles.topline}>
        <div className={styles.userBalance}>
          {`${formatAmount(userAmount)}`}
          <img
            className={styles.coinImage}
            src={PreloadImages.COIN_ICON}
            alt={t("Coin Icon")}
            width={25}
            height={25}
          />
        </div>
        <div className={styles.inventoryWrapper}>
          <img
            className={styles.image}
            src={PreloadImages.INVENTORY_ICON}
            alt={t("Inventory Icon")}
            width={33}
            height={33}
            onClick={handleNavigateToInventory}
            style={{ cursor: "pointer" }}
          />
          {marketItemBadge && <span className={styles.notificationDot} />}
        </div>
      </div>
      {Object.keys(marketItems)?.length === 0 && !isLoading && (
        <p className={styles.noDataText}>{t("Беда беда...")}</p>
      )}

      {Object.keys(marketItems).map((section, index) => (
        <Fragment key={section}>
          {index > 0 && <div className={styles.separator} />}
          <div className={cx(styles.marketItemsList, styles.fadeIn)}>
            {marketItems[section]?.map((item) => (
              <div style={{ position: "relative" }} key={item.id}>
                {item.sale && (
                  <span className={styles.badge}>
                    -{item.sale.discount_percentage}%
                  </span>
                )}
                <MarketCard
                  currentStep={currentStep}
                  handleCardClick={handleCardClick}
                  handleChipClick={handleChipClick}
                  item={item}
                  t={t}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default Market;
