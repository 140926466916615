// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__OV48d {
  font-size: 1.3rem;
  text-align: center;
  color: var(--subhead-color);
  margin-bottom: 16px;
}

.styles_content__0NSOj {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.styles_filtersContent__Mvk9t {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: rgba(24, 34, 45, 0.85);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.styles_section__B21O2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.styles_optionLabel__NEFl4 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  color: var(--text-color);
  cursor: pointer;
}

.styles_noData__avS6v {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/SortModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,wCAAA;EACA,+BAAA;EACA,gCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,wBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACF","sourcesContent":[".title {\n  font-size: 1.3rem;\n  text-align: center;\n  color: var(--subhead-color);\n  margin-bottom: 16px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.filtersContent {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  background-color: rgba(24, 34, 45, 0.85);\n  border-bottom-left-radius: 12px;\n  border-bottom-right-radius: 12px;\n}\n\n.section {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.optionLabel {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 1rem;\n  color: var(--text-color);\n  cursor: pointer;\n}\n\n.noData {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__OV48d`,
	"content": `styles_content__0NSOj`,
	"filtersContent": `styles_filtersContent__Mvk9t`,
	"section": `styles_section__B21O2`,
	"optionLabel": `styles_optionLabel__NEFl4`,
	"noData": `styles_noData__avS6v`
};
export default ___CSS_LOADER_EXPORT___;
