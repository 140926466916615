import React, { memo } from "react";
import styles from "./styles.module.scss";
import Button from "../../ui/Button/Button";
import LoaderComponent from "../../ui/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";
import SortModalForQuest from "./SortModalForQuest/SortModalForQuest";
import FiltersModalForQuest from "./FiltersModalForQuest/FiltersModalForQuest";
import { useHeroesForQuestHook } from "./useHeroesForQuestHook";
import HeroCell from "./HeroCell";
import { TbFilterOff } from "react-icons/tb";
import cx from "classnames";
import { TiDelete } from "react-icons/ti";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";

const HeroesForQuest = (props) => {
  const {
    heroes,
    isFiltersModalOpen,
    isSortModalOpen,
    handleOpenSortModal,
    handleOpenFiltersModal,
    applySorting,
    applyFilters,
    handleSelectHeroes,
    isHeroSelected,
    handleCheckboxChange,
    lastHeroElementRef,
    internalSelectedHeroes,
    isDataLoading,
    setIsFiltersModalOpen,
    setIsSortModalOpen,
    sortOption,
    isLoading,
    resetFilters,
    appliedFiltersCount,
    page,
    activeFilters,
    text,
    renderArrowIcon,
    handleOpenMarket,
    handleSetFavorites,
    favoritesOption,
    filtersAreDifferent,
    sortOptionIsDefault,
  } = useHeroesForQuestHook(props);

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      {!!internalSelectedHeroes?.length &&
        internalSelectedHeroes.map((hero) => {
          return (
            <div key={hero.id} className={styles.heroTag}>
              <span>{hero.hero_name}</span>
              <TiDelete
                color={"red"}
                size={15}
                onClick={() => handleCheckboxChange(hero)}
              />
            </div>
          );
        })}

      <div className={cx(styles.wrapper, styles.fadeIn)}>
        <div className={styles.topButtons}>
          <Button onClick={handleOpenFiltersModal}>
            {t("Filters")}
            {!!appliedFiltersCount && (
              <>
                &nbsp;
                <span className={styles.badge}>({appliedFiltersCount})</span>
              </>
            )}
          </Button>
          {(filtersAreDifferent ||
            !sortOptionIsDefault ||
            favoritesOption === "disabled") && (
            <Button onClick={resetFilters} className={styles.clearButton}>
              <div className={styles.clear}>
                <TbFilterOff size={17} />
              </div>
            </Button>
          )}
          <Button before={renderArrowIcon()} onClick={handleOpenSortModal}>
            {text}
          </Button>
        </div>
        <div className={styles.heroesList}>
          {isDataLoading && page === 1 && (
            <div className={styles.loading}>
              <LoaderComponent />
            </div>
          )}
          {!heroes?.length && !isDataLoading && (
            <div className={styles.noHeroes}>
              <img
                className={styles.image}
                src={AsyncPreloadImages.NO_HEROES_ICON}
                alt={t("noHero")}
              />
              <Button onClick={handleOpenMarket}>{t("To inventory")}</Button>
            </div>
          )}
          {heroes.map((hero, index) => {
            const isLastItem = heroes?.length === index + 1;
            const refProp = isLastItem ? lastHeroElementRef : null;
            const isSelected = isHeroSelected(hero.id);
            const isDisabled =
              !isSelected &&
              internalSelectedHeroes?.length >= props.heroesCount;

            return (
              <HeroCell
                hero={hero}
                isSelected={isSelected}
                onCheckboxChange={handleCheckboxChange}
                isDisabled={isDisabled}
                heroesCount={props.heroesCount}
                internalSelectedHeroes={internalSelectedHeroes}
                ref={refProp}
                key={hero.id}
              />
            );
          })}
        </div>
        <div className={styles.buttonsRow}>
          <Button onClick={() => props.handleCloseHeroes(true)}>
            {t("Cancel")}
          </Button>
          <Button
            className={cx({
              [styles.disabledButton]:
                internalSelectedHeroes?.length !== props.heroesCount,
            })}
            onClick={handleSelectHeroes}
            disabled={internalSelectedHeroes?.length !== props.heroesCount}
          >
            {t("Choose")}
          </Button>
        </div>
        {isFiltersModalOpen && (
          <FiltersModalForQuest
            activeFilters={activeFilters}
            isModalOpen={isFiltersModalOpen}
            setIsModalOpen={setIsFiltersModalOpen}
            applyFilters={applyFilters}
          />
        )}
        {isSortModalOpen && (
          <SortModalForQuest
            isModalOpen={isSortModalOpen}
            setIsModalOpen={setIsSortModalOpen}
            applySorting={applySorting}
            selectedOption={sortOption}
            handleSetFavorites={handleSetFavorites}
            favoritesOption={favoritesOption}
          />
        )}
      </div>
    </>
  );
};

export default memo(HeroesForQuest);
