import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  getCharImagesByRarity,
  getColorByClassName,
} from "../../../utils/imagesGetter";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import HeroCardLevel from "../HeroCardLevel/HeroCardLevel";
import CustomSheet from "../../ui/Modal/Modal";

const HeroCardTopRow = ({ character, currentStep, allImagesLoaded }) => {
  const { t } = useTranslation();
  const [isLevelModalOpen, setIsLevelModalOpen] = useState(false);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.levelRow, {
          [styles.blink]: currentStep === 1,
        })}
        onClick={() => setIsLevelModalOpen(true)}
      >
        <img
          alt={"levelIcon"}
          src={AsyncPreloadImages.LEVEL_ICON}
          width={"50px"}
          height={"50px"}
        />
        <span className={styles.level}>{Math.floor(character.level)}</span>
      </div>
      <CustomSheet
        isOpen={isLevelModalOpen}
        onClose={() => setIsLevelModalOpen(false)}
      >
        <div className={styles.heroInfo}>
          <span className={styles.heroName}>{character.hero_name}</span>
          <img
            className={styles.heroImage}
            src={character?.hero_preview_url}
            alt={"hero"}
          />
          <div className={styles.heroInfoRow}>
            <span>{t("Level")}:</span>
            <span className={styles.levelDigit}>
              {Math.floor(character?.level)}
            </span>
          </div>
          <span>{`${t("Class")}: ${character.class}`}</span>
          <div className={styles.heroInfoRow}>
            <span>{t("Rarity type")}:</span>
            <span
              style={{
                color: getColorByClassName(
                  character?.rarity_level?.toLowerCase(),
                ),
              }}
            >
              {character?.rarity_level_name}
            </span>
          </div>
          <div className={styles.rarityRow}>
            <span>{t("Characteristic Roll Range")}:</span>
            <span
              className={styles.noWrap}
              style={{
                color: getColorByClassName(
                  character?.rarity_level?.toLowerCase(),
                ),
              }}
            >
              {`${character?.rarity_level_characteristic_min} - ${character.rarity_level_characteristic_max}`}
            </span>
          </div>
          <span>{character?.rarity_level_description}</span>
        </div>
      </CustomSheet>

      {/* Hero Card Level */}
      <HeroCardLevel character={character} allImagesLoaded={allImagesLoaded} />

      {/* Class Modal */}
      <img
        className={cx(styles.classIcon, {
          [styles.blink]: currentStep === 2,
        })}
        alt={"classIcon"}
        src={character?.class_image_url}
        width={"50px"}
        height={"50px"}
        onClick={() => setIsClassModalOpen(true)}
      />
      <CustomSheet
        isOpen={isClassModalOpen}
        onClose={() => setIsClassModalOpen(false)}
      >
        <div className={styles.classContent}>
          <span className={styles.classTitle}>{character.class}</span>
          <img
            alt={"classIcon"}
            src={character?.class_image_url}
            width={"100px"}
            height={"100px"}
          />
          <div className={styles.characteristicRow}>
            <span className={styles.mainCharacteristic}>
              {`${t("Main characteristic")}: ${t(
                character.class_main_characteristic,
              )}`}
            </span>
            <img
              height={"20px"}
              width={"20px"}
              className={styles.class_main_characteristic}
              alt={character.id}
              src={getCharImagesByRarity(character?.class_main_characteristic)}
            />
          </div>
          <span>{character.class_description}</span>
        </div>
      </CustomSheet>
    </div>
  );
};

export default HeroCardTopRow;
