import React, { useEffect, useState } from "react";
import styles from "./nft.module.scss";
import { useDispatch } from "react-redux";
import {
  fetchUserData,
  getNftCollectionAddress,
} from "../../services/registrationService";
import { useTelegram } from "../../hooks/Layout/useTelegramHook";
import TonWeb from "tonweb";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import cx from "classnames";

const NFTList = () => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userWallet, setUserWallet] = useState(null);
  const [nftCollection, setNftCollection] = useState(null);
  const dispatch = useDispatch();
  const { tg } = useTelegram();

  const getUrl = (collection, id) => {
    const baseUrl = process.env.REACT_APP_NFT_URL;
    return baseUrl.replace(":collection", collection).replace(":address", id);
  };

  const getCollectionUrl = (collection, id) => {
    const baseUrl = process.env.REACT_APP_COLLECTION_URL;
    return baseUrl.replace(":collection", collection).replace(":address", id);
  };

  useEffect(() => {
    fetchUserData(dispatch).then((r) =>
      setUserWallet(r?.user_settings?.ton_wallet_address),
    );
    getNftCollectionAddress().then((r) =>
      setNftCollection(r?.collection_address),
    );
  }, []);

  const convertToUserFriendlyAddress = (rawAddress) => {
    const address = new TonWeb.utils.Address(rawAddress);
    return address.toString(true, true, false, false);
  };

  useEffect(() => {
    if (!userWallet || !nftCollection) return;
    const fetchNFTs = async () => {
      try {
        const response = await fetch(
          getCollectionUrl(nftCollection, userWallet),
        );
        const data = await response.json();

        if (response.ok) {
          setNfts(data.nft_items);
        } else {
          setError("Error fetching NFTs");
        }
      } catch (err) {
        setError("Network error");
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, [userWallet, nftCollection]);

  const handleClick = (nft) => {
    tg.openLink(
      getUrl(
        convertToUserFriendlyAddress(nft?.collection?.address),
        convertToUserFriendlyAddress(nft?.address),
      ),
    );
  };

  if (loading)
    return (
      <div className={styles.loader}>
        <LoaderComponent />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.container}>
      {!nfts.length && (
        <div className={styles.noData}>
          <span>It's empty</span>
        </div>
      )}
      <ul className={cx(styles.list, styles.fadeIn)}>
        {nfts.map((nft) => (
          <li
            key={nft.address}
            className={styles.item}
            onClick={() => handleClick(nft)}
          >
            <div className={styles.imageWrapper}>
              {nft.metadata?.image ? (
                <img
                  src={nft.metadata.image}
                  alt={nft.metadata.name || "NFT"}
                  className={styles.image}
                />
              ) : (
                <div className={styles.placeholder}>No Image</div>
              )}
            </div>

            <div className={styles.details}>
              <h3 className={styles.nftName}>
                {nft.metadata?.name || "Unnamed NFT"}
              </h3>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NFTList;
