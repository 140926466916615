import React from "react";
import styles from "./styles.module.scss";
import HeroSlot from "../../components/ForCauldron/HeroSlot/HeroSlot";
import CauldronCards from "../../components/ForCauldron/CauldronCards/CauldronCards";
import CauldronHeroes from "../../components/ForCauldron/CauldronHeroes/CauldronHeroes";
import CauldronImage from "../../components/ForCauldron/CauldronImage/CauldronImage";
import CauldronPaymentModal from "../../components/ForCauldron/CauldronPaymentModal/CauldronPaymentModal";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { useCauldronHook } from "../../hooks/Pages/useCauldronHook";

const Cauldron = () => {
  const {
    isLoading,
    isSelect,
    cauldronData,
    newHero,
    heroSlots,
    handleOpenSelect,
    areAllHeroesSelected,
    handleCauldronClick,
    handleOpenHeroDetail,
    handleCloseSelect,
    hapticEnabled,
    tg,
    t,
    isOpen,
    setOpen,
    handleBuyStarMarketItem,
    handleBuyMarketItem,
    handleHeroesSelect,
    userAmount,
    pathMarketItem,
    starMarketItem,
  } = useCauldronHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {!isSelect && (
          <div className={styles.mainContainer}>
            <div className={styles.topSide}>
              <CauldronCards
                chances={cauldronData?.chances}
                newHero={newHero}
                handleOpenHeroDetail={handleOpenHeroDetail}
              />
            </div>
            <div className={styles.bottomSide}>
              <div className={styles.slots}>
                {heroSlots.map((hero, index) => (
                  <HeroSlot
                    index={index}
                    key={index}
                    onClick={() => handleOpenSelect(index)}
                    hero={hero}
                  />
                ))}
              </div>
              <CauldronImage
                isReady={areAllHeroesSelected}
                onClick={handleCauldronClick}
                newHero={newHero}
              />
            </div>
          </div>
        )}
        {isSelect && (
          <CauldronHeroes
            handleCloseSelect={handleCloseSelect}
            onSelectHero={handleHeroesSelect}
            tg={tg}
            hapticEnabled={hapticEnabled}
          />
        )}
      </div>
      <CauldronPaymentModal
        t={t}
        userAmount={userAmount}
        marketItems={{ path: pathMarketItem, star: starMarketItem }}
        handleBuyStarMarketItem={handleBuyStarMarketItem}
        handleBuyMarketItem={handleBuyMarketItem}
        isOpen={isOpen && !newHero?.id}
        setOpen={() => setOpen(false)}
      />
    </>
  );
};

export default Cauldron;
