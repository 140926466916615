import { axiosInstance } from "./axiosService";

const getUserWallets = () => {
  return axiosInstance.get("/me/wallets");
};

const getUserBadges = (types = "quests,tasks,market_items,market") => {
  return axiosInstance.get(`/me/badges?types=${types}`);
};

export { getUserWallets, getUserBadges };
