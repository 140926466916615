import React from "react";
import styles from "./styles.module.scss";
import { PreloadImages } from "../../../utils/globals";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const QuestsBanner = ({
  handleGoToQuests,
  getQuestsCount,
  nextQuestTime,
  t,
}) => {
  return (
    <div
      className={styles.questsBanner}
      style={{
        background: `url(${PreloadImages.QUESTS_BANNER}) no-repeat center center`,
      }}
      onClick={handleGoToQuests}
    >
      <div className={styles.bannerOverlay}>
        <span className={styles.text}>{t("Next quests")}:&nbsp;</span>
        <FlipClockCountdown
          onComplete={getQuestsCount}
          to={nextQuestTime}
          labelStyle={{ display: "none" }}
          showLabels={false}
          renderMap={[false, true, true, true]}
        />
      </div>
    </div>
  );
};

export default QuestsBanner;
