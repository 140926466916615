import React, { useRef, useCallback } from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../../components/ui/LoaderComponent/LoaderComponent";
import { formatAmount } from "../../../utils/formatNumbers";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";

const ScrollableList = ({ users, loadMore, isLoading, page }) => {
  const { t } = useTranslation();
  const observer = useRef();

  const lastUserElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, loadMore],
  );

  const renderUser = (user, index) => {
    const isLastElement = users?.length === index + 1;

    return (
      <div
        ref={isLastElement ? lastUserElementRef : null}
        className={styles.cell}
        key={index}
      >
        <div className={styles.cellContent}>
          <div className={styles.avatar}>
            <img
              src={AsyncPreloadImages.REFERRAL_DARK}
              alt="Avatar"
              className={styles.avatarImage}
            />
          </div>
          <div className={styles.row}>
            <span className={styles.username}>
              {user?.referral_user?.username || "-"}
            </span>
            <div className={styles.userInfo}>
              <span className={styles.reward}>
                {`+${formatAmount(
                  user?.reward?.amount,
                )} ${user?.reward?.currency_code}`}
              </span>
              <span>{t("received")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.listWrapper}>
      {!users?.length && (
        <div className={styles.noData}>
          <img src={AsyncPreloadImages.NO_REFERRAL_ICON} alt="No Data" />
          <span>{t("noHistory")}</span>
          <span className={styles.caption}>{t("inviteFriendsMessage")}</span>
        </div>
      )}
      {!!users?.length && (
        <div className={styles.list}>
          {users.map((user, index) => renderUser(user, index))}
          {isLoading && page > 1 && <LoaderComponent />}
        </div>
      )}
    </div>
  );
};

export default ScrollableList;
