import i18n from "i18next";

export const getQuestsStep = () => [
  i18n.t("Embark on thrilling quests!"),
  i18n.t("New quests appear every day, so don’t miss out."),
  i18n.t("Here you can see when the next quests become available."),
  i18n.t("Here you can see your completed quests."),
  i18n.t("Daily quests are assigned based on the level of your active heroes."),
  i18n.t("Let's take a look at one of your quests."),
];

export const getQuestSteps = () => [
  i18n.t("Here’s your quest! Complete it to become stronger."),
  i18n.t(
    "This quest will reward you with valuable coins upon successful completion.",
  ),
  i18n.t("Here you can select your hero to undertake this quest."),
  i18n.t("Then choose your hero by checking the box next to their name."),
  i18n.t(
    "Tap Select to confirm your hero. See your chance of success and start the quest.",
  ),
];

export const getQuestsHistorySteps = () => [
  i18n.t("Here is your list of completed quests."),
  i18n.t(
    "Keep completing quests to level up your heroes and earn more rewards.",
  ),
];

export const getMarketSteps = () => [
  i18n.t("Welcome to the Market!"),
  i18n.t("Here you can buy treasures and other exciting items."),
  i18n.t("Each treasure contains unique heroes."),
  i18n.t(
    "Now, you can also purchase Golden Quest Scrolls and Divine Quest Scrolls to access special quests.",
  ),
  i18n.t("Stay tuned for more items coming soon!"),
];

export const getProfileSteps = () => [
  i18n.t("Manage your profile here."),
  i18n.t("Check your Inventory to see all your treasures."),
  i18n.t("Review your Transaction History to track all your activities."),
  i18n.t("Complete Tasks for additional rewards."),
  i18n.t("Adjust your settings to tailor your game experience."),
  i18n.t("Connect or update your TON wallet for seamless rewards."),
];

export const mainPageSteps = (isExist) => {
  const steps = [
    i18n.t("Welcome to The Path!"),
    i18n.t("I am Zephyr, your guide through the game."),
    i18n.t("Let’s turn the music on to make it more fun."),
    i18n.t("Music only works if your phone's sound is turned on."),
    i18n.t(
      "Take a moment to familiarize yourself with the main sections of the game.",
    ),
    i18n.t("Explore the icons on the left to access various features."),
    i18n.t("Check out the icons on the right for more options."),
    i18n.t(
      "Here is the information banners section. You can see active events here.",
    ),
  ];

  if (!isExist) {
    steps.push(i18n.t("Time to open a hero!"), "");
  }

  return steps;
};

export const getGiveawaysSteps = () => [
  i18n.t("Participate in exciting giveaways!"),
  i18n.t("Enter daily, weekly, and special draws for amazing rewards."),
];

export const getGiveawaySteps = () => [
  i18n.t("Check the leaderboard to see who’s leading."),
  i18n.t("Good luck and may the best player win!"),
];

export const getTransactionsSteps = () => [
  i18n.t("Track all your in-game transactions and activities."),
  i18n.t("Stay informed about your earnings and expenditures."),
];

export const getTasksSteps = () => [
  i18n.t("Complete these tasks to earn more rewards!"),
  i18n.t("Each completed task rewards you with additional prizes."),
  i18n.t("Stay active and maximize your earnings!"),
];

export const getHeroDetailSteps = () => [
  i18n.t("Dive deeper into your hero's abilities and stats."),
  i18n.t(
    "Here you can view detailed skills and all the information about your hero.",
  ),
];

export const getHeroesSteps = () => [
  i18n.t("Welcome to your Heroes page!"),
  i18n.t("Here you will find all your mighty heroes."),
  i18n.t("You can sort and filter them to find the ones you’re interested in."),
  i18n.t(
    "This page supports left and right gestures for easy navigation between pages.",
  ),
  i18n.t("Here you can see your total number of heroes."),
  i18n.t("At the moment, you can level up 20 heroes, so choose them wisely!"),
];

export const getHeroSteps = () => [
  i18n.t("This is your hero page."),
  i18n.t("Here you can see the hero legend."),
  i18n.t("Here you can see the hero class legend."),
  i18n.t(
    "Here you can add it to your favorites and see detailed information about it.",
  ),
  i18n.t("Favorite heroes are displayed first on the Heroes page."),
];

export const getRatingSteps = () => [
  i18n.t("Climb the ranks on the leaderboard!"),
  i18n.t("See your position among players worldwide."),
  i18n.t(
    "Earn points through your heroes' combined power, skills, items, and buffs.",
  ),
  i18n.t("Top players win amazing rewards."),
  i18n.t("Aim for the top spot today!"),
];

export const getInventorySteps = () => [
  i18n.t("Great! Here is your Inventory."),
  i18n.t("Head over to your chests and unlock your first hero."),
  "",
  "",
];

export const getRouletteSteps = () => [
  i18n.t(
    "Here comes the decisive moment in your game, let's unlock your first hero and start the journey!",
  ),
  "",
  "",
];

export const getReferralSteps = () => [
  i18n.t("Invite your friends to join The Path!"),
  i18n.t("Share your unique referral link easily."),
  i18n.t("Earn points for every friend you bring."),
  i18n.t("Boost your leaderboard ranking with referrals."),
  i18n.t("Unlock exclusive rewards through your network."),
];
