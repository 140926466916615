import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import Button from "../../components/ui/Button/Button";

const Boxes = ({ marketItems, t, navigateToMarket, navigateToRoulette }) => {
  return (
    <>
      {!marketItems?.length && (
        <div className={cx(styles.noData, styles.fadeIn)}>
          <img
            alt={t("No Treasures Yet")}
            src={AsyncPreloadImages.NO_TREASURES_ICON}
            className={styles.noDataImage}
          />
          <div className={styles.noDataText}>{t("No Treasures Yet")}</div>
          <div className={styles.noDataCaption}>
            {t(
              "As soon as you acquire chests, they'll appear here for you to explore.",
            )}
          </div>
          <Button onClick={navigateToMarket}>{t("Get a Chest")}</Button>
        </div>
      )}
      {!!marketItems?.length && (
        <div className={cx(styles.marketItemsList, styles.fadeIn)}>
          {marketItems.map((item) => (
            <div
              key={item.id}
              className={styles.card}
              onClick={() =>
                navigateToRoulette(item.id, item?.market_item?.type, item)
              }
            >
              <img
                className={styles.cardImg}
                alt={item.market_item.title}
                src={item.market_item.image_url}
              />
              <div className={styles.cardCell}>
                <span className={styles.text}>{item.market_item.title}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Boxes;
