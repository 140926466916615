import React from "react";
import styles from "./styles.module.scss";
import CoinLoader from "../../CoinLoader/CoinLoader";

const LoaderComponent = ({ size }) => {
  return (
    <div className={styles.loader}>
      <CoinLoader size={size} />
    </div>
  );
};

export default LoaderComponent;
