import React, { Fragment } from "react";
import styles from "./styles.module.scss";
import { Badge, Image, Info, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import MarketSnackbar from "./MarketSnackbar";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import { useMarketHook } from "../../hooks/Pages/useMarketHook";
import MarketCard from "./MarketCard";
import MarketModal from "./MarketModal";
import SuccessModal from "./SuccessModal";
import cx from "classnames";

const Market = () => {
  const {
    handleBuyMarketItem,
    t,
    marketItems,
    isLoading,
    userAmount,
    snackbar,
    setSnackbar,
    activeId,
    isModalOpen,
    setIsModalOpen,
    activeModalItem,
    setActiveModalItem,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    handleOpenRoulette,
    setIsSuccessModalOpen,
    isSuccessModalOpen,
    activeItemImage,
    marketItemBadge,
    currentStep,
  } = useMarketHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={cx(styles.container, styles.fadeIn)}>
        <div className={styles.topline}>
          <Info type={"text"}>
            <div className={styles.userBalance}>
              {`${formatAmount(userAmount)}`}
              <Image
                size={25}
                className={styles.coinImage}
                src={PreloadImages.COIN_ICON}
              />
            </div>
          </Info>
          <div className={styles.inventoryWrapper}>
            <Image
              size={33}
              onClick={handleNavigateToInventory}
              className={styles.image}
              src={PreloadImages.INVENTORY_ICON}
            />
            {marketItemBadge && <span className={styles.notificationDot} />}
          </div>
        </div>
        {Object.keys(marketItems).length === 0 && !isLoading && (
          <Text>Беда беда...</Text>
        )}

        {Object.keys(marketItems).map((section, index) => (
          <Fragment key={section}>
            {/*<span className={styles.sectionTitle}>{section}</span>*/}
            {index > 0 && <div className={styles.separator} />}
            <div className={cx(styles.marketItemsList, styles.fadeIn)}>
              {marketItems[section]?.map((item) => (
                <div style={{ position: "relative" }} key={item.id}>
                  {item.sale && (
                    <Badge
                      className={styles.badge}
                      mode="primary"
                      type="number"
                    >
                      -{item.sale.discount_percentage}%
                    </Badge>
                  )}
                  <MarketCard
                    currentStep={currentStep}
                    handleCardClick={handleCardClick}
                    handleChipClick={handleChipClick}
                    setActiveModalItem={setActiveModalItem}
                    item={item}
                    setIsModalOpen={setIsModalOpen}
                    t={t}
                  />
                </div>
              ))}
            </div>
          </Fragment>
        ))}
        {snackbar.open && (
          <MarketSnackbar
            handleOpenRoulette={handleOpenRoulette}
            setSnackbar={setSnackbar}
            snackbar={snackbar}
            activeId={activeId}
          />
        )}
      </div>
      <MarketModal
        t={t}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleBuyMarketItem={handleBuyMarketItem}
        activeModalItem={activeModalItem}
      />
      <SuccessModal
        activeItemImage={activeItemImage}
        handleOpenRoulette={handleOpenRoulette}
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
    </>
  );
};

export default Market;
