import React, { useState } from "react";
import styles from "./styles.module.scss";
import ReactSlider from "react-slider";
import Popup from "reactjs-popup";
import cx from "classnames";

const LevelFilter = ({
  selectedRange,
  setSelectedRange,
  isHeroInventory,
  t,
  hapticEnabled,
  tg,
}) => {
  const [tempRange, setTempRange] = useState(selectedRange);
  const isActive = selectedRange[0] !== 1 || selectedRange[1] !== 100;

  const handlePopupClose = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (
      tempRange[0] !== selectedRange[0] ||
      tempRange[1] !== selectedRange[1]
    ) {
      setSelectedRange(tempRange);
    }
    setTempRange([1, 100]);
  };

  const handlePopupOpen = () => {
    setTempRange(selectedRange);
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  return (
    <Popup
      keepTooltipInside
      arrow={false}
      trigger={
        <button
          className={cx(styles.triggerButton, { [styles.active]: isActive })}
        >
          {t("Level")}
        </button>
      }
      position={isHeroInventory ? "left bottom" : "right center"} //TODO: Fix the position of popup (in cauldron doesn't work)
      className={styles.popupWrapper}
      onClose={handlePopupClose}
      onOpen={handlePopupOpen}
    >
      <div className={styles.popupContent}>
        <div className={styles.title}>
          <span>{t("adjustLevelRange")}</span>
        </div>
        <div className={styles.sliderContainer}>
          <ReactSlider
            className={styles.slider}
            thumbClassName={styles.thumb}
            trackClassName={styles.track}
            min={1}
            max={100}
            value={tempRange}
            onChange={(value) => setTempRange(value)}
            pearling
            minDistance={1}
          />
        </div>
        <div className={styles.levelDisplay}>
          <span>Level:</span>
          <span className={styles.levelValue}>
            <strong>{tempRange[0]}</strong>
          </span>
          <span>-</span>
          <span className={styles.levelValue}>
            <strong>{tempRange[1]}</strong>
          </span>
        </div>
      </div>
    </Popup>
  );
};

export default LevelFilter;
