// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__MgaBK {
  border: unset;
  padding: 12px;
  border-radius: 12px;
  color: var(--tg-theme-button-text-color);
  background-color: var(--tg-theme-button-color);
  cursor: pointer;
  animation: styles_fadeInAnimation__ytaC- 1s forwards;
  font-size: 1rem;
  transition: transform 0.1s ease, background-color 0.3s ease, opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.styles_button__MgaBK:disabled {
  cursor: not-allowed;
  filter: grayscale(10%) brightness(50%);
}
@media (hover: hover) and (pointer: fine) {
  .styles_button__MgaBK:not(:disabled):hover {
    opacity: 0.85 !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}
.styles_button__MgaBK:not(:disabled):active {
  transform: scale(0.98);
  transition: transform 0.1s ease;
}

.styles_icon__kOqjf {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes styles_fadeInAnimation__ytaC- {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__AcooG {
  opacity: 0;
  animation: styles_fadeInAnimation__ytaC- 1s forwards;
}`, "",{"version":3,"sources":["webpack://./src/components/common/TelegramButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,8CAAA;EACA,eAAA;EACA,oDAAA;EACA,eAAA;EACA,8EAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;AACE;EACE,mBAAA;EACA,sCAAA;AACJ;AAEE;EACE;IACE,wBAAA;IACA,yCAAA;EAAJ;AACF;AAGE;EACE,sBAAA;EACA,+BAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE;IACE,UAAA;EAFF;EAIA;IACE,UAAA;EAFF;AACF;AAKA;EACE,UAAA;EACA,oDAAA;AAHF","sourcesContent":[".button {\n  border: unset;\n  padding: 12px;\n  border-radius: 12px;\n  color: var(--tg-theme-button-text-color);\n  background-color: var(--tg-theme-button-color);\n  cursor: pointer;\n  animation: fadeInAnimation 1s forwards;\n  font-size: 1rem;\n  transition: transform 0.1s ease, background-color 0.3s ease, opacity 0.3s ease;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  &:disabled {\n    cursor: not-allowed;\n    filter: grayscale(10%) brightness(50%);\n  }\n\n  @media (hover: hover) and (pointer: fine) {\n    &:not(:disabled):hover {\n      opacity: 0.85 !important;\n      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);\n    }\n  }\n\n  &:not(:disabled):active {\n    transform: scale(0.98);\n    transition: transform 0.1s ease;\n  }\n}\n\n.icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__MgaBK`,
	"fadeInAnimation": `styles_fadeInAnimation__ytaC-`,
	"icon": `styles_icon__kOqjf`,
	"fadeIn": `styles_fadeIn__AcooG`
};
export default ___CSS_LOADER_EXPORT___;
