import React from "react";
import styles from "./styles.module.scss";
import { useSettingsPageHook } from "../../hooks/Pages/useSettingsPageHook";
import LanguageSwitcher from "../../components/common/LanguageSwitcher/LanguageSwitcher";
import { FixedLayout, Switch, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { GrPowerReset } from "react-icons/gr";

const Settings = () => {
  const {
    t,
    hapticEnabled,
    handleHapticToggle,
    activeLanguage,
    setActiveLanguage,
    isLoading,
    setIsLoading,
    volume,
    handleVolumeChange,
    navigateToMainPage,
    handleCancelReset,
    handleConfirmReset,
    handleResetGuideClick,
    tutorials,
    showConfirmation,
  } = useSettingsPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.settings}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.labelCell}>
            <Text>{t("language")}</Text>
          </div>
          <LanguageSwitcher
            setIsLoading={setIsLoading}
            activeLanguage={activeLanguage}
            setActiveLanguage={setActiveLanguage}
          />
        </div>

        <div className={styles.row}>
          <div className={styles.labelCell}>
            <Text>{t("hapticFeedback")}</Text>
          </div>
          <div className={styles.controlCell}>
            <Switch checked={hapticEnabled} onChange={handleHapticToggle} />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.labelCell}>
            <Text>{t("Music level")}</Text>
          </div>
          <div className={styles.controlCell}>
            <input
              className={styles.range}
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
          </div>
        </div>

        {!!tutorials?.length && (
          <div className={styles.row}>
            <div className={styles.labelCell}>
              <Text>
                {showConfirmation ? t("Are you sure?") : t("Reset Guide")}
              </Text>
            </div>
            <div className={styles.controlCell}>
              {showConfirmation ? (
                <div className={styles.confirmationButtons}>
                  <TelegramButton
                    className={styles.button}
                    onClick={handleConfirmReset}
                  >
                    {t("Yes")}
                  </TelegramButton>
                  <TelegramButton
                    className={styles.button}
                    onClick={handleCancelReset}
                  >
                    {t("No")}
                  </TelegramButton>
                </div>
              ) : (
                <TelegramButton
                  className={styles.resetButton}
                  onClick={handleResetGuideClick}
                >
                  <GrPowerReset />
                </TelegramButton>
              )}
            </div>
          </div>
        )}
      </div>

      <FixedLayout className={styles.fixedLayout}>
        <TelegramButton onClick={navigateToMainPage}>
          {t("To the Main Page")}
        </TelegramButton>
      </FixedLayout>
    </div>
  );
};

export default Settings;
