import React from "react";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import Button from "../../components/ui/Button/Button";
import Select from "react-select";
import { formatAmount } from "../../utils/formatNumbers";
import { BsArrowRight } from "react-icons/bs";
import { useMarketItemHook } from "../../hooks/Pages/useMarketItemHook";
import InfoModal from "../../components/InfoModal/InfoModal";
import SuccessModal from "../Market/SuccessModal";
import { StarIcon } from "../../assets/icons";
import cx from "classnames";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { customStyles } from "../../components/ForHeroes/FiltersModal/selectStyles";

const MarketItem = () => {
  const { t } = useTranslation();
  const {
    handleCloseModal,
    handleOpenModal,
    handleBuyMarketItem,
    isLoading,
    isModalOpen,
    marketItem,
    handleOpenRoulette,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
    levelOptions,
    quantityOptions,
    handleLevelChange,
    handleQuantityChange,
    isButtonLoading,
    maxHeroLevel,
    iframe,
  } = useMarketItemHook();

  if (isLoading) return <LoaderComponent />;

  const CurrencyIndicator = ({ currency, size = 17 }) => {
    if (currency === "STAR") {
      return <StarIcon size={size} />;
    }
    return <span>{currency}</span>;
  };

  return (
    <>
      <div className={cx(styles.marketItemWrapper, styles.fadeIn)}>
        <img
          alt={t("loot")}
          className={styles.image}
          src={marketItem.image_url}
        />
        <div className={styles.marketItemContent}>
          <div className={styles.info}>
            <span className={styles.title}>
              {marketItem.title}
              {marketItem.sale && (
                <span className={styles.sale}>
                  {`-${marketItem.sale.discount_percentage}%`}
                </span>
              )}
            </span>
          </div>
          {["quest_lootbox"].includes(marketItem?.type) && (
            <div className={styles.selectColumn}>
              <label htmlFor="quantity-select">{t("Select quantity")}:</label>
              <Select
                inputId="quantity-select"
                className={styles.select}
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                styles={customStyles}
                options={quantityOptions}
                isClearable={false}
                placeholder={t("Quantity")}
                onChange={handleQuantityChange}
                defaultValue={quantityOptions.find((opt) => opt.value === 1)}
              />
            </div>
          )}
          {[
            "hero_items_lootbox",
            "quest_lootbox",
            "daily_quest_lootbox",
          ].includes(marketItem?.type) && (
            <div className={styles.selectColumn}>
              <label htmlFor="level-select">{t("Select level")}:</label>
              <Select
                inputId="level-select"
                styles={customStyles}
                className={styles.select}
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                options={levelOptions}
                isClearable={false}
                placeholder={t("Level")}
                onChange={handleLevelChange}
                defaultValue={levelOptions.find(
                  (opt) =>
                    opt.value ===
                    (marketItem?.type === "daily_quest_lootbox"
                      ? maxHeroLevel
                      : 1),
                )}
              />
            </div>
          )}
          {
            <Button
              disabled={isButtonLoading || !marketItem}
              onClick={handleBuyMarketItem}
            >
              <div className={styles.buttonContent}>
                <span
                  className={cx(styles.spanRow, {
                    [styles.notCenter]:
                      marketItem?.price?.currency_code === "STAR",
                  })}
                >
                  {marketItem.sale ? (
                    <>
                      <span className={styles.strikethrough}>
                        {formatAmount(marketItem?.final_price_no_sale?.amount)}
                      </span>
                      <BsArrowRight />
                      {formatAmount(marketItem?.final_price?.amount)}
                    </>
                  ) : (
                    `${formatAmount(marketItem?.final_price.amount)}`
                  )}
                </span>
                <CurrencyIndicator
                  currency={marketItem?.price?.currency_code}
                />
              </div>
            </Button>
          }
          <div className={styles.description}>
            <span>{t("Description")}</span>
            <span className={styles.text}>{marketItem.description}</span>
          </div>
          <div className={styles.content}>
            <span>{t("Content")}</span>
            <div className={styles.column}>
              <span className={styles.text}>
                {t("Click more or check out on pathgame.app")}
              </span>
              <Button onClick={handleOpenModal}>{t("More")}</Button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <InfoModal handleCloseModal={handleCloseModal} url={iframe} />
      )}
      <SuccessModal
        activeItemImage={marketItem?.image_url}
        handleOpenRoulette={handleOpenRoulette}
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
    </>
  );
};

export default MarketItem;
