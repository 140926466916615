import React, { memo, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import AttributesRow from "../AttributesRow/AttributesRow";
import MiniCardImages from "../MiniCard/MiniCardImages";
import CustomCheckbox from "../../ui/Checkbox/Checkbox";

const HeroCell = React.forwardRef(
  ({ hero, isSelected, onCheckboxChange, isDisabled }, ref) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
      setIsImageLoaded(true);
    };

    return (
      <div
        ref={ref}
        className={cx(styles.cell, { [styles.disabled]: isDisabled })}
        onClick={() => {
          if (isDisabled) return;
          onCheckboxChange(hero);
        }}
      >
        <div
          className={cx(styles.miniCardWrapper, {
            [styles.cardLoading]: !isImageLoaded,
          })}
        >
          <MiniCardImages
            isCauldron
            onAllImagesLoaded={() => handleImageLoad()}
            character={hero}
          />
          <span
            className={styles.text}
          >{`Lv. ${Math.floor(hero?.level)}`}</span>
          {!isImageLoaded && <div className={styles.loaderOverlay} />}
        </div>

        <div className={styles.mainInfo}>
          <div className={styles.header}>
            <span className={styles.heroName}>{hero.hero_name}</span>
            <img
              alt={hero.hero_name}
              src={hero.class_image_url}
              width="20"
              className={styles.classBadge}
            />
          </div>

          <AttributesRow character={hero} isQuest />
        </div>

        <CustomCheckbox
          checked={isSelected}
          onChange={() => {
            onCheckboxChange(hero);
          }}
        />
      </div>
    );
  },
);

export default memo(HeroCell);
