// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_logo__XkKQs {
  border-radius: unset;
  box-shadow: unset;
  background: unset;
  border: unset;
  animation: styles_blink__Dhi3P 2s infinite;
  transition: transform 0.3s;
}

.styles_loader__OxBX\\+ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes styles_blink__Dhi3P {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CoinLoader/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,0CAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,YAAA;EACF;AACF","sourcesContent":[".logo {\n  border-radius: unset;\n  box-shadow: unset;\n  background: unset;\n  border: unset;\n  animation: blink 2s infinite;\n  transition: transform 0.3s;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n@keyframes blink {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `styles_logo__XkKQs`,
	"blink": `styles_blink__Dhi3P`,
	"loader": `styles_loader__OxBX+`
};
export default ___CSS_LOADER_EXPORT___;
