import React from "react";
import Button from "../../components/ui/Button/Button";

const NoData = ({ children, buttonText, onButtonClick, mainText, subText }) => {
  return (
    <div>
      <div>{children}</div>
      {mainText && <p>{mainText}</p>}
      {subText && <p>{subText}</p>}
      {buttonText && onButtonClick && (
        <Button onClick={onButtonClick}>{buttonText}</Button>
      )}
    </div>
  );
};

export default NoData;
