import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getUserWallets } from "../../services/userService";
import { getTransactions } from "../../services/transactionsService";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { fetchUserData } from "../../services/registrationService";
import { useDispatch } from "react-redux";
import useGuide from "./useGuideHook";
import { getTransactionsSteps } from "../../utils/guideSteps";

export const useTransactionsHook = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedWalletId, setSelectedWalletId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const observer = useRef();

  useTelegramBackButton(-1);

  useEffect(() => {
    fetchUserData(dispatch).finally(() => setIsLoading(false));
    getUserWallets().then((res) => {
      const pathWallet = res?.find(
        (wallet) => wallet?.balance?.currency_code === "PATH",
      );
      if (pathWallet) {
        setSelectedWalletId(pathWallet.id);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedWalletId) return;

    if (!isDataLoading) setIsDataLoading(true);

    getTransactions(selectedWalletId, page, 10)
      .then((response) => {
        const data = response.data?.data || [];
        const paginationInfo = response.data?.pagination_info || {};

        setTransactions((prevTransactions) => [...prevTransactions, ...data]);
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  }, [selectedWalletId, page]);

  const lastTransactionElementRef = useCallback(
    (node) => {
      if (isDataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isDataLoading, hasMore],
  );

  const getIsSentTransaction = (transaction) => {
    return transaction.wallet_id === selectedWalletId;
  };

  const getDate = (date) => {
    return new Date(date)
      .toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(/\//g, ".");
  };

  const steps = useMemo(() => getTransactionsSteps(), []);

  useGuide(steps, null);

  return {
    transactions,
    isDataLoading,
    page,
    getIsSentTransaction,
    lastTransactionElementRef,
    isLoading,
    getDate,
  };
};
