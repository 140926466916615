export const calculateProgress = (startedAt, endedAt) => {
  const now = new Date();
  const start = new Date(startedAt);
  const end = new Date(endedAt);

  const totalDuration = end - start;

  if (totalDuration === 0) {
    return 0;
  }

  const remainingTime = end - now;
  return Math.max((remainingTime / totalDuration) * 100, 0);
};
