import React from "react";
import MiniCard from "../../components/ForHeroes/MiniCard/MiniCard";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import styles from "./styles.module.scss";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import Button from "../../components/ui/Button/Button";
import { useTranslation } from "react-i18next";

const HeroesList = ({
  heroes,
  isLoading,
  allImagesLoaded,
  handleOpenMarket,
}) => {
  const { t } = useTranslation();

  if (!heroes?.length && !isLoading) {
    return (
      <div className={styles.noHeroes}>
        <img
          className={styles.image}
          src={AsyncPreloadImages.NO_HEROES_ICON}
          alt="noHero"
        />
        <Button onClick={handleOpenMarket}>{t("To inventory")}</Button>
      </div>
    );
  }

  return (
    <div className={styles.heroes}>
      {!allImagesLoaded && !!heroes?.length && (
        <div className={styles.loader}>
          <LoaderComponent />
        </div>
      )}
      {heroes.map((hero, index) => (
        <MiniCard key={hero.id} character={hero} delay={index * 150} />
      ))}
    </div>
  );
};

export default HeroesList;
