import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./CustomToast.module.scss";

const SuccessIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#4BB543">
    <path d="M12 0C5.383 0 0 5.383 0 12c0 6.617 5.383 12 12 12 6.617 0 12-5.383 12-12C24 5.383 18.617 0 12 0zm-1.2 17.4l-5.4-5.4 1.68-1.68L10.8 13.92l6.72-6.72L19.2 9l-8.4 8.4z" />
  </svg>
);

const ErrorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#FF3E3E">
    <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm5.656 16.97L16.97 17.656 12 12.686l-4.97 4.97-0.686-0.686L11.314 12 6.343 7.03l0.686-0.686L12 11.314l4.97-4.97 0.686 0.686L12.686 12l4.97 4.97z" />
  </svg>
);

const CustomToast = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      className={styles.toastContainer}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      stacked
      limit={10}
      draggablePercent={20}
    />
  );
};

export const showActionToast = (message, action) => {
  toast(message, {
    icon: <SuccessIcon />,
    onClick: action,
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    icon: <SuccessIcon />,
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    icon: <ErrorIcon />,
  });
};

export default CustomToast;
