import React from "react";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";
import styles from "./styles.module.scss";
import TelegramButton from "../common/TelegramButton/TelegramButton";
import LoaderComponent from "../common/LoaderComponent/LoaderComponent";
import { Caption, FixedLayout } from "@telegram-apps/telegram-ui";
import cx from "classnames";
import InfoModal from "../InfoModal/InfoModal";
import { FaCircleQuestion } from "react-icons/fa6";
import { useRouletteHook } from "../../hooks/Pages/useRouletteHook";
import PageTitle from "../PageTitle/PageTitle";
import HeroDetail from "../../pages/HeroDetail/HeroDetail";

const Roulette = () => {
  const {
    isHero,
    marketItem,
    prizeList,
    handlePrizeDefined,
    handleOpenModal,
    getButtonTitle,
    handleStart,
    isModalOpen,
    handleClickMore,
    isLoaderEnd,
    isNoMore,
    t,
    isLoading,
    hero,
    start,
    prizeIndex,
    handleCloseModal,
    isButtonLoading,
    currentStep,
  } = useRouletteHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      {!isHero && (
        <FixedLayout vertical="top" className={styles.fixedLayout}>
          <PageTitle isNoMargin>{marketItem?.market_item?.title}</PageTitle>
          <img
            alt="loot"
            className={cx(styles.image, styles.fadeIn)}
            src={marketItem?.market_item?.image_url}
          />
        </FixedLayout>
      )}
      {!isHero && (
        <RoulettePro
          // transitionFunction="ease"
          // options={{ withoutAnimation: true }}
          classes={{ wrapper: cx(styles.roulette, styles.fadeIn) }}
          prizes={prizeList}
          prizeIndex={prizeIndex}
          start={start}
          onPrizeDefined={handlePrizeDefined}
          spinningTime={10}
        />
      )}
      {isHero && (
        <div
          className={cx(styles.heroContainer, styles.fadeIn, {
            [styles.height]: !isLoaderEnd,
          })}
        >
          <HeroDetail hero={hero} />
        </div>
      )}
      <FixedLayout className={cx(styles.buttonRow, styles.fadeIn)}>
        {!isHero && (
          <div className={styles.column}>
            <FaCircleQuestion
              size={24}
              className={styles.question}
              onClick={handleOpenModal}
            />
            <Caption className={styles.caption}>
              {marketItem?.market_item?.description}
            </Caption>
          </div>
        )}
        {!isHero && (
          <TelegramButton
            disabled={isButtonLoading}
            className={cx(styles.button, {
              [styles.active]: currentStep === 1,
            })}
            onClick={handleStart}
          >
            {getButtonTitle()}
          </TelegramButton>
        )}
        {isHero && (
          <TelegramButton
            className={cx(styles.fadeIn, styles.button)}
            onClick={handleClickMore}
          >
            {isNoMore ? t("Buy more") : t("Open one more")}
          </TelegramButton>
        )}
      </FixedLayout>
      {isModalOpen && <InfoModal handleCloseModal={handleCloseModal} />}
    </>
  );
};

export default Roulette;
