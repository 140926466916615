export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#1f1f1f" : "#2a2a2a", // Темный фон для фокуса и обычного состояния
    borderColor: state.isFocused ? "#ff9800" : "#444", // Яркая оранжевая граница при фокусе
    boxShadow: state.isFocused ? "0 0 0 1px #ff9800" : "none", // Тонкая оранжевая тень при фокусе
    "&:hover": {
      borderColor: "#ff9800", // Изменение цвета границы при наведении
    },
    borderRadius: "8px",
    minHeight: "42px",
    padding: "0 8px",
    color: "#fff",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#2a2a2a",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
    marginTop: "4px",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#ff9800"
      : state.isFocused
        ? "#3a3a3a"
        : "#2a2a2a", // Яркий оранжевый для выбранных опций, темно-серый при наведении
    color: state.isSelected ? "#1f1f1f" : "#fff", // Темный текст для выбранных опций, белый для остальных
    cursor: "pointer",
    padding: "10px 16px",
    "&:hover": {
      backgroundColor: "#3a3a3a", // Темно-серый фон при наведении
    },
    borderRadius: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff",
    fontWeight: "bold",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#ff9800",
    borderRadius: "8px",
    padding: "2px 6px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#1f1f1f",
    fontWeight: "bold",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#1f1f1f",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e68900",
      color: "#1f1f1f",
      borderRadius: "50%",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  placeholder: (provided) => ({
    ...provided,
    color: "#aaa",
    fontStyle: "italic",
  }),
};
