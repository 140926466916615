import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTelegramData, setUserData } from "../store/slices/telegramSlice";
import i18n from "i18next";
import App from "../App";

export const InitApp = () => {
  const [isTg, setIsTg] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const params = new URLSearchParams(tg.initData);
      const user = JSON.parse(decodeURIComponent(params.get("user")));
      const parsedData = {
        initData: tg.initData,
        user,
        platform: tg.platform,
        theme: tg.themeParams,
        colorScheme: tg.colorScheme,
      };
      const isUserFromTelegram = !!user?.id;
      setIsTg(isUserFromTelegram);

      if (!isUserFromTelegram) {
        window.location.href = "https://t.me/ThePathGameBot/discover";
      } else {
        dispatch(setTelegramData(parsedData));
        dispatch(setUserData(parsedData.user));

        const userLanguage = user.language_code || "en";
        i18n.changeLanguage(userLanguage);
      }
    } else {
      setIsTg(false);
    }
  }, [dispatch]);

  if (!isTg) {
    return null;
  }

  return <App />;
};
