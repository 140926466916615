import React from "react";
import styles from "./styles.module.scss";
import { BiSolidBookmarkHeart } from "react-icons/bi";
import cx from "classnames";

const Favorite = ({ isQuests }) => {
  return (
    <div
      className={cx(styles.favoriteContainer, {
        [styles.questsFavorite]: isQuests,
      })}
    >
      <div className={styles.favoriteRow}>
        <BiSolidBookmarkHeart
          size={isQuests ? 20 : 40}
          className={styles.favorite}
        />
        <div className={cx(styles.heart, { [styles.questsHeart]: isQuests })} />
      </div>
    </div>
  );
};

export default Favorite;
