// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slot__yQjeU {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 45, 45, 0.7);
  border: 2px dashed #555;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 4px;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
@media (min-width: 768px) {
  .styles_slot__yQjeU {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 767px) {
  .styles_slot__yQjeU {
    width: 100px;
    height: 100px;
  }
}

.styles_plusIcon__qFb7P {
  color: #fff;
}

.styles_rotateRight__m0b77 {
  transform: rotate(-45deg);
}

.styles_rotateLeft__XAFoz {
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/components/ForCauldron/HeroSlot/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uCAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,8DAAA;AACF;AACE;EAdF;IAeI,YAAA;IACA,aAAA;EAEF;AACF;AAAE;EAnBF;IAoBI,YAAA;IACA,aAAA;EAGF;AACF;;AAAA;EACE,WAAA;AAGF;;AAAA;EACE,yBAAA;AAGF;;AAAA;EACE,wBAAA;AAGF","sourcesContent":[".slot {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(45, 45, 45, 0.7);\n  border: 2px dashed #555;\n  border-radius: 8px;\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  padding: 4px;\n  position: relative;\n  transition: background-color 0.3s ease, border-color 0.3s ease;\n\n  @media (min-width: 768px) {\n    width: 150px;\n    height: 150px;\n  }\n\n  @media (max-width: 767px) {\n    width: 100px;\n    height: 100px;\n  }\n}\n\n.plusIcon {\n  color: #fff;\n}\n\n.rotateRight {\n  transform: rotate(-45deg);\n}\n\n.rotateLeft {\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slot": `styles_slot__yQjeU`,
	"plusIcon": `styles_plusIcon__qFb7P`,
	"rotateRight": `styles_rotateRight__m0b77`,
	"rotateLeft": `styles_rotateLeft__XAFoz`
};
export default ___CSS_LOADER_EXPORT___;
