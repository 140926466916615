// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__1wPz5 {
  font-size: 16px;
  color: var(--text-color);
  background: var(--button-bg);
  border: 2px solid var(--border-color);
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  text-shadow: 1px 1px 2px #000;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4);
}
.styles_button__1wPz5:disabled {
  cursor: not-allowed;
  filter: grayscale(10%) brightness(50%);
  box-shadow: none;
}
@media (hover: hover) and (pointer: fine) {
  .styles_button__1wPz5:not(:disabled):hover {
    background: var(--hover-bg);
    border-color: var(--secondory-bg);
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.2), 0 6px 12px rgba(0, 0, 0, 0.5);
  }
}
.styles_button__1wPz5:not(:disabled):active {
  transform: scale(0.98);
  transition: transform 0.1s ease;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.3);
}

.styles_icon__XZfe5 {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Button/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,wBAAA;EACA,4BAAA;EACA,qCAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;EACA,6BAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,aAAA;EACA,kFAAA;AACF;AACE;EACE,mBAAA;EACA,sCAAA;EACA,gBAAA;AACJ;AAEE;EACE;IACE,2BAAA;IACA,iCAAA;IACA,mFAAA;EAAJ;AACF;AAGE;EACE,sBAAA;EACA,+BAAA;EACA,4EAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF","sourcesContent":[".button {\n  font-size: 16px;\n  color: var(--text-color);\n  background: var(--button-bg);\n  border: 2px solid var(--border-color);\n  border-radius: 12px;\n  padding: 12px;\n  cursor: pointer;\n  text-shadow: 1px 1px 2px #000;\n  transition: all 0.3s ease-in-out;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  outline: none;\n  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4);\n\n  &:disabled {\n    cursor: not-allowed;\n    filter: grayscale(10%) brightness(50%);\n    box-shadow: none;\n  }\n\n  @media (hover: hover) and (pointer: fine) {\n    &:not(:disabled):hover {\n      background: var(--hover-bg);\n      border-color: var(--secondory-bg);\n      box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.2), 0 6px 12px rgba(0, 0, 0, 0.5);\n    }\n  }\n\n  &:not(:disabled):active {\n    transform: scale(0.98);\n    transition: transform 0.1s ease;\n    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.3);\n  }\n}\n\n.icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__1wPz5`,
	"icon": `styles_icon__XZfe5`
};
export default ___CSS_LOADER_EXPORT___;
