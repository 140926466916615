import React from "react";
import styles from "./styles.module.scss";
import { useRatingPageHook } from "../../hooks/Pages/useRatingPageHook";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import RatingCell from "./RatingCell";
import PageTitle from "../../components/PageTitle/PageTitle";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const Rating = () => {
  const { t } = useTranslation();
  const { leaders, isLoading, userData, currentStep } = useRatingPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.container, styles.fadeIn)}>
      <PageTitle title={"leaderboard"} />
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {leaders.map((user, index) => (
            <RatingCell
              key={index}
              user={user}
              isGuide={currentStep === 3}
              t={t}
            />
          ))}
        </div>
      </div>
      {userData && <RatingCell user={userData} isCurrentUser t={t} />}
    </div>
  );
};

export default Rating;
