import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import HeroesForQuest from "../../components/ForHeroes/HeroesForQuest/HeroesForQuest";
import cx from "classnames";
import { useQuestHook } from "../../hooks/Pages/useQuestHook";
import QuestInfo from "../../components/ForQuests/QuestInfo/QuestInfo";
import QuestActions from "../../components/ForQuests/QuestActions/QuestActions";
import ResultModal from "./ResultModal/ResultModal";

const Quest = () => {
  const {
    isLoading,
    isSelect,
    quest,
    endDate,
    handleRemoveHero,
    selectedHeroes,
    handleStartQuest,
    setSelectedHeroes,
    handleCloseHeroes,
    questChance,
    setQuestChance,
    isChanceLoading,
    isResultModalOpen,
    setIsResultModalOpen,
    levelUpInfo,
    setIsChanceLoading,
    handleOpenHeroesList,
    navigate,
    isTooltipOpen,
    currentStep,
    handleCloseModal,
    prize,
    isUpdate,
    setIsUpdate,
    getData,
  } = useQuestHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div
        className={cx(styles.wrapper, styles.fadeIn, {
          [styles.overflow]: !isSelect,
        })}
      >
        <QuestInfo
          questChance={questChance}
          quest={quest}
          endDate={endDate}
          isChanceLoading={isChanceLoading}
          currentStep={currentStep}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          getData={getData}
        />
        {!isSelect && (
          <QuestActions
            handleStartQuest={handleStartQuest}
            quest={quest}
            selectedHeroes={selectedHeroes}
            handleOpenHeroesList={handleOpenHeroesList}
            handleRemoveHero={handleRemoveHero}
            isChanceLoading={isChanceLoading}
            navigate={navigate}
            isTooltipOpen={isTooltipOpen}
            currentStep={currentStep}
          />
        )}
        {isSelect && (
          <HeroesForQuest
            questId={quest.id}
            questChance={questChance}
            setQuestChance={setQuestChance}
            handleCloseHeroes={handleCloseHeroes}
            selectedHeroes={selectedHeroes}
            setSelectedHeroes={setSelectedHeroes}
            heroesCount={quest.required_heroes_count}
            mainCharacteristic={quest.main_characteristic}
            setIsChanceLoading={setIsChanceLoading}
          />
        )}
      </div>
      {isResultModalOpen && (
        <ResultModal
          prize={prize}
          handleCloseModal={handleCloseModal}
          quest={quest}
          setIsResultModalOpen={setIsResultModalOpen}
          levelUpInfo={levelUpInfo}
        />
      )}
    </>
  );
};

export default Quest;
