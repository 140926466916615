import React from "react";
import { Avatar, Caption, Text } from "@telegram-apps/telegram-ui";
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import paths from "../paths";

const QuestHeroes = ({
  selectedHeroes,
  handleOpenHeroesList,
  quest,
  handleRemoveHero,
  navigate,
  currentStep,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Text className={cx(styles.fadeIn, styles.heroesText)}>
        {quest.status === "started" ||
        quest.status === "finished" ||
        quest.status === "completed"
          ? t("Your heroes")
          : t("Choose your heroes")}
      </Text>
      {!selectedHeroes.length && quest.status !== "completed" && (
        <Avatar
          className={cx(styles.avatar, {
            [styles.activeSelector]: currentStep === 2,
          })}
          onClick={handleOpenHeroesList}
          size={48}
        >
          +
        </Avatar>
      )}
      <div className={styles.heroesRow}>
        {!!selectedHeroes.length &&
          selectedHeroes.map((hero) => (
            <div
              key={hero.id}
              className={cx(styles.selectedHero, styles.fadeIn)}
            >
              <Avatar
                size={48}
                src={hero.hero_preview_url}
                className={cx(styles.heroImg, styles.fadeIn)}
                onClick={() => {
                  if (["created", "failed"].includes(quest.status)) return;
                  navigate(`${paths.heroDetail}`, {
                    state: { hero: hero, isFromQuest: true },
                  });
                }}
              >
                {quest.status !== "finished" &&
                  quest.status !== "started" &&
                  quest.status !== "completed" && (
                    <div
                      onClick={() => handleRemoveHero(hero.id)}
                      className={styles.clearIcon}
                    >
                      <IoCloseCircleOutline />
                    </div>
                  )}
              </Avatar>
              <Caption>{hero.hero_name}</Caption>
            </div>
          ))}
      </div>
    </>
  );
};

export default QuestHeroes;
