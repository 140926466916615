import React, { useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import Popup from "reactjs-popup";

const ClassFilter = ({
  classes,
  selectedClass,
  setSelectedClass,
  t,
  tg,
  hapticEnabled,
}) => {
  const [tempClass, setTempClass] = useState(selectedClass);
  const isActive = Boolean(selectedClass);

  const handlePopupClose = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (tempClass !== selectedClass) {
      setSelectedClass(tempClass);
    }
    setTempClass(selectedClass);
  };

  const handleOptionClick = (value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setTempClass((prev) => (prev === value ? null : value));
  };

  const handlePopupOpen = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setTempClass(selectedClass);
  };

  const renderClassRadioButtons = () => {
    if (!classes || classes.length === 0) {
      return <div className={styles.noClasses}>{t("noClassesAvailable")}</div>;
    }

    return classes.map((cls) => (
      <div key={cls.id} className={styles.radioContainer}>
        <input
          type="radio"
          id={cls.id}
          name="class"
          className={styles.radio}
          checked={tempClass === cls.id}
          readOnly
          onClick={() => handleOptionClick(cls.id)}
        />
        <label htmlFor={cls.id} className={styles.radioLabel}>
          {cls.name}
        </label>
      </div>
    ));
  };

  return (
    <Popup
      keepTooltipInside
      arrow={false}
      trigger={
        <button
          className={cx(styles.triggerButton, { [styles.active]: isActive })}
        >
          {t("Class")}
        </button>
      }
      position="right top"
      className={styles.popupWrapper}
      onClose={handlePopupClose}
      onOpen={handlePopupOpen}
    >
      <div className={styles.popupContent}>
        <div className={styles.title}>{t("chooseYourClass")}</div>
        <div className={styles.optionsContainer}>
          {renderClassRadioButtons()}
        </div>
      </div>
    </Popup>
  );
};

export default ClassFilter;
