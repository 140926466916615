import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useLoaderHook from "../Layout/useLoaderHook";
import { useTelegram } from "../Layout/useTelegramHook";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import { buyMarketItem, getMarketItems } from "../../services/marketService";
import { getUserBadges, getUserWallets } from "../../services/userService";
import paths from "../../pages/paths";
import { useSocket } from "../../utils/socketContext";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getMarketSteps } from "../../utils/guideSteps";

export const useMarketHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useLoaderHook();
  const { tg } = useTelegram();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { userChannel } = useSocket();

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [marketItems, setMarketItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, type: "" });
  const [activeId, setActiveId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModalItem, setActiveModalItem] = useState(null);
  const [activeItemImage, setActiveItemImage] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [marketItemBadge, setMarketItemBadge] = useState(null);

  useEffect(() => {
    tg.BackButton.hide();

    fetchUserData(dispatch);

    getMarketItems()
      .then((data) => {
        setMarketItems(data);
      })
      .finally(() => setIsLoading(false));

    fetchUserWallets();

    getUserBadges("market_items").then((r) => {
      setMarketItemBadge(
        r?.find((item) => item?.type === "market_items")?.badge,
      );
    });

    getMarketItemBadge();
  }, []);

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (payload?.content_type === "user_quests") {
        return payload;
      }
      if (payload?.user_market_item_id) {
        setActiveId(payload?.user_market_item_id);
        setIsSuccessModalOpen(true);
        getMarketItemBadge();
      }
      return payload;
    };
  }, [userChannel]);

  const getMarketItemBadge = () => {
    getUserBadges("market_items").then((r) => {
      setMarketItemBadge(
        r?.find((item) => item?.type === "market_items")?.badge,
      );
    });
  };

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency_code === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  const handleBuyMarketItem = (id) => {
    setIsModalOpen(false);
    buyMarketItem(id)
      .then((r) => {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        setActiveId(r.id);
        showSnackbar("success");
        fetchUserWallets();
        getMarketItemBadge();
      })
      .catch(() => {
        showSnackbar("error");
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      });
  };

  const handleBuyStarMarketItem = (id) => {
    buyMarketItem(id)
      .then((r) => {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        tg.openTelegramLink(r.payment_url);
      })
      .catch(() => {
        showSnackbar("error");
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      });
  };

  const showSnackbar = (type) => {
    setSnackbar({ open: true, type });
    setTimeout(() => setSnackbar({ open: false, type: "" }), 3000);
  };

  const handleMarketItemClick = (id, type) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.marketItem, { state: { id, type } });
  };

  const handleNavigateToInventory = () => {
    navigate(paths.inventory, { state: { from: pathname } });
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
  };

  const handleCardClick = (item) => {
    if (item?.disabled_until) {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }
    if (item.status !== "coming_soon") {
      handleMarketItemClick(item.id, item.type);
    } else {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
    }
  };

  const handleChipClick = (e, item) => {
    if (item?.disabled_until) {
      e.stopPropagation();
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }
    if (item.status === "coming_soon") {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }

    if (
      ["daily_quest_lootbox", "quest_lootbox", "hero_items_lootbox"].includes(
        item.type,
      ) ||
      item?.payment_provider === "aeon"
    ) {
      return;
    }

    e.stopPropagation();
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();

    if (item?.price?.currency_code !== "PATH") {
      handleBuyStarMarketItem(item.id);
      setActiveItemImage(item?.image_url);
    } else {
      setActiveModalItem(item);
      setIsModalOpen(true);
    }
  };

  const handleOpenRoulette = () => {
    navigate(paths.roulette, {
      state: { from: pathname, id: activeId },
    });
  };

  const actionMap = {
    2: (dispatch) => dispatch(toggleHideBackground()),
    3: (dispatch) => dispatch(toggleShowBackground()),
  };

  const steps = useMemo(() => getMarketSteps(), []);

  const { currentStep } = useGuide(steps, actionMap);

  return {
    handleBuyMarketItem,
    t,
    marketItems,
    isLoading,
    userAmount,
    snackbar,
    setSnackbar,
    activeId,
    isModalOpen,
    setIsModalOpen,
    activeModalItem,
    setActiveModalItem,
    handleNavigateToInventory,
    handleCardClick,
    handleChipClick,
    handleOpenRoulette,
    setIsSuccessModalOpen,
    isSuccessModalOpen,
    activeItemImage,
    marketItemBadge,
    currentStep,
  };
};
