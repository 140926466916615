import React, { useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import Popup from "reactjs-popup";
import { getSlotImage } from "../../utils";

const slotOptions = [
  { value: "shoulders", label: "Shoulders" },
  { value: "head", label: "Head" },
  { value: "neck", label: "Neck" },
  { value: "off_hand", label: "Off-Hand" },
  { value: "chest", label: "Chest" },
  { value: "main_hand", label: "Main-Hand" },
  { value: "hands", label: "Hands" },
  { value: "legs", label: "Legs" },
  { value: "back", label: "Back" },
  { value: "finger_1", label: "Ring (1)" },
  { value: "feet", label: "Feet" },
  { value: "finger_2", label: "Ring (2)" },
];

const SlotFilter = ({
  selectedSlots,
  setSelectedSlots,
  t,
  tg,
  hapticEnabled,
}) => {
  const [tempSlots, setTempSlots] = useState(selectedSlots);
  const isActive = selectedSlots.length > 0;

  const handlePopupClose = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (
      tempSlots.length !== selectedSlots.length ||
      !tempSlots.every((slot) => selectedSlots.includes(slot))
    ) {
      setSelectedSlots(tempSlots);
    }
    setTempSlots([]);
  };

  const handlePopupOpen = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setTempSlots(selectedSlots);
  };

  const handleSlotClick = (value) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (tempSlots.includes(value)) {
      setTempSlots(tempSlots.filter((item) => item !== value));
    } else {
      setTempSlots([...tempSlots, value]);
    }
  };

  const renderSlotIcons = (options) =>
    options.map((option) => (
      <div
        key={option.value}
        className={cx(styles.slotIcon, {
          [styles.selected]: tempSlots.includes(option.value),
        })}
        onClick={() => handleSlotClick(option.value)}
        role="button"
        tabIndex={0}
      >
        <img
          className={styles.emptySlotImg}
          src={getSlotImage(option.value)}
          alt={option.label}
        />
      </div>
    ));

  return (
    <Popup
      keepTooltipInside
      arrow={false}
      trigger={
        <button
          className={cx(styles.triggerButton, { [styles.active]: isActive })}
        >
          {t("slot")}
        </button>
      }
      position="left bottom"
      className={styles.popupWrapper}
      onClose={handlePopupClose}
      onOpen={handlePopupOpen}
    >
      <div className={styles.popupContent}>
        <div className={styles.title}>{t("selectSlots")}</div>
        <div className={styles.gridContainer}>
          {renderSlotIcons(slotOptions)}
        </div>
      </div>
    </Popup>
  );
};

export default SlotFilter;
