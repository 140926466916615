import React from "react";
import styles from "./styles.module.scss";
import TelegramButton from "../../common/TelegramButton/TelegramButton";
import cx from "classnames";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { RiNftFill } from "react-icons/ri";

const HeroCardActions = ({
  currentStep,
  character,
  onClickFavorite,
  handleOpenDetailPage,
  handleMint,
  isUserWallet,
}) => {
  return (
    <div className={styles.row}>
      <TelegramButton
        className={cx({ [styles.active]: currentStep === 3 })}
        onClick={(e) => onClickFavorite(e, character.id, !character.favorite)}
      >
        {character.favorite ? <MdFavorite /> : <MdFavoriteBorder />}
      </TelegramButton>

      <TelegramButton
        className={cx({ [styles.active]: currentStep === 3 })}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDetailPage();
        }}
      >
        <BsInfoCircle />
      </TelegramButton>

      <TelegramButton
        disabled={!isUserWallet}
        onClick={(e) => {
          e.stopPropagation();
          handleMint();
        }}
      >
        <RiNftFill />
      </TelegramButton>
    </div>
  );
};

export default HeroCardActions;
