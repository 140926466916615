import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getNextQuestTime, getUserQuests } from "../../services/questsService";
import { fetchUserData } from "../../services/registrationService";
import paths from "../../pages/paths";
import { useSocket } from "../../utils/socketContext";
import { useTelegram } from "../Layout/useTelegramHook";
import useAndroidScrollFix from "../Layout/useAndroidScrollFix";
import { getQuestsCount } from "../../services/questService";
import {
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getQuestsStep } from "../../utils/guideSteps";

export const useQuestsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const finishedQuestId = useSelector((state) => state.socket.finishedQuestId);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  useAndroidScrollFix();

  const statusFromQuery =
    searchParams.get("status") || location?.state?.activeStatus || "new";

  const [activeStatus, setActiveStatus] = useState(statusFromQuery);
  const [quests, setQuests] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [questsCounts, setQuestsCounts] = useState({
    new: 0,
    active: 0,
  });

  const [nextQuestTime, setNextQuestTime] = useState(null);

  const observer = useRef();

  const mapQuestCounts = (countsData) => {
    const newCount = countsData
      .filter((item) => ["failed", "created"].includes(item.status))
      .reduce((sum, item) => sum + item.total_count, 0);

    const activeCount = countsData
      .filter((item) => ["finished", "started"].includes(item.status))
      .reduce((sum, item) => sum + item.total_count, 0);

    setQuestsCounts({
      new: newCount,
      active: activeCount,
    });
  };

  const fetchQuests = (reset = false) => {
    const questStatusMap = {
      new: "failed,created",
      active: "finished,started",
      default: "completed",
    };

    const questStatus = questStatusMap[activeStatus] || questStatusMap.default;

    getUserQuests(page, 10, questStatus)
      .then((r) => {
        const newQuests = r.data.data;
        setQuests((prevQuests) =>
          reset ? newQuests : [...prevQuests, ...newQuests],
        );
        const paginationInfo = r.data.pagination_info || {};
        setHasMore(paginationInfo.page_number < paginationInfo.total_pages);
      })
      .finally(() => setIsDataLoading(false));
  };

  useEffect(() => {
    getNextQuestTime().then((r) => {
      const { current_quests_generation_at, next_quests_generation_at } = r;
      setNextQuestTime({
        startQuestTime: current_quests_generation_at,
        nextQuestTime: next_quests_generation_at,
      });
    });
    fetchUserData(dispatch).finally(() => setIsLoading(false));
  }, [dispatch]);

  const { lobbyChannel } = useSocket();

  useEffect(() => {
    if (!lobbyChannel) return;
    lobbyChannel.onMessage = (event, payload) => {
      if (event !== "quests_created") {
        return payload;
      }
      const { current_quests_generation_at, next_quests_generation_at } =
        payload;
      setNextQuestTime({
        startQuestTime: current_quests_generation_at,
        nextQuestTime: next_quests_generation_at,
      });
      if (activeStatus === "new") {
        if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
        setIsDataLoading(true);
        setPage(1);
        fetchQuests(true);
      }
      return payload;
    };
  }, [lobbyChannel]);

  useEffect(() => {
    setIsDataLoading(true);
    setQuests([]);
    fetchQuests(true);
    getQuestsCount("failed,created,finished,started").then((r) => {
      mapQuestCounts(r);
    });
  }, [activeStatus]);

  useEffect(() => {
    if (page !== 1) fetchQuests();
  }, [page]);

  const lastQuestElementRef = useCallback(
    (node) => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, quests],
  );

  const handleSetActiveStatus = (status) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setPage(1);
    setActiveStatus(status);
    setSearchParams({ status });
  };

  const navigateToQuest = (id) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(`${paths.quest.replace(":id", id)}`);
  };

  const handleGoToHistory = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.questsHistory, { state: { activeStatus: activeStatus } });
  };

  useEffect(() => {
    if (!finishedQuestId || activeStatus !== "active") return;
    setQuests((prevQuests) => {
      const questIndex = prevQuests.findIndex(
        (item) => item.id === finishedQuestId,
      );
      if (questIndex === -1) {
        return prevQuests;
      }
      const updatedQuests = [...prevQuests];
      updatedQuests[questIndex] = {
        ...updatedQuests[questIndex],
        status: "finished",
      };
      return updatedQuests;
    });
  }, [finishedQuestId]);

  const actionMap = {
    2: (dispatch) => {
      dispatch(toggleHideBackground());
    },
    4: (dispatch) => {
      dispatch(toggleShowBackground());
    },
  };

  const steps = useMemo(() => getQuestsStep(), []);

  const { currentStep } = useGuide(steps, actionMap);

  return {
    quests,
    handleSetActiveStatus,
    navigateToQuest,
    lastQuestElementRef,
    isDataLoading,
    isLoading,
    page,
    activeStatus,
    questsCounts,
    nextQuestTime,
    handleGoToHistory,
    currentStep,
  };
};
