import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTelegram } from "./useTelegramHook";
import paths from "../../pages/paths";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  setInitialEnter,
  setIsPlaying,
} from "../../store/slices/telegramSlice";
import { getUserWallets } from "../../services/userService";
import { getUserRating } from "../../services/ratingService";

export const useHeaderHook = () => {
  const { pathname } = useLocation();
  const { tg } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const username = useSelector((state) => state.telegram.username);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const isPlaying = useSelector((state) => state.telegram.isPlaying);
  const currentStep = useSelector((state) => state.guide.currentStep);

  const colorScheme = telegramData?.colorScheme;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);

  const [userAmount, setUserAmount] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const usernameTextRef = useRef(null);
  const [overflowAmount, setOverflowAmount] = useState(0);
  const [animationName, setAnimationName] = useState("");

  const isMainPageGuide = currentStep === 2 && pathname === paths.index;

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency_code === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  useEffect(() => {
    if (pathname !== paths.index) {
      if (!isLoading) setIsLoading(true);
      return;
    }

    const fetchData = async () => {
      try {
        const ratingResponse = await getUserRating();
        setUserRating(ratingResponse.rank || "0");

        const walletsResponse = await fetchUserWallets();
        const wallet = walletsResponse?.find(
          (wallet) => wallet?.balance?.currency_code === "PATH",
        );
        if (wallet) {
          setUserAmount(wallet?.balance?.amount);
        }

        dispatch(setInitialEnter(false));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  const handleGoToProfile = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.profile, { state: { from: pathname } });
  };
  const { t } = useTranslation();

  const handleToggleMusic = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (!isPlaying) {
      setAttemptCount((prevCount) => prevCount + 1);
    }
    dispatch(setIsPlaying(!isPlaying));
    handleShowSnackbar();
  };

  const handleShowSnackbar = () => {
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isThirdAttempt = attemptCount > 0 && attemptCount % 3 === 0;

  const getDescriptionMessage = () => {
    if (isThirdAttempt) {
      return t("If the music doesn't start, please reload the page.");
    } else if (attemptCount === 1 && isPlaying) {
      return t("Please turn on the sound on your phone.");
    }
    return "";
  };

  const handleOpenRating = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.rating);
  };

  useEffect(() => {
    const innerElement = usernameTextRef.current;
    const outerElement = innerElement.parentElement;

    let styleElement;

    if (innerElement && outerElement) {
      const innerWidth = innerElement.scrollWidth;
      const outerWidth = outerElement.clientWidth;
      const overflow = innerWidth - outerWidth;

      if (overflow > 0) {
        setOverflowAmount(overflow);

        const newAnimationName = `marquee_${Date.now()}`;
        setAnimationName(newAnimationName);

        const keyframes = `
          @keyframes ${newAnimationName} {
            0% {
              transform: translateX(0);
            }
            25% {
              transform: translateX(-${overflow}px);
            }
            50% {
              transform: translateX(-${overflow}px);
            }
            75% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(0);
            }
          }
        `;
        styleElement = document.createElement("style");
        styleElement.type = "text/css";
        styleElement.innerHTML = keyframes;
        document.head.appendChild(styleElement);
      } else {
        setOverflowAmount(0);
        setAnimationName("");
      }
    }

    return () => {
      if (styleElement && document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, [username]);

  return {
    username,
    handleGoToProfile,
    colorScheme,
    snackbarOpen,
    handleToggleMusic,
    handleCloseSnackbar,
    getDescriptionMessage,
    isThirdAttempt,
    isPlaying,
    userAmount,
    userRating,
    isLoading,
    pathname,
    handleOpenRating,
    overflowAmount,
    animationName,
    usernameTextRef,
    isMainPageGuide,
  };
};
