import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTelegram } from "../Layout/useTelegramHook";
import { useForm } from "react-hook-form";
import { registerUser } from "../../services/registrationService";
import { setIsGame, setUsername } from "../../store/slices/telegramSlice";
import i18n from "i18next";
import { toast, Slide } from "react-toastify";
import { useSocket } from "../../utils/socketContext";
import { setGlobalLoading } from "../../store/slices/loaderSlice";
import { useTranslation } from "react-i18next";

export const useRegistrationHook = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.telegram.user);
  const telegramData = useSelector((state) => state.telegram.telegramData);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const { disconnectAll } = useSocket();
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const startParam = params.get("tgWebAppStartParam");
    if (startParam) {
      setValue("referralCode", startParam);
    }
  }, [setValue]);

  useEffect(() => {
    if (userData?.username) {
      setValue("username", userData?.username);
    }
  }, [userData, setValue]);

  useEffect(() => {
    const usernameInput = document.querySelector("#username");
    if (usernameInput) {
      usernameInput.focus();
    }
  }, []);

  const showToast = (message, description) => {
    toast.dismiss();
    toast(
      <div>
        <strong>{message}</strong>
        <div>{description}</div>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
      },
    );
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[A-Za-z0-9_]+$/;
    if (!usernameRegex.test(username)) {
      showToast(t("Accepted characters"));
      return false;
    }
    if (username.length < 5 || username.length > 32) {
      showToast(t("Username length"));
      return false;
    }
    return true;
  };

  const handleMainButtonClick = async (data) => {
    const { username, referralCode } = data;

    if (!username) {
      tg.showAlert(t("Username required"));
      return;
    }

    if (!validateUsername(username)) {
      return;
    }

    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    try {
      const isNumeric = !isNaN(Number(referralCode));
      await registerUser({
        username,
        ...(isNumeric
          ? { ref_id: referralCode }
          : { referral_code: referralCode }),
        init_data: telegramData?.initData,
        user_settings: {
          language_code: userData?.language_code || "en",
        },
      });
      i18n.changeLanguage(userData?.language_code || "en");
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("success");
      dispatch(setUsername(username));
      dispatch(setGlobalLoading(true));
      dispatch(setIsGame(true));
      disconnectAll();
    } catch (error) {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      console.error("Registration error:", error);
      showToast(t("Error message"), t("Error description"));
    }
  };

  const handleSubmitData = () => {
    handleSubmit(handleMainButtonClick)();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleMainButtonClick)();
    }
  };

  const handleFindReferralCode = (event) => {
    event.preventDefault();
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    tg.openTelegramLink("https://t.me/thepathchat/6");
  };

  return {
    register,
    handleKeyDown,
    handleSubmitData,
    handleFindReferralCode,
  };
};
