import React from "react";
import styles from "./styles.module.scss";
import { getCharImagesByRarity } from "../../../utils/imagesGetter";
import Popup from "reactjs-popup";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const AttributesRow = ({
  character,
  allImagesLoaded,
  isDetail,
  isQuest,
  isInventory,
}) => {
  const { t } = useTranslation();

  const attributes = [
    {
      name: "Strength",
      value: character.strength || character.strength_total,
      bonus: character.strength_bonus,
      total: isInventory ? character.strength : character.strength_total,
    },
    {
      name: "Agility",
      value: character.agility || character.agility_total,
      bonus: character.agility_bonus,
      total: isInventory ? character.agility : character.agility_total,
    },
    {
      name: "Intelligence",
      value: character.intelligence || character.intelligence_total,
      bonus: character.intelligence_bonus,
      total: isInventory
        ? character.intelligence
        : character.intelligence_total,
    },
    {
      name: "Endurance",
      value: character.endurance || character.endurance_total,
      bonus: character.endurance_bonus,
      total: isInventory ? character.endurance : character.endurance_total,
    },
    {
      name: "Charisma",
      value: character.charisma || character.charisma_total,
      bonus: character.charisma_bonus,
      total: isInventory ? character.charisma : character.charisma_total,
    },
    {
      name: "Luck",
      value: character.luck || character.luck_total,
      bonus: character.luck_bonus,
      total: isInventory ? character.luck : character.luck_total,
    },
  ];

  if (isQuest) {
    return (
      <div
        className={cx(styles.attributesRow, {
          [styles.visible]: allImagesLoaded || isDetail,
          [styles.quest]: isQuest,
        })}
      >
        {attributes.map((attr) => (
          <div
            key={attr.name}
            className={cx(styles.attribute, styles.questAttribute)}
          >
            <div className={styles.attributePopupTrigger}>
              <img
                className={cx(styles.charIcon, {
                  [styles.charIconQuest]: isQuest,
                })}
                alt={attr.name}
                src={getCharImagesByRarity(attr.name)}
              />
              <span className={styles.attributeValue}>{attr.total}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={cx(styles.attributesRow, {
        [styles.visible]: allImagesLoaded || isDetail,
        [styles.quest]: isQuest,
      })}
    >
      {attributes.map((attr) => (
        <Popup
          key={attr.name}
          trigger={
            <div key={attr.name} className={styles.attribute}>
              <div className={styles.attributePopupTrigger}>
                <img
                  className={cx(styles.charIcon, {
                    [styles.charIconQuest]: isQuest,
                  })}
                  alt={attr.name}
                  src={getCharImagesByRarity(attr.name)}
                />
                <span
                  className={cx(styles.attributeValue, {
                    [styles.questAttributes]: isQuest,
                  })}
                >
                  {attr.total}
                </span>
              </div>
            </div>
          }
          position="top center"
          keepTooltipInside
        >
          <div className={styles.popupContent}>
            <span>{t(attr.name)}</span>
            {!isQuest && (
              <div className={styles.attributeValueRow}>
                <span>{attr.value}&nbsp;</span>
                <span className={styles.bonus}>+&nbsp;{attr.bonus}</span>
              </div>
            )}
          </div>
        </Popup>
      ))}
    </div>
  );
};

export default AttributesRow;
