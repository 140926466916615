// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestTimer_progress__inaLT > svg {
  transform: scaleX(-1) rotate(-90deg);
}`, "",{"version":3,"sources":["webpack://./src/components/QuestTimer/QuestTimer.module.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;AAAJ","sourcesContent":[".progress {\n  > svg {\n    transform: scaleX(-1) rotate(-90deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `QuestTimer_progress__inaLT`
};
export default ___CSS_LOADER_EXPORT___;
