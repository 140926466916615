// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalContent__Exr4G {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px 16px 16px;
  background-color: #1e1e2f;
  border-radius: 12px;
  color: #ffffff;
}

.styles_modal__4IbhO {
  background-color: #1e1e2f;
}

.styles_title__QBQ8K {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #ffcc00;
}

.styles_congrats__kpFKJ {
  font-size: 16px;
  text-align: center;
}

.styles_subtitle__aYTEP {
  font-size: 20px;
  font-weight: 600;
  color: #ffcc00;
  border-bottom: 1px solid #444;
  padding-bottom: 4px;
  text-align: center;
  width: 100%;
}

.styles_list__beZKy {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
}

.styles_list__beZKy li {
  margin-bottom: 8px;
}

.styles_warning__lVObU {
  font-size: 16px;
  color: #ff4d4d;
}

.styles_callToAction__kClHw {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.styles_button__xnEeo {
  align-self: center;
  padding: 12px 24px;
  background-color: #ffcc00;
  color: #1e1e2f;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.styles_button__xnEeo:hover {
  background-color: #e6b800;
}

.styles_modalHeader__wYBW2 {
  padding: 16px 16px 0 16px;
  background-color: #1e1e2f;
}`, "",{"version":3,"sources":["webpack://./src/components/NFTModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,yBAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,6BAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,sCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,yBAAA;AACF","sourcesContent":[".modalContent {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 0 16px 16px 16px;\n  background-color: #1e1e2f;\n  border-radius: 12px;\n  color: #ffffff;\n}\n\n.modal {\n  background-color: #1e1e2f;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  text-align: center;\n  color: #ffcc00;\n}\n\n.congrats {\n  font-size: 16px;\n  text-align: center;\n}\n\n.subtitle {\n  font-size: 20px;\n  font-weight: 600;\n  color: #ffcc00;\n  border-bottom: 1px solid #444;\n  padding-bottom: 4px;\n  text-align: center;\n  width: 100%;\n}\n\n.list {\n  list-style-type: disc;\n  padding-left: 20px;\n  font-size: 16px;\n}\n\n.list li {\n  margin-bottom: 8px;\n}\n\n.warning {\n  font-size: 16px;\n  color: #ff4d4d;\n}\n\n.callToAction {\n  font-size: 18px;\n  text-align: center;\n  font-weight: 500;\n}\n\n.button {\n  align-self: center;\n  padding: 12px 24px;\n  background-color: #ffcc00;\n  color: #1e1e2f;\n  border: none;\n  border-radius: 8px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.button:hover {\n  background-color: #e6b800;\n}\n\n.modalHeader {\n  padding: 16px 16px 0 16px;\n  background-color: #1e1e2f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `styles_modalContent__Exr4G`,
	"modal": `styles_modal__4IbhO`,
	"title": `styles_title__QBQ8K`,
	"congrats": `styles_congrats__kpFKJ`,
	"subtitle": `styles_subtitle__aYTEP`,
	"list": `styles_list__beZKy`,
	"warning": `styles_warning__lVObU`,
	"callToAction": `styles_callToAction__kClHw`,
	"button": `styles_button__xnEeo`,
	"modalHeader": `styles_modalHeader__wYBW2`
};
export default ___CSS_LOADER_EXPORT___;
