import React from "react";
import styles from "./styles.module.scss";
import { BiSolidBookmarkHeart } from "react-icons/bi";
import cx from "classnames";

const Favorite = ({ isQuests, isCauldron, isHide }) => {
  if (isHide) {
    return null;
  }
  return (
    <div
      className={cx(styles.favoriteContainer, {
        [styles.questsFavorite]: isQuests,
      })}
    >
      <div className={styles.favoriteRow}>
        <BiSolidBookmarkHeart
          size={isQuests || isCauldron ? 20 : 40}
          className={styles.favorite}
        />
        <div
          className={cx(styles.heart, {
            [styles.questsHeart]: isQuests || isCauldron,
          })}
        />
      </div>
    </div>
  );
};

export default Favorite;
