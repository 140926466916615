import React from "react";
import styles from "../Quests/styles.module.scss";
import { Avatar, Cell, List, Text } from "@telegram-apps/telegram-ui";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import cx from "classnames";
import {
  getColorByStatus,
  renderAttributeIcons,
} from "../../utils/attributesIconsUtils";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useQuestsHistoryHook } from "../../hooks/Pages/useQuestsHistoryHook";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import QuestsCount from "../../components/QuestsCount/QuestsCount";

const QuestsHistory = () => {
  const { t } = useTranslation();
  const {
    quests,
    navigateToQuest,
    lastQuestElementRef,
    isLoading,
    questsCount,
  } = useQuestsHistoryHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.wrapper, styles.fadeIn)}>
      <PageTitle isNoMargin>{t("Completed quests")}</PageTitle>
      {!quests?.length && (
        <div className={styles.noDataWrapper}>
          <img
            className={styles.noDataImg}
            alt={t("no_quest")}
            src={AsyncPreloadImages.NO_QUESTS}
          />
        </div>
      )}
      {!!quests.length && (
        <>
          <List className={cx(styles.list, styles.fadeIn)}>
            {quests.map((quest, index) => (
              <Cell
                multiline
                key={quest.id}
                className={styles.cell}
                onClick={() => navigateToQuest(quest.id)}
                after={<Text />}
                ref={index === quests.length - 1 ? lastQuestElementRef : null}
                before={
                  <div className={styles.levelAvatar}>
                    <Text className={styles.level}>{quest.level}</Text>
                    <Avatar
                      className={styles.rarityImg}
                      size={48}
                      src={
                        AsyncPreloadImages[
                          `${quest.rarity_level.toUpperCase()}_QUEST`
                        ]
                      }
                    />
                  </div>
                }
                titleBadge={
                  quest.status !== "completed" && (
                    <div
                      className={cx(styles.statusBadge, {
                        [styles.blink]: quest.status === "started",
                      })}
                      style={{ background: getColorByStatus(quest.status) }}
                    />
                  )
                }
                description={
                  <div className={styles.attributeIcons}>
                    {renderAttributeIcons(quest)}
                  </div>
                }
                subtitle={`${t("Reward")}: ${quest?.money_reward.amount} ${quest?.money_reward.currency_code}`}
              >
                {quest.name}
              </Cell>
            ))}
          </List>
          <QuestsCount questsCount={questsCount} />
        </>
      )}
    </div>
  );
};

export default QuestsHistory;
