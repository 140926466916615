import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import AttributesRow from "../../components/ForHeroes/AttributesRow/AttributesRow";
import HeroCardLevel from "../../components/ForHeroes/HeroCardLevel/HeroCardLevel";
import HeroSkills from "../../components/ForHeroes/HeroSkills/HeroSkills";
import NewHeroModal from "../../components/ForHeroes/NewHeroModal/NewHeroModal";
import { useHeroDetailHook } from "../../hooks/Pages/useHeroDetailHook";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const HeroDetail = ({ hero, isNewHero }) => {
  const { t } = useTranslation();

  const {
    activeSkill,
    handlePrevSkill,
    handleNextSkill,
    handleSkillClick,
    displayedSkills,
    getColorByClassname,
    heroData,
    isLoading,
    isModalOpen,
    setIsModalOpen,
  } = useHeroDetailHook(hero, isNewHero);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(styles.heroDetailWrapper, styles.fadeIn, {
          [styles.noOverlay]: isNewHero,
        })}
      >
        <div className={styles.cell}>
          <div className={styles.avatarWrapper}>
            <img
              className={styles.avatar}
              src={heroData?.hero_preview_url}
              alt="Hero"
            />
          </div>
          <div className={styles.heroInfo}>
            <span className={styles.heroName}>{heroData.hero_name}</span>
            <div className={styles.subtitle}>
              <span>{heroData.class}</span>
              <span
                style={{
                  color: getColorByClassname(
                    heroData.rarity_level.toLowerCase(),
                  ),
                }}
              >
                {heroData.rarity_level_name}
              </span>
            </div>
            <div className={styles.level}>
              {`${t("Level")} `}
              <span className={styles.levelInfo}>
                {Math.floor(heroData.level)}
              </span>
            </div>
          </div>
          <img
            className={styles.classIcon}
            src={heroData?.class_image_url}
            alt="Class"
          />
        </div>
        <HeroCardLevel character={heroData} isDetail />
        <AttributesRow character={heroData} isDetail />
        <span className={styles.heroBio}>{heroData.hero_bio}</span>
        <HeroSkills
          activeSkill={activeSkill}
          displayedSkills={displayedSkills}
          handleNextPage={handleNextSkill}
          handlePrevPage={handlePrevSkill}
          handleSkillClick={handleSkillClick}
          getColorByClassname={getColorByClassname}
        />
      </div>
      <NewHeroModal
        heroData={heroData}
        setOpen={setIsModalOpen}
        isOpen={isModalOpen}
      />
    </>
  );
};

export default HeroDetail;
