// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_countdownWrapper__uujD- {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.styles_countdown__\\+yY6F {
  color: white;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.styles_questsCount__k52HY {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ForQuests/QuestsCount/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACF","sourcesContent":[".countdownWrapper {\n  display: flex;\n  gap: 8px;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 16px;\n}\n\n.countdown {\n  color: white;\n  min-height: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 4px;\n}\n\n.questsCount {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdownWrapper": `styles_countdownWrapper__uujD-`,
	"countdown": `styles_countdown__+yY6F`,
	"questsCount": `styles_questsCount__k52HY`
};
export default ___CSS_LOADER_EXPORT___;
