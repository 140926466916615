import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { getNonZeroAttributes } from "../../../utils/skillsUtils";

const HeroSkills = ({
  handlePrevPage,
  displayedSkills,
  activeSkill,
  handleSkillClick,
  handleNextPage,
  getColorByClassname,
}) => {
  const { t } = useTranslation();

  const skillsContainerRef = useRef(null);
  const skillRefs = useRef([]);

  useEffect(() => {
    if (activeSkill && skillsContainerRef.current && skillRefs.current) {
      const index = displayedSkills.findIndex(
        (skill) => skill.id === activeSkill.id,
      );
      if (index !== -1 && skillRefs.current[index]) {
        const skillElement = skillRefs.current[index];
        const container = skillsContainerRef.current;
        const scrollOffset =
          skillElement.offsetLeft -
          container.offsetWidth / 2 +
          skillElement.offsetWidth / 2;

        container.scrollTo({
          left: scrollOffset,
          behavior: "smooth",
        });
      }
    }
  }, [activeSkill, displayedSkills]);

  return (
    <>
      <div className={styles.skillsWrapper}>
        <button className={styles.arrow} onClick={handlePrevPage}>
          <FaChevronLeft className={styles.arrowIcon} />
        </button>
        <div className={styles.skillsContainer} ref={skillsContainerRef}>
          {displayedSkills.map((skill, index) => (
            <div
              key={skill.id}
              style={
                skill?.rarity_level
                  ? {
                      border: `3px solid ${getColorByClassname(skill?.rarity_level?.toLowerCase())}`,
                    }
                  : undefined
              }
              className={`${styles.skillWrapper} ${
                activeSkill === skill && styles.activeSkill
              }`}
              onClick={() => handleSkillClick(skill)}
              ref={(el) => (skillRefs.current[index] = el)}
            >
              <img
                alt={"skill"}
                className={cx(styles.skillIcon, {
                  [styles.disabledSkill]: !skill.active,
                })}
                key={index}
                src={skill.image_url}
              />
            </div>
          ))}
        </div>
        <button className={styles.arrow} onClick={handleNextPage}>
          <FaChevronRight className={styles.arrowIcon} />
        </button>
      </div>
      {activeSkill && (
        <div className={cx(styles.activeSkillWrapper, styles.fadeIn)}>
          <span className={styles.skillName}>{activeSkill.name}</span>
          <span>
            {t("Required level")}:&nbsp;
            <span className={styles.requiredLevel}>
              {activeSkill.required_level}
            </span>
          </span>
          {activeSkill?.rarity_level && (
            <>
              <span>
                {t("Rarity level")}:&nbsp;
                <span
                  className={styles.rarityLevel}
                  style={{
                    color: getColorByClassname(activeSkill.rarity_level),
                  }}
                >
                  {activeSkill.rarity_level}
                </span>
              </span>
              <span>{`${t("Rarity multiplayer")}: ${activeSkill?.effect_multiplayer} `}</span>
            </>
          )}
          <span className={styles.description}>{activeSkill.description}</span>
          <span
            className={styles.attributesTitle}
          >{`${t("Attributes bonus")}:`}</span>
          {getNonZeroAttributes(activeSkill, t).map(
            ([translatedKey, value]) => (
              <span key={translatedKey}>
                {`${translatedKey}:`}
                &nbsp;
                <span className={styles.attributeBonus}>+&nbsp;{value}%</span>
              </span>
            ),
          )}
        </div>
      )}
    </>
  );
};

export default HeroSkills;
