import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import MiniCardImages from "../MiniCard/MiniCardImages";
import AttributesRow from "../AttributesRow/AttributesRow";
import CustomCheckbox from "../../ui/Checkbox/Checkbox";

const HeroCell = ({
  isSentinel,
  sentinelRef,
  item,
  index,
  heroes,
  isLoaded,
  toggleHeroSelection,
  handleHeroImagesLoaded,
  selectedHeroes,
}) => {
  return (
    <div
      ref={isSentinel ? sentinelRef : null}
      key={item.id}
      className={cx(styles.heroCard, {
        [styles.lastCard]: index === heroes.length - 1,
      })}
      onClick={() => toggleHeroSelection(item)}
    >
      <div
        className={cx(styles.miniCardContainer, {
          [styles.loading]: !isLoaded,
        })}
      >
        <MiniCardImages
          isCauldron
          character={item}
          onAllImagesLoaded={() => handleHeroImagesLoaded(item.id)}
        />
        <span className={styles.text}>{`Lv. ${Math.floor(item?.level)}`}</span>
        {!isLoaded && <div className={styles.loaderOverlay} />}
      </div>
      <div className={styles.heroInfo}>
        <div className={styles.row}>
          <span className={styles.text}>{item?.hero_name}</span>
          <img
            alt={item.hero_name}
            src={item.class_image_url}
            width={20}
            height={20}
            className={styles.classBadge}
          />
        </div>
        <AttributesRow isQuest character={item} isDetail />
      </div>
      <CustomCheckbox
        checked={selectedHeroes.some((hero) => hero.id === item.id)}
        onChange={() => toggleHeroSelection(item)}
      />
    </div>
  );
};

export default HeroCell;
