import React from "react";
import { Snackbar } from "@telegram-apps/telegram-ui";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import { AiOutlineCheck } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const MarketSnackbar = ({ snackbar, setSnackbar, handleOpenRoulette }) => {
  const { t } = useTranslation();
  const onClose = () => {
    setSnackbar({ open: false, type: "" });
  };
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <Snackbar
      duration={7000}
      onClick={handleOpenRoulette}
      open={snackbar.open}
      onClose={onClose}
      before={
        snackbar.type === "success" ? (
          <AiOutlineCheck color="green" />
        ) : (
          <MdError color="red" />
        )
      }
      after={
        snackbar.type === "success" && (
          <TelegramButton className={styles.closeButton} onClick={handleClose}>
            {t("Close")}
          </TelegramButton>
        )
      }
      description={
        snackbar.type === "success"
          ? t("Your purchase was successful")
          : t("Your purchase failed")
      }
    >
      {snackbar.type === "success" ? t("You're all set") : t("Error")}
    </Snackbar>
  );
};

export default MarketSnackbar;
