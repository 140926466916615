import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailHeroes,
  getHero,
  makeRemoveHeroFavorite,
} from "../../services/heroService";
import { fetchUserData } from "../../services/registrationService";
import { useSwipeable } from "react-swipeable";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import {
  setPosition,
  toggleHideBackground,
  toggleShowBackground,
} from "../../store/slices/guideSlice";
import useGuide from "./useGuideHook";
import { getHeroSteps } from "../../utils/guideSteps";
import { postHeroItem } from "../../services/heroItemsService";
import { mintHero } from "../../services/nftService";
import { useTelegram } from "../Layout/useTelegramHook";

export const useHeroHook = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialCharacter = location.state?.character;
  const navigate = useNavigate();

  const [characterId, setCharacterId] = useState(initialCharacter?.id);
  const [heroData, setHeroData] = useState(null);
  const [nextHeroId, setNextHeroId] = useState(null);
  const [prevHeroId, setPrevHeroId] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState({
    mainCard: false,
    texture: false,
    hero: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMinting, setIsMinting] = useState(false);
  const [currentInventorySlot, setCurrentInventorySlot] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMainModalOpen, setIsMainModalOpen] = useState(false);
  const [isUserWallet, setIsUserWallet] = useState(null);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [iframe, setIframe] = useState(null);
  const [isNFTModalOpen, setIsNFTModalOpen] = useState(false);

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  const [searchParams] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
    favorite_desc: String(searchParams.get("favorite_desc") || "enabled"),
    hero_status: String(searchParams.get("hero_status") || "ALL"),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  useTelegramBackButton(`${paths.heroes}?${queryString}`);

  const fetchHeroDetails = async (id) => {
    try {
      const data = await getDetailHeroes(id, queryParams);
      setNextHeroId(data.next_id);
      setPrevHeroId(data.previous_id);
      const hero = await getHero(id);
      setHeroData(hero);
      setIsMinting(hero.mint_status);
    } catch (error) {
      navigate(`${paths.heroes}?${queryString}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(dispatch).then((r) => {
      setIsUserWallet(!!r?.user_settings?.ton_wallet_address);
      startGuide();
    });
  }, []);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    fetchHeroDetails(characterId);
  }, [characterId]);

  const updateHero = () => {
    getHero(characterId).then((hero) => setHeroData(hero));
  };

  const totalImages = 3;
  const allLoaded = loadedImages === totalImages;

  const handleAllImagesLoaded = () => {
    setAllImagesLoaded(true);
  };

  useEffect(() => {
    if (allLoaded) {
      handleAllImagesLoaded();
    }
  }, [allLoaded]);

  const handleImageLoad = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  const handleImageError = (imageName) => {
    setLoadedImages((prev) => prev + 1);
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: false,
    }));
  };

  const goToNextHero = () => {
    if (nextHeroId) {
      setIsLoading(true);
      setLoadedImages(0);
      setAllImagesLoaded(false);
      setCharacterId(nextHeroId);
    }
  };

  const goToPrevHero = () => {
    if (prevHeroId) {
      setIsLoading(true);
      setLoadedImages(0);
      setAllImagesLoaded(false);
      setCharacterId(prevHeroId);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNextHero,
    onSwipedRight: goToPrevHero,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleOpenDetailPage = () => {
    if (isMinting) return;
    navigate(`${paths.heroDetail}?${queryString}`, {
      state: { hero: heroData },
    });
  };

  const onClickFavorite = (e, id, favorite) => {
    e.stopPropagation();
    makeRemoveHeroFavorite(id, favorite).then(() => {
      updateHero();
    });
  };

  const actionMap = {
    1: (dispatch) => {
      dispatch(toggleHideBackground());
    },
    3: (dispatch) => {
      dispatch(setPosition("top"));
    },
    4: (dispatch) => {
      dispatch(toggleShowBackground());
      dispatch(setPosition("bottom"));
    },
  };

  const steps = useMemo(() => getHeroSteps(), []);

  const { startGuide, currentStep } = useGuide(steps, actionMap, {
    autoStart: false,
  });

  const handlePutOnItem = (itemId, slot) => {
    return postHeroItem(heroData.id, itemId, slot).then(() => {
      fetchHeroDetails(characterId);
    });
  };

  const handleMint = () => {
    if (!isUserWallet) return;
    mintHero(heroData.id).then((r) => {
      fetchHeroDetails(heroData.id);
      setIframe(r?.payment_url);
      setIsInfoModalOpen(true);
      setIsNFTModalOpen(false);
    });
  };

  const handleOpenModal = (slotName) => {
    if (isMinting) return;
    const hasItemsInSlot =
      heroData?.user_hero_items &&
      heroData?.user_hero_items.hasOwnProperty(slotName) &&
      heroData?.user_hero_items[slotName].length > 0;

    setCurrentInventorySlot(slotName);

    if (hasItemsInSlot) {
      setIsMainModalOpen(true);
      setSelectedItem(heroData?.user_hero_items[slotName][0]);
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = (isActive) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(null);
    if (!isActive) {
      setIsModalOpen(false);
    }
    if (isMainModalOpen) {
      setIsMainModalOpen(false);
    }
  };

  const closeMainModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (isMainModalOpen) setIsMainModalOpen(false);
    if (selectedItem) setSelectedItem(null);
  };

  const userHeroItems = heroData?.user_hero_items || {};

  const handleItemClick = (item) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setSelectedItem(item);
  };

  const handleCloseInfoModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsInfoModalOpen(false);
  };

  const handleOpenNFTModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsNFTModalOpen(true);
  };

  return {
    heroData,
    allImagesLoaded,
    handleOpenDetailPage,
    allLoaded,
    isImageLoaded,
    handleImageLoad,
    handleImageError,
    isLoading,
    swipeHandlers,
    updateHero,
    onClickFavorite,
    currentStep,
    handlePutOnItem,
    selectedItem,
    setSelectedItem,
    isMinting,
    handleMint,
    currentInventorySlot,
    isModalOpen,
    handleOpenModal,
    isMainModalOpen,
    closeModal,
    closeMainModal,
    setIsModalOpen,
    userHeroItems,
    handleItemClick,
    setCurrentInventorySlot,
    setIsMainModalOpen,
    isUserWallet,
    isInfoModalOpen,
    handleCloseInfoModal,
    iframe,
    isNFTModalOpen,
    setIsNFTModalOpen,
    handleOpenNFTModal,
  };
};
