import React from "react";
import styles from "./styles.module.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "../../components/ui/Button/Button";

const CauldronDetail = ({ data, t }) => {
  if (!data) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <PageTitle title={data?.market_item?.title} />
      <img
        src={data?.market_item?.image_url}
        alt={"marketItem"}
        className={styles.image}
      />
      <span className={styles.description}>
        {data?.market_item?.description}
      </span>
      <Button disabled className={styles.button}>
        {t("use")}
      </Button>
    </div>
  );
};

export default CauldronDetail;
