import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import NavBarItem from "./NavbarItem";
import { useNavbarHook } from "../../../hooks/Layout/useNavbarHook";

const Navbar = () => {
  const { location, isIos, navItems, handleNavigate } = useNavbarHook();

  return (
    <div
      id={"navbarId"}
      className={cx(styles.navbar, {
        [styles.ios]: isIos,
      })}
    >
      {navItems.map((item) => (
        <NavBarItem
          key={item.paths[0]}
          mainSrc={item.src}
          isActive={item.paths.includes(location.pathname)}
          onClick={() => {
            handleNavigate(item.paths[0]);
          }}
          disabled={item.disabled}
        />
      ))}
    </div>
  );
};

export default Navbar;
