import React from "react";
import styles from "./syles.module.scss";
import Popup from "reactjs-popup";
import cx from "classnames";

const HeroCardLevel = ({ isDetail, character }) => {
  const transformDecimalToFraction = () => {
    const decimalPart = character?.level % 1;
    return Math.round(decimalPart * 100);
  };

  const progressValue = transformDecimalToFraction();

  if (isDetail) {
    return (
      <div className={cx(styles.progressContent, styles.detailProgress)}>
        <div className={styles.progressWrapper}>
          <div
            className={styles.progress}
            style={{ width: `${progressValue}%` }}
          />
        </div>
        <span>{`${character?.experience}/${character.next_level_experience}`}</span>
      </div>
    );
  }

  return (
    <Popup
      keepTooltipInside
      trigger={
        <div className={styles.heroCardProgressContent}>
          <div className={styles.progressWrapper}>
            <div
              className={styles.progress}
              style={{ width: `${progressValue}%` }}
            />
          </div>
        </div>
      }
      position="bottom center"
    >
      <div className={styles.popupContent}>
        <span>{`${character?.experience}/${character?.next_level_experience}`}</span>
      </div>
    </Popup>
  );
};

export default HeroCardLevel;
