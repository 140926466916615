import React from "react";
import styles from "./styles.module.scss";
import { Text } from "@telegram-apps/telegram-ui";
import cx from "classnames";

const QuestAttributes = ({ quest, attributeIcons, className }) => {
  return (
    <div className={cx(styles.attributesRow, className)}>
      {Object.entries({
        strength: quest.strength,
        agility: quest.agility,
        intelligence: quest.intelligence,
        endurance: quest.endurance,
        charisma: quest.charisma,
      })
        .filter(([_, value]) => value > 0)
        .map(([key, value]) => (
          <div key={key} className={styles.attribute}>
            <img
              height={"20px"}
              width={"20px"}
              key={key}
              src={attributeIcons[key]}
              alt={key}
              className={styles.icon}
            />
            <Text className={styles.text}>{value}</Text>
          </div>
        ))}
    </div>
  );
};

export default QuestAttributes;
