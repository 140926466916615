import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  hideGuide,
  nextStep,
  resetGuide,
  showGuide,
  toggleShowBackground,
  updateSteps,
} from "../../store/slices/guideSlice";
import { useLocation } from "react-router-dom";
import { updateUserSettings } from "../../services/settingsService";
import paths from "../../pages/paths";

const useGuide = (steps, actionMap, options = {}) => {
  const { autoStart = true } = options;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.guide.currentStep);
  const tutorials = useSelector((state) => state.guide.tutorials);

  const startGuide = () => {
    const formattedSteps = steps.map((step, index) => ({
      message: step,
      action: index === steps.length - 1 ? "FINISH" : "NEXT",
    }));
    dispatch(toggleShowBackground());
    dispatch(updateSteps({ steps: formattedSteps }));
    dispatch(showGuide());
  };

  const goToNextStep = () => {
    dispatch(nextStep());
  };

  const reset = () => {
    dispatch(resetGuide());
  };

  const closeGuide = () => {
    dispatch(hideGuide());
    reset();
  };

  useEffect(() => {
    if (autoStart && steps.length > 0) {
      startGuide();
    }
    return reset;
  }, [autoStart, steps]);

  useEffect(() => {
    if (actionMap && actionMap[currentStep]) {
      actionMap[currentStep](dispatch);
    }

    if (!tutorials) {
      return;
    }

    if (currentStep === steps.length - 1) {
      const basePath = pathname.split("/")[1]
        ? `/${pathname.split("/")[1]}`
        : pathname;

      if (
        !tutorials.includes(basePath) &&
        basePath !== paths.index &&
        basePath !== paths.inventory &&
        basePath !== paths.roulette
      ) {
        updateUserSettings({ tutorials: [...tutorials, basePath] });
      }
    }
  }, [currentStep, actionMap, dispatch, tutorials]);

  return {
    startGuide,
    goToNextStep,
    reset,
    closeGuide,
    currentStep,
  };
};

export default useGuide;
