import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../services/registrationService";
import {
  buyMarketItem,
  getMarketItemPrice,
} from "../../services/marketService";
import { useTelegram } from "../Layout/useTelegramHook";
import { useSocket } from "../../utils/socketContext";

export const useMarketItemHook = () => {
  const location = useLocation();
  const isFromRoulette = location.state?.isFromRoulette;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketItemId = location.state?.id;
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  useTelegramBackButton(isFromRoulette ? paths.market : -1);
  const { t } = useTranslation();
  const { tg } = useTelegram();

  const [marketItem, setMarketItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, type: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [maxHeroLevel, setMaxHeroLevel] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [level, setLevel] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(true);
  const [iframe, setIframe] = useState("");

  const { userChannel } = useSocket();

  useEffect(() => {
    getMarketItemPrice(marketItem?.id || marketItemId, quantity, level || 1)
      .then(setMarketItem)
      .catch(() => navigate(paths.market))
      .finally(() => {
        if (isLoading) setIsLoading(false);
        setIsButtonLoading(false);
      });
  }, [quantity, level]);

  useEffect(() => {
    fetchUserData(dispatch).then((r) =>
      setMaxHeroLevel(r?.max_user_hero_level),
    );
    if (!marketItemId) {
      navigate(paths.market);
    }
  }, [marketItemId, navigate, dispatch]);

  const handleBuyStarMarketItem = () => {
    buyMarketItem(marketItem.id, quantity, level || 1)
      .then((r) => {
        if (marketItem?.price?.currency_code === "USD") {
          setIframe(r?.payment_url);
          setIsModalOpen(true);
          return;
        }
        tg.openTelegramLink(r.payment_url);
      })
      .catch(() => {
        showSnackbar("error");
      });
  };

  const handleBuyMarketItem = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (marketItem?.price?.currency_code !== "PATH") {
      handleBuyStarMarketItem(marketItem.id);
      return;
    }
    buyMarketItem(
      marketItemId,
      quantity,
      level ?? (marketItem?.type === "daily_quest_lootbox" ? maxHeroLevel : 1),
    )
      .then((r) => {
        setActiveId(r.id);
        showSnackbar("success");
      })
      .catch(() => showSnackbar("error"));
  };

  const showSnackbar = (type, timeout = 3000) => {
    setSnackbar({ open: true, type });
    setTimeout(() => setSnackbar({ open: false, type: "" }), timeout);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsModalOpen(false);
  };

  const handleOpenRoulette = () => {
    if (marketItem?.type === "hero_items_lootbox") {
      navigate(paths.treasureOpen, {
        state: { id: activeId },
      });
      return;
    }
    if (["daily_quest_lootbox", "quest_lootbox"].includes(marketItem.type)) {
      navigate(paths.quests);
      return;
    }
    navigate(paths.roulette, {
      state: { id: activeId },
    });
  };

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (
        payload?.user_market_item_id &&
        ["user_quests", "hero_items_lootbox"].includes(payload?.content_type)
      ) {
        if (payload?.content_type === "hero_items_lootbox") {
          setActiveId(payload?.user_market_item_id);
        }
        showSnackbar("success");
        return payload;
      }
      if (payload?.user_market_item_id) {
        setActiveId(payload?.user_market_item_id);
        setIsSuccessModalOpen(true);
        if (iframe) setIframe("");
        if (isModalOpen) setIsModalOpen(false);
      }
      return payload;
    };
  }, [userChannel]);

  const handleQuantityChange = (selectedOption) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (quantity === selectedOption.value) {
      return;
    }
    setQuantity(selectedOption.value);
    setIsButtonLoading(true);
  };

  const handleLevelChange = (selectedOption) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (level === selectedOption.value) {
      return;
    }
    setLevel(selectedOption.value);
    setIsButtonLoading(true);
  };
  const quantityOptions = [
    { value: 1, label: 1 },
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
  ];

  const levelOptions = Array.from({ length: maxHeroLevel }, (_, index) => ({
    value:
      marketItem?.type === "daily_quest_lootbox"
        ? maxHeroLevel - index
        : index + 1,
    label:
      marketItem?.type === "daily_quest_lootbox"
        ? maxHeroLevel - index
        : index + 1,
  }));

  return {
    handleCloseModal,
    handleOpenModal,
    handleBuyMarketItem,
    isLoading,
    isModalOpen,
    snackbar,
    t,
    setSnackbar,
    marketItem,
    handleOpenRoulette,
    isSuccessModalOpen,
    setIsSuccessModalOpen,
    levelOptions,
    quantityOptions,
    handleLevelChange,
    handleQuantityChange,
    isButtonLoading,
    iframe,
    maxHeroLevel,
  };
};
