import React from "react";
import styles from "./styles.module.scss";
import {
  Caption,
  Cell,
  Chip,
  Image,
  Spinner,
  Text,
} from "@telegram-apps/telegram-ui";
import { getColorByClassName } from "../../utils/imagesGetter";
import Countdown from "react-countdown";
import QuestAttributes from "./QuestAttributes";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { FaCircleQuestion } from "react-icons/fa6";
import Popup from "reactjs-popup";
import { formatAmount } from "../../utils/formatNumbers";
import { attributeIcons } from "../../utils/attributesIconsUtils";

const QuestInfo = ({
  quest,
  endDate,
  questChance,
  isChanceLoading,
  currentStep,
}) => {
  const { t } = useTranslation();

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours}${t("h")} ${remainingMinutes}${t("m")} ${remainingSeconds}${t("s")}`;
    } else if (minutes > 0) {
      return `${minutes}${t("m")} ${remainingSeconds}${t("s")}`;
    } else {
      return `${remainingSeconds}${t("s")}`;
    }
  };

  const getChanceColorClass = () => {
    if (!questChance) {
      return;
    }
    if (questChance <= 20) {
      return styles.red;
    } else if (questChance <= 50) {
      return styles.orange;
    } else if (questChance <= 80) {
      return styles.yellow;
    } else {
      return styles.green;
    }
  };

  return (
    <div className={styles.questInfo}>
      <Cell
        multiline
        className={styles.cell}
        before={
          <div className={styles.levelAvatar}>
            <Text className={styles.level}>{quest.level}</Text>
            <Image
              className={styles.rarityImg}
              size={48}
              src={`https://s3.pathgame.app/public/v2/quests/${quest.rarity_level}.webp`}
            />
          </div>
        }
        subtitle={
          <div className={styles.row}>
            <Caption className={styles.info}>
              <span
                className={cx({ [styles.active]: currentStep === 1 })}
              >{`${t("Reward")}: ${formatAmount(quest?.money_reward?.amount)} ${quest?.money_reward?.currency_code}`}</span>
              <div>
                <span>{t("Rarity type")}:</span>&nbsp;
                <span
                  style={{ color: getColorByClassName(quest.rarity_level) }}
                >
                  {quest.rarity_level_name}
                </span>
              </div>
              <span>{`${t("Experience")}: ${quest.experience_reward}`}</span>
              <span>{`${t("Duration")}: ~${formatDuration(quest.duration_in_seconds)}`}</span>
              {quest.status !== "completed" && (
                <div className={styles.chanceRow}>
                  <span>{t("Chance")}:&nbsp;</span>
                  {!questChance && (
                    <Popup
                      keepTooltipInside
                      position="bottom center"
                      arrow={false}
                      trigger={
                        <div>
                          {!isChanceLoading && (
                            <FaCircleQuestion
                              size={10}
                              className={styles.question}
                            />
                          )}
                          {isChanceLoading && (
                            <Spinner className={styles.spinner} size={"s"} />
                          )}
                        </div>
                      }
                    >
                      <div className={styles.popupContent}>
                        <Caption>
                          {t("Choose a hero to see the quest success rate")}
                        </Caption>
                      </div>
                    </Popup>
                  )}
                  {questChance && (
                    <span className={cx(getChanceColorClass())}>
                      {questChance}%
                    </span>
                  )}
                </div>
              )}
            </Caption>
            {quest.status !== "created" && (
              <Chip className={styles.chip}>{t(quest.status)}</Chip>
            )}
          </div>
        }
      >
        {quest.name}
      </Cell>
      <div className={styles.countDown}>
        {quest.status === "started" && <Countdown date={endDate} />}
      </div>
      <QuestAttributes attributeIcons={attributeIcons} quest={quest} />
    </div>
  );
};

export default QuestInfo;
