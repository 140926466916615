import React from "react";
import styles from "./styles.module.scss";
import { PreloadImages } from "../../../utils/globals";
import { useHeaderHook } from "../../../hooks/Layout/useHeaderHook";
import cx from "classnames";
import { FaCrown } from "react-icons/fa";
import paths from "../../../pages/paths";
import { formatAmount, formatNumber } from "../../../utils/formatNumbers";

const Header = () => {
  const {
    username,
    handleGoToProfile,
    handleToggleMusic,
    isPlaying,
    userAmount,
    userRating,
    isLoading,
    pathname,
    handleOpenRating,
    overflowAmount,
    animationName,
    usernameTextRef,
    isMainPageGuide,
  } = useHeaderHook();

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.username} onClick={handleGoToProfile}>
          <img
            alt={"profile"}
            className={styles.avatar}
            height={20}
            width={20}
            src={PreloadImages.PROFILE_LIGHT}
          />
          <span className={styles.usernameText}>
            <span
              className={styles.usernameTextContent}
              ref={usernameTextRef}
              style={
                overflowAmount > 0 && animationName
                  ? {
                      animation: `${animationName} 10s linear infinite`,
                    }
                  : {}
              }
            >
              {username}
            </span>
          </span>
        </div>
        {!isLoading && pathname === paths.index && (
          <div className={styles.userInfo}>
            <div className={styles.userRating} onClick={handleOpenRating}>
              <FaCrown color={"#f5e1a4"} size={15} />
              <span
                className={styles.userInfoText}
              >{`${userRating < 100000 ? formatAmount(userRating) : formatNumber(userRating) || "-"}`}</span>
            </div>
            <div className={styles.userAmount}>
              <img
                alt={"coin"}
                className={styles.userAmountImg}
                src={PreloadImages.COIN_ICON}
                width={15}
                height={15}
              />
              <span className={styles.userInfoText}>
                {formatNumber(userAmount)}
              </span>
            </div>
          </div>
        )}
        <div className={styles.rightSide}>
          <img
            className={cx(styles.music, { [styles.active]: isMainPageGuide })}
            width={30}
            height={30}
            alt={"music"}
            onClick={handleToggleMusic}
            src={
              isPlaying
                ? PreloadImages.MUSIC_ICON_ON
                : PreloadImages.MUSIC_ICON_OFF
            }
          />
        </div>
      </div>
    </>
  );
};

export default Header;
