import { apiUrl } from "../utils/globals";
import { axiosInstance, axiosListInstance } from "./axiosService";

const getHeroes = ({
  page,
  pageSize,
  orderParam = null,
  classIds = null,
  heroIds = null,
  rarityLevelIds = null,
  questId = undefined,
  ids = null,
  favoritesOption = null,
  heroStatus = null,
  mint_status = null,
}) => {
  const updatedOrderParam =
    favoritesOption === "enabled"
      ? orderParam
        ? `favorite_desc,${orderParam}`
        : "favorite_desc"
      : orderParam;

  const params = {
    page,
    page_size: pageSize,
    order_by: updatedOrderParam,
    ...(mint_status || mint_status === "" ? { mint_status: mint_status } : {}),
    ...(classIds ? { class_ids: classIds } : {}),
    ...(heroIds ? { hero_ids: heroIds } : {}),
    ...(rarityLevelIds ? { rarity_level_ids: rarityLevelIds } : {}),
    ...(questId !== undefined ? { active_quest_id: questId || "" } : {}),
    ...(ids ? { ids: ids } : {}),
    ...(heroStatus && heroStatus !== "ALL"
      ? { active: heroStatus === "ACTIVE" ? "t" : "f" }
      : {}),
  };

  return axiosListInstance.get(`${apiUrl}user_heroes`, { params });
};

const getHero = (id) => {
  return axiosInstance.get(`/user_heroes/${id}`);
};

const getDetailHeroes = (id, params = {}) => {
  const updatedOrderParam =
    params.favorite_desc === "enabled"
      ? params.order_by
        ? `favorite_desc,${params.order_by}`
        : "favorite_desc"
      : params.order_by;

  const queryParams = {
    ...(params.page ? { page: params.page } : {}),
    ...(params.page_size ? { page_size: params.page_size } : {}),
    order_by: updatedOrderParam,
    ...(params.class_ids ? { class_ids: params.class_ids } : {}),
    ...(params.hero_ids ? { hero_ids: params.hero_ids } : {}),
    ...(params.rarity_level_ids
      ? { rarity_level_ids: params.rarity_level_ids }
      : {}),
    ...(params.hero_status && params.hero_status !== "ALL"
      ? { active: params.hero_status === "ACTIVE" ? "t" : "f" }
      : {}),
  };

  return axiosInstance.get(`/user_heroes/${id}/previous_and_next`, {
    params: queryParams,
  });
};

const makeRemoveHeroFavorite = (id, state) => {
  return axiosInstance.patch(`/user_heroes/${id}`, {
    favorite: state,
  });
};

export { getHeroes, getHero, getDetailHeroes, makeRemoveHeroFavorite };
