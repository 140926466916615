import React from "react";
import styles from "./styles.module.scss";
import { useSettingsPageHook } from "../../hooks/Pages/useSettingsPageHook";
import LanguageSwitcher from "../../components/ui/LanguageSwitcher/LanguageSwitcher";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import Button from "../../components/ui/Button/Button";
import { GrPowerReset } from "react-icons/gr";
import cx from "classnames";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FaCircleQuestion } from "react-icons/fa6";
import Popup from "reactjs-popup";
import { LuPower, LuPowerOff } from "react-icons/lu";

const Settings = () => {
  const {
    t,
    hapticEnabled,
    handleHapticToggle,
    activeLanguage,
    setActiveLanguage,
    isLoading,
    setIsLoading,
    volume,
    handleVolumeChange,
    navigateToMainPage,
    handleCancelReset,
    handleConfirmReset,
    handleResetGuideClick,
    tutorials,
    showConfirmation,
    handleNotificationToggle,
    notificationEnabled,
  } = useSettingsPageHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.settings}>
      <PageTitle>{t("settings")}</PageTitle>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.labelCell}>
            <span>{t("language")}</span>
          </div>
          <div className={styles.controlCell}>
            <LanguageSwitcher
              setIsLoading={setIsLoading}
              activeLanguage={activeLanguage}
              setActiveLanguage={setActiveLanguage}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.labelCell}>
            <span>{t("hapticFeedback")}</span>
          </div>
          <div className={styles.controlCell}>
            <button
              className={cx(styles.toggleButton, {
                [styles.active]: hapticEnabled,
              })}
              onClick={handleHapticToggle}
            >
              {hapticEnabled ? <LuPower /> : <LuPowerOff />}
            </button>
          </div>
        </div>

        <div className={styles.row}>
          <div className={cx(styles.labelCell, styles.customCell)}>
            <span>{t("notifications")}</span>
            <Popup
              keepTooltipInside
              position="bottom center"
              arrow={false}
              trigger={
                <div>
                  <FaCircleQuestion size={15} className={styles.question} />
                </div>
              }
            >
              <div className={styles.popupContent}>
                <span>{t("dailyNotif")}</span>
              </div>
            </Popup>
          </div>
          <div className={styles.controlCell}>
            <button
              className={cx(styles.toggleButton, {
                [styles.active]: notificationEnabled,
              })}
              onClick={handleNotificationToggle}
            >
              {notificationEnabled ? <LuPower /> : <LuPowerOff />}
            </button>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.labelCell}>
            <span>{t("Music level")}</span>
          </div>
          <div className={styles.controlCell}>
            <input
              className={styles.range}
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
          </div>
        </div>

        {!!tutorials?.length && (
          <div className={styles.row}>
            <div className={styles.labelCell}>
              <span>
                {showConfirmation ? t("Are you sure?") : t("Reset Guide")}
              </span>
            </div>
            <div className={styles.controlCell}>
              {showConfirmation ? (
                <div className={styles.confirmationButtons}>
                  <Button
                    className={styles.button}
                    onClick={handleConfirmReset}
                  >
                    {t("Yes")}
                  </Button>
                  <Button className={styles.button} onClick={handleCancelReset}>
                    {t("No")}
                  </Button>
                </div>
              ) : (
                <Button
                  className={styles.resetButton}
                  onClick={handleResetGuideClick}
                >
                  <GrPowerReset />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.fixedLayout}>
        <Button onClick={navigateToMainPage}>{t("To the Main Page")}</Button>
      </div>
    </div>
  );
};

export default Settings;
