import React from "react";
import styles from "./styles.module.scss";
import Button from "../../components/ui/Button/Button";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { useProfileHook } from "../../hooks/Pages/useProfileHook";
import { formatAmount } from "../../utils/formatNumbers";
import { FiCopy, FiX } from "react-icons/fi";
import { SiTon } from "react-icons/si";
import { PreloadImages } from "../../utils/globals";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import cx from "classnames";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";

const Profile = () => {
  const {
    isLoading,
    userAmount,
    userName,
    userRating,
    userWallet,
    truncateAddress,
    handleDeleteWallet,
    handleOpenConnectModal,
    handleCopyAddress,
    handleOpenTransactions,
    handleOpenUserTasks,
    handleOpenTreasures,
    t,
    handleOpenSettings,
    checkBadge,
    currentStep,
    openModal,
    closeModal,
    isModalOpen,
    handleContactSupport,
  } = useProfileHook();

  if (isLoading) return <LoaderComponent />;

  const renderCell = ({
    title,
    subtitle,
    icon,
    action,
    onClick,
    active,
    badge,
    key,
    withWithdraw,
  }) => (
    <div
      key={key}
      className={cx(styles.cell, styles.fadeIn, { [styles.active]: active })}
      onClick={onClick}
    >
      <div className={styles.cellContent}>
        <div className={styles.before}>
          <div className={styles.badgeContainer}>
            {icon && <img src={icon} alt="" className={styles.avatar} />}
            {badge && <span className={styles.notificationDot} />}
          </div>
        </div>
        <div className={styles.cellMain}>
          <span className={styles.cellTitle}>{title}</span>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        <div className={styles.actionRow}>
          {withWithdraw && (
            <Button disabled>
              <BiMoneyWithdraw />
            </Button>
          )}
          {action && <div className={styles.after}>{action}</div>}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={`${styles.wrapper} ${styles.fadeIn}`}>
        <div
          className={cx(
            styles.cell,
            styles.topCell,
            styles.fadeIn,
            styles.unclickable,
          )}
        >
          <div className={styles.cellContent}>
            <div className={styles.before}>
              <div className={styles.avatarWrapper}>
                <img
                  className={styles.topAvatarImg}
                  src={PreloadImages.PROFILE_LIGHT}
                  alt="profile"
                />
              </div>
            </div>
            <div className={styles.cellMain}>
              <span className={styles.username}>{userName}</span>
              <div
                className={styles.subtitle}
              >{`${t("rank")} ${userRating || "-"}`}</div>
            </div>
          </div>
        </div>

        <div className={`${styles.list} ${styles.fadeIn}`}>
          {userAmount?.map((item, i) =>
            renderCell({
              withWithdraw: true,
              title: item?.balance?.currency_name,
              icon:
                item?.balance?.currency_code === "PATH"
                  ? PreloadImages.COIN_ICON
                  : AsyncPreloadImages.NOTCOIN_ICON,
              key: i,
              subtitle: item?.balance.currency_code,
              action: (
                <span className={styles.title}>
                  {formatAmount(item?.balance?.amount)}
                </span>
              ),
            }),
          )}

          {renderCell({
            title: t("Inventory"),
            icon: PreloadImages.INVENTORY_ICON,
            onClick: handleOpenTreasures,
            active: currentStep === 1,
            badge: checkBadge("market_items"),
            action: <MdKeyboardArrowRight size={25} className={styles.arrow} />,
          })}

          {renderCell({
            title: t("Transaction history"),
            icon: PreloadImages.TRANSACTIONS_HISTORY_ICON,
            onClick: handleOpenTransactions,
            active: currentStep === 2,
            action: <MdKeyboardArrowRight size={25} className={styles.arrow} />,
          })}

          {renderCell({
            title: t("tasks"),
            icon: PreloadImages.TASKS_ICON,
            onClick: handleOpenUserTasks,
            active: currentStep === 3,
            badge: checkBadge("tasks"),
            action: <MdKeyboardArrowRight size={25} className={styles.arrow} />,
          })}

          {renderCell({
            title: t("settings"),
            icon: PreloadImages.SETTINGS_ICON,
            onClick: handleOpenSettings,
            active: currentStep === 4,
            action: <MdKeyboardArrowRight size={25} className={styles.arrow} />,
          })}

          {renderCell({
            title: t("support"),
            icon: PreloadImages.SUPPORT_ICON,
            onClick: handleContactSupport,
            action: <MdKeyboardArrowRight size={25} className={styles.arrow} />,
          })}

          <div className={`${styles.walletWrapper} ${styles.fadeIn}`}>
            {userWallet ? (
              <div className={cx(styles.cell, styles.fadeIn)}>
                <div className={styles.cellContent}>
                  <div className={styles.before}>
                    <img
                      src={PreloadImages.WALLET_ICON}
                      alt="wallet"
                      className={cx(styles.avatar)}
                    />
                  </div>
                  <div className={styles.cellMain}>
                    <div className={styles.walletRow}>
                      <span>{truncateAddress(userWallet)}</span>
                      <FiCopy
                        onClick={handleCopyAddress}
                        className={styles.copy}
                        size={24}
                      />
                    </div>
                  </div>
                  <div className={styles.after}>
                    <FiX
                      className={styles.deleteIcon}
                      size={24}
                      onClick={openModal}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={cx(styles.tonButtonWrapper)}>
                <Button
                  className={cx({ [styles.active]: currentStep === 5 })}
                  onClick={handleOpenConnectModal}
                >
                  <div className={styles.tonButton}>
                    <SiTon />
                    <span>{t("connect_wallet")}</span>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalWrapper}>
            <div className={styles.modalHeader}>
              <FiX
                size={28}
                onClick={closeModal}
                className={styles.closeIcon}
              />
            </div>
            <div className={styles.modalContent}>
              <span>{t("unlink_wallet_confirmation")}</span>
              <Button onClick={handleDeleteWallet}>{t("unlink")}</Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
