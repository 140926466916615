import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { fetchUserData } from "../../services/registrationService";
import {
  getMeMarketItem,
  getMeMarketItems,
  openMarketItems,
} from "../../services/marketService";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useTelegram } from "../Layout/useTelegramHook";
import {
  resetGuide,
  setIsGuider,
  toggleHideBackground,
} from "../../store/slices/guideSlice";
import { updateUserSettings } from "../../services/settingsService";
import useGuide from "./useGuideHook";
import { getRouletteSteps } from "../../utils/guideSteps";

const prizes = [
  { image: AsyncPreloadImages.COMMON_BACK_PREVIEW, text: "Common" },
  { image: AsyncPreloadImages.RARE_BACK_PREVIEW, text: "Rare" },
  { image: AsyncPreloadImages.ELITE_BACK_PREVIEW, text: "Elite" },
  { image: AsyncPreloadImages.LEGENDARY_BACK_PREVIEW, text: "Legendary" },
  { image: AsyncPreloadImages.DIVINE_BACK_PREVIEW, text: "Divine" },
  { image: AsyncPreloadImages.COMMON_BACK_PREVIEW, text: "Common" },
  { image: AsyncPreloadImages.RARE_BACK_PREVIEW, text: "Rare" },
  { image: AsyncPreloadImages.ELITE_BACK_PREVIEW, text: "Elite" },
  { image: AsyncPreloadImages.LEGENDARY_BACK_PREVIEW, text: "Legendary" },
  { image: AsyncPreloadImages.DIVINE_BACK_PREVIEW, text: "Divine" },
  { image: AsyncPreloadImages.COMMON_BACK_PREVIEW, text: "Common" },
  { image: AsyncPreloadImages.RARE_BACK_PREVIEW, text: "Rare" },
  { image: AsyncPreloadImages.ELITE_BACK_PREVIEW, text: "Elite" },
  { image: AsyncPreloadImages.LEGENDARY_BACK_PREVIEW, text: "Legendary" },
  { image: AsyncPreloadImages.DIVINE_BACK_PREVIEW, text: "Divine" },
  { image: AsyncPreloadImages.COMMON_BACK_PREVIEW, text: "Common" },
  { image: AsyncPreloadImages.RARE_BACK_PREVIEW, text: "Rare" },
  { image: AsyncPreloadImages.ELITE_BACK_PREVIEW, text: "Elite" },
  { image: AsyncPreloadImages.LEGENDARY_BACK_PREVIEW, text: "Legendary" },
  { image: AsyncPreloadImages.DIVINE_BACK_PREVIEW, text: "Divine" },
];

const reproductionArray = (array = [], length = 0) => [
  ...Array(length)
    .fill("_")
    .map(() => array[Math.floor(Math.random() * array?.length)]),
];

const reproducedPrizeList = [
  ...prizes,
  ...reproductionArray(prizes, prizes?.length * 3),
  ...prizes,
  ...reproductionArray(prizes, prizes?.length * 10),
];

const generateId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

const prizeList = reproducedPrizeList.map((prize) => ({
  ...prize,
  id: generateId(),
}));

export const useRouletteHook = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const id = location.state.id;

  const navigate = useNavigate();
  const [hero, setHero] = useState(null);
  const [isHero, setIsHero] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [marketItem, setMarketItem] = useState(null);
  const [start, setStart] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(0);
  const [itemId, setItemId] = useState(null);
  const [isNoMore, setIsNoMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();

  const actionMap = {
    1: (dispatch) => {
      dispatch(toggleHideBackground());
      dispatch(setIsGuider(false));
    },
    2: (dispatch) => {
      handleStart();
      updateUserSettings({
        tutorials: [...tutorials, paths.index, paths.roulette, paths.inventory],
      });
      dispatch(resetGuide());
    },
  };

  const steps = useMemo(() => getRouletteSteps(), []);

  const { startGuide, currentStep } = useGuide(steps, actionMap, {
    autoStart: false,
  });

  useEffect(() => {
    setItemId(id);
  }, [id]);

  useTelegramBackButton(-1);

  useEffect(() => {
    fetchUserData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!itemId) return;
    getMeMarketItem(itemId)
      .then((data) => {
        if (data.is_used) {
          navigate(-1);
          return;
        }
        startGuide();
        setMarketItem(data);
      })
      .finally(() => setIsLoading(false));
  }, [itemId]);

  const preloadHeroImages = (preview) => {
    const img = new Image();
    img.src = preview;
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleStart = () => {
    setIsButtonLoading(true);
    if (start) {
      setIsHero(true);
      setStart(false);
      setIsButtonLoading(false);
      return;
    }
    openMarketItems(itemId).then((r) => {
      setPrizeIndex(getIndexFromRarity(r.rarity_level));
      setHero(r);
      preloadHeroImages(r?.hero_preview_url);

      getMeMarketItems(marketItem.market_item.id)
        .then((data) => {
          if (data?.length) {
            setItemId(data[0].id);
          } else {
            setIsNoMore(true);
          }
        })
        .finally(() => {
          setStart(true);
          setIsButtonLoading(false);
        });
    });
  };

  const getIndexFromRarity = (rarity) => {
    if (!rarity) return;
    switch (rarity.toLowerCase()) {
      case "divine":
        return 19;
      case "legendary":
        return 18;
      case "elite":
        return 17;
      case "rare":
        return 16;
      case "common":
        return 15;
      default:
        return;
    }
  };

  const handlePrizeDefined = () => {
    setTimeout(() => {
      setStart(false);
      setIsHero(true);
    }, 500);
  };

  const getButtonTitle = () => {
    if (start) {
      return t("Skip");
    }
    return t("Open");
  };

  const handleClickMore = () => {
    if (isNoMore) {
      navigate(paths.marketItem, {
        state: {
          id: marketItem.market_item.id,
          isFromRoulette: true,
        },
      });
      return;
    }
    if (start) setStart(false);
    setIsHero(false);
  };

  const handleOpenModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsModalOpen(false);
  };

  const tutorials = useSelector((state) => state.guide.tutorials);

  return {
    isHero,
    marketItem,
    prizeList,
    handlePrizeDefined,
    handleOpenModal,
    getButtonTitle,
    handleStart,
    isModalOpen,
    handleClickMore,
    isNoMore,
    t,
    isLoading,
    hero,
    start,
    prizeIndex,
    handleCloseModal,
    isButtonLoading,
    currentStep,
  };
};
