const paths = {
  index: "/",
  hero: "/hero",
  heroDetail: "/heroDetail",
  heroes: "/heroes",
  referral: "/referral",
  giveaways: "/giveaways",
  giveaway: "/giveaway",
  tasks: "/tasks",
  task: "/task",
  settings: "/settings",
  rating: "/rating",
  market: "/market",
  marketItem: "/marketItem",
  inventory: "/inventory",
  about: "/about",
  info: "/info",
  profile: "/profile",
  error: "/error",
  transactions: "/transactions",
  roulette: "/roulette",
  quests: "/quests",
  quest: "/quest/:id",
  questsHistory: "/questsHistory",
  treasureOpen: "/treasureOpen",
  cauldron: "/cauldron",
};
export default paths;
