// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_itemSlot__q3iIZ {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.styles_badge__BxwGx {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.styles_itemImage__NCQ\\+T {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.styles_itemLevel__2\\+XI5 {
  font-size: 10px;
  color: #fff;
  background-color: #333;
  padding: 2px 4px;
  border-radius: 4px;
  position: absolute;
  bottom: -10px;
}

.styles_close__c4lp8 {
  position: absolute;
  right: -5px;
  top: -5px;
  color: red;
  cursor: pointer;
}

.styles_emptySlot__dVfEx {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.styles_emptySlotImg__Q\\+ACV {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(100%) brightness(30%);
}

.styles_slotName__RhAsa {
  font-size: 12px;
  color: #555;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/ItemSlot/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uCAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,0BAAA;AACF","sourcesContent":[".itemSlot {\n  position: relative;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n}\n\n.badge {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: red;\n  width: 5px;\n  height: 5px;\n  border-radius: 50%;\n}\n\n.itemImage {\n  width: 100%;\n  height: auto;\n  border-radius: 4px;\n}\n\n.itemLevel {\n  font-size: 10px;\n  color: #fff;\n  background-color: #333;\n  padding: 2px 4px;\n  border-radius: 4px;\n  position: absolute;\n  bottom: -10px;\n}\n\n.close {\n  position: absolute;\n  right: -5px;\n  top: -5px;\n  color: red;\n  cursor: pointer;\n}\n\n.emptySlot {\n  width: 100%;\n  height: 100%;\n  border-radius: 4px;\n}\n\n.emptySlotImg {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  filter: grayscale(100%) brightness(30%);\n}\n\n.slotName {\n  font-size: 12px;\n  color: #555;\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemSlot": `styles_itemSlot__q3iIZ`,
	"badge": `styles_badge__BxwGx`,
	"itemImage": `styles_itemImage__NCQ+T`,
	"itemLevel": `styles_itemLevel__2+XI5`,
	"close": `styles_close__c4lp8`,
	"emptySlot": `styles_emptySlot__dVfEx`,
	"emptySlotImg": `styles_emptySlotImg__Q+ACV`,
	"slotName": `styles_slotName__RhAsa`
};
export default ___CSS_LOADER_EXPORT___;
