import React from "react";
import styles from "./common.module.scss";
import { CardChip } from "@telegram-apps/telegram-ui/dist/components/Blocks/Card/components/CardChip/CardChip";
import { CardCell } from "@telegram-apps/telegram-ui/dist/components/Blocks/Card/components/CardCell/CardCell";
import { formatAmount } from "../../utils/formatNumbers";
import { BsArrowRight } from "react-icons/bs";
import { Card, CircularProgress } from "@telegram-apps/telegram-ui";
import { StarIcon } from "../../assets/icons";
import { useTelegram } from "../../hooks/Layout/useTelegramHook";
import cx from "classnames";

const MarketCard = ({
  item,
  handleCardClick,
  handleChipClick,
  t,
  currentStep,
}) => {
  const renderStarDescription = () => (
    <div className={styles.starsRow}>
      <span className={styles.strikethrough}>
        {formatAmount(item.price.amount)}
      </span>
      <BsArrowRight />
      <div className={styles.starsRow}>
        <span className={styles.prize}>
          {formatAmount(item?.final_price?.amount)}
        </span>
        <div className={styles.flex}>
          <StarIcon size={15} />
        </div>
      </div>
    </div>
  );

  const renderDefaultDescription = () => (
    <div className={styles.noWrap}>
      <span className={styles.strikethrough}>
        {formatAmount(item.price.amount)}
      </span>
      <BsArrowRight className={styles.arrowIcon} />
      <span className={styles.prize}>
        {formatAmount(item?.final_price?.amount)}
      </span>
      &nbsp;
      {item?.price?.currency_code}
    </div>
  );

  const renderDescription = () => {
    return item?.price?.currency_code === "STAR"
      ? renderStarDescription()
      : renderDefaultDescription();
  };

  const renderSubtitle = () => {
    return (
      <div className={styles.row}>
        <div className={styles.starsRow}>
          <span className={styles.prize}>
            {formatAmount(item.price.amount)}
          </span>
          {item?.price?.currency_code === "STAR" && (
            <div className={styles.flex}>
              <StarIcon size={15} />
            </div>
          )}
        </div>
        <div>
          {item?.price?.currency_code !== "STAR" && (
            <span>{item?.price?.currency_code}</span>
          )}
        </div>
      </div>
    );
  };

  const calculateProgress = (startedAt, endedAt) => {
    if (!startedAt || !endedAt) {
      return 0;
    }
    const now = new Date();
    const start = new Date(startedAt);
    const end = new Date(endedAt);

    const totalDuration = end - start;

    if (totalDuration === 0) {
      return 0;
    }

    const remainingTime = end - now;
    return Math.max((remainingTime / totalDuration) * 100, 0);
  };

  const { tg } = useTelegram();
  const isDarkScheme = tg.colorScheme === "dark";
  return (
    <Card
      key={item.id}
      className={cx(styles.card, {
        [styles.disabledCard]: item?.disabled_until,
      })}
      onClick={() => handleCardClick(item)}
    >
      {item?.sale && (
        <div
          className={cx(styles.progressWrapper, {
            [styles.dark]: isDarkScheme,
          })}
        >
          <CircularProgress
            progress={calculateProgress(
              item?.sale?.start_datetime,
              item?.sale?.end_datetime,
            )}
            size="small"
          />
        </div>
      )}

      <CardChip
        className={cx(styles.chip, { [styles.active]: currentStep === 2 })}
        onClick={(e) => handleChipClick(e, item)}
      >
        {item.status === "coming_soon" ? t("why_click_here") : t("Buy")}
      </CardChip>
      <img className={styles.cardImg} alt={item.title} src={item.image_url} />
      <CardCell
        className={styles.cardCell}
        multiline
        readOnly
        subtitle={item.sale ? renderDescription() : renderSubtitle()}
      >
        <span className={styles.text}>{item.title}</span>
      </CardCell>
    </Card>
  );
};

export default MarketCard;
