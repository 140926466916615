import { axiosInstance } from "./axiosService";

const getMarketItems = () => {
  return axiosInstance.get("/market_items/grouped?level=1");
};

const getMarketItemPrice = (id, quantity = 1, level = 1) => {
  return axiosInstance.get(
    `/market_items/${id}?quantity=${quantity}&level=${level}`,
  );
};
const buyMarketItem = (id, quantity = 1, level = 1) => {
  return axiosInstance.post(`/market_items/${id}/buy_item`, {
    quantity,
    level,
  });
};
const getMeMarketItems = (id) => {
  return axiosInstance.get("/me/market_items", {
    params: {
      is_used: false,
      market_item_id: id,
    },
  });
};

const getMeMarketItem = (id) => {
  return axiosInstance.get(`/me/market_items/${id}`);
};
const openMarketItems = (id) => {
  return axiosInstance.post(`/me/market_items/${id}`);
};

export {
  getMarketItems,
  buyMarketItem,
  getMeMarketItems,
  openMarketItems,
  getMeMarketItem,
  getMarketItemPrice,
};
