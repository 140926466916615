import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";
import { useGiveawaysHook } from "../../hooks/Pages/useGiveawaysHook";
import { formatAmount } from "../../utils/formatNumbers";
import NoData from "./NoData";
import PageTitle from "../../components/PageTitle/PageTitle";
import cx from "classnames";
import { calculateProgress } from "../../utils/utils";
import SegmentedControl, {
  SegmentedControlItem,
} from "../../components/SegmentControl/SegmentControl";

const Giveaways = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    handleChangeStatus,
    activeStatus,
    isDataLoading,
    page,
    giveawaysData,
    lastGiveawayElementRef,
    handleGoToGiveaway,
  } = useGiveawaysHook();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.wrapper, styles.fadeIn)}>
      <PageTitle isNoMargin title={t("Giveaways")} />
      <SegmentedControl>
        <SegmentedControlItem
          selected={activeStatus === "active"}
          onClick={() => handleChangeStatus("active")}
        >
          {t("Active")}
        </SegmentedControlItem>
        <SegmentedControlItem
          selected={activeStatus === "completed"}
          onClick={() => handleChangeStatus("completed")}
        >
          {t("Completed")}
        </SegmentedControlItem>
      </SegmentedControl>
      {isDataLoading && page === 1 && (
        <div className={styles.dataLoader}>
          <LoaderComponent />
        </div>
      )}
      {!isDataLoading && !giveawaysData?.length && <NoData />}
      {(!isDataLoading || page > 1) && giveawaysData?.length > 0 && (
        <div className={styles.list}>
          {giveawaysData.map((item, index) => {
            const progress = calculateProgress(item.started_at, item.ended_at);
            const isLowProgress = progress > 70;

            return (
              <div
                key={item.id}
                ref={
                  index === giveawaysData.length - 1
                    ? lastGiveawayElementRef
                    : null
                }
                className={cx(styles.cell, styles.fadeIn)}
                onClick={() => handleGoToGiveaway(item.id)}
              >
                <div className={styles.avatar}>
                  <img src={item.image_url} alt={item.title} />
                </div>
                <div className={styles.cellContent}>
                  <span>{item.title}</span>
                  <span className={styles.subhead}>
                    {`${formatAmount(item?.pool?.amount)} ${item?.pool?.currency_code}`}
                  </span>
                  {item.status !== "completed" && (
                    <div className={styles.progress}>
                      <div
                        className={cx(styles.progressBar, {
                          [styles.lowProgress]: isLowProgress,
                        })}
                        style={{
                          width: `${progress}%`,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Giveaways;
