import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Fireworks } from "fireworks-js";
import { attributeIcons } from "../../../utils/attributesIconsUtils";
import { Caption, Image, Text } from "@telegram-apps/telegram-ui";
import cx from "classnames";
import BloodAnimation from "../../../components/BloodAnimation/BloodAnimation";
import { useTranslation } from "react-i18next";
import { getNonZeroAttributes } from "../../../utils/skillsUtils";
import { useNavigate } from "react-router-dom";
import paths from "../../paths";

const ResultModal = (props) => {
  const { quest, setIsResultModalOpen, levelUpInfo } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLevelInfo, setShowLevelInfo] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const fireworksContainerRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!levelUpInfo?.length) {
      const timer = setTimeout(() => {
        setShowLevelInfo(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, levelUpInfo]);

  useEffect(() => {
    if (fireworksContainerRef.current && quest.status === "completed") {
      const fireworks = new Fireworks(fireworksContainerRef.current, {});
      const timer = setTimeout(() => {
        fireworks.start();
      }, 1000);

      return () => {
        fireworks.stop();
        clearTimeout(timer);
      };
    }
  }, [fireworksContainerRef, quest]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVisible(true);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  const handleNext = () => {
    const isLastIndex = currentIndex >= (levelUpInfo?.length || 0) - 1;

    if (!isLastIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      if (quest.status === "completed") {
        navigate(paths.quests);
      } else {
        setIsResultModalOpen(false);
      }
    }
  };

  const renderIncreasedAttributes = () => {
    const attributes = levelUpInfo[currentIndex] || {};
    return Object.entries(attributes)
      ?.filter(
        ([key, value]) =>
          key !== "hero_name" &&
          key !== "level" &&
          key !== "skill" &&
          value > 0,
      )
      ?.map(([key, value]) => (
        <div key={key} className={styles.attributeRow}>
          <img height="20px" width="20px" src={attributeIcons[key]} alt={key} />
          <span className={styles.whiteText}>+{value}</span>
        </div>
      ));
  };

  const getColorByClassname = (classname) => {
    const colors = {
      common: "saddlebrown",
      rare: "gray",
      elite: "orange",
      legendary: "cornflowerblue",
      divine: "rgb(50, 229, 94)",
    };

    return colors[classname] || "black";
  };

  const renderSkill = () => {
    const skill = levelUpInfo[currentIndex]?.skill;
    if (!skill) return null;

    return (
      <div className={styles.skillInfo}>
        <Caption className={styles.whiteText}>{t("New skill")}</Caption>
        <div
          className={styles.skillWrapper}
          style={
            skill?.rarity_level
              ? {
                  border: `3px solid ${getColorByClassname(skill?.rarity_level?.toLowerCase())}`,
                }
              : undefined
          }
        >
          <Image
            className={cx(styles.skillIcon)}
            size={48}
            src={skill.image_url}
          />
        </div>
        <Text className={styles.skillName}>{skill.name}</Text>
        <Text className={styles.whiteText}>
          {t("Rarity type")}:&nbsp;
          <Text
            className={styles.rarityLevel}
            style={{
              color: getColorByClassname(skill.rarity_level),
            }}
          >
            {skill.rarity_level}
          </Text>
        </Text>
        {getNonZeroAttributes(skill, t).map(([translatedKey, value]) => (
          <Text key={translatedKey}>
            {`${translatedKey}:`}
            &nbsp;
            <span className={styles.attributeBonus}>+&nbsp;{value}%</span>
          </Text>
        ))}
      </div>
    );
  };

  return (
    <div ref={fireworksContainerRef} className={styles.wrapper}>
      {quest.status !== "completed" && <BloodAnimation />}
      <div className={styles.content}>
        <div className={styles.line}>
          <span className={styles.questName}>{quest.name}</span>
        </div>
        <span
          style={{ color: quest.status === "completed" ? "#07bc0c" : "red" }}
          className={`${styles.status} ${styles.show}`}
        >
          {quest.status === "completed" ? t("completed") : t("failed")}
        </span>
      </div>
      {!!levelUpInfo?.length && showLevelInfo && (
        <>
          <div className={styles.levelInfo}>
            <span className={styles.whiteText}>
              {levelUpInfo[currentIndex].hero_name}
            </span>
            <span
              className={styles.whiteText}
            >{`${t("New level")}: ${levelUpInfo[currentIndex].level}`}</span>
            <div className={styles.attributesList}>
              {renderIncreasedAttributes()}
            </div>
            {renderSkill()}
          </div>
        </>
      )}
      <button
        className={`${styles.resultButton} ${buttonVisible ? styles.show : ""}`}
        onClick={handleNext}
        style={{
          background: quest.status === "completed" ? "#07bc0c" : "red",
        }}
      >
        {currentIndex < levelUpInfo?.length - 1 ? t("next") : t("Close")}
      </button>
    </div>
  );
};

export default ResultModal;
