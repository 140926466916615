import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";
import styles from "./styles.module.scss";
import { FaQuestion, FaSpinner } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const CauldronCards = ({
  chances,
  isLoading,
  newHero,
  handleOpenHeroDetail,
}) => {
  const { t } = useTranslation();

  const [cards, setCards] = useState([
    {
      id: 1,
      image: AsyncPreloadImages.DIVINE_BACK_MAIN,
      text: "divine",
      visible: true,
    },
    {
      id: 2,
      image: AsyncPreloadImages.LEGENDARY_BACK_MAIN,
      text: "legendary",
      visible: true,
    },
    {
      id: 3,
      image: AsyncPreloadImages.ELITE_BACK_MAIN,
      text: "elite",
      visible: true,
    },
    {
      id: 4,
      image: AsyncPreloadImages.RARE_BACK_MAIN,
      text: "rare",
      visible: true,
    },
    {
      id: 5,
      image: AsyncPreloadImages.COMMON_BACK_MAIN,
      text: "common",
      visible: true,
    },
  ]);

  const getChance = (name) => {
    if (!chances?.length) return null;
    const chance = chances.find((item) => item?.slug === name)?.chance;
    return chance !== undefined ? chance : null;
  };

  useEffect(() => {
    if (newHero?.rarity_level) {
      const randomOrder = [...cards.keys()]
        .map((key) => ({ key, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ key }) => key);

      const lastCardIndex = cards.findIndex(
        (card) => card.text === newHero.rarity_level,
      );

      const indicesToAnimate = [
        ...randomOrder.filter((index) => index !== lastCardIndex),
        lastCardIndex,
      ];

      setCards((prevCards) =>
        prevCards.map((card, idx) => {
          const animationIndex = indicesToAnimate.indexOf(idx);
          return {
            ...card,
            visible: false,
            animationIndex,
          };
        }),
      );

      const totalAnimationTime = indicesToAnimate.length * 0.5 + 1.5;

      const timeoutId = setTimeout(() => {
        handleOpenHeroDetail();
      }, totalAnimationTime * 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [newHero]);

  const cardVariants = {
    initial: {
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    hidden: (custom) => ({
      opacity: [1, 0.7, 1, 0.5],
      scale: [1, 0.9, 0.8, 0],
      transition: {
        duration: 1.5,
        delay: custom.animationIndex * 0.5,
        ease: "easeInOut",
      },
    }),
    lastHidden: (custom) => ({
      opacity: [1, 0.7, 1, 0.5],
      scale: [1, 1.05, 1.1, 0],
      transition: {
        duration: 2,
        delay: custom.animationIndex * 0.5,
        ease: "easeInOut",
      },
    }),
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {cards.map((card) => (
          <motion.div
            className={styles.card}
            key={card.id}
            variants={cardVariants}
            custom={{
              isLastCard: card.text === newHero?.rarity_level,
              animationIndex: card.animationIndex || 0,
            }}
            initial="initial"
            animate={
              card.visible
                ? "visible"
                : card.text === newHero?.rarity_level
                  ? "lastHidden"
                  : "hidden"
            }
          >
            <img src={card.image} alt={card.text} />
            {!newHero?.rarity_level && (
              <div className={styles.chanceContainer}>
                <span>{t("Chance")}:</span>
                {isLoading ? (
                  <FaSpinner className={styles.spinner} />
                ) : (
                  <span>
                    {getChance(card.text) !== null ? (
                      `${(getChance(card.text) * 100).toFixed(2)}%`
                    ) : (
                      <FaQuestion size={24} />
                    )}
                  </span>
                )}
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CauldronCards;
