// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalOverlay__UF-zV {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.styles_backButton__YXmMd {
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 20px;
  opacity: 0;
  z-index: 9999;
  cursor: pointer;
  animation: styles_fadeInAnimation__yrg9K 1s forwards 1s;
}

@keyframes styles_fadeInAnimation__yrg9K {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_modalContent__eNu9i {
  max-width: 90%;
  width: 800px;
  height: 80%;
  position: relative;
  display: flex;
}

.styles_closeButton__Llxfs {
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
  color: var(--text-color);
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

.styles_iframe__IzHyX {
  flex-grow: 1;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,aAAA;EACA,eAAA;EACA,uDAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAGA;EACE,cAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AADF;;AAIA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,wBAAA;EACA,+FAAA;AADF;;AAOA;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAJF","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 101;\n}\n\n.backButton {\n  background: white;\n  border-radius: 50%;\n  position: absolute;\n  top: 10px;\n  left: 20px;\n  opacity: 0;\n  z-index: 9999;\n  cursor: pointer;\n  animation: fadeInAnimation 1s forwards 1s;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n\n.modalContent {\n  max-width: 90%;\n  width: 800px;\n  height: 80%;\n  position: relative;\n  display: flex;\n}\n\n.closeButton {\n  position: absolute;\n  top: 5px;\n  right: 10px;\n  background: transparent;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  z-index: 100;\n  color: var(--text-color);\n  text-shadow: -0.5px -0.5px 0 #000,\n  0.5px -0.5px 0 #000,\n  -0.5px 0.5px 0 #000,\n  0.5px 0.5px 0 #000;\n}\n\n.iframe {\n  flex-grow: 1;\n  border: none;\n  width: 100%;\n  height: 100%;\n  border-radius: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `styles_modalOverlay__UF-zV`,
	"backButton": `styles_backButton__YXmMd`,
	"fadeInAnimation": `styles_fadeInAnimation__yrg9K`,
	"modalContent": `styles_modalContent__eNu9i`,
	"closeButton": `styles_closeButton__Llxfs`,
	"iframe": `styles_iframe__IzHyX`
};
export default ___CSS_LOADER_EXPORT___;
