import React from "react";
import styles from "./styles.module.scss";
import { useRegistrationHook } from "../../hooks/Registration/useRegistrationHook";
import { FixedLayout } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import PageTitle from "../../components/PageTitle/PageTitle";

const Registration = () => {
  const { t } = useTranslation();
  const { register, handleKeyDown, handleSubmitData, handleFindReferralCode } =
    useRegistrationHook();

  return (
    <div className={`${styles.registrationContainer} ${styles.fadeIn}`}>
      <PageTitle isNoMargin title={"begin_your_path"} />
      <div className={`${styles.registrationWrapper} ${styles.fadeIn}`}>
        <form
          id="registrationForm"
          className={`${styles.form} ${styles.fadeIn}`}
        >
          <div className={`${styles.inputWrapper} ${styles.fadeIn}`}>
            <label
              htmlFor="username"
              className={`${styles.label} ${styles.fadeIn}`}
            >
              {t("username")}
            </label>
            <input
              id="username"
              className={`${styles.input} ${styles.fadeIn}`}
              {...register("username")}
              placeholder={t("enter_username")}
              onKeyDown={handleKeyDown}
              maxLength={32}
            />
          </div>
          <div className={`${styles.referralWrapper} ${styles.fadeIn}`}>
            <div className={`${styles.inputWrapper} ${styles.fadeIn}`}>
              <label
                htmlFor="referralCode"
                className={`${styles.label} ${styles.fadeIn}`}
              >
                {t("referral_code")}
              </label>
              <input
                className={`${styles.input} ${styles.fadeIn}`}
                {...register("referralCode")}
                placeholder={t("enter_referral_code")}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button
              type="button"
              className={`${styles.referralButton} ${styles.fadeIn}`}
              onClick={handleFindReferralCode}
            >
              {t("find_referral_code")}
            </button>
          </div>
        </form>
      </div>
      <FixedLayout className={`${styles.fixedLayoutBottom} ${styles.fadeIn}`}>
        <TelegramButton size="l" stretched onClick={handleSubmitData}>
          {t("start")}
        </TelegramButton>
      </FixedLayout>
    </div>
  );
};

export default Registration;
