import React, { useState } from "react";
import styles from "./styles.module.scss";
import CustomSheet from "../../ui/Modal/Modal";
import { getCardImagesByRarity } from "../../../utils/imagesGetter";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoaderComponent from "../../ui/LoaderComponent/LoaderComponent";
import { useTranslation } from "react-i18next";

const NewHeroModal = ({ isOpen, setOpen, heroData }) => {
  const { t } = useTranslation();
  const [loadedCount, setLoadedCount] = useState(0);

  const totalImages = 3;

  const handleImageLoad = () => {
    setLoadedCount((prev) => prev + 1);
  };

  const handleImageError = () => {
    setLoadedCount((prev) => prev + 1);
  };

  const isReadyToShow = loadedCount === totalImages;
  return (
    <CustomSheet isOpen={isOpen} onClose={() => setOpen(false)}>
      <div
        className={styles.modalContent}
        style={{
          visibility: isReadyToShow ? "visible" : "hidden",
        }}
      >
        <span>{t("New Hero Unlocked!")}</span>
        {!isReadyToShow && (
          <div className={styles.loader}>
            <LoaderComponent />
          </div>
        )}
        <div className={styles.heroCard}>
          <LazyLoadImage
            alt="mainCard"
            src={getCardImagesByRarity(heroData?.rarity_level).FRONT.MAIN}
            className={styles.cardWrapper}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
          <LazyLoadImage
            alt="texture"
            src={getCardImagesByRarity(heroData?.rarity_level).FRONT.TEXTURE}
            className={styles.cardTexture}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
          <LazyLoadImage
            className={styles.heroImg}
            alt="hero"
            src={heroData?.hero_preview_url}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        </div>
      </div>
    </CustomSheet>
  );
};

export default NewHeroModal;
