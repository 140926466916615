import React from "react";
import styles from "./styles.module.scss";
import HeroInventory from "../../../components/ForHeroes/HeroInventory/HeroInventory";
import { useHeroItems } from "../../../hooks/Pages/useHeroItemsHook";
import cx from "classnames";
import PrizeModal from "../../TreasureOpen/PrizeModal/PrizeModal";

const InventoryItems = ({ isInventory }) => {
  const {
    items,
    isDataLoading,
    lastItemElementRef,
    closeModal,
    selectedItem,
    setSelectedItem,
    inventory,
    handleItemClick,
    filters,
    setFilters,
    userFilters,
    pathname,
    resetFilters,
  } = useHeroItems({
    order_by: "",
    isModalOpen: true,
  });
  return (
    <div className={cx(styles.wrapper, { [styles.overflow]: !!selectedItem })}>
      <HeroInventory
        setSelectedItem={setSelectedItem}
        items={items}
        isDataLoading={isDataLoading}
        lastItemElementRef={lastItemElementRef}
        page={filters.page}
        isInventory={isInventory}
        inventory={inventory}
        handleItemClick={handleItemClick}
        filters={filters}
        setFilters={setFilters}
        userFilters={userFilters}
        pathname={pathname}
        resetFilters={resetFilters}
      />
      {!!selectedItem && (
        <PrizeModal prize={selectedItem} onClose={closeModal} isInventory />
      )}
    </div>
  );
};

export default InventoryItems;
