// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__104H\\+ {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.styles_content__K1ZIG {
  display: flex;
  width: 100%;
  flex: 1 1;
  overflow: hidden;
  z-index: 1;
}

.styles_overlay__D0MVw {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

@keyframes styles_fadeInAnimation__2GHcd {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__wbF8W {
  opacity: 0;
  animation: styles_fadeInAnimation__2GHcd 1s forwards;
}

.styles_blackOverlay__2ier- {
  background-color: rgba(0, 0, 0, 0.6);
}

@media screen and (orientation: portrait) {
  .styles_wrapper__104H\\+ {
    max-width: 500px;
    max-height: 1000px;
  }
}
@media screen and (orientation: landscape) {
  .styles_wrapper__104H\\+ {
    width: 100%;
    height: 100%;
    max-width: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui/DefaultLayout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AACA;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,gBAAA;EACA,UAAA;AAEF;;AACA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;AAEF;;AACA;EACE;IACE,UAAA;EAEF;EAAA;IACE,UAAA;EAEF;AACF;AACA;EACE,UAAA;EACA,oDAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAGA;EACE;IACE,gBAAA;IACA,kBAAA;EAAF;AACF;AAGA;EACE;IACE,WAAA;IACA,YAAA;IACA,eAAA;EADF;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  background-size: cover;\n  background-position: center;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  overflow: hidden;\n}\n.content {\n  display: flex;\n  width: 100%;\n  flex: 1;\n  overflow: hidden;\n  z-index: 1;\n}\n\n.overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.blackOverlay {\n  background-color: rgba(0, 0, 0, 0.6);\n}\n\n\n@media screen and (orientation: portrait) {\n  .wrapper {\n    max-width: 500px;\n    max-height: 1000px;\n  }\n}\n\n@media screen and (orientation: landscape) {\n  .wrapper {\n    width: 100%;\n    height: 100%;\n    max-width: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__104H+`,
	"content": `styles_content__K1ZIG`,
	"overlay": `styles_overlay__D0MVw`,
	"fadeIn": `styles_fadeIn__wbF8W`,
	"fadeInAnimation": `styles_fadeInAnimation__2GHcd`,
	"blackOverlay": `styles_blackOverlay__2ier-`
};
export default ___CSS_LOADER_EXPORT___;
