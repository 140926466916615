import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import paths from "../../pages/paths";
import { useTelegram } from "../Layout/useTelegramHook";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../../services/registrationService";
import { getHero } from "../../services/heroService";
// import useGuide from "./useGuideHook";
// import { getHeroDetailSteps } from "../../utils/guideSteps";

export const useHeroDetailHook = (rouletteHero) => {
  const location = useLocation();
  const hero = location.state.hero ?? rouletteHero;
  const isFromQuest = location?.state?.isFromQuest;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSkill, setActiveSkill] = useState(null);
  const [heroData, setHeroData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserData(dispatch);
    if (!hero) {
      navigate(paths.hero);
      return;
    }
    getHero(hero.id)
      .then((data) => {
        setHeroData(data);
        setActiveSkill(data.skills[0]);
      })
      .finally(() => setIsLoading(false));
  }, [hero, dispatch, navigate]);

  const [searchParams] = useSearchParams();

  const queryParams = {
    page: String(searchParams.get("page") || 1),
    order_by: String(searchParams.get("order_by") || "total_stats_desc"),
    class_ids: String(searchParams.get("class_ids") || ""),
    hero_ids: String(searchParams.get("hero_ids") || ""),
    rarity_level_ids: String(searchParams.get("rarity_level_ids") || ""),
    favorite_desc: String(searchParams.get("favorite_desc") || "enabled"),
    hero_status: String(searchParams.get("hero_status") || "ALL"),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const backPath = isFromQuest ? -1 : `${paths.hero}?${queryString}`;
  const backState = isFromQuest ? undefined : { state: { character: hero } };

  useTelegramBackButton(backPath, backState);

  const handlePrevSkill = () => {
    if (!heroData || !activeSkill) return;
    const skills = heroData.skills;
    const currentIndex = skills.findIndex(
      (skill) => skill.id === activeSkill.id,
    );
    const prevIndex = (currentIndex - 1 + skills.length) % skills.length;
    setActiveSkill(skills[prevIndex]);
  };

  const handleNextSkill = () => {
    if (!heroData || !activeSkill) return;
    const skills = heroData.skills;
    const currentIndex = skills.findIndex(
      (skill) => skill.id === activeSkill.id,
    );
    const nextIndex = (currentIndex + 1) % skills.length;
    setActiveSkill(skills[nextIndex]);
  };

  const handleSkillClick = (skill) => {
    setActiveSkill(skill);
  };

  const displayedSkills = heroData?.skills || [];

  const getColorByClassname = (classname) => {
    const colors = {
      common: "saddlebrown",
      rare: "gray",
      elite: "orange",
      legendary: "cornflowerblue",
      divine: "rgb(50, 229, 94)",
    };

    return colors[classname] || "black";
  };

  const { tg } = useTelegram();
  const isDarkScheme = tg.colorScheme === "dark";

  // const steps = useMemo(() => getHeroDetailSteps(), []);

  // useGuide(steps, null);

  return {
    activeSkill,
    handlePrevSkill,
    handleNextSkill,
    handleSkillClick,
    displayedSkills,
    getColorByClassname,
    isDarkScheme,
    heroData,
    isLoading,
  };
};
