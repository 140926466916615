import { axiosInstance, axiosListInstance } from "./axiosService";

export const getHeroItems = ({
  page = 1,
  pageSize = 50,
  availableForClass = null,
  levelLessOrEqual = null,
  inventorySlot = null,
  is_equipped = null,
  order_by = "total_stats_desc",
}) => {
  const params = {
    page,
    page_size: pageSize,
    ...(order_by ? { order_by: order_by } : {}),
    ...(is_equipped ? { is_equipped: is_equipped } : {}),
    ...(availableForClass ? { available_for_class: availableForClass } : {}),
    ...(levelLessOrEqual ? { level_less_or_equal: levelLessOrEqual } : {}),
    ...(inventorySlot ? { inventory_slot: inventorySlot } : {}),
  };

  return axiosListInstance.get(`/me/user_hero_items`, { params });
};

export const postHeroItem = (heroId, itemId, slot) => {
  return axiosInstance.post(`/me/user_hero_items/add_item_to_inventory`, {
    user_hero_id: heroId,
    user_hero_item_id: itemId,
    inventory_slot: slot,
  });
};
