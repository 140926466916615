// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes styles_fadeInAnimation__7H0SQ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.styles_fadeIn__5suHW {
  opacity: 0;
  animation: styles_fadeInAnimation__7H0SQ 1s forwards;
}

.styles_tasksWrapper__aUv37 {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.styles_tasksList__spFRd {
  flex: 1 1;
  overflow-y: auto;
  border-radius: 16px;
}

.styles_cell__D\\+iiT {
  display: flex;
  align-items: center;
  background: var(--primary-bg);
  border-radius: 16px;
  padding: 12px 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.styles_before__f5mpB {
  flex-shrink: 0;
  margin-right: 16px;
}

.styles_taskImage__J85nm {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.styles_cellMain__kNXQs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
}

.styles_taskTitle__\\+ZObB {
  color: var(--text-color);
  font-size: 1rem;
  margin-bottom: 4px;
  word-break: break-word;
}

.styles_reward__UpAbY {
  color: var(--text-color);
  font-size: 0.9rem;
}

.styles_after__rXmpG {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tasks/styles.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,UAAA;EACA,oDAAA;AAAF;;AAGA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;AAAF;;AAIA;EACE,SAAA;EACA,gBAAA;EACA,mBAAA;AADF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AAFF;;AAKA;EACE,cAAA;EACA,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAFF;;AAKA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,wBAAA;EACA,iBAAA;AAFF;;AAMA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;AAHF","sourcesContent":["@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.fadeIn {\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n}\n\n.tasksWrapper {\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n}\n\n\n.tasksList {\n  flex: 1;\n  overflow-y: auto;\n  border-radius: 16px;\n}\n\n\n.cell {\n  display: flex;\n  align-items: center;\n  background: var(--primary-bg);\n  border-radius: 16px;\n  padding: 12px 16px;\n  margin-bottom: 10px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.before {\n  flex-shrink: 0;\n  margin-right: 16px;\n}\n\n.taskImage {\n  width: 40px;\n  height: 40px;\n  object-fit: contain;\n}\n\n.cellMain {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  padding-right: 16px;\n}\n\n.taskTitle {\n  color: var(--text-color);\n  font-size: 1rem;\n  margin-bottom: 4px;\n  word-break: break-word;\n}\n\n.reward {\n  color: var(--text-color);\n  font-size: 0.9rem;\n}\n\n\n.after {\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fadeIn": `styles_fadeIn__5suHW`,
	"fadeInAnimation": `styles_fadeInAnimation__7H0SQ`,
	"tasksWrapper": `styles_tasksWrapper__aUv37`,
	"tasksList": `styles_tasksList__spFRd`,
	"cell": `styles_cell__D+iiT`,
	"before": `styles_before__f5mpB`,
	"taskImage": `styles_taskImage__J85nm`,
	"cellMain": `styles_cellMain__kNXQs`,
	"taskTitle": `styles_taskTitle__+ZObB`,
	"reward": `styles_reward__UpAbY`,
	"after": `styles_after__rXmpG`
};
export default ___CSS_LOADER_EXPORT___;
