import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBackgroundVisible: true,
  isGuideVisible: true,
  currentStep: 0,
  steps: [],
  position: "bottom",
  tutorials: null,
  isGuider: true,
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    setPosition: (state, action) => {
      state.position = action.payload;
    },
    toggleShowBackground: (state) => {
      state.isBackgroundVisible = true;
    },
    toggleHideBackground: (state) => {
      state.isBackgroundVisible = false;
    },
    showGuide: (state) => {
      state.isGuider = true;
      state.isGuideVisible = true;
      state.position = "bottom";
    },
    hideGuide: (state) => {
      state.isGuideVisible = false;
      state.currentStep = 0;
    },
    nextStep: (state) => {
      if (state.currentStep < state.steps?.length - 1) {
        state.currentStep += 1;
      } else {
        state.isGuideVisible = false;
      }
    },
    resetGuide: (state) => {
      state.currentStep = 0;
      state.isGuideVisible = false;
    },
    updateSteps: (state, action) => {
      state.steps = action.payload.steps;
    },
    setTutorials: (state, action) => {
      state.tutorials = action.payload;
    },
    setIsGuider: (state, action) => {
      state.isGuider = action.payload;
    },
  },
});

export const {
  showGuide,
  hideGuide,
  nextStep,
  resetGuide,
  updateSteps,
  toggleShowBackground,
  toggleHideBackground,
  setPosition,
  setTutorials,
  setIsGuider,
} = guideSlice.actions;

export default guideSlice.reducer;
