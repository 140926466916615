import { axiosInstance } from "./axiosService";

export const updateUserSettings = (settings) => {
  return axiosInstance.patch("/me", { user_settings: settings });
};

export const updateSocialLinks = (settings) => {
  return axiosInstance.patch("/me", { user_settings: settings });
};

export const updateReferralLink = (referralLink) => {
  return updateUserSettings({ ref_code: referralLink });
};
