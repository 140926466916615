import React from "react";
import styles from "./styles.module.scss";

const BloodAnimation = () => {
  return (
    <div className={styles.body}>
      <div className={styles.drop}></div>
      <div className={styles.wave}></div>
    </div>
  );
};

export default BloodAnimation;
