import { getImageUrl } from "../../utils/constants";

export const equipmentSlots = {
  left: ["head", "shoulders", "chest", "hands", "off_hand", "finger_2"],
  right: ["neck", "back", "legs", "feet", "main_hand", "finger_1"],
};

export const getSlotImage = (slotName) => {
  return `${getImageUrl()}inventory/${slotName}.webp`;
};
