import React from "react";
import styles from "./styles.module.scss";
import {
  Avatar,
  Headline,
  Snackbar,
  Subheadline,
} from "@telegram-apps/telegram-ui";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { useReferralHook } from "../../hooks/Pages/useReferralHook";
import ScrollableList from "./List/ScrollableList";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import { FiCopy } from "react-icons/fi";
import { BsArrowUpCircle } from "react-icons/bs";
import { formatAmount } from "../../utils/formatNumbers";
import { PreloadImages } from "../../utils/globals";
import cx from "classnames";

const Referral = () => {
  const { t } = useTranslation();
  const {
    users,
    isLoading,
    handleCopyLink,
    handleShareLink,
    referralAmount,
    loadMoreReferrals,
    page,
    totalEntries,
    colorScheme,
    snackbarOpen,
    handleCloseSnackbar,
    currentStep,
  } = useReferralHook();

  if (isLoading && page === 1) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={`${styles.wrapper} ${styles.fadeIn}`}>
        <Avatar
          className={styles.avatar}
          size={64}
          src={
            colorScheme !== "dark"
              ? PreloadImages.PROFILE_DARK
              : PreloadImages.PROFILE_LIGHT
          }
        />
        <div className={`${styles.column} ${styles.fadeIn}`}>
          <Subheadline level="1" weight="1">
            <span
              className={cx({
                [styles.active]: currentStep === 3,
              })}
            >
              {t("total_friends_invited")}
            </span>
          </Subheadline>
          <Headline weight="1">{formatAmount(totalEntries) || "0"}</Headline>
          <Subheadline
            level="1"
            weight="1"
            style={{ color: "var(--tgui--hint_color)" }}
          >
            <span className={styles.noWrap}>
              {`${formatAmount(referralAmount?.amount) || "0"} ${
                referralAmount?.currency_code
              }`}
            </span>
          </Subheadline>
        </div>
        <div className={`${styles.row} ${styles.fadeIn}`}>
          <InlineButtonsItem
            className={cx(styles.text, {
              [styles.activeNoPadding]: currentStep === 1,
            })}
            onClick={handleCopyLink}
            text={t("copy_referral_link")}
          >
            <FiCopy size={26} />
          </InlineButtonsItem>
          <InlineButtonsItem
            className={cx(styles.text, {
              [styles.activeNoPadding]: currentStep === 2,
            })}
            onClick={handleShareLink}
            text={t("send_via_telegram")}
          >
            <BsArrowUpCircle size={26} />
          </InlineButtonsItem>
        </div>
        <SectionHeader>{t("friends_history")}</SectionHeader>
        <ScrollableList
          users={users}
          loadMore={loadMoreReferrals}
          isLoading={isLoading}
          page={page}
          colorScheme={colorScheme}
        />
      </div>
      {snackbarOpen && (
        <Snackbar
          className={styles.snackbar}
          before={<FiCopy size={20} />}
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          description={t("Copied to clipboard")}
        />
      )}
    </>
  );
};

export default Referral;
