// Transactions.jsx
import React from "react";
import styles from "./styles.module.scss";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import { useTransactionsHook } from "../../hooks/Pages/useTransactionsHook";
import TransactionCell from "./TransactionsCell";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t } = useTranslation();
  const {
    transactions,
    isDataLoading,
    page,
    getIsSentTransaction,
    lastTransactionElementRef,
    isLoading,
    getDate,
  } = useTransactionsHook();

  if (isLoading || (isDataLoading && page === 1)) {
    return <LoaderComponent />;
  }

  return (
    <div className={cx(styles.transactionsWrapper, styles.fadeIn)}>
      <div className={styles.title}>{t("Transaction history")}</div>
      {!transactions?.length && !isDataLoading && (
        <div className={styles.empty}>{t("No transactions found")}</div>
      )}
      {!!transactions?.length && (
        <div className={styles.list}>
          {transactions.map((transaction, index) => (
            <TransactionCell
              key={transaction.id}
              transaction={transaction}
              isLast={index === transactions?.length - 10}
              refCallback={lastTransactionElementRef}
              getDate={getDate}
              getIsSentTransaction={getIsSentTransaction}
              t={t}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Transactions;
