// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_languageSwitcher__I86sy {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.styles_languageSwitcher__I86sy > div {
  width: 100%;
}

.styles_switch__iOeBX input {
  opacity: 0;
  width: 0;
  height: 0;
}

.styles_select__Kdzyf {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LanguageSwitcher/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;AAEJ;;AAEA;EACE,UAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".languageSwitcher {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  > div {\n    width: 100%;\n  }\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.select {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSwitcher": `styles_languageSwitcher__I86sy`,
	"switch": `styles_switch__iOeBX`,
	"select": `styles_select__Kdzyf`
};
export default ___CSS_LOADER_EXPORT___;
