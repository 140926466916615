import paths from "../pages/paths";
import Settings from "../pages/Settings/Settings";
import Rating from "../pages/Rating/Rating";
import Market from "../pages/Market/Market";
import MainPage from "../pages/MainPage/MainPage";
import Task from "../pages/Task/Task";
import Profile from "../pages/Profile/Profile";
import Tasks from "../pages/Tasks/Tasks";
import Referral from "../pages/Referral/Referral";
import Giveaways from "../pages/Giveaways/Giveaways";
import Giveaway from "../pages/Giveaway/Giveaway";
import MarketItem from "../pages/MarketItem/MarketItem";
import Inventory from "../pages/Inventory/Inventory";
import Heroes from "../pages/Heroes/Heroes";
import Hero from "../pages/Hero/Hero";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Transactions from "../pages/Transactions/Transactions";
import Roulette from "../components/Roulette/Roulette";
import HeroDetail from "../pages/HeroDetail/HeroDetail";
import Quests from "../pages/Quests/Quests";
import Quest from "../pages/Quest/Quest";
import QuestsHistory from "../pages/QuestsHistory/QuestsHistory";
import TreasureOpen from "../pages/TreasureOpen/TreasureOpen";
import Cauldron from "../pages/Cauldron/Cauldron";

const routesConfig = [
  {
    path: paths.index,
    component: MainPage,
    requiresAuth: true,
  },
  {
    path: paths.hero,
    component: Hero,
    requiresAuth: true,
  },
  {
    path: paths.heroDetail,
    component: HeroDetail,
    requiresAuth: true,
  },
  {
    path: paths.heroes,
    component: Heroes,
    requiresAuth: true,
  },
  {
    path: paths.referral,
    component: Referral,
    requiresAuth: true,
  },
  {
    path: paths.giveaways,
    component: Giveaways,
    requiresAuth: true,
  },
  {
    path: paths.giveaway,
    component: Giveaway,
    requiresAuth: true,
  },
  {
    path: paths.settings,
    component: Settings,
    requiresAuth: true,
  },
  {
    path: paths.rating,
    component: Rating,
    requiresAuth: true,
  },
  {
    path: paths.market,
    component: Market,
    requiresAuth: true,
  },
  {
    path: paths.marketItem,
    component: MarketItem,
    requiresAuth: true,
  },
  {
    path: paths.tasks,
    component: Tasks,
    requiresAuth: true,
  },
  {
    path: paths.task,
    component: Task,
    requiresAuth: true,
  },
  {
    path: paths.profile,
    component: Profile,
    requiresAuth: true,
  },
  {
    path: paths.inventory,
    component: Inventory,
    requiresAuth: true,
  },
  {
    path: paths.error,
    component: ErrorPage,
    requiresAuth: true,
  },
  {
    path: paths.transactions,
    component: Transactions,
    requiresAuth: true,
  },
  {
    path: paths.roulette,
    component: Roulette,
    requiresAuth: true,
  },
  {
    path: paths.quests,
    component: Quests,
    requiresAuth: true,
  },
  {
    path: paths.quest,
    component: Quest,
    requiresAuth: true,
  },
  {
    path: paths.questsHistory,
    component: QuestsHistory,
    requiresAuth: true,
  },
  {
    path: paths.treasureOpen,
    component: TreasureOpen,
    requiresAuth: true,
  },
  {
    path: paths.cauldron,
    component: Cauldron,
    requiresAuth: true,
  },
];

export default routesConfig;
