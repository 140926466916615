// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_questsBanner__-ZnbX {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #000;
  font-size: 0.7rem;
  cursor: pointer;
  opacity: 0;
  animation: styles_fadeInAnimation__TCSn5 1s forwards;
  height: 48px;
  background-size: cover !important;
}

.styles_text__jZOlA {
  color: white;
  text-shadow: -1px -1px 0 #000;
}

.styles_bannerOverlay__kYorr {
  padding: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.2);
}

@keyframes styles_fadeInAnimation__TCSn5 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
:root {
  --fcc-flip-duration: 0.5s !important;
  --fcc-spacing: 8px !important;
  --fcc-digit-block-width: 20px !important;
  --fcc-digit-block-height: 25px !important;
  --fcc-digit-block-radius: 5px !important;
  --fcc-digit-block-spacing: 5px !important;
  --fcc-digit-font-size: 12px !important;
  --fcc-digit-color: var(--tg-theme-link-color) !important;
  --fcc-background: #333 !important;
  --fcc-divider-color: transparent !important;
  --fcc-divider-height: 1px !important;
  --fcc-separator-size: 6px !important;
  --fcc-separator-color: var(--tg-theme-link-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ForMainPage/QuestsBanner/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;EACA,oDAAA;EACA,YAAA;EACA,iCAAA;AACF;;AAEA;EACE,YAAA;EACA,6BAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,6BAAA;EACA,oCAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,oCAAA;EACA,6BAAA;EACA,wCAAA;EACA,yCAAA;EACA,wCAAA;EACA,yCAAA;EACA,sCAAA;EACA,wDAAA;EACA,iCAAA;EACA,2CAAA;EACA,oCAAA;EACA,oCAAA;EACA,4DAAA;AAAF","sourcesContent":[".questsBanner {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n  color: #000;\n  font-size: 0.7rem;\n  cursor: pointer;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n  height: 48px;\n  background-size: cover !important;\n}\n\n.text {\n  color: white;\n  text-shadow: -1px -1px 0 #000;\n}\n\n.bannerOverlay {\n  padding: 4px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  align-items: center;\n  justify-content: space-around;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n:root {\n  --fcc-flip-duration: 0.5s !important;\n  --fcc-spacing: 8px !important;\n  --fcc-digit-block-width: 20px !important;\n  --fcc-digit-block-height: 25px !important;\n  --fcc-digit-block-radius: 5px !important;\n  --fcc-digit-block-spacing: 5px !important;\n  --fcc-digit-font-size: 12px !important;\n  --fcc-digit-color: var(--tg-theme-link-color) !important;\n  --fcc-background: #333 !important;\n  --fcc-divider-color: transparent !important;\n  --fcc-divider-height: 1px !important;\n  --fcc-separator-size: 6px !important;\n  --fcc-separator-color: var(--tg-theme-link-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questsBanner": `styles_questsBanner__-ZnbX`,
	"fadeInAnimation": `styles_fadeInAnimation__TCSn5`,
	"text": `styles_text__jZOlA`,
	"bannerOverlay": `styles_bannerOverlay__kYorr`
};
export default ___CSS_LOADER_EXPORT___;
