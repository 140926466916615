import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import {
  getMeMarketItem,
  getMeMarketItems,
  openMarketItems,
} from "../../services/marketService";
import useTelegramBackButton from "../../hooks/Layout/useTelegramBackButton";
import { fetchUserData } from "../../services/registrationService";
import { useDispatch } from "react-redux";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import PageTitle from "../../components/PageTitle/PageTitle";
import paths from "../paths";
import PrizeModal from "./PrizeModal/PrizeModal";
import { useTranslation } from "react-i18next";

const TreasureOpen = () => {
  useTelegramBackButton(-1);
  const location = useLocation();
  const { t } = useTranslation();
  const id = location.state?.id;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemId, setItemId] = useState(id);
  const [prize, setPrize] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [marketItem, setMarketItem] = useState(null);
  const [marketItemsCount, setMarketItemsCount] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);

  useEffect(() => {
    fetchUserData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!itemId) return;
    setIsLoading(true);
    getMeMarketItem(itemId)
      .then((data) => {
        if (data.is_used) {
          navigate(-1);
          return;
        }
        setMarketItem(data);
        return getMeMarketItems(data.market_item.id);
      })
      .then((items) => {
        setMarketItemsCount(items?.length || 0);
      })
      .catch((error) => {
        console.error("Failed to fetch market items", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleOpenTreasure = () => {
    if (isNoMore) {
      navigate(paths.marketItem, {
        state: { id: marketItem.market_item.id, isFromRoulette: true },
      });
      return;
    }

    setIsButtonLoading(true);
    openMarketItems(itemId)
      .then((data) => {
        setPrize(data);
        return getMeMarketItems(marketItem.market_item.id);
      })
      .then((items) => {
        if (items?.length > 0) {
          setMarketItemsCount(items.length);
          const newItem = items[0];
          setItemId(newItem.id);
          setMarketItem(newItem);
        } else {
          setIsNoMore(true);
          setMarketItemsCount(0);
        }
      })
      .catch((error) => {
        console.error("Failed to open treasure", error);
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  };

  const handleCloseModal = () => {
    setPrize(null);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.wrapper}>
      <PageTitle isNoMargin title={marketItem?.market_item?.title} />
      <img
        className={styles.image}
        alt={"box"}
        src={marketItem?.market_item?.image_url}
      />
      <span>{marketItem?.market_item?.description}</span>
      {!!marketItemsCount && <span>{`${t("left")}: ${marketItemsCount}`}</span>}
      <TelegramButton
        disabled={isButtonLoading}
        className={styles.button}
        onClick={handleOpenTreasure}
      >
        {isNoMore ? t("Buy more") : t("Open")}
      </TelegramButton>
      {prize && <PrizeModal prize={prize} onClose={handleCloseModal} />}
    </div>
  );
};

export default TreasureOpen;
