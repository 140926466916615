// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_cauldron__E5Z8U {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.styles_icon__--j0d {
  height: 19vh;
  width: auto;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
}`, "",{"version":3,"sources":["webpack://./src/components/ForCauldron/CauldronImage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,yDAAA;AACF","sourcesContent":[".cauldron {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.icon {\n  height: 19vh;\n  width: auto;\n  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cauldron": `styles_cauldron__E5Z8U`,
	"icon": `styles_icon__--j0d`
};
export default ___CSS_LOADER_EXPORT___;
