import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

const QuestAttributes = ({ quest, attributeIcons, className, diff }) => {
  const attributes = {
    strength: quest.strength || 0,
    agility: quest.agility || 0,
    intelligence: quest.intelligence || 0,
    endurance: quest.endurance || 0,
    charisma: quest.charisma || 0,
    luck: quest.luck || 0,
  };

  const fullAttributes = {
    ...attributes,
    ...Object.keys(diff || {}).reduce((acc, key) => {
      if (!attributes[key]) acc[key] = 0;
      return acc;
    }, {}),
  };

  return (
    <div className={cx(styles.attributesRow, className)}>
      {Object.entries(fullAttributes)
        .filter(([key, value]) => value > 0 || (diff?.[key] ?? 0) !== 0)
        .map(([key, value]) => {
          const diffValue = diff?.[key] || 0;
          return (
            <div key={key} className={styles.attribute}>
              <img
                height="20px"
                width="20px"
                src={attributeIcons[key] || ""}
                alt={key}
              />
              <span className={styles.text}>{value}</span>
              {diffValue !== 0 && (
                <span
                  className={cx(
                    diffValue > 0 ? styles.positive : styles.negative,
                  )}
                >
                  {diffValue > 0 ? `+${diffValue}` : diffValue}
                </span>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default QuestAttributes;
