import React from "react";
import LoaderComponent from "../../components/ui/LoaderComponent/LoaderComponent";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PageTitle from "../../components/PageTitle/PageTitle";
import NFTList from "./NFT";
import InventoryItems from "./InventoryItems/InventoryItems";
import CustomSheet from "../../components/ui/Modal/Modal";
import CauldronDetail from "../CauldronDetail/CauldronDetail";
import { useInventoryHook } from "../../hooks/Pages/useInventoryHook";
import Boxes from "./Boxes";
import SegmentedControl, {
  SegmentedControlItem,
} from "../../components/SegmentControl/SegmentControl";

const Inventory = () => {
  const {
    activeType,
    handleSetActiveType,
    navigateToMarket,
    isModalOpen,
    isLoading,
    marketItems,
    navigateToRoulette,
    setIsModalOpen,
    activeItem,
  } = useInventoryHook();
  const { t } = useTranslation();

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className={cx(styles.inventoryWrapper, styles.fadeIn)}>
        <PageTitle isNoMargin title={t("Inventory")} />
        <div>
          <SegmentedControl>
            <SegmentedControlItem
              selected={activeType === "boxes"}
              onClick={() => handleSetActiveType("boxes")}
            >
              {t("boxes")}
            </SegmentedControlItem>
            <SegmentedControlItem
              selected={activeType === "nft"}
              onClick={() => handleSetActiveType("nft")}
            >
              NFT
            </SegmentedControlItem>
            <SegmentedControlItem
              selected={activeType === "items"}
              onClick={() => handleSetActiveType("items")}
            >
              {t("Items")}
            </SegmentedControlItem>
          </SegmentedControl>
        </div>

        {activeType === "items" && <InventoryItems isInventory />}
        {activeType === "nft" && <NFTList />}
        {activeType === "boxes" && (
          <Boxes
            navigateToMarket={navigateToMarket}
            t={t}
            marketItems={marketItems}
            navigateToRoulette={navigateToRoulette}
          />
        )}
      </div>
      <CustomSheet isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CauldronDetail data={activeItem} t={t} />
      </CustomSheet>
    </>
  );
};

export default Inventory;
