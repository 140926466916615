import React from "react";
import styles from "./styles.module.scss";
import { getGradientByClassName } from "../../../utils/imagesGetter";
import { getSlotImage } from "../utils";

const ItemSlot = ({ item, slotName, handleOpenModal }) => {
  return (
    <div
      onClick={handleOpenModal}
      className={styles.itemSlot}
      style={{ backgroundImage: getGradientByClassName(item?.rarity_level) }}
    >
      {item ? (
        <>
          <img
            width="30px"
            height="30px"
            src={item?.item_image_url}
            alt={item?.item_name}
            className={styles.itemImage}
          />
          <span className={styles.itemLevel}>Lv.{item?.level}</span>
        </>
      ) : (
        <div
          style={{
            backgroundImage: `radial-gradient(circle, rgb(204, 204, 204) 0%, black 100%)`,
          }}
          className={styles.emptySlot}
        >
          <img
            className={styles.emptySlotImg}
            src={getSlotImage(slotName)}
            alt={"slot"}
          />
        </div>
      )}
    </div>
  );
};

export default ItemSlot;
