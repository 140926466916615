import React from "react";
import styles from "./common.module.scss";
import Button from "../../components/ui/Button/Button";
import { useTranslation } from "react-i18next";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import CustomSheet from "../../components/ui/Modal/Modal";

const SuccessModal = ({
  isSuccessModalOpen,
  setIsSuccessModalOpen,
  handleOpenRoulette,
  activeItemImage,
}) => {
  const { t } = useTranslation();

  return (
    <CustomSheet
      isOpen={isSuccessModalOpen}
      onClose={() => {
        setIsSuccessModalOpen(false);
      }}
    >
      <div className={styles.successModalContent}>
        <span className={styles.title}>
          {t("Chest purchased! Time to see what's inside.")}
        </span>
        <div className={styles.relative}>
          <img
            className={styles.image}
            src={AsyncPreloadImages.PURCHASED_ICON}
            alt={t("Purchased item")}
          />
          <img
            alt={"marketItem"}
            src={activeItemImage}
            className={styles.marketItemImage}
          />
        </div>
        <Button className={styles.button} onClick={() => handleOpenRoulette()}>
          {t("Open Now")}
        </Button>
      </div>
    </CustomSheet>
  );
};

export default SuccessModal;
