import React, { useState } from "react";
import i18n from "i18next";
import styles from "./styles.module.scss";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import { useTelegram } from "../../../hooks/Layout/useTelegramHook";
import { updateUserLanguage } from "../../../services/walletService";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = ({
  activeLanguage,
  setActiveLanguage,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const { tg } = useTelegram();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleChangeLanguage = async (languageCode) => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    setIsLoading(true);
    try {
      await updateUserLanguage(languageCode);
      await i18n.changeLanguage(languageCode);
      setActiveLanguage(languageCode);
    } catch (error) {
      console.error("Failed to update language:", error);
    } finally {
      setIsLoading(false);
    }
    setIsPopupOpen(false);
  };

  const getLanguageText = (language) => {
    switch (language) {
      case "ru":
        return t("Russian");
      case "uk":
        return t("Ukrainian");
      case "it":
        return t("Italian");
      case "de":
        return t("German");
      default:
        return t("English");
    }
  };

  const languageText = getLanguageText(activeLanguage);

  return (
    <div className={styles.languageSwitcher}>
      <Popup
        keepTooltipInside
        arrow={false}
        trigger={
          <button className={styles.triggerButton}>{languageText}</button>
        }
        position={"bottom center"}
        className={styles.popupWrapper}
        onClose={() => setIsPopupOpen(false)}
        onOpen={() => setIsPopupOpen(true)}
        open={isPopupOpen}
      >
        <div className={styles.popupContent}>
          <div className={styles.title}>
            <span>{t("Select a language")}</span>
          </div>
          <div className={styles.languageOptions}>
            <button onClick={() => handleChangeLanguage("en")}>
              {t("English")}
            </button>
            <button onClick={() => handleChangeLanguage("ru")}>
              {t("Russian")}
            </button>
            <button onClick={() => handleChangeLanguage("uk")}>
              {t("Ukrainian")}
            </button>
            <button onClick={() => handleChangeLanguage("it")}>
              {t("Italian")}
            </button>
            <button onClick={() => handleChangeLanguage("de")}>
              {t("German")}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default LanguageSwitcher;
