import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTelegramBackButton from "../Layout/useTelegramBackButton";
import { useTelegram } from "../Layout/useTelegramHook";
import { useEffect, useState } from "react";
import { useSocket } from "../../utils/socketContext";
import { getCauldronChances } from "../../services/cauldronService";
import { fetchUserData } from "../../services/registrationService";
import { getHero } from "../../services/heroService";
import { getUserWallets } from "../../services/userService";
import { buyCauldronMarketItem } from "../../services/marketService";
import { showErrorToast } from "../../components/ui/Toast/Toast";
import paths from "../../pages/paths";

export const useCauldronHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);

  useTelegramBackButton(-1);

  const { tg } = useTelegram();

  const [isSelect, setIsSelect] = useState(false);
  const [heroSlots, setHeroSlots] = useState([null, null, null]);
  const [isOpen, setOpen] = useState(false);
  const [newHero, setNewHero] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userAmount, setUserAmount] = useState(null);

  const { userChannel } = useSocket();
  const [cauldronData, setCauldronData] = useState(null);

  useEffect(() => {
    if (heroSlots.every((hero) => hero !== null)) {
      getCauldronChances(heroSlots.map((item) => item.id)).then((r) => {
        setCauldronData(r);
      });
    }
  }, [heroSlots]);

  useEffect(() => {
    fetchUserData(dispatch).finally(() => setIsLoading(false));
    fetchUserWallets();
  }, []);

  useEffect(() => {
    if (!userChannel) return;

    userChannel.onMessage = (event, payload) => {
      if (payload && payload?.user_hero_id) {
        getHero(payload?.user_hero_id)
          .then((r) => {
            setNewHero(r);
          })
          .finally(() => setOpen(false));
      }
      return payload;
    };
  }, [userChannel]);

  const fetchUserWallets = async () => {
    try {
      const response = (await getUserWallets()) || [];
      const wallet = response?.find(
        (wallet) => wallet?.balance?.currency_code === "PATH",
      );
      if (wallet) {
        setUserAmount(wallet?.balance?.amount);
      }
    } catch (error) {
      console.error("Error fetching user wallets:", error);
    }
  };

  const handleOpenSelect = () => {
    if (newHero?.id) {
      if (hapticEnabled) tg.HapticFeedback.notificationOccurred("error");
      return;
    }
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (areAllHeroesSelected) setHeroSlots([null, null, null]);
    if (cauldronData?.chances?.length) setCauldronData(null);
    setIsSelect(true);
  };

  const handleCloseSelect = () => {
    setIsSelect(false);
  };

  const handleHeroesSelect = (selectedHeroes) => {
    setHeroSlots(selectedHeroes);
    setIsSelect(false);
  };

  const handleCauldronClick = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    if (!areAllHeroesSelected) {
      setIsSelect(true);
    } else {
      setOpen(true);
    }
  };

  const handleBuyStarMarketItem = () => {
    buyCauldronMarketItem(
      starMarketItem?.id,
      heroSlots.map((item) => item.id),
    ).then((r) => {
      tg.openTelegramLink(r.payment_url);
    });
  };

  const handleBuyMarketItem = () => {
    buyCauldronMarketItem(
      pathMarketItem.id,
      heroSlots.map((item) => item.id),
    )
      .then((hero) => {
        getHero(hero?.id ? hero.id : hero).then((r) => {
          setNewHero(r);
        });
      })
      .catch((e) => {
        if (e.response?.data?.message === "Insufficient funds") {
          showErrorToast(t("insufficientFunds"));
        }
      });
    setOpen(false);
  };

  const handleOpenHeroDetail = () => {
    if (hapticEnabled) tg.HapticFeedback.selectionChanged();
    navigate(paths.heroDetail, {
      state: { hero: newHero, isFromQuest: true, isNewHero: true },
    });
  };

  const pathMarketItem = cauldronData?.market_items?.find(
    (item) => item?.price?.currency_code === "PATH",
  );

  const starMarketItem = cauldronData?.market_items?.find(
    (item) => item?.price?.currency_code === "STAR",
  );

  const areAllHeroesSelected = heroSlots.every((hero) => hero !== null);
  return {
    isLoading,
    isSelect,
    cauldronData,
    newHero,
    heroSlots,
    handleOpenSelect,
    areAllHeroesSelected,
    handleCauldronClick,
    handleOpenHeroDetail,
    handleCloseSelect,
    hapticEnabled,
    tg,
    t,
    isOpen,
    setOpen,
    handleBuyStarMarketItem,
    handleBuyMarketItem,
    handleHeroesSelect,
    userAmount,
    starMarketItem,
    pathMarketItem,
  };
};
