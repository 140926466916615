import { axiosInstance, axiosListInstance } from "./axiosService";

export const getHeroItems = ({
  page = 1,
  pageSize = 50,
  availableForClass = null,
  levelLessOrEqual = null,
  inventorySlot = null,
  is_equipped = undefined, // Теперь undefined используется для отсутствия параметра
  order_by = "total_stats_desc",
  filters = {},
}) => {
  const params = {
    page,
    page_size: pageSize,
    ...(order_by ? { order_by: order_by } : {}),
    ...(is_equipped !== undefined ? { is_equipped: is_equipped } : {}), // Передаем параметр, только если он задан
    ...(availableForClass || filters.selectedClass
      ? { available_for_class: availableForClass || filters.selectedClass }
      : {}),
    ...(levelLessOrEqual || filters.selectedRange
      ? {
          level_less_or_equal: levelLessOrEqual || filters.selectedRange?.[1],
          level_greater_or_equal: filters.selectedRange?.[0],
        }
      : {}),
    ...(inventorySlot || filters.selectedSlots?.length
      ? { inventory_slot: inventorySlot || filters.selectedSlots }
      : {}),
    ...(filters.selectedRarities?.length
      ? { rarity_level_ids: filters.selectedRarities }
      : {}),
  };

  return axiosListInstance.get(`/me/user_hero_items`, { params });
};

export const postHeroItem = (heroId, itemId, slot) => {
  return axiosInstance.post(`/me/user_hero_items/add_item_to_inventory`, {
    user_hero_id: heroId,
    user_hero_item_id: itemId,
    inventory_slot: slot,
  });
};

export const getHeroesFilters = () => {
  return axiosInstance.get(`/user_hero_items_filters`);
};

export const getItemsDiff = (userHeroId, inventorySlot, itemId) => {
  return axiosInstance.get(`/me/user_hero_items/diff/${itemId}`, {
    params: {
      user_hero_id: userHeroId,
      inventory_slot: inventorySlot,
    },
  });
};

export const getItemsBadges = (level_less_or_equal, available_for_class) => {
  return axiosInstance.get(`/me/user_hero_items/count_by_inventory_slot`, {
    params: {
      level_less_or_equal: level_less_or_equal,
      available_for_class: available_for_class,
      is_equipped: "f",
    },
  });
};
