import React from "react";
import styles from "./styles.module.scss";

const CustomCheckbox = ({ checked, onChange, label }) => {
  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
        className={styles.hiddenCheckbox}
      />
      <span className={styles.customCheckbox}></span>
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
};

export default CustomCheckbox;
