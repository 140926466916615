// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalHeader__RjVSb {
  padding: 16px 16px 0 16px;
}

.styles_cell__-gbQX:hover {
  background: transparent;
}

.styles_row__9-lsf {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/SortModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AACF","sourcesContent":[".modalHeader {\n  padding: 16px 16px 0 16px;\n}\n\n.cell:hover {\n  background: transparent;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n  padding: 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": `styles_modalHeader__RjVSb`,
	"cell": `styles_cell__-gbQX`,
	"row": `styles_row__9-lsf`
};
export default ___CSS_LOADER_EXPORT___;
