import { AsyncPreloadImages } from "./asyncPreload";

export const getCharImagesByRarity = (attribute) => {
  const attributeKey = `${attribute.toUpperCase()}_ICON`;
  return AsyncPreloadImages[attributeKey] || "";
};

export const getCardImagesByRarity = (rarityLevel) => {
  if (!rarityLevel) return "";
  const rarity = rarityLevel?.toUpperCase();
  return {
    BACK: {
      MAIN: AsyncPreloadImages[`${rarity}_BACK_MAIN`],
      PREVIEW: AsyncPreloadImages[`${rarity}_BACK_PREVIEW`],
    },
    FRONT: {
      MAIN: AsyncPreloadImages[`${rarity}_FRONT_MAIN`],
      PREVIEW: AsyncPreloadImages[`${rarity}_FRONT_PREVIEW`],
      TEXTURE: AsyncPreloadImages[`${rarity}_FRONT_TEXTURE`],
      TEXTURE_PREVIEW: AsyncPreloadImages[`${rarity}_FRONT_TEXTURE_PREVIEW`],
    },
  };
};

export const getLeaderImage = (index) => {
  if (index === 1) return AsyncPreloadImages.LEADERBOARD_FIRST;
  if (index === 2) return AsyncPreloadImages.LEADERBOARD_SECOND;
  if (index === 3) return AsyncPreloadImages.LEADERBOARD_THIRD;
  if (index > 3 && index <= 51) return AsyncPreloadImages.LEADERBOARD_STONE;
  if (index > 51) return AsyncPreloadImages.LEADERBOARD_WOOD;
  return null;
};

export const getColorByClassName = (classname) => {
  const colors = {
    common: "saddlebrown",
    rare: "silver",
    elite: "gold",
    legendary: "cornflowerblue",
    divine: "rgb(50, 229, 94)",
  };

  return colors[classname] || "black";
};

export const getGradientByClassName = (classname) => {
  const colors = {
    common: "saddlebrown",
    rare: "silver",
    elite: "gold",
    legendary: "cornflowerblue",
    divine: "rgb(50, 229, 94)",
  };

  const baseColor = colors[classname] || "black";

  return `radial-gradient(circle, white 0%, ${baseColor} 100%)`;
};

export const getSortText = (sortOption, t) => {
  switch (sortOption) {
    case "total_stats_desc":
      return { text: t("Strongest"), direction: "up" };
    case "total_stats_asc":
      return { text: t("Weakest"), direction: "down" };
    case "experience_asc":
      return { text: t("Least Experienced"), direction: "down" };
    case "experience_desc":
      return { text: t("Most Experienced"), direction: "up" };
    case "inserted_at_desc":
      return { text: t("Newest"), direction: "up" }; // No icon
    case "inserted_at_asc":
      return { text: t("Oldest"), direction: "down" }; // No icon
    case "strength_desc":
      return { text: t("Strength"), direction: "up" };
    case "agility_desc":
      return { text: t("Agility"), direction: "up" };
    case "intelligence_desc":
      return { text: t("Intelligence"), direction: "up" };
    case "endurance_desc":
      return { text: t("Endurance"), direction: "up" };
    case "charisma_desc":
      return { text: t("Charisma"), direction: "up" };
    case "luck_desc":
      return { text: t("Luck"), direction: "up" };
    default:
      return { text: t("Unknown parameter"), direction: null };
  }
};

export const getSortOptions = (t) => [
  { label: t("Strongest First"), value: "total_stats_desc" },
  { label: t("Weakest First"), value: "total_stats_asc" },
  { label: t("Highest Level"), value: "experience_desc" },
  { label: t("Lowest Level"), value: "experience_asc" },
  { label: t("Newest First"), value: "inserted_at_desc" },
  { label: t("Oldest First"), value: "inserted_at_asc" },
];

export const getAttributeOptions = (t) => [
  { label: t("Strength"), value: "strength_desc" },
  { label: t("Agility"), value: "agility_desc" },
  { label: t("Intelligence"), value: "intelligence_desc" },
  { label: t("Endurance"), value: "endurance_desc" },
  { label: t("Charisma"), value: "charisma_desc" },
  { label: t("Luck"), value: "luck_desc" },
];
