import React from "react";
import styles from "./styles.module.scss";
import HeroCard from "../../components/ForHeroes/HeroCard/HeroCard";
import cx from "classnames";
import { useHeroHook } from "../../hooks/Pages/useHeroHook";
import CoinLoader from "../../components/CoinLoader/CoinLoader";
import AttributesRow from "../../components/ForHeroes/AttributesRow/AttributesRow";
import HeroCardTopRow from "../../components/ForHeroes/HeroCardTopRow/HeroCardTopRow";
import LoaderComponent from "../../components/common/LoaderComponent/LoaderComponent";
import HeroInventory from "../../components/ForHeroes/HeroInventory/HeroInventory";
import ItemSlot from "../../components/ForHeroes/ItemSlot/ItemSlot";
import { equipmentSlots } from "../../components/ForHeroes/utils";
import { useHeroItems } from "../../hooks/Pages/useHeroItemsHook";
import { Modal } from "@telegram-apps/telegram-ui";
import PrizeModal from "../TreasureOpen/PrizeModal/PrizeModal";
import InfoModal from "../../components/InfoModal/InfoModal";
import NftModal from "../../components/NFTModal/NFTModal";

const Hero = () => {
  const {
    heroData,
    allImagesLoaded,
    handleOpenDetailPage,
    allLoaded,
    isImageLoaded,
    handleImageLoad,
    handleImageError,
    isLoading,
    swipeHandlers,
    updateHero,
    onClickFavorite,
    currentStep,
    handlePutOnItem,
    selectedItem,
    setSelectedItem,
    isMinting,
    handleMint,
    currentInventorySlot,
    isModalOpen,
    handleOpenModal,
    isMainModalOpen,
    closeModal,
    closeMainModal,
    setIsModalOpen,
    userHeroItems,
    handleItemClick,
    setCurrentInventorySlot,
    isUserWallet,
    isInfoModalOpen,
    handleCloseInfoModal,
    iframe,
    isNFTModalOpen,
    setIsNFTModalOpen,
    handleOpenNFTModal,
  } = useHeroHook();

  const {
    items,
    isDataLoading,
    lastItemElementRef,
    page,
    refreshItems,
    inventory,
  } = useHeroItems({
    currentInventorySlot,
    classType: heroData?.class_id,
    heroLevel: heroData?.level,
  });

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <LoaderComponent />
      </div>
    );
  }

  if (!heroData) {
    return null;
  }

  const onClick = (id) => {
    const isSlotEquipped =
      heroData?.user_hero_items?.[currentInventorySlot]?.[0]?.id === id;
    handlePutOnItem(isSlotEquipped ? null : id, currentInventorySlot)
      .then(() => {
        refreshItems();
        setSelectedItem(null);
        if (selectedItem) setSelectedItem(null);
        if (currentInventorySlot) setCurrentInventorySlot(null);
        if (isModalOpen) setIsModalOpen(false);
        closeMainModal();
      })
      .catch(() => {
        alert("Какая-то ошибка");
      });
  };

  return (
    <>
      {!allImagesLoaded && (
        <div className={styles.notLoaded}>
          <CoinLoader />
        </div>
      )}
      <div className={cx(styles.hero, styles.fadeIn)} {...swipeHandlers}>
        {allLoaded && (
          <HeroCardTopRow
            character={heroData}
            allImagesLoaded={allImagesLoaded}
            currentStep={currentStep}
          />
        )}
        <div className={styles.heroWrapper}>
          {allLoaded && (
            <div className={cx(styles.leftItems, { [styles.gray]: isMinting })}>
              {equipmentSlots.left.map((slot) => (
                <ItemSlot
                  key={slot}
                  handleOpenModal={() => handleOpenModal(slot)}
                  item={userHeroItems[slot] ? userHeroItems[slot][0] : null}
                  slotName={slot}
                />
              ))}
            </div>
          )}

          <HeroCard
            handleOpenDetailPage={handleOpenDetailPage}
            character={heroData}
            allLoaded={allLoaded}
            isImageLoaded={isImageLoaded}
            handleImageLoad={handleImageLoad}
            handleImageError={handleImageError}
            updateHero={updateHero}
            onClickFavorite={onClickFavorite}
            currentStep={currentStep}
            isMinting={isMinting}
            handleMint={handleOpenNFTModal}
            isUserWallet={isUserWallet}
          />

          {allLoaded && (
            <div
              className={cx(styles.rightItems, { [styles.gray]: isMinting })}
            >
              {equipmentSlots.right.map((slot) => (
                <ItemSlot
                  key={slot}
                  slotName={slot}
                  item={userHeroItems[slot] ? userHeroItems[slot][0] : null}
                  handleOpenModal={() => handleOpenModal(slot)}
                />
              ))}
            </div>
          )}
        </div>
        <AttributesRow character={heroData} allImagesLoaded={allImagesLoaded} />
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onOpenChange={(open) => {
              if (!open) setIsModalOpen(false);
              if (selectedItem) setSelectedItem(null);
              if (currentInventorySlot) setCurrentInventorySlot(null);
            }}
            className={cx(styles.inventoryModal, {
              [styles.disabledScroll]: !!selectedItem,
            })}
          >
            {!!selectedItem && !isMainModalOpen && (
              <PrizeModal
                prize={selectedItem}
                onClose={closeModal}
                isInventory
                isHeroInventory
                onModalButtonClick={onClick}
              />
            )}
            <HeroInventory
              setSelectedItem={setSelectedItem}
              items={items}
              isDataLoading={isDataLoading}
              lastItemElementRef={lastItemElementRef}
              page={page}
              inventory={inventory}
              handleItemClick={handleItemClick}
            />
          </Modal>
        )}
        {!!selectedItem && isMainModalOpen && (
          <PrizeModal
            prize={selectedItem}
            onClose={closeModal}
            isInventory
            isHeroInventory
            onModalButtonClick={onClick}
            isActive
          />
        )}
        {isInfoModalOpen && (
          <InfoModal handleCloseModal={handleCloseInfoModal} url={iframe} />
        )}
        {isNFTModalOpen && (
          <NftModal
            isOpen={isNFTModalOpen}
            setIsOpen={setIsNFTModalOpen}
            handleMint={handleMint}
          />
        )}
      </div>
    </>
  );
};

export default Hero;
