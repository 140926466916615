// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_row__zlKkY {
  z-index: 101;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 12px;
}

.styles_active__58daX {
  animation: styles_highlight__doQZW 1s ease-in-out infinite alternate;
  box-shadow: 0 0 20px rgba(255, 255, 0, 0.8);
  z-index: 1000;
}

@keyframes styles_highlight__doQZW {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 30px rgb(255, 255, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ForHeroes/HeroCardActions/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,SAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,oCAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,oEAAA;EACA,2CAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,2CAAA;EACF;EACA;IACE,qCAAA;EACF;AACF","sourcesContent":[".row {\n  z-index: 101;\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 0;\n  width: min-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  background-color: rgba(0, 0, 0, 0.5);\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\n  padding: 12px;\n  border-radius: 12px;\n}\n\n.active {\n  animation: highlight 1s ease-in-out infinite alternate;\n  box-shadow: 0 0 20px rgba(255, 255, 0, 0.8);\n  z-index: 1000;\n}\n\n@keyframes highlight {\n  0% {\n    box-shadow: 0 0 10px rgba(255, 255, 0, 0.4);\n  }\n  100% {\n    box-shadow: 0 0 30px rgba(255, 255, 0, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `styles_row__zlKkY`,
	"active": `styles_active__58daX`,
	"highlight": `styles_highlight__doQZW`
};
export default ___CSS_LOADER_EXPORT___;
