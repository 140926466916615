import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/i18n";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { InitApp } from "./AppContainer/InitApp";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/discover\.pathgame\.app/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_RELEASE_LEVEL,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        error?.isAxiosError &&
        [400, 401, 404].includes(error.response?.status)
      ) {
        return null;
      }
      if (error?.message === "Network Error") {
        return null;
      }
      const tonSdkErrorMessages = [
        "[TON_CONNECT_SDK_ERROR] Pj Operation aborted",
        "[TON_CONNECT_SDK_ERROR] Pj Aborted after attempts 1",
        "[TON_CONNECT_SDK_ERROR] VL Operation aborted",
      ];
      if (tonSdkErrorMessages.includes(error?.message)) {
        return null;
      }
      if (
        error?.message?.includes(
          "Unable to retrieve launch parameters from any known source",
        )
      ) {
        return null;
      }
      if (error?.isAxiosError && error?.message === "timeout exceeded") {
        return null;
      }
      return event;
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <InitApp />
  </Provider>,
);
