// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomToast_toastContainer__81EkK {
  width: auto !important;
}

.CustomToast_toast__g1z5r {
  background: linear-gradient(135deg, #2c3e50, #4ca1af);
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  min-height: 60px;
  display: flex;
  align-items: center;
}

.CustomToast_body__HVq23 {
  font-size: 16px;
}

.CustomToast_toast__g1z5r > svg {
  margin-right: 12px;
}

.CustomToast_Toastify__progress-bar__reU1e {
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Toast/CustomToast.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,qDAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,uCAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".toastContainer {\n  width: auto !important;\n}\n\n.toast {\n  background: linear-gradient(135deg, #2c3e50, #4ca1af);\n  color: #fff;\n  border: 2px solid #fff;\n  border-radius: 10px;\n  padding: 16px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);\n  min-height: 60px;\n  display: flex;\n  align-items: center;\n}\n\n.body {\n  font-size: 16px;\n}\n\n.toast > svg {\n  margin-right: 12px;\n}\n\n.Toastify__progress-bar {\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": `CustomToast_toastContainer__81EkK`,
	"toast": `CustomToast_toast__g1z5r`,
	"body": `CustomToast_body__HVq23`,
	"Toastify__progress-bar": `CustomToast_Toastify__progress-bar__reU1e`
};
export default ___CSS_LOADER_EXPORT___;
