import React from "react";
import styles from "./styles.module.scss";
import { Caption, Text } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import TelegramButton from "../../components/common/TelegramButton/TelegramButton";
import paths from "../paths";

const GiveawayNoLeaders = ({ giveaway }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToReferral = () => {
    navigate(paths.referral);
  };

  return (
    <div className={`${styles.relative} ${styles.fadeIn}`}>
      <img
        className={`${styles.noLeadersImg} ${styles.fadeIn}`}
        alt={"noLeaders"}
        src={AsyncPreloadImages.NO_LEADERS_IN_GIVEAWAYS}
      />
      {giveaway.status !== "completed" && (
        <>
          <Text>{t("No Participants Yet")}</Text>
          <Caption>{t("Be the first by inviting a friend!")}</Caption>
          <TelegramButton onClick={navigateToReferral} className={styles.btn}>
            {t("invite_friend")}
          </TelegramButton>
        </>
      )}
    </div>
  );
};

export default GiveawayNoLeaders;
