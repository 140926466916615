// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__qiF3J {
  position: relative;
  text-align: center;
  color: var(--text-color);
  animation: styles_fadeIn__kXhcq 1s ease-in-out;
  flex-shrink: 0;
  margin-bottom: 24px;
  font-family: "Alegreya", serif !important;
  text-shadow: 2px 2px 4px #000;
  font-size: 1.7rem;
}

.styles_noMargin__Ho8TE {
  margin-bottom: unset;
}

.styles_titleContent__qrg8s {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_text__rzUYu {
  color: var(--text-color);
  text-align: center;
  font-weight: bold;
}

.styles_leftComponent__RPhfi,
.styles_rightComponent__Iu\\+mU {
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-shadow: 1px 1px 2px #000;
}

.styles_leftComponent__RPhfi {
  left: 16px;
}

.styles_rightComponent__Iu\\+mU {
  right: 16px;
}

@keyframes styles_fadeIn__kXhcq {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,wBAAA;EACA,8CAAA;EACA,cAAA;EACA,mBAAA;EACA,yCAAA;EACA,6BAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,wBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;;EAEE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,6BAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF","sourcesContent":[".title {\n  position: relative;\n  text-align: center;\n  color: var(--text-color);\n  animation: fadeIn 1s ease-in-out;\n  flex-shrink: 0;\n  margin-bottom: 24px;\n  font-family: \"Alegreya\", serif !important;\n  text-shadow: 2px 2px 4px #000;\n  font-size: 1.7rem;\n}\n\n.noMargin {\n  margin-bottom: unset;\n}\n\n.titleContent {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  color: var(--text-color);\n  text-align: center;\n  font-weight: bold;\n}\n\n.leftComponent,\n.rightComponent {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  color: var(--text-color);\n  text-shadow: 1px 1px 2px #000;\n}\n\n.leftComponent {\n  left: 16px;\n}\n\n.rightComponent {\n  right: 16px;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__qiF3J`,
	"fadeIn": `styles_fadeIn__kXhcq`,
	"noMargin": `styles_noMargin__Ho8TE`,
	"titleContent": `styles_titleContent__qrg8s`,
	"text": `styles_text__rzUYu`,
	"leftComponent": `styles_leftComponent__RPhfi`,
	"rightComponent": `styles_rightComponent__Iu+mU`
};
export default ___CSS_LOADER_EXPORT___;
