import React from "react";
import {
  Cell,
  Checkbox,
  Modal,
  Radio,
  Section,
} from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import styles from "../../SortModal/styles.module.scss";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";
import { useTranslation } from "react-i18next";
import {
  getAttributeOptions,
  getSortOptions,
} from "../../../../utils/imagesGetter";

const SortModalForQuest = ({
  isModalOpen,
  setIsModalOpen,
  applySorting,
  selectedOption,
  handleSetFavorites,
  favoritesOption,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (value) => {
    applySorting(value);
    setIsModalOpen(false);
  };

  const sortOptions = getSortOptions(t);
  const attributeOptions = getAttributeOptions(t);

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={(open) => {
        if (!open) setIsModalOpen(false);
      }}
      header={
        <ModalHeader
          className={styles.modalHeader}
          after={
            <ModalClose>
              <Icon28Close
                style={{ color: "var(--tgui--plain_foreground)" }}
                onClick={() => setIsModalOpen(false)}
              />
            </ModalClose>
          }
        />
      }
    >
      <Section header={t("General")}>
        <Cell
          className={styles.cell}
          Component="label"
          before={
            <Checkbox
              checked={favoritesOption === "enabled"}
              onChange={handleSetFavorites}
            />
          }
        >
          {t("Favorites First")}
        </Cell>
      </Section>
      <Section header={t("Sort by Overall Stats")}>
        {sortOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
      <Section header={t("By Attribute in Descending Order")}>
        {attributeOptions.map((option, index) => (
          <Cell
            className={styles.cell}
            key={index}
            Component="label"
            before={
              <Radio
                name="sortOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            multiline
          >
            {option.label}
          </Cell>
        ))}
      </Section>
    </Modal>
  );
};

export default SortModalForQuest;
