import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import { AsyncPreloadImages } from "../../../utils/asyncPreload";

const CauldronImage = ({ isReady, onClick, newHero }) => {
  const [isBrewing, setIsBrewing] = useState(false);

  useEffect(() => {
    if (newHero) {
      setIsBrewing(true);
      setTimeout(() => setIsBrewing(false), 3000);
    }
  }, [newHero]);

  return (
    <motion.div
      className={styles.cauldron}
      onClick={onClick}
      animate={isBrewing ? "brewing" : isReady ? "ready" : "idle"}
      variants={{
        idle: {},
        ready: {
          scale: [1, 1.05, 1],
          transition: {
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut",
          },
        },
        brewing: {
          rotate: [0, -5, 5, 0],
          transition: {
            duration: 0.5,
            repeat: Infinity,
            ease: "easeInOut",
          },
        },
      }}
    >
      <img
        src={AsyncPreloadImages.CAULDRON_IMAGE}
        alt={"cauldron"}
        className={styles.icon}
      />
    </motion.div>
  );
};

export default CauldronImage;
