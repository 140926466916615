import { useTelegram } from "./useTelegramHook";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import usePreloadImages from "./usePreloadImages";
import { AsyncPreloadImages } from "../../utils/asyncPreload";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import paths from "../../pages/paths";
import { useSocket } from "../../utils/socketContext";
import { eventHandlers } from "../../utils/eventHandlers";
import { useTranslation } from "react-i18next";
import { hideGuide } from "../../store/slices/guideSlice";

export const useDefaultLayoutHook = () => {
  const { tg } = useTelegram();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userChannel, lobbyChannel } = useSocket();
  usePreloadImages(AsyncPreloadImages);

  const hapticEnabled = useSelector((state) => state.telegram.hapticEnabled);
  const isInitialEnter = useSelector((state) => state.telegram.initialEnter);
  const isDarkScheme = tg.colorScheme === "dark";
  const tutorials = useSelector((state) => state.guide.tutorials);
  const isGuideVisible = useSelector((state) => state.guide.isGuideVisible);

  const [settingsButtonShown, setSettingsButtonShown] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    message: "",
    description: "",
    buttonText: "",
    onButtonClick: null,
  });

  useEffect(() => {
    if (tutorials?.length > 0 && !settingsButtonShown && tg.SettingsButton) {
      tg.SettingsButton.show();
      tg.SettingsButton.onClick(() => {
        if (hapticEnabled) tg.HapticFeedback.selectionChanged();
        navigate(paths.settings, { state: { from: pathname } });
      });
      setSettingsButtonShown(true);
    }
  }, [tutorials]);

  useEffect(() => {
    const context = {
      t,
      navigate,
      handleShowSnackbar,
      handleCloseSnackbar,
      dispatch,
    };

    const channels = { userChannel, lobbyChannel };

    eventHandlers.forEach(({ eventName, handler, channel: channelName }) => {
      const channel = channels[channelName];
      if (channel) {
        const boundHandler = (payload) => handler(payload, context);
        channel.on(eventName, boundHandler);
        handler._boundHandlers = handler._boundHandlers || [];
        handler._boundHandlers.push({ channel, boundHandler });
      }
    });

    return () => {
      eventHandlers.forEach(({ eventName, handler }) => {
        if (handler._boundHandlers) {
          handler._boundHandlers.forEach(({ channel, boundHandler }) => {
            channel.off(eventName, boundHandler);
          });
        }
      });
    };
  }, [userChannel, lobbyChannel]);

  useEffect(() => {
    const handleResize = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      const wrapper = document.querySelector(".wrapper");

      if (wrapper) {
        if (isPortrait) {
          wrapper.style.width = "100%";
          wrapper.style.height = "auto";
        } else {
          wrapper.style.width = "100%";
          wrapper.style.height = "100%";
        }
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const pathsToCheck = [
    paths.referral,
    paths.settings,
    paths.marketItem,
    paths.roulette,
    paths.heroDetail,
    paths.giveaway,
    paths.quest,
    paths.task,
  ];

  const isNoGameWrapper = pathsToCheck.some((path) =>
    matchPath({ path, end: true }, pathname),
  );

  const pathsToCheck2 = [
    paths.hero,
    paths.heroes,
    paths.profile,
    paths.inventory,
    paths.tasks,
    paths.transactions,
    paths.treasureOpen,
  ];

  const isNoGameInterface = pathsToCheck2.some((path) =>
    matchPath({ path, end: true }, pathname),
  );

  const isOnlyNavbar = pathname === paths.heroes;

  const isWithoutHeader = false;

  const handleShowSnackbar = (config) => {
    setSnackbarConfig(config);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseGuide = () => {
    dispatch(hideGuide());
  };

  return {
    isInitialEnter,
    isDarkScheme,
    isNoGameWrapper,
    isNoGameInterface,
    isOnlyNavbar,
    snackbarOpen,
    handleCloseSnackbar,
    snackbarConfig,
    isWithoutHeader,
    isGuideVisible,
    handleCloseGuide,
  };
};
