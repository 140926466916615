import React from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const PaginationControls = ({ page, hasMore, onNext, onPrev }) => (
  <>
    <button
      disabled={page === 1}
      onClick={onPrev}
      className={cx(styles.leftButton, {
        [styles.disabledButton]: page === 1,
      })}
    >
      <MdKeyboardArrowLeft size={20} />
    </button>
    <button
      disabled={!hasMore}
      onClick={onNext}
      className={cx(styles.rightButton, {
        [styles.disabledButton]: !hasMore,
      })}
    >
      <MdKeyboardArrowRight size={20} />
    </button>
  </>
);

export default PaginationControls;
