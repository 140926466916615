import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

const MiniCardTitle = ({ character }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const nameRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      if (nameRef.current.scrollWidth > 50) {
        setShouldAnimate(true);
      }
    }
  }, [character?.hero_name]);

  return (
    <div className={styles.heroTitle}>
      <img
        alt="class"
        src={character?.class_image_url}
        width="20px"
        height="20px"
      />
      <div className={cx(styles.name, { [styles.animate]: shouldAnimate })}>
        <span ref={nameRef}>{character?.hero_name}</span>
      </div>
      <span className={styles.level}>{Math.floor(character?.level)}</span>
    </div>
  );
};

export default MiniCardTitle;
