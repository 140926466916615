// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_segmentControl__WzHKY {
  display: flex;
  background: #3c2f2f;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.styles_controlItem__RVJde {
  flex: 1 1;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  color: var(--text-color);
  background: var(--button-bg);
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  text-shadow: 1px 1px 2px #000;
}

.styles_controlItem__RVJde.styles_active__V7GIK {
  background: var(--secondory-bg);
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/SegmentControl/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,SAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,wBAAA;EACA,4BAAA;EACA,YAAA;EACA,eAAA;EACA,uCAAA;EACA,6BAAA;AACF;;AAEA;EACE,+BAAA;EACA,cAAA;AACF","sourcesContent":[".segmentControl {\n  display: flex;\n  background: #3c2f2f;\n  border: 2px solid var(--border-color);\n  border-radius: 8px;\n  overflow: hidden;\n}\n\n.controlItem {\n  flex: 1;\n  text-align: center;\n  padding: 8px;\n  font-size: 14px;\n  color: var(--text-color);\n  background: var(--button-bg);\n  border: none;\n  cursor: pointer;\n  transition: background 0.3s ease-in-out;\n  text-shadow: 1px 1px 2px #000;\n}\n\n.controlItem.active {\n  background: var(--secondory-bg);\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmentControl": `styles_segmentControl__WzHKY`,
	"controlItem": `styles_controlItem__RVJde`,
	"active": `styles_active__V7GIK`
};
export default ___CSS_LOADER_EXPORT___;
