import React from "react";
import styles from "./styles.module.scss";
import { getGradientByClassName } from "../../../utils/imagesGetter";
import LoaderComponent from "../../common/LoaderComponent/LoaderComponent";
import cx from "classnames";

const HeroInventory = ({
  items,
  isDataLoading,
  lastItemElementRef,
  page,
  isInventory,
  inventory,
  handleItemClick,
}) => {
  if (isDataLoading && page === 1) {
    return (
      <div
        className={cx(styles.loader, { [styles.inventoryLoader]: isInventory })}
      >
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {inventory.map((_, index) => {
        const item = items[index];
        const isLastItem = index === items.length - 1;

        return (
          <div
            key={index}
            className={cx(styles.item, {
              [styles.disabledItem]: !!item?.user_hero && !isInventory,
            })}
            ref={isLastItem && item ? lastItemElementRef : null}
            style={
              item
                ? { backgroundImage: getGradientByClassName(item.rarity_level) }
                : {
                    backgroundImage: `radial-gradient(circle, rgb(204, 204, 204) 0%, black 100%)`,
                    filter: "brightness(50%)",
                  }
            }
            onClick={item ? () => handleItemClick(item) : undefined}
          >
            {item && (
              <>
                <img src={item?.item_image_url} alt={item.item_name} />
                <div className={styles.level}>{`Lv. ${item?.level}`}</div>
                {item?.class_image_url && (
                  <img
                    width={20}
                    height={20}
                    alt={"class"}
                    src={item?.class_image_url}
                    className={styles.classImageUrl}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HeroInventory;
