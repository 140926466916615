import React, { useState, useEffect } from "react";
import styles from "./QuestTimer.module.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import cx from "classnames";
import { calculateProgress } from "../../../utils/utils";

const QuestTimer = ({ startQuestTime, nextQuestTime, className }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const updateProgress = () => {
      const calculatedProgress = calculateProgress(
        startQuestTime,
        nextQuestTime,
      );
      setProgress(calculatedProgress);
    };

    updateProgress();
    const intervalId = setInterval(updateProgress, 1000);

    return () => clearInterval(intervalId);
  }, [startQuestTime, nextQuestTime]);

  return (
    <div className={cx(styles.progress, className)}>
      <CircularProgressbar
        value={progress}
        rotation={0.25}
        styles={buildStyles({
          pathColor: `rgba(94, 130, 55, 1)`,
          trailColor: "#3c2f2f",
          strokeLinecap: "butt",
        })}
      />
    </div>
  );
};

export default QuestTimer;
