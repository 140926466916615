import React, { useState } from "react";
import styles from "../../FiltersModal/styles.module.scss";
import Select from "react-select";
import Button from "../../../ui/Button/Button";
import LoaderComponent from "../../../ui/LoaderComponent/LoaderComponent";
import { customStyles } from "../../FiltersModal/selectStyles";
import { useQuestHeroesFiltersHook } from "./useQuestHeroesFiltersHook";
import CustomSheet from "../../../ui/Modal/Modal";

const FiltersModalForQuest = ({
  isModalOpen,
  setIsModalOpen,
  applyFilters,
  activeFilters,
}) => {
  const {
    t,
    clearAllFilters,
    handleFilterChange,
    handleHeroStatusChange,
    isLoading,
    filters,
    classOptions,
    rarityOptions,
    heroOptions,
  } = useQuestHeroesFiltersHook(activeFilters);

  const heroesOptions = [
    { value: "ALL", label: t("All") },
    { value: "ACTIVE", label: t("Active") },
    { value: "INACTIVE", label: t("Inactive") },
  ];

  const [disableDrag, setDisableDrag] = useState(false);

  const handleApplyFilters = () => {
    applyFilters(filters);
    setIsModalOpen(false);
  };

  return (
    <CustomSheet
      disableDrag={disableDrag}
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <div className={styles.content}>
        <div className={styles.filtersContent}>
          {isLoading ? (
            <div className={styles.noData}>
              <LoaderComponent />
            </div>
          ) : (
            <>
              <Select
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                options={heroesOptions}
                value={heroesOptions.find(
                  (option) => option.value === filters.hero_status,
                )}
                onChange={handleHeroStatusChange}
                styles={customStyles}
                isClearable={false}
                placeholder={t("All")}
                onMenuOpen={() => setDisableDrag(true)}
                onMenuClose={() => setDisableDrag(false)}
              />
              <Select
                noOptionsMessage={() => t("No options")}
                isClearable
                isSearchable={false}
                isMulti
                options={heroOptions}
                value={heroOptions.filter((option) =>
                  filters.hero_ids.includes(option.value),
                )}
                onChange={(value) => handleFilterChange("hero_ids", value)}
                styles={customStyles}
                placeholder={t("Hero")}
                onMenuOpen={() => setDisableDrag(true)}
                onMenuClose={() => setDisableDrag(false)}
              />
              <Select
                noOptionsMessage={() => t("No options")}
                isSearchable={false}
                isMulti
                options={rarityOptions}
                value={rarityOptions.filter((option) =>
                  filters.rarity_level_ids.includes(option.value),
                )}
                onChange={(value) =>
                  handleFilterChange("rarity_level_ids", value)
                }
                styles={customStyles}
                isClearable
                placeholder={t("Rarity")}
                onMenuOpen={() => setDisableDrag(true)}
                onMenuClose={() => setDisableDrag(false)}
              />
              <Select
                noOptionsMessage={() => t("No options")}
                isClearable
                isSearchable={false}
                isMulti
                options={classOptions}
                value={classOptions.filter((option) =>
                  filters.class_ids.includes(option.value),
                )}
                onChange={(value) => handleFilterChange("class_ids", value)}
                styles={customStyles}
                placeholder={t("Class")}
                onMenuOpen={() => setDisableDrag(true)}
                onMenuClose={() => setDisableDrag(false)}
              />
              <Button
                className={styles.applyButton}
                onClick={handleApplyFilters}
              >
                {t("Apply")}
              </Button>
            </>
          )}
          {!isLoading &&
            (filters.class_ids.length > 0 ||
              filters.hero_ids.length > 0 ||
              filters.rarity_level_ids.length > 0 ||
              filters.hero_status !== "ALL") && (
              <button
                onClick={clearAllFilters}
                className={styles.clearAllButton}
              >
                {t("Clear all")}
              </button>
            )}
        </div>
      </div>
    </CustomSheet>
  );
};

export default FiltersModalForQuest;
