import { getImageUrl } from "./constants";

const baseUrl = getImageUrl();
const profilesPath = `${baseUrl}profiles/`;
const cardsFrontPath = `${baseUrl}cards/front/`;
const cardsBackPath = `${baseUrl}cards/back/`;
const texturesFrontPath = `${baseUrl}cards/front/texture_`;
const iconsPath = `${baseUrl}icons/`;
const noDataPath = `${baseUrl}noData/`;
const leaderboardsPath = `${baseUrl}leaderboards/`;
const attributesPath = `${baseUrl}attributes/`;
const classesPath = `${baseUrl}classes/`;
const questsPath = `${baseUrl}quests/`;
const inventoryPath = `${baseUrl}inventory/`;

export const AsyncPreloadImages = {
  DIVINE_FRONT_MAIN: `${cardsFrontPath}divine.webp`,
  LEGENDARY_FRONT_MAIN: `${cardsFrontPath}legendary.webp`,
  ELITE_FRONT_MAIN: `${cardsFrontPath}elite.webp`,
  RARE_FRONT_MAIN: `${cardsFrontPath}rare.webp`,
  COMMON_FRONT_MAIN: `${cardsFrontPath}common.webp`,

  DIVINE_FRONT_PREVIEW: `${cardsFrontPath}divine_preview.webp`,
  LEGENDARY_FRONT_PREVIEW: `${cardsFrontPath}legendary_preview.webp`,
  ELITE_FRONT_PREVIEW: `${cardsFrontPath}elite_preview.webp`,
  RARE_FRONT_PREVIEW: `${cardsFrontPath}rare_preview.webp`,
  COMMON_FRONT_PREVIEW: `${cardsFrontPath}common_preview.webp`,

  DIVINE_BACK_MAIN: `${cardsBackPath}divine.webp`,
  LEGENDARY_BACK_MAIN: `${cardsBackPath}legendary.webp`,
  ELITE_BACK_MAIN: `${cardsBackPath}elite.webp`,
  RARE_BACK_MAIN: `${cardsBackPath}rare.webp`,
  COMMON_BACK_MAIN: `${cardsBackPath}common.webp`,

  COMMON_BACK_PREVIEW: `${cardsBackPath}common_preview.webp`,
  DIVINE_BACK_PREVIEW: `${cardsBackPath}divine_preview.webp`,
  ELITE_BACK_PREVIEW: `${cardsBackPath}elite_preview.webp`,
  LEGENDARY_BACK_PREVIEW: `${cardsBackPath}legendary_preview.webp`,
  RARE_BACK_PREVIEW: `${cardsBackPath}rare_preview.webp`,

  COMMON_FRONT_TEXTURE: `${texturesFrontPath}common.webp`,
  DIVINE_FRONT_TEXTURE: `${texturesFrontPath}divine.webp`,
  ELITE_FRONT_TEXTURE: `${texturesFrontPath}elite.webp`,
  RARE_FRONT_TEXTURE: `${texturesFrontPath}rare.webp`,
  LEGENDARY_FRONT_TEXTURE: `${texturesFrontPath}legendary.webp`,

  COMMON_FRONT_TEXTURE_PREVIEW: `${texturesFrontPath}common_preview.webp`,
  DIVINE_FRONT_TEXTURE_PREVIEW: `${texturesFrontPath}divine_preview.webp`,
  ELITE_FRONT_TEXTURE_PREVIEW: `${texturesFrontPath}elite_preview.webp`,
  LEGENDARY_FRONT_TEXTURE_PREVIEW: `${texturesFrontPath}legendary_preview.webp`,
  RARE_FRONT_TEXTURE_PREVIEW: `${texturesFrontPath}rare_preview.webp`,

  ASSASSIN_ICON: `${classesPath}assassin.webp`,
  BARD_ICON: `${classesPath}bard.webp`,
  GUARDIAN_ICON: `${classesPath}defender.webp`,
  MAGE_ICON: `${classesPath}mage.webp`,
  WARRIOR_ICON: `${classesPath}warrior.webp`,

  STRENGTH_ICON: `${attributesPath}strength.webp`,
  LUCK_ICON: `${attributesPath}luck.webp`,
  INTELLIGENCE_ICON: `${attributesPath}int.webp`,
  ENDURANCE_ICON: `${attributesPath}endurance.webp`,
  CHARISMA_ICON: `${attributesPath}charisma.webp`,
  AGILITY_ICON: `${attributesPath}agility.webp`,

  DIVINE_BACK: `${cardsBackPath}divine_preview.webp`,
  COMMON_BACK: `${cardsBackPath}common_preview.webp`,
  ELITE_BACK: `${cardsBackPath}elite_preview.webp`,
  LEGENDARY_BACK: `${cardsBackPath}legendary_preview.webp`,
  RARE_BACK: `${cardsBackPath}rare_preview.webp`,

  NO_REFERRAL_ICON: `${noDataPath}no_referral.webp`,
  NO_HEROES_ICON: `${noDataPath}no_heroes.webp`,
  NO_TREASURES_ICON: `${noDataPath}no_treasures.webp`,
  NO_GIVEAWAYS_IMG: `${noDataPath}no_giveaways.webp`,
  NO_LEADERS_IN_GIVEAWAYS: `${noDataPath}no_participants.webp`,
  NO_QUESTS: `${noDataPath}no_quests.webp`,

  LEADERBOARD_FIRST: `${leaderboardsPath}first.webp`,
  LEADERBOARD_SECOND: `${leaderboardsPath}second.webp`,
  LEADERBOARD_THIRD: `${leaderboardsPath}third.webp`,
  LEADERBOARD_WOOD: `${leaderboardsPath}wood.webp`,
  LEADERBOARD_STONE: `${leaderboardsPath}stone.webp`,

  REFERRAL_WHITE: `${profilesPath}profile_referral_white.webp`,
  REFERRAL_DARK: `${profilesPath}profile_referral_dark.webp`,

  MY_TREASURES_ICON: `${iconsPath}my_treasures.webp`,
  TRANSACTIONS_HISTORY_ICON: `${iconsPath}transactions_history.webp`,
  PURCHASED_ICON: `${iconsPath}purchased.webp`,
  TASKS_ICON: `${iconsPath}tasks.webp`,
  SETTINGS_ICON: `${iconsPath}settings.webp`,
  COMPLETED_QUESTS: `${iconsPath}completed_quests.webp`,
  LEVEL_ICON: `https://s3.pathgame.app/public/new/level.webp`,
  NOTCOIN_ICON: "https://s3.pathgame.app/public/static/coins/notcoin.webp",

  COMMON_QUEST: `${questsPath}common.webp`,
  DIVINE_QUEST: `${questsPath}divine.webp`,
  LEGENDARY_QUEST: `${questsPath}legendary.webp`,
  RARE_QUEST: `${questsPath}rare.webp`,
  ELITE_QUEST: `${questsPath}elite.webp`,

  BACK_ITEM: `${inventoryPath}back.webp`,
  CHEST_ITEM: `${inventoryPath}chest.webp`,
  FEET_ITEM: `${inventoryPath}feet.webp`,
  FINGER_1_ITEM: `${inventoryPath}finger_1.webp`,
  FINGER_2_ITEM: `${inventoryPath}finger_2.webp`,
  HANDS_ITEM: `${inventoryPath}hands.webp`,
  HEAD_ITEM: `${inventoryPath}head.webp`,
  LEGS_ITEM: `${inventoryPath}legs.webp`,
  MAIN_HAND_ITEM: `${inventoryPath}main_hand.webp`,
  NECK_ITEM: `${inventoryPath}neck.webp`,
  OFF_HAND_ITEM: `${inventoryPath}off_hand.webp`,
  SHOULDERS_ITEM: `${inventoryPath}shoulders.webp`,

  CAULDRON_IMAGE: `${baseUrl}cauldron.webp`,
};
