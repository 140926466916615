import React from "react";
import styles from "./styles.module.scss";
import { PreloadImages } from "../../../utils/globals";
import { CircularProgress, Image } from "@telegram-apps/telegram-ui";
import { formatAmount } from "../../../utils/formatNumbers";
import { calculateProgress } from "../../../utils/utils";

const GiveawayBanner = ({ handleGoToGiveaway, giveawayBanner }) => {
  return (
    <div
      className={styles.giveawayBanner}
      style={{
        background: `url(${PreloadImages.GIVEAWAY_BANNER}) no-repeat center center`,
      }}
      onClick={handleGoToGiveaway}
    >
      <div className={styles.bannerOverlay}>
        <Image className={styles.notcoinImg} src={giveawayBanner?.image_url} />
        <span className={styles.poolAmount}>
          {formatAmount(giveawayBanner?.pool_amount)}
        </span>
        <span className={styles.poolAmount}>
          {giveawayBanner?.pool_currency}
        </span>
        <div className={styles.progress}>
          <CircularProgress
            progress={calculateProgress(
              giveawayBanner?.started_at,
              giveawayBanner?.ended_at,
            )}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default GiveawayBanner;
