import React from "react";
import styles from "./styles.module.scss";
import Iframe from "react-iframe";

const InfoModal = ({ handleCloseModal, url }) => {
  const isDevelopment = process.env.REACT_APP_RELEASE_LEVEL === "development";

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={handleCloseModal}>
          &times;
        </button>
        <Iframe
          url={
            url
              ? url
              : isDevelopment
                ? "https://ebat-game-dev.ebat.me/wiki"
                : "https://pathgame.app/wiki"
          }
          className={styles.iframe}
          display="block"
          position="relative"
        />
      </div>
    </div>
  );
};

export default InfoModal;
