import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { getCardImagesByRarity } from "../../../utils/imagesGetter";
import Favorite from "../../ui/Favorite/Favorite";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MiniCardImages = ({
  character,
  onAllImagesLoaded,
  isQuests,
  isCauldron,
  isHide,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState({
    hero: false,
    texture: false,
    miniCard: false,
  });

  const handleImageLoad = (imageName) => {
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  useEffect(() => {
    if (isImageLoaded.hero && isImageLoaded.texture && isImageLoaded.miniCard) {
      onAllImagesLoaded?.();
    }
  }, [isImageLoaded]);

  const handleImageError = (imageName) => {
    setIsImageLoaded((prev) => ({
      ...prev,
      [imageName]: false,
    }));
  };

  return (
    <div
      className={cx(styles.miniCard, {
        [styles.isMinting]: !!character?.mint_status,
      })}
    >
      {character.favorite && (
        <Favorite isCauldron={isCauldron} isQuests={isQuests} isHide={isHide} />
      )}
      <LazyLoadImage
        alt="hero"
        src={character?.hero_preview_url}
        className={cx(styles.heroImage, {
          [styles.hidden]: !isImageLoaded.hero,
        })}
        onLoad={() => handleImageLoad("hero")}
        onError={() => handleImageError("hero")}
      />
      <img
        alt="texture"
        src={
          getCardImagesByRarity(character?.rarity_level).FRONT.TEXTURE_PREVIEW
        }
        className={cx(styles.heroTexture, {
          [styles.hidden]: !isImageLoaded.texture,
        })}
        onLoad={() => handleImageLoad("texture")}
        onError={() => handleImageError("texture")}
      />
      <img
        alt="miniCard"
        src={getCardImagesByRarity(character?.rarity_level).FRONT.PREVIEW}
        className={cx(styles.heroMiniCard, {
          [styles.hidden]: !isImageLoaded.miniCard,
        })}
        onLoad={() => handleImageLoad("miniCard")}
        onError={() => handleImageError("miniCard")}
      />
    </div>
  );
};

export default MiniCardImages;
