// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_giveawayBanner__N7CCd {
  justify-content: space-around;
  background-size: cover !important;
  border-radius: 8px;
  color: #000;
  text-shadow: -1px -1px 0 #fff;
  font-size: 0.7rem;
  cursor: pointer;
  opacity: 0;
  animation: styles_fadeInAnimation__26LDc 1s forwards;
  height: 48px;
}

.styles_bannerOverlay__8Gipe {
  padding: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.4);
}

.styles_giveawayBanner__N7CCd {
  justify-content: space-around;
  background-size: cover !important;
}

.styles_poolAmount__KxRGf {
  font-size: 1rem;
  color: var(--tg-theme-link-color);
  text-shadow: -0.5px -0.5px 0 #000;
  font-weight: bold;
  text-align: center;
}

.styles_progress__0v2YA > svg {
  transform: scaleX(-1) rotate(-90deg);
}

.styles_notcoinImg__0cQ6s {
  background: unset;
  box-shadow: unset;
  border-radius: unset;
}

@keyframes styles_fadeInAnimation__26LDc {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ForMainPage/GiveawayBanner/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,iCAAA;EACA,kBAAA;EACA,WAAA;EACA,6BAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;EACA,oDAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,6BAAA;EACA,oCAAA;AACF;;AAEA;EACE,6BAAA;EACA,iCAAA;AACF;;AAEA;EACE,eAAA;EACA,iCAAA;EACA,iCAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,iBAAA;EACA,iBAAA;EACA,oBAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF","sourcesContent":[".giveawayBanner {\n  justify-content: space-around;\n  background-size: cover !important;\n  border-radius: 8px;\n  color: #000;\n  text-shadow: -1px -1px 0 #fff;\n  font-size: 0.7rem;\n  cursor: pointer;\n  opacity: 0;\n  animation: fadeInAnimation 1s forwards;\n  height: 48px;\n}\n\n.bannerOverlay {\n  padding: 4px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  justify-content: space-around;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n.giveawayBanner {\n  justify-content: space-around;\n  background-size: cover !important;\n}\n\n.poolAmount {\n  font-size: 1rem;\n  color: var(--tg-theme-link-color);\n  text-shadow: -0.5px -0.5px 0 #000;\n  font-weight: bold;\n  text-align: center;\n}\n\n\n.progress > svg {\n  transform: scaleX(-1) rotate(-90deg);\n}\n\n.notcoinImg {\n  background: unset;\n  box-shadow: unset;\n  border-radius: unset;\n}\n\n@keyframes fadeInAnimation {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giveawayBanner": `styles_giveawayBanner__N7CCd`,
	"fadeInAnimation": `styles_fadeInAnimation__26LDc`,
	"bannerOverlay": `styles_bannerOverlay__8Gipe`,
	"poolAmount": `styles_poolAmount__KxRGf`,
	"progress": `styles_progress__0v2YA`,
	"notcoinImg": `styles_notcoinImg__0cQ6s`
};
export default ___CSS_LOADER_EXPORT___;
